import React, { Component } from 'react';
import Spinner from './../others/Spinner';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import { Link } from "react-router-dom";
import _ from 'underscore';
import formatString from '../../utilities/format-string';
import Lightbox from 'react-image-lightbox-rotate-latest';

class GroupDocuments extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: false,
            documentsClients: [],
            imagesUrlIndex: 0,
            isOpenPhoto: false,
            currentDocument: {},
        }
    }

    UNSAFE_componentWillMount = () => {
        this.getResource();
    }

    getResource = async () => {

        this.setState({
            loading: true
        });

        const params = new URLSearchParams({
            keys: 'Documentos,Documentos.nombre,Documentos.type,Documentos.URL,Documentos.Socio.nombre,Documentos.Socio.apellido_paterno,Documentos.Socio.apellido_materno',
            include: 'Documentos,Documentos.Socio',
        });
        const res = await Rest.peticion(`${config.api_url}/classes/Grupos/${this.props.match.params.id_grupo}?${params.toString()}`, {
            method: 'GET',
        }).then(res => {return res.json()}).then(res => {return res;}).catch(error => {return error});

        if (_.isEmpty(res.code) && _.isEmpty(res.error)) {
            const filter = _.filter(res.Documentos, documento => {
                return !(documento.type === 'fotogrupal' || documento.type === 'firma')
            });
            const groups = _.groupBy(filter, item => {
                return item.Socio.objectId
            })
            this.setState({
                documentsClients: _.values(groups),
            })
            
        } else {
            
            alert('No se ha podido obtener los documentos');
        
        }

        this.setState({
            loading: false
        })
        
    }

    setLabelDoc = (type) => {
        switch (type) {
            case "actanacimiento":
                return 'Acta de nacimiento';
            case "curp":
                return 'CURP';
            case "credencialreverso":
                return 'INE (atrás)';
            case "credencialanverso":
                return 'INE (frente)';
            case "comprobantedomicilio":
                return 'Comprobante de domicilio';
            default:
                return 'Sin especificar';
        }
    }

    setViewDocument = (indexClient, indexDocument) => {
        this.setState({
            isOpenPhoto: true,
            currentDocument: this.state.documentsClients[indexClient][indexDocument]
        });
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />
                <table className="table">
                    <thead>
                        <tr>
                            <th>Documentos</th>
                            <th></th>
                        </tr>
                    </thead>
                    <>
                        {
                            this.state.documentsClients.map((documentsClient, indexDsC) => <tbody key={`tbody-${indexDsC}`} id={`tbody-${indexDsC}`}>
                                <tr key={documentsClient[0].Socio.objectId} id={documentsClient[0].Socio.objectId}>
                                    <th>{documentsClient[0].Socio.nombre} {documentsClient[0].Socio.apellido_paterno} {documentsClient[0].Socio.apellido_materno} </th>
                                    <th></th>
                                </tr>
                                {documentsClient.map((document, indexDs) => <>
                                    <tr key={document.objectId} id={document.objectId}>
                                        <td>{this.setLabelDoc(document.type)}</td>
                                        <td className="text-right">
                                            <button onClick={() => this.setViewDocument(indexDsC, indexDs)} className="btn btn-link" title="Abrir" target="_blank">
                                                <i className="fas fa-eye"></i> Ver documento
                                            </button>
                                        </td>
                                    </tr>
                                </>)}
                            </tbody>)
                        }
                    </>
                </table>
                {this.state.isOpenPhoto && (
                    <Lightbox
                        mainSrc={`${config.domain}${this.state.currentDocument.URL}`}
                        onCloseRequest={() => this.setState({isOpenPhoto: false})}
                        imageTitle={`${this.setLabelDoc(this.state.currentDocument.type)} de ${this.state.currentDocument.Socio.nombre} ${this.state.currentDocument.Socio.apellido_paterno} ${this.state.currentDocument.Socio.apellido_materno}`}
                    />
                )}
            </>
        );
    }
}

export default GroupDocuments;