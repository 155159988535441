import React, { Component } from 'react';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import _ from 'underscore';

class SelectBranchOffice extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collection: [],
            value: props.defaultValue || '',
        }

        if(this.props.placeholder) {
            this.placeholder = this.props.placeholder;
        }

        if(this.props.onlyAccountedFor) {
            this.onlyAccountedFor = this.props.onlyAccountedFor;
        }

        this.handleChange     = this.handleChange.bind(this);

    }

    placeholder = 'Elija una opción...';
    onlyAccountedFor = undefined;

    UNSAFE_componentWillMount = async () => {
        const params = new URLSearchParams({
            where: JSON.stringify({
                contabilizar: this.onlyAccountedFor
            }),
            order: 'nombre',
            limit: 1000,
            count: 1
        });
        const branchOffices = await Rest.peticion(`${config.api_url}/classes/Sucursales?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {return res.json()})
        .then(res => {return res})
        .catch(error => console.log(error));

        if (_.isEmpty(branchOffices.code) && _.isEmpty(branchOffices.error)) {
            this.setState({
                collection: branchOffices.results
            })
        } else {
            console.log('Ocurrió un error al obtener las sucursales');
            
        }
        
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        if (nextProps.defaultValue) {
            this.setState({
                value: nextProps.defaultValue || ''
            });
        } else {
            if (_.isObject(nextProps.value) && !_.isEmpty(nextProps.value)) {
                this.setState({
                    value: nextProps.value.objectId
                })
            } else if (!_.isEmpty(nextProps.value)) {
                this.setState({
                    value: nextProps.value
                })
            } else {
                this.setState({
                    value: ''
                })
            }
        }
         
        
        
    }

    setLabel = () => {
        const branchOffices = _.findWhere(this.state.collection, {
            objectId: this.state.value
        });
        if (!_.isEmpty(branchOffices)) {
            return branchOffices.nombre;
        } else {
            return '';
        }
        
    }

    handleChange = !this.props.disabled && this.props.onChange || (() => {console.log('sin permiso');});

    setOption = (item, index) => <option key={index} value={item.objectId}>{item.nombre}</option>;

    render() {
        return (<>
            {!this.props.readOnly && (this.props.disabled === true && <div className="form-control text-uppercase" style={{backgroundColor: '#e9ecef'}}>{this.setLabel()}</div> || <select className="form-control text-uppercase" name={this.props.name} id={this.props.id} value={this.state.value} onChange={this.handleChange} disabled={this.props.disabled} required={this.props.required}>
                <option value="">{this.placeholder}</option>
                {
                    (this.state.collection && this.state.collection.length) && this.state.collection.map((item, index) => 
                        this.setOption(item, index)
                    )
                }
            </select>)}
            {this.props.readOnly && <p>{this.setLabel()}</p>}
        </>);
    }
}

export default SelectBranchOffice;