import React, { Component } from 'react';
import Rest from '../../Server/Rest';
import config from './../../settings/config';
import _ from 'underscore';
import Popover from './../others/Popover';

class GroupPaymentsCheck extends Component {
    constructor(props){
        super(props);
        if (!_.isEmpty(this.props.pagosRealizados)) {
            
            this.state.paymentsMade = this.props.pagosRealizados;
        }
    }
    state = {
        paymentsMade: []
    }
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (!_.isEmpty(nextProps.amountId)) {
            const params = new URLSearchParams({
                limit: 1000,
                where: JSON.stringify({
                    SolicitudMonto: {
                        __type: 'Pointer',
                        className: 'SolicitudesMontos',
                        objectId: nextProps.amountId
                    }
                }),
                count: 1,
                order: '-createdAt',
                include: 'registrado_por'
            });
            
            Rest.peticion(`${config.api_url}/classes/SolicitudesPagosRealizados?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                this.setState({
                    paymentsMade: res.results || []
                })
            })
            .catch(error => {
                
                console.log('Ha ocurrido un error inesperado. Intente más tarde.');
            });
        }
    }

    setTotal = (payments) => {
        let total = 0.0;
        _.each(payments, payment => {
            total += payment.monto_pagado;
        });
        return new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( total );
    }

    setTotalEquivalentPayments = (payments) => {
        let total = 0;
        _.each(payments, payment => {
            total += payment.pagos_adelantados || 0;
        });
        return total;
    }

    render() {
        return (
            <>
                {this.state.paymentsMade.length> 0 && <div>
                    <table className="table m-0 text-muted">
                        <thead>
                            <tr>
                                <th className="border-top-0">#</th>
                                <th className="border-top-0">Registrado al</th>
                                <th className="border-top-0">Registrado por</th>
                                <th className="border-top-0">Monto pagado</th>
                                <th className="border-top-0">Pagos<br/>adelantados
                                    {!(this.props.is_print === 'true') &&<Popover title="Pagos adelantados" content="Muestra la cantidad de pagos adelantados en el abono realizado."/>}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.paymentsMade.map((payment, index) => <tr key={index}>
                                <td>{index+1}</td>
                                <td>{new Date(payment.pagado_al.iso).toLocaleDateString()}</td>
                                <td className="text-uppercase">{`${payment.registrado_por.nombre} ${payment.registrado_por.apellidos}`}</td>
                                <td>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( payment.monto_pagado )}</td>
                                <td>{payment.pagos_adelantados||'0'}</td>
                            </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <th className="text-right">Totales</th>
                                <th>{this.state.paymentsMade.length > 0 && this.setTotal(this.state.paymentsMade)}</th>
                                <th>{this.state.paymentsMade.length > 0 && this.setTotalEquivalentPayments(this.state.paymentsMade)}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div> || <div className="alert alert-warning text-center" role="alert">
                    Sin pagos registrados
                </div>}
            </>
        );
    }
}

export default GroupPaymentsCheck;