import React, { Component } from 'react';
import requisitions_service from '../../services/requisitions';
import _ from 'underscore';
import formatString from '../../utilities/format-string';

class GroupPaymentFormSingle extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            [`pago_tipo_${this.props.id}`]: '',
            abono_cantidad: 0.0
        }
        
        this.handleAmountOption = this.handleAmountOption.bind(this);
        this.handleAmountDeposit = this.handleAmountDeposit.bind(this);
    }

    pago_a_restar = 0.0;

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.resetform === true) {
            this.setState({
                [`pago_tipo_${this.props.id}`]: '',
                abono_cantidad: 0.0
            })
        }
    }

    handleAmountOption = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState((state) => ({
            [name]: value,
            abono_cantidad: name === `pago_tipo_${this.props.id}` ? 0.0 : state.abono_cantidad
        }));

        setTimeout(() => {
            this.props.handleAmount();
        }, 100);
        return true;
    }

    handleAmountDeposit = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        setTimeout(() => {
            this.props.handleAmount();
        }, 100);
        return true;
        
    };

    getTotalPayment = requisitions_service.getTotalPayment;

    currentDebt = (payments, format = true) => {
        let monto_acumulado = this.props.amount.monto_acumulado || 0.0;

        let debt = this.getTotalPayment(payments);
        const result = debt - monto_acumulado;
        if (format) {
            if (result < 0) { // cuando tiene saldo a favor
                return <b>{Math.abs(result).toFixed(2)}</b>
            } else if (result > 0) { // cuando tiene deuda
                return <span className={(this.state[`pago_tipo_${this.props.id}`] === 'pagorecibo')?'text-white':'text-danger'}>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( result )}</span>;
            } else { // cuando no debe nada
                return '$0';
            }
        } else {
            if (result < 0) { // cuando tiene saldo a favor
                return 0;        
            } else if (result > 0) { // cuando tiene deuda
                return result;
            } else { // cuando no debe nada
                return 0;
            }
        }
    }

    setPaymentDate = () => {
        const {amount} = this.props;
        if (!_.isEmpty(amount.ultima_fecha_pago)) {
            return formatString.setLegibleDate(amount.ultima_fecha_pago.iso);
        } else {
            return 'Sin fecha registrada'
        }
    }

    setEquivalentPayments = (currentDebt, abono_cantidad = 0, monto_recurrente, payments) => {
        abono_cantidad = parseFloat(abono_cantidad);

        // Si hay cantidad especificada
        if (abono_cantidad) {
            // si no hay deuda acumulada dividir el abono por el monto recurrente
            if (currentDebt === 0) {
                return Math.floor(abono_cantidad/monto_recurrente);
            } else {
                // determinar si la cantidad abonada es mayor a la deuda para
                // calcular los pagos adelantados
                const res = abono_cantidad - currentDebt;
                if (res > 0) {
                    return Math.floor(res/monto_recurrente);
                } else {
                    return 0;
                }
            }
        } else {
            return 0;
        }
        
    }

    getPaymentId = (payments) => {
        let _payment = '';
        let 
        currentFinalHour = new Date();
        currentFinalHour.setHours(24);
        currentFinalHour.setMinutes(0);
        currentFinalHour.setSeconds(0);
        currentFinalHour.setMilliseconds(0);
        currentFinalHour = currentFinalHour.getTime();
        _.each(payments, payment => {
            if (new Date(payment.fecha_pago.iso).getTime() <= currentFinalHour) {
                _payment = payment;
            }
        });
        if (_.isEmpty(_payment)) {
            _payment = payments[0];
        }
        return _payment.objectId;
    }

    render() {

        const {payments, amount, paymentsMade} = this.props;
        const monto_recurrente = payments[0].monto;
        const currentDebt = this.currentDebt(payments, false);
        const equivalentPayments = this.setEquivalentPayments(currentDebt, this.state.abono_cantidad, monto_recurrente, payments);

        const totalAmountPayable = _.reduce(payments, function(memo, item){ return memo + item.monto; }, 0);
        const totalPaymentsMade = _.reduce(paymentsMade, function(memo, item){ return memo + item.monto_pagado; }, 0);
        const balanceDue = totalAmountPayable - totalPaymentsMade;
        
        return (
            <>
                <td>
                    {this.setPaymentDate()}
                </td>
                <td>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( monto_recurrente )}</td>
                <td className="w-25">
                    {!(this.props.is_print === 'true') &&
                    <label className={
                        `btn ${(this.state[`pago_tipo_${this.props.id}`] === 'pagorecibo')?'btn-success':'btn-secondary'} 
                         btn-block m-0
                         ${currentDebt === 0 ? 'disabled' : ''}`}>
                        <input type="hidden" name="payment" value={`${amount.objectId}_${this.getPaymentId(payments)}_${this.state[`pago_tipo_${this.props.id}`]}_${currentDebt}_${this.state.abono_cantidad}_${equivalentPayments}`}/>
                        <input 
                        className="d-none input-receipt-amount"
                        type="checkbox" 
                        onChange={this.handleAmountOption}
                        value="pagorecibo"
                        data-amount={currentDebt}
                        name={`pago_tipo_${this.props.id}`}
                        checked={this.state[`pago_tipo_${this.props.id}`] === 'pagorecibo'}
                        disabled={currentDebt === 0}/>
                        {this.currentDebt(payments)}
                    </label> || new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( currentDebt )}
                </td>
                {this.props.is_print === 'true' && <td>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(totalAmountPayable)}</td>}
                {this.props.is_print === 'true' && <td>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(totalPaymentsMade)}</td>}
                {this.props.is_print === 'true' && <td>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(balanceDue)}</td>}
                <td>
                    {!(this.props.is_print === 'true') &&
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <label className={`btn ${(this.state[`pago_tipo_${this.props.id}`] === 'abono')?'btn-success':'btn-secondary'} m-0`}>
                                <input 
                                className="d-none"
                                type="checkbox" 
                                onChange={this.handleAmountOption}
                                value="abono"
                                checked={this.state[`pago_tipo_${this.props.id}`] === 'abono'} 
                                name={`pago_tipo_${this.props.id}`}/>
                                Abonar
                            </label>
                        </div>
                        <input className="form-control" 
                        type="number" 
                        name="abono_cantidad" 
                        min="0.0" 
                        step="any"
                        value={this.state.abono_cantidad} 
                        onChange={this.handleAmountDeposit} 
                        pattern="^[0-9]+"
                        disabled={!(this.state[`pago_tipo_${this.props.id}`] === 'abono')}/>
                    </div>
                    }
                </td>
                <td className="pr-0">
                    {!(this.props.is_print === 'true') &&
                    <span className="form-control border-0">
                        {equivalentPayments}
                    </span>}
                </td>
            </>
        );
    }
}

export default GroupPaymentFormSingle;