import React, { Component } from 'react';
import _ from 'underscore';
import Parse from 'parse';
import config from './../../settings/config';
import user from './../../settings/user';
import Rest from './../../Server/Rest';
import $ from 'jquery';
import Spinner from '../others/Spinner';

class GroupStatusForm extends Component {
    constructor(props){
        super(props);
        this.handleEndCycle = this.handleEndCycle.bind(this);

        
        this.state = this.initState();
    }


    initState = () => {
        const state = {
            loading: false,
            status: '',
            status_comentario: '',
            status_socios: []
        }
        return state;
    }

    UNSAFE_componentWillReceiveProps = (props) => {
        this.setState(this.initState());
        if (!_.isEmpty(props.requisition)) {
            const status_socios = _.map(props.requisition.Grupo.Socios, socio => {
                return {
                    objectId: socio.objectId,
                    status: '',
                    status_comentario: ''
                }
            });
            this.setState({
                status: '',
                status_comentario: '',
                status_socios: status_socios
            })
        }
    }

    componentDidMount = () => {
        $('#modaEndCycle').on('hidden.bs.modal', function (e) {
            $('.modal').modal('hide');
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeStatusSocios = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const name_split = name.split(',');
        let {status_socios} = this.state;
        status_socios[name_split[1]][name_split[0]] = value;
        
        this.setState({
            status_socios: status_socios
        });
    }

    handleEndCycle = (event) => {

        if (event) event.preventDefault();

        const r = window.confirm('¿Los cambios son correctos?');
        
        if (r === true) {

            const currentUser = Parse.User.current().toJSON();

            const fecha_actual = {
                __type: 'Date',
                iso: new Date().toISOString()
            };

            let requests = [];

            requests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Grupos/${this.props.requisition.Grupo.objectId}`,
                body: {
                    status: this.state.status,
                    status_comentario: _.isEmpty(this.state.status_comentario) ? {__op:'Delete'} : this.state.status_comentario.toUpperCase(),
                }
            });

            requests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Solicitudes/${this.props.requisition.objectId}`,
                body: {
                    fecha_finalizacion: fecha_actual,
                    finalizado_por: {
                        __type: 'Pointer',
                        className: '_User',
                        objectId: currentUser.objectId
                    },
                    status: 'baja',
                    status_comentario: _.isEmpty(this.state.status_comentario) ? {__op:'Delete'} : this.state.status_comentario.toUpperCase(),
                }
            });

            _.each(this.state.status_socios, socio => {
                socio.status_comentario = socio.status_comentario.toUpperCase();
                requests.push({
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Socios/${socio.objectId}`,
                    body: {
                        status: socio.status,
                        status_comentario: socio.status_comentario
                    }
                });
                let old_socio = _.findWhere(this.props.requisition.Grupo.Socios, {objectId: socio.objectId});
                
                let _data_status = {};

                old_socio.status = old_socio.status.toUpperCase();
                if (!_.isEqual(old_socio.status, socio.status)) {
                    _data_status.status = old_socio.status;
                }

                if (!_.isEmpty(old_socio.status_comentario)) {
                    old_socio.status_comentario = old_socio.status_comentario.toUpperCase();
                    if (!_.isEqual(old_socio.status_comentario, socio.status_comentario)) {
                        _data_status.status_comentario = old_socio.status_comentario;
                    }
                }

                if (!_.isEmpty(_data_status)) {
                    requests.push({
                        method: 'POST',
                        path: `${config.api_path2}/classes/SociosHistorial`,
                        body: {
                            datos_anteriores: _data_status,
                            afectado_por: currentUser.nombre+' '+currentUser.apellidos,
                            usuario_email: currentUser.email,
                            usuario_telefono: currentUser.telefono,
                            grupo_datos: 'datos-personales',
                            Rol: {
                                __type: 'Pointer',
                                className: 'Roles',
                                objectId: user.rol.objectId
                            },
                            User: {
                                __type: 'Pointer',
                                className: '_User',
                                objectId: currentUser.objectId
                            },
                            Socio: {
                                __type: 'Pointer',
                                className: 'Socios',
                                objectId: socio.objectId
                            }
                        }
                    })
                }
                
            });

            this.setState({
                loading: true
            });

            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify({
                    requests: requests
                })
            }).then(res => {
                return res.json();
            }).then(res => {
                if (res.code || res.error) {
                    alert('Ha ocurrido un error al intentar cambiar el status del grupo');
                } else {
                    $('.modal').modal('hide');
                    this.setState(this.initState());
                    this.props.handleFilter();
                    alert('El status del grupo ha sido actualizado');
                }
            }).catch(error => {
                console.log(error);
                return error;
            }).then(() => {
                this.setState({
                    loading: false
                });
            });
        }

    }

    render() {
        return (
            <div className="modal fade" id="modaEndCycle" tabIndex="-1" role="dialog" aria-labelledby="modaEndCycle" aria-hidden="true">
                <Spinner loading={this.state.loading}/>
                <div className="modal-dialog">
                    <form onSubmit={this.handleEndCycle} className="modal-content mb-0">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modaEndCycle">Finalizar ciclo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="card-body">
                            CICLO: {this.props.requisition.ciclo}
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Status de grupo</label>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name="status" id="status_ACTIVO" value="activo" checked={this.state.status==='activo'} onChange={this.handleChange} required />
                                                <label className="form-check-label" htmlFor="status_ACTIVO">
                                                    Activo
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name="status" id="status_BAJA" value="baja" checked={this.state.status==='baja'} onChange={this.handleChange} required />
                                                <label className="form-check-label" htmlFor="status_BAJA">
                                                    Baja
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name="status" id="status_RECHAZADO" value="rechazado" checked={this.state.status==='rechazado'} onChange={this.handleChange} required />
                                                <label className="form-check-label" htmlFor="status_RECHAZADO">
                                                    Rechazado
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name="status" id="status_MOROSIDAD" value="morosidad" checked={this.state.status==='morosidad'} onChange={this.handleChange} required />
                                                <label className="form-check-label" htmlFor="status_MOROSIDAD">
                                                    Morosidad
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label htmlFor="status_comentario">Escribe un comentario (Opcional)</label>
                                                <textarea className="form-control text-uppercase" id="status_comentario" name="status_comentario" value={this.state.status_comentario} onChange={this.handleChange} rows="3"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <br />
                            
                            <label><b>Modifica el status de las clientas del grupo</b></label>
                        
                            {(!_.isEmpty(this.props.requisition.Grupo) && this.props.requisition.Grupo.Socios.length) && this.props.requisition.Grupo.Socios.map((socio, index) => this.state.status_socios[index] && <div className="card mb-3" key={index}>
                                <div className="card-body">
                                    <p>
                                        {socio.nombre} {socio.apellido_paterno} {socio.apellido_materno}
                                    </p>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Status</label>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name={['status',index]} id={`status_activo_${index}`} value="ACTIVO" checked={this.state.status_socios[index].status === 'ACTIVO'} onChange={this.handleChangeStatusSocios} required />
                                                <label className="form-check-label" htmlFor={`status_activo_${index}`}>
                                                    Activa
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_${index}`} value="BAJA_VOLUNTARIA" checked={this.state.status_socios[index].status === 'BAJA_VOLUNTARIA'} onChange={this.handleChangeStatusSocios} required />
                                                <label className="form-check-label" htmlFor={`status_baja_${index}`}>
                                                    Baja voluntaria
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_castigada_${index}`} value="BAJA_CASTIGADA" checked={this.state.status_socios[index].status === 'BAJA_CASTIGADA'} onChange={this.handleChangeStatusSocios} />
                                                <label className="form-check-label" htmlFor={`status_baja_castigada_${index}`}>
                                                    Baja castigada
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_rechazada_${index}`} value="RECHAZADO" checked={this.state.status_socios[index].status === 'RECHAZADO'} onChange={this.handleChangeStatusSocios} required />
                                                <label className="form-check-label" htmlFor={`status_baja_rechazada_${index}`}>
                                                    Rechazada
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_morocidad_${index}`} value="MOROSIDAD" checked={this.state.status_socios[index].status === 'MOROSIDAD'} onChange={this.handleChangeStatusSocios} />
                                                <label className="form-check-label" htmlFor={`status_baja_morocidad_${index}`}>
                                                    Morosidad
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_fallecida_${index}`} value="FALLECIDA" checked={this.state.status_socios[index].status === 'FALLECIDA'} onChange={this.handleChangeStatusSocios} required />
                                                <label className="form-check-label" htmlFor={`status_baja_fallecida_${index}`}>
                                                    Fallecida
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <label htmlFor={`status_comentario${index}`}>Comentario del status
                                                {(this.state.status_socios[index].status === 'BAJA_VOLUNTARIA') && <span>*</span>}
                                                {(this.state.status_socios[index].status === 'BAJA_CASTIGADA') && <span>*</span>}
                                                {(this.state.status_socios[index].status === 'RECHAZADO') && <span>*</span>}
                                                {(this.state.status_socios[index].status === 'MOROSIDAD') && <span>*</span>}
                                            </label>
                                            <textarea className="form-control text-uppercase" id={`status_comentario${index}`} name={['status_comentario',index]} value={this.state.status_socios[index].status_comentario} onChange={this.handleChangeStatusSocios} rows="3" required={
                                                (this.state.status_socios[index].status === 'BAJA_VOLUNTARIA') ||
                                                (this.state.status_socios[index].status === 'BAJA_CASTIGADA') ||
                                                (this.state.status_socios[index].status === 'RECHAZADO') ||
                                                (this.state.status_socios[index].status === 'MOROSIDAD')
                                            } ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                        
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default GroupStatusForm;