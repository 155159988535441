import React, { Component } from 'react';

import Img4040 from './../../assets/static/img404.png';

class NotFound extends Component {
    render() {
        return (
 
            <div className="d-flex flex-column align-items-center justify-content-center">
                <img src={Img4040} alt="Error 404" width="450" className="img-fluid" />
                <h2 className="text-pink-dark mt-4">Página no encontrada</h2>
                <p className="text-dark">Lo sentimos. No pudimos encontrar lo que buscabas.</p>
            </div>
                
        );
    }
}

export default NotFound;