import React, { Component } from 'react';
import GroupSearchPartner from '../groups/GroupSearchPartner';

export default class PartnerSearchPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <>
        <div className="dropdown">
          <button className="btn btn-secondary btn-block mt-3" type="button" data-toggle="dropdown" aria-expanded="false">
            {this.props.title}
          </button>
          <div className="dropdown-menu">
            <button type="button" className="dropdown-item" data-toggle="modal" data-target="#searchClientPopup">Buscar</button>
            <a className="dropdown-item" href="#" onClick={() => this.props.handleAddPartner()}>Nuevo</a>
          </div>
        </div>

        
        <div className="modal fade" id="searchClientPopup" tabIndex={-1} aria-labelledby="searchClientPopupLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="searchClientPopupLabel">Buscar cliente</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body pt-0">
                    <GroupSearchPartner
                    containerListStyle={{position: 'relative !important', maxWidth: '100%', minWidth: '100%'}}
                    showCloseBtn={false}
                    scrollable={false}
                    containerShadow={false}
                    handleAddPartner={this.props.searchClient}/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

      </>
    );
  }
}
