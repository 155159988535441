import React, { Component } from 'react';
import SelectBranchOffice from './SelectBranchOffice';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import _ from 'underscore';
import Parse from 'parse';

class SelectEmpleyee extends SelectBranchOffice {
    

    UNSAFE_componentWillMount = async () => {
        
        let where = {
            cargo: {
                $in: ['PROMOTOR', 'PROMOTOR C.I.']
            }
        }
        
        if (Parse.User.current().toJSON().Sucursal) {
            where.Sucursal = Parse.User.current().toJSON().Sucursal
        }
        const params = new URLSearchParams({
            where: JSON.stringify(where),
            order: 'nombre',
            limit: 1000,
            count: 1
        });
        const branchOffices = await Rest.peticion(`${config.api_url}/classes/Empleados?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {return res.json()})
        .then(res => {return res})
        .catch(error => console.log(error));

        if (_.isEmpty(branchOffices.code) && _.isEmpty(branchOffices.error)) {
            this.setState({
                collection: branchOffices.results
            })
        } else {
            console.log('Ocurrió un error al obtener los empleados');
            
        } 
    }

    setLabel = () => {
        const branchOffices = _.findWhere(this.state.collection, {
            objectId: this.state.value
        });
        if (!_.isEmpty(branchOffices)) {
            return `${branchOffices.nombre} ${branchOffices.apellido_paterno} ${branchOffices.apellido_materno}`;
        } else {
            return '';
        }
        
    }
    
    setOption = (item, index) => <option key={index} value={item.objectId}>{item.nombre} {item.apellido_paterno} {item.apellido_materno}</option>;

}

export default SelectEmpleyee;