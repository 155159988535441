import React, { useEffect, useState } from "react";
import PersonalData from "./PersonalData";
import PledgeData from "./PledgeData";
import EconomicData from "./EconomicData";

const Tabs = React.memo((props) => {
    const [data, setData] = useState({});
    const [index, setIndex] = useState(null);

    useEffect(() => {
        if (JSON.stringify(props.client) !== JSON.stringify(data)) {
            setData(props.client);
        }
        if (props.index !== undefined) {
            setIndex(props.index);
        }
    }, [props, data]);

    return <div>
        <nav className="sticky-top d-flex" style={styles.clientDataTabs}>
            <div className="container align-self-end overflow-hidden p-0">
                <div className="nav nav-tabs border-0 d-flex flex-nowrap pl-3 pr-3 nav-tab-data-client" style={styles.navTabData} id={`nav-tab-data-${props.index}`} role="tablist">
                    <a href="#" className={`rounded-0 border-0 btn btn-outline-primary text-primary flex-shrink-0 active`} id={`nav-tab-personal-data-${props.index}`} data-toggle="tab" data-target={`#persola-data-${props.index}`} role="tab" aria-controls={`persola-data-${props.index}`} aria-selected="true">Datos personales</a>
                    <a href="#" className={`rounded-0 border-0 btn btn-outline-primary text-primary flex-shrink-0`} id={`nav-tab-security-${props.index}`} data-toggle="tab" data-target={`#security-${props.index}`} role="tab" aria-controls={`security-${props.index}`} aria-selected="false">Garantía prendaria</a>
                    <a href="#" className={`rounded-0 border-0 btn btn-outline-primary text-primary flex-shrink-0`} id={`nav-tab-economic-data-${props.index}`} data-toggle="tab" data-target={`#economic-data-${props.index}`} role="tab" aria-controls={`economic-data-${props.index}`} aria-selected="false">Datos económicos</a>
                </div>
            </div>
        </nav>
        <div className="tab-content container" id={`nav-tabContent-data-${props.index}`}>
            <div style={styles.tabContentClientData} className="tab-pane fade show active" id={`persola-data-${props.index}`} role="tabpanel" aria-labelledby={`nav-tab-personal-data-${props.index}`}>
                <PersonalData data={data} handleChange={props.handleChangeClient} index={index}/>
            </div>
            <div style={styles.tabContentClientData} className="tab-pane fade" id={`security-${props.index}`} role="tabpanel" aria-labelledby={`nav-tab-security-${props.index}`}>
                <PledgeData data={data} handleChange={props.handleChangeClient} index={index}/>
            </div>
            <div style={styles.tabContentClientData} className="tab-pane fade" id={`economic-data-${props.index}`} role="tabpanel" aria-labelledby={`nav-tab-economic-data-${props.index}`}>
                <EconomicData data={data} handleChange={props.handleChangeClient} index={index}/>
            </div>
        </div>
    </div>
})

export default Tabs;

const styles = {
    clientTabs: {
        marginTop: -50,
        background: '#e3d3de',
        height: 50,
        top: 150,
    },
    clientDataTabs: {
        background: '#F1F1F1',
        height: 50,
        top: 200,
    },
    tabContentClientData: {
        paddingTop: 50
    },
    navTabData: {
        overflowX: 'auto',
        scrollBehavior: 'smooth',
    }
}