import React, { Component } from 'react';
import Spinner from './../others/Spinner';

class PartnerSpinner extends Spinner {

    setSpinner () {
        return <div className="row">
            <div className="col">
                <div className="card shadow border-0">
                    <div className="card-body">
                        <div className="row justify-content-between flex-nowrap">
                            <div className="col-auto pr-0" style={{width:40}}>
                                {
                                    this.props.statusData === 'saving' && <i className="fas fa-spinner fa-spin fa-fw text-info"></i>
                                }
                                {
                                    this.props.statusData === 'saved' && <i className="fas fa-check-circle text-success"></i>
                                }
                                {
                                    this.props.statusData === 'error' && <i className="fas fa-times text-danger"></i>
                                }
                            </div>
                            <div className="col">Guardando datos de la clienta</div>
                        </div>
                        <div className="p-2"></div>
                        <div className="row justify-content-between flex-nowrap">
                            <div className="col-auto pr-0" style={{width:40}}>
                                {
                                    this.props.statusBuro === 'saving' && <i className="fas fa-spinner fa-spin fa-fw text-info"></i>
                                }
                                {
                                    this.props.statusBuro === 'saved' && <i className="fas fa-check-circle text-success"></i>
                                }
                                {
                                    this.props.statusBuro === 'error' && <i className="fas fa-times text-danger"></i>
                                }
                            </div>
                            <div className="col">Guardando buró</div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    } 
}

export default PartnerSpinner;