import React from 'react';
import PartnerHistory from './../partners/PartnerHistory';
import Rest from './../../Server/Rest';
import config from './../../settings/config';
import TextPhone from './../others/TextPhone';
import _ from 'underscore';

class EmployeeHistory extends PartnerHistory {

    constructor (props) {
        super(props);

        this.state = {
            loading: false,
            collection: []
        }

        this.titles.Sucursal = 'Sucursal';
        this.titles.cargo = 'Cargo';
        this.titles.fecha_ingreso = 'Fecha de ingreso';
    }
    
    getHistory = async () => {
        this.handleSpinner();

        const params = new URLSearchParams({
            where: JSON.stringify({
                Empleado: {
                    __type: 'Pointer',
                    className: 'Empleados',
                    objectId: this.props.match.params.id_empleado
                }
            }),
            order: '-createdAt'
        });

        const res = await Rest.peticion(`${config.api_url}/classes/EmpleadosHistorial?${params.toString()}`, {
            method: 'GET'
        }).then(res => {return res.json()}).then(res => {return res;}).catch(error => {return error});
        
        if (_.isEmpty(res.code) && _.isEmpty(res.error)) {
            
            this.setState({
                collection: res.results
            })
            
        } else {
            
            alert('No se ha podido obtener el historial');
        
        }
        
        this.handleSpinner();
        
    }

    setStatus = (value) => {
        return value;
    }

    setCargo = (cargo) => {
        
        let _cargo;
        
        switch (cargo) {

            case 'ANALISTA':
                _cargo = 'ANALISTA'
                break;
            case 'AUDITOR':
                _cargo = 'AUDITOR'
                break;
            case 'GERENCIA_REGIONAL':
                _cargo = 'GERENCIA REGIONAL'
                break;
            case 'GERENCIA':
                _cargo = 'GERENCIA'
                break;
            case 'JURIDICO':
                _cargo = 'JURIDICO'
                break;
            case 'PROMOTOR':
                _cargo = 'PROMOTOR'
                break;
            case 'SUPERVISOR':
                _cargo = 'SUPERVISOR'
                break;

            default:
                _cargo = cargo;
                break;
        }

        return _cargo;
    }

    setValue = (_value, key) => {
        
        let res;

        const value = _.isEmpty(_value)? <i>Sin dato previo</i>: _value;
        
        switch (key) {
            case 'telefono':
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: <TextPhone phone={value} /></small>
                break;
            case 'nacimiento_fecha':
            case 'fecha_ingreso':
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: {this.setBirthday(value)}</small>
                break;
            case 'status':
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: {this.setStatus(value)}</small>
                break;
            case 'cargo':
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: {this.setCargo(value)}</small>
                break;
            default:
                res = <small className="badge-primary text-uppercase mr-1 d-inline-block rounded pr-2 pl-2" key={key}><b>{this.titles[key]}</b>: {value}</small>
                break;
        }

        return res;
    
    }
}

export default EmployeeHistory;