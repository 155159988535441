import React, { Component } from 'react';
import InputDate from './../forms/InputDate';
import entidadesMexico from './../../colecciones/entidades-mexico.json';
import paises from './../../colecciones/paises.json';
import _ from 'underscore';
import InputTel from '../forms/InputTel';
import Rest from './../../Server/Rest';
import config from './../../settings/config';
import user from './../../settings/user';
import SelectBranchOffice from './../forms/SelectBranchOffice';
import TextPhone from './../others/TextPhone';
import PartnerStatusPaymentBadge from './PartnerStatusPaymentBadge';
import formatString from '../../utilities/format-string';

class PartnerFormDataPersonal extends Component {
    constructor(props) {
        super(props);

        this.state = this.initState;

        const d = new Date();
        this.fecha_registro = d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();

        this.handleChange     = this.handleChange.bind(this);
        this.handleChangeCP   = this.handleChangeCP.bind(this);
    }

    fecha_registro = '';

    settlements = [];

    initState = {
        // (fieldset) datos preliminares
        lugar_registro: {},
        status: '',
        status_comentario: '',

        // (fieldset) datos personales
        apellido_paterno: '',
        apellido_materno: '',
        nombre: '',
        sexo: '',
        clave_elector: '',
        numero_ocr: '',
        nacimiento_pais: '',
        nacimiento_entidad: '',
        nacimiento_fecha: '',
        curp: '',
        estado_civil: '',
        numero_dependientes: 0,
        ocupacion: '',
        telefono: '',
        conyugue_nombre: '',
        conyugue_lugar_trabajo: '',

        // datos domiciliarios
        domicilio_direccion: '',
        domicilio_colonia: '',
        domicilio_cp: '',
        domicilio_poblacion: '',
        domicilio_entidad: '',
        domicilio_tiempo_habitado: '',
        domicilio_tipo: '',
        domicilio_numero_servicio: '',
        domicilio_numero_servicio_tipo: '',

        // datos de garantia prendaria
        garantia_prendaria_1_nombre: '',
        garantia_prendaria_1_marca: '',
        garantia_prendaria_1_estado: '',
        garantia_prendaria_2_nombre: '',
        garantia_prendaria_2_marca: '',
        garantia_prendaria_2_estado: '',
        garantia_prendaria_3_nombre: '',
        garantia_prendaria_3_marca: '',
        garantia_prendaria_3_estado: '',

        // referencias personales
        referencia_1_nombre_completo: '',
        referencia_1_direccion: '',
        referencia_1_telefono: '',
        referencia_2_nombre_completo: '',
        referencia_2_direccion: '',
        referencia_2_telefono: ''
    };

    setRegisterDate = (fecha) => {
        if (fecha) {
            const d = new Date(fecha);
            this.fecha_registro = formatString.setLegibleDate(d.toISOString());
        }
    }

    setEstadoCivil = (value) => {
        
        let text = '';

        switch (value) {
            case 'SOLTERO':
                text = 'SOLTERA';
                break;
            case 'CASADO':
                text = 'CASADA';
                break;
            case 'DIVORCIADO':
                text = 'DIVORCIADA';
                break;
            case 'UNIÓN LIBRE':
                text = 'UNIÓN LIBRE';
                break;
            case 'VIUDO':
                text = 'VIUDA';
                break;
        
            default:
                text = value;
                break;
        }
        return text;
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // para resetear el formulario
        if (nextProps.resetform === true) {
            this.setState(this.initState);
        }
        // sirve para la edición de socios
        if (!_.isEmpty(nextProps.data)) {
            // Establecemos la fecha de registro
            this.setRegisterDate(nextProps.data.fecha_registro);
            this.setState(nextProps.data);
        }

        if (nextProps.readOnly === false) {
            this.getDataByCP(nextProps.data.domicilio_cp);
        }
    }

    componentWillUpdate = (nextProps, nextState) => {
        this.props.setData(nextState);
    }
    
    handleChange = (event) => {
            
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if (!_.isEmpty(value) && target.type === "number") {
            value = parseInt(value);
        }
    
        this.setState({
            [name]: value
        });

    }

    handleChangeCP = (event) => {
        
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });

        this.getDataByCP(value);

    }

    getDataByCP = async (cp) => {
        if (cp.length === 5) {
                
            this.settlements = [];
            
            if (!_.isEmpty(cp)) {
                
                const params = new URLSearchParams({
                    where: JSON.stringify({
                        cp: cp
                    }),
                    limit: 1000,
                    count: 1
                });
 
                const solfinCP = await Rest.peticion(`${config.api_url}/classes/ColoniasBarrios?${params.toString()}`, {
                    method: 'GET'
                }).then(res => {return res.json()}).then(res => {return res}).catch(error => console.log(error));

                if (!solfinCP.code) {

                    if (solfinCP.results.length) {

                        _.each(_.pluck(solfinCP.results, 'nombre'), (asentamiento) => {
                            this.settlements.push( asentamiento.toUpperCase() );
                        });

                    }
                }


                const solfin = await Rest.peticion(`${config.api_url}/classes/CodigoPostal?${params.toString()}`, {
                    method: 'GET'
                }).then(res => {return res.json()}).then(res => {return res}).catch(error => console.log(error));
                
                if (!solfin.code) {
                    
                    if (solfin.results.length) {

                        _.each(solfin.results[0].asentamiento, (asentamiento) => {
                            
                            this.settlements.push( asentamiento.toUpperCase() );
                            
                        });
                        
                        this.settlements = this.settlements.sort();
                        this.settlements = _.uniq(this.settlements);

                                        
                        this.setState({
                            domicilio_poblacion: solfin.results[0].municipio,
                            domicilio_entidad: solfin.results[0].estado
                        });
 
                        return;
                    }
                }

                
                const sepomex = await fetch('https://api.copomex.com/query/info_cp/'+cp+'?type=simplified&token=bfc2983b-6538-4c83-9655-6e2db67d4090', {
                    method: 'GET'
                })
                .then(res => {
                    return res.json();
                })
                .then(res => {

                    if (res.error) {
                        return false
                    } else {
                        return res;
                    }

                })
                .catch(error => {
                    console.log(error);
                });

                if (sepomex) {
                    
                    _.each(sepomex.response.asentamiento, (asentamiento) => {
                        
                        this.settlements.push(asentamiento.toUpperCase());

                    });
                    
                    this.settlements = this.settlements.sort();
                    this.settlements = _.uniq(this.settlements);
                    
                    this.setState({
                        domicilio_poblacion: sepomex.response.municipio,
                        domicilio_entidad: sepomex.response.estado
                    });
 
                    Rest.peticion(`${config.api_url}/classes/CodigoPostal?`, {
                        method: 'POST',
                        body: JSON.stringify(sepomex.response),
                    }).catch(error => console.log(error));

                }
                
            }
        }
        
    }

    handleChangeSettlement = () => {
        this.settlements = [];
        
        this.setState({
            domicilio_colonia: ''
        });
    }

    render() {
        return (
            <>
                {this.html_datosPreliminares()}
                
                {this.html_datosPersonales()}

                {this.html_datosDomicilio()}

                {this.html_garantiaPrendaria()}

                {this.html_referenciasPersonales()}
            </>
        );
    }

    /*Para solo lectura*/
    setStatus = () => {
        let status;
        
        switch (this.state.status) {
            case 'ACTIVO':
                status = 'ACTIVO';
                break;
            case 'BAJA_VOLUNTARIA':
                status = 'BAJA VOLUNTARIA';
                break;
            case 'BAJA_CASTIGADA':
                status = 'BAJA CASTIGADA';
                break;
            case 'RECHAZADO':
                status = 'RECHAZADO';
                break;
            case 'MOROSIDAD':
                status = 'MOROSIDAD';
                break;
            case 'FALLECIDA':
                status = 'FALLECIDA';
                break;
        }
        return status;
    }

    html_datosPreliminares = () => (
        <div className="card mb-3">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        {!this.props.readOnly && <div className="form-group">
                            <label htmlFor="fecha-registro">Fecha de registro</label>
                            <span className="form-control text-muted" id="fecha-registro">{this.fecha_registro}</span>
                        </div> || <div className="form-group">
                            <small className="text-muted">Fecha de registro</small>
                            <p className="text-info">{this.fecha_registro}</p>
                        </div>}
                        <div className="form-group">
                            {!this.props.readOnly && <label htmlFor="lugar-registro">
                                Lugar de registro
                            </label> || <small className="text-muted">
                                Lugar de registro
                            </small>}
                            
                            <SelectBranchOffice name="lugar_registro" id="lugar-registro" readOnly={this.props.readOnly} value={this.state.lugar_registro} onChange={this.handleChange} required={this.props.required} />
                        </div>
                    </div>
                    <div className="col-md-8">
                        {(!this.props.readOnly && (user.rol.es_administrador === true && <>
                            <div className="d-flex">
                                <label>Status</label> <PartnerStatusPaymentBadge partnerId={this.props.partnerId} />
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="radio" name="status" id="status_activo" value="ACTIVO" checked={this.state.status === 'ACTIVO'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="status_activo">
                                            Activa
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="radio" name="status" id="status_baja_voluntaria" value="BAJA_VOLUNTARIA" checked={this.state.status === 'BAJA_VOLUNTARIA'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="status_baja_voluntaria">
                                            Baja voluntaria
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="radio" name="status" id="status_baja_castigada" value="BAJA_CASTIGADA" checked={this.state.status === 'BAJA_CASTIGADA'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="status_baja_castigada">
                                            Baja castigada
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="radio" name="status" id="status_baja_rechazada" value="RECHAZADO" checked={this.state.status === 'RECHAZADO'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="status_baja_rechazada">
                                            Rechazada
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="radio" name="status" id="status_baja_morocidad" value="MOROSIDAD" checked={this.state.status === 'MOROSIDAD'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="status_baja_morocidad">
                                            Morosidad
                                        </label>
                                    </div>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="radio" name="status" id="status_baja_fallecida" value="FALLECIDA" checked={this.state.status === 'FALLECIDA'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="status_baja_fallecida">
                                            Fallecida
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <label htmlFor="status_comentario">Comentario del status
                                        
                                        {(this.state.status === 'BAJA_VOLUNTARIA') && <span>*</span>}
                                        {(this.state.status === 'BAJA_CASTIGADA') && <span>*</span>}
                                        {(this.state.status === 'RECHAZADO') && <span>*</span>}
                                        {(this.state.status === 'MOROSIDAD') && <span>*</span>}
                                        
                                    </label>
                                    <textarea className="form-control text-uppercase" id="status_comentario" name="status_comentario" value={this.state.status_comentario} onChange={this.handleChange} rows="3" required={
                                        (this.state.status === 'BAJA_VOLUNTARIA') ||
                                        (this.state.status === 'BAJA_CASTIGADA') ||
                                        (this.state.status === 'RECHAZADO') ||
                                        (this.state.status === 'MOROSIDAD')
                                    } ></textarea>
                                </div>
                            </div>
                        </>)) || <>
                            <small className="text-muted">Status</small>
                            <p className="d-flex">
                                {this.setStatus()} 
                                <PartnerStatusPaymentBadge partnerId={this.props.partnerId} />
                            </p>
                            <small className="text-muted">Comentario del status</small>
                            <p>
                                {this.state.status_comentario}
                            </p>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    html_datosPersonales = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Datos personales</legend>
                <div className="row">
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="apellido_paterno">Apellido paterno</label>
                                <input type="text" className="form-control text-uppercase" id="apellido_paterno" name="apellido_paterno" value={this.state.apellido_paterno} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Apellido paterno</small>
                                <p>
                                    {this.state.apellido_paterno}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="apellido_materno">Apellido materno</label>
                                <input type="text" className="form-control text-uppercase" id="apellido_materno" name="apellido_materno" value={this.state.apellido_materno} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Apellido materno</small>
                                <p>
                                    {this.state.apellido_materno}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="nombre_completo">Nombre(s)</label>
                                <input type="text" className="form-control text-uppercase" id="nombre_completo" name="nombre" value={this.state.nombre} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Nombre(s)</small>
                                <p>
                                    {this.state.nombre}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="Sexo">Sexo</label>
                                <div className="d-flex">
                                    <div className="form-check col">
                                        <input className="form-check-input" type="radio" id="sexo_hombre" name="sexo" value="HOMBRE" checked={this.state.sexo === 'HOMBRE'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="sexo_hombre">
                                        Hombre
                                        </label>
                                    </div>
                                    <div className="form-check col">
                                        <input className="form-check-input" type="radio" id="sexo_mujer" name="sexo" value="MUJER" checked={this.state.sexo === 'MUJER'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="sexo_mujer">
                                        Mujer
                                        </label>
                                    </div>
                                </div>
                            </> || <>
                                <small className="text-muted">Sexo</small>
                                <p>
                                    {this.state.sexo}
                                </p>
                            </>
                        }
                                
                    </div>
                    <div className="col-12"></div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="clave_elector">Clave de elector</label>
                                <input type="text" className="form-control text-uppercase" maxLength="18" minLength="18" id="clave_elector" name="clave_elector" value={this.state.clave_elector} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Clave de elector</small>
                                <p>
                                    {this.state.clave_elector}
                                </p>
                            </>
                        }
                        
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="numero_ocr">Número de OCR</label>
                                <input type="text" className="form-control text-uppercase" maxLength="13" minLength="13" id="numero_ocr" name="numero_ocr" value={this.state.numero_ocr} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Número de OCR</small>
                                <p>
                                    {this.state.numero_ocr}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="pais_nacimiento">País de nacimiento</label>
                                <select className="form-control text-uppercase" id="pais_nacimiento" name="nacimiento_pais" value={this.state.nacimiento_pais} onChange={this.handleChange} required={this.props.required} >
                                    <option value="" disabled>Elija una opción...</option>
                                    {paises.map((pais, index) => <option value={pais} key={index}>{pais}</option>)}
                                </select> 
                            </> || <>
                                <small className="text-muted">País de nacimiento</small>
                                <p>
                                    {this.state.nacimiento_pais}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="entidad_nacimiento">Entidad de nacimiento</label>
                                {
                                    (
                                        this.state.nacimiento_pais === 'MÉXICO'
                                    ) && <select className="form-control text-uppercase" id="entidad_nacimiento" name="nacimiento_entidad" value={this.state.nacimiento_entidad} onChange={this.handleChange} required={this.props.required} >
                                        <option value="" disabled>Elija una opción...</option>
                                        {entidadesMexico.map((entidad, index) => <option value={entidad} key={index}>{entidad}</option>)}
                                    </select> ||
                                    <input type="text" className="form-control text-uppercase" id="entidad_nacimiento" name="nacimiento_entidad" value={this.state.nacimiento_entidad} onChange={this.handleChange} required={this.props.required} />
                                }
                            </> || <>
                                <small className="text-muted">Entidad de nacimiento</small>
                                <p>
                                    {this.state.nacimiento_entidad}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="fecha_nacimiento">Fecha de nacimiento</label>
                                <InputDate handleChange={this.handleChange} name="nacimiento_fecha" hasDate={this.state.nacimiento_fecha} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Fecha de nacimiento</small>
                                <p>
                                    {this.state.nacimiento_fecha && <>{formatString.setLegibleDate(new Date(new Date(this.state.nacimiento_fecha).getTime()+(1000*60*60*24)).toISOString())}</>}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="curp">CURP</label>
                                <input type="text" className="form-control text-uppercase" id="curp" name="curp" value={this.state.curp} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">CURP</small>
                                <p>
                                    {this.state.curp}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="estado_civil">Estado civil</label>
                                <select className="form-control text-uppercase" id="estado_civil" name="estado_civil" value={this.state.estado_civil} onChange={this.handleChange} required={this.props.required} >
                                    <option value="" disabled>Elija una opción...</option>
                                    <option value="SOLTERO">SOLTERA</option>
                                    <option value="CASADO">CASADA</option>
                                    <option value="DIVORCIADO">DIVORCIADA</option>
                                    <option value="UNIÓN LIBRE">UNIÓN LIBRE</option>
                                    <option value="VIUDO">VIUDA</option>
                                </select>
                            </> || <>
                                <small className="text-muted">Estado civil</small>
                                <p>
                                    {this.setEstadoCivil(this.state.estado_civil)}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="numero_dependientes">Número de dependientes</label>
                                <input type="number" className="form-control" id="numero_dependientes" name="numero_dependientes" value={this.state.numero_dependientes} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Número de dependientes</small>
                                <p>
                                    {this.state.numero_dependientes}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="ocupacion">Ocupación</label>
                                <input type="text" className="form-control text-uppercase" id="ocupacion" name="ocupacion" value={this.state.ocupacion} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Ocupación</small>
                                <p>
                                    {this.state.ocupacion}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4 mb-3">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="telefono">Teléfono</label>
                                <InputTel id="telefono" name="telefono" value={this.state.telefono} handleChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Teléfono</small>
                                <p>
                                    <TextPhone phone={this.state.telefono} />
                                </p>
                            </>
                        }
                    </div>
                    <div className="col-12"></div>
                    <div className="form-group col-md-8">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="conyugue_nombre">Nombre del conyugue</label>
                                <input type="text" 
                                className="form-control text-uppercase" 
                                id="conyugue_nombre" 
                                name="conyugue_nombre" 
                                value={this.state.conyugue_nombre} onChange={this.handleChange} 
                                disabled={(
                                    this.state.estado_civil === 'SOLTERO' || 
                                    this.state.estado_civil === 'DIVORCIADO' || 
                                    this.state.estado_civil === 'VIUDO'
                                )} required={(
                                    this.state.estado_civil === 'CASADO' ||
                                    this.state.estado_civil === 'UNIÓN LIBRE'
                                )} />
                            </> || <>
                                <small className="text-muted">Nombre del conyugue</small>
                                <p>
                                    {this.state.conyugue_nombre}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="conyugue_lugar_trabajo">Lugar de trabajo del conyugue</label>
                                <input type="text" 
                                className="form-control text-uppercase" 
                                id="conyugue_lugar_trabajo" 
                                name="conyugue_lugar_trabajo" 
                                value={this.state.conyugue_lugar_trabajo} 
                                onChange={this.handleChange} 
                                disabled={(
                                    this.state.estado_civil === 'SOLTERO' || 
                                    this.state.estado_civil === 'DIVORCIADO' || 
                                    this.state.estado_civil === 'VIUDO'
                                )} required={(
                                    this.state.estado_civil === 'CASADO' ||
                                    this.state.estado_civil === 'UNIÓN LIBRE'
                                )} />
                            </> || <>
                                <small className="text-muted">Lugar de trabajo del conyugue</small>
                                <p>
                                    {this.state.conyugue_lugar_trabajo}
                                </p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </fieldset>
    );

    html_datosDomicilio = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Datos del domicilio</legend>
                <div className="row">
                    <div className="form-group col-md-8">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="domicilio_direccion">Dirección</label>
                                <input type="text" className="form-control text-uppercase" id="domicilio_direccion" name="domicilio_direccion" value={this.state.domicilio_direccion} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Dirección</small>
                                <p>
                                    {this.state.domicilio_direccion}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="domicilio_cp">C.P.</label>
                                <input type="text" className="form-control text-uppercase" id="cp" name="domicilio_cp" value={this.state.domicilio_cp} onChange={this.handleChangeCP} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">C.P.</small>
                                <p>
                                    {this.state.domicilio_cp}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="domicilio_entidad">Estado</label>
                                <input type="text" className="form-control text-uppercase" id="domicilio_entidad" name="domicilio_entidad" value={this.state.domicilio_entidad} onChange={this.handleChange} required={this.props.required} />    
                            </> || <>
                                <small className="text-muted">Estado</small>
                                <p className="text-uppercase">
                                    {this.state.domicilio_entidad}
                                </p>
                            </>
                        }
                        
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="domicilio_poblacion">Población</label>
                                <input type="text" className="form-control text-uppercase" id="domicilio_poblacion" name="domicilio_poblacion" value={this.state.domicilio_poblacion} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Población</small>
                                <p className="text-uppercase">
                                    {this.state.domicilio_poblacion}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="domicilio_colonia">Fraccionamiento o Colonia</label>
                                {
                                    (this.settlements.length && <div className="input-group">
                                        <select className="custom-select text-uppercase" id="domicilio_colonia" name="domicilio_colonia" value={this.state.domicilio_colonia} onChange={this.handleChange} required={this.props.required} >
                                            <option value="">Elija una opción...</option>
                                            {this.settlements.map((asentamiento, index) => <option value={asentamiento} key={index}>{asentamiento}</option>)}
                                        </select>
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button" onClick={this.handleChangeSettlement}>Otro</button>
                                        </div>
                                    </div>) || (
                                        <>
                                            <input type="text" className="form-control text-uppercase" id="domicilio_colonia" name="domicilio_colonia" value={this.state.domicilio_colonia} onChange={this.handleChange} required={this.props.required} />
                                            <small className="form-text">Escriba un C.P. para obtener una lista</small>
                                        </>
                                    )
                                }
                            </> || <>
                                <small className="text-muted">Fraccionamiento o Colonia</small>
                                <p>
                                    {this.state.domicilio_colonia}
                                </p>
                            </>
                        }
                        
                        
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="domicilio_tiempo_habitado">Tiempo habitado en ella</label>
                                <input type="text" className="form-control text-uppercase" id="domicilio_tiempo_habitado" name="domicilio_tiempo_habitado" value={this.state.domicilio_tiempo_habitado} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Tiempo habitado en ella</small>
                                <p>
                                    {this.state.domicilio_tiempo_habitado}
                                </p>
                            </>
                        }
                    </div>
                    
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="Sexo">Domicilio</label>
                                <div className="d-flex flex-column flex-sm-row">
                                    <div className="form-check col">
                                        <input className="form-check-input" type="radio" name="domicilio_tipo" id="domicilio_propio" value="PROPIO" checked={this.state.domicilio_tipo === 'PROPIO'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="domicilio_propio">
                                        Propio
                                        </label>
                                    </div>
                                    <div className="form-check col">
                                        <input className="form-check-input" type="radio" name="domicilio_tipo" id="domicilio_rentado" value="RENTADO" checked={this.state.domicilio_tipo === 'RENTADO'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="domicilio_rentado">
                                        Rentado
                                        </label>
                                    </div>
                                    <div className="form-check col">
                                        <input className="form-check-input" type="radio" name="domicilio_tipo" id="domicilio_familiar" value="FAMILIAR" checked={this.state.domicilio_tipo === 'FAMILIAR'} onChange={this.handleChange} required={this.props.required} />
                                        <label className="form-check-label" htmlFor="domicilio_familiar">
                                        Familiar
                                        </label>
                                    </div>
                                </div>
                            </> || <>
                                <small className="text-muted">Domicilio</small>
                                <p>
                                    {this.state.domicilio_tipo}
                                </p>
                            </>
                        }
                    </div>

                    <div className="col-md-4">
                        
                        {
                            !this.props.readOnly && <div className="row">
                                <div className="form-group col-7">
                                    <label htmlFor="domicilio_numero_servicio">Número de servicio</label>
                                    <input type="text" className="form-control text-uppercase"  id="domicilio_numero_servicio" name="domicilio_numero_servicio" value={this.state.domicilio_numero_servicio} onChange={this.handleChange} required={this.props.required} />
                                </div>
                                <div className="form-group col-5 pl-0">
                                    <label htmlFor="domicilio_numero_servicio_tipo">Tipo servicio</label>
                                    <select className="custom-select" id="domicilio_numero_servicio_tipo" name="domicilio_numero_servicio_tipo" value={this.state.domicilio_numero_servicio_tipo} onChange={this.handleChange} required={this.props.required}>
                                        <option value="" disabled>Elija una opción...</option>
                                        <option value="AGUA">Agua</option>
                                        <option value="CABLE">Cable</option>
                                        <option value="LUZ">Luz</option>
                                        <option value="TELÉFONO">Teléfono</option>
                                        <option value="CONSTANCIA DE RESIDENCIA">Constancia de residencia</option>
                                    </select>
                                </div>
                            </div> || <div className="row">
                                <div className="form-group col-7">
                                    <small className="text-muted">Número de servicio</small>
                                    <p>
                                        {this.state.domicilio_numero_servicio}
                                    </p>
                                </div>
                                <div className="form-group col-5 pl-0">
                                    <small className="text-muted">Tipo servicio</small>
                                    <p>
                                        {this.state.domicilio_numero_servicio_tipo}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </fieldset>
    );

    html_garantiaPrendaria = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Garantías prendarias</legend>
                <div className="row">
                    <div className="form-group col-md-6">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="articulo_1_nombre">Artículo 1</label>
                                <input type="text" className="form-control text-uppercase" id="articulo_1_nombre" name="garantia_prendaria_1_nombre" value={this.state.garantia_prendaria_1_nombre} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Artículo 1</small>
                                <p>
                                    {this.state.garantia_prendaria_1_nombre}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-3">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="articulo_1_marca">Marca</label>
                                <input type="text" className="form-control text-uppercase" id="articulo_1_marca" name="garantia_prendaria_1_marca" value={this.state.garantia_prendaria_1_marca} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Marca</small>
                                <p>
                                    {this.state.garantia_prendaria_1_marca}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-3">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="articulo_1_estado">Estado del artículo</label>
                                <select className="form-control text-uppercase" id="articulo_1_estado" name="garantia_prendaria_1_estado" value={this.state.garantia_prendaria_1_estado} onChange={this.handleChange} required={this.props.required} >
                                    <option value="" disabled>Elija una opción...</option>
                                    <option value="BUENO">Bueno</option>
                                    <option value="MALO">Malo</option>
                                    <option value="REGULAR">Regular</option>
                                </select>
                            </> || <>
                                <small className="text-muted">Estado del artículo</small>
                                <p>
                                    {this.state.garantia_prendaria_1_estado}
                                </p>
                            </>
                        }
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="form-group col-md-6">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="articulo_2_nombre">Artículo 2</label>
                                <input type="text" className="form-control text-uppercase" id="articulo_2_nombre" name="garantia_prendaria_2_nombre" value={this.state.garantia_prendaria_2_nombre} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Artículo 2</small>
                                <p>
                                    {this.state.garantia_prendaria_2_nombre}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-3">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="articulo_2_marca">Marca</label>
                                <input type="text" className="form-control text-uppercase" id="articulo_2_marca" name="garantia_prendaria_2_marca" value={this.state.garantia_prendaria_2_marca} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Marca</small>
                                <p>
                                    {this.state.garantia_prendaria_2_marca}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-3">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="articulo_2_estado">Estado del artículo</label>
                                <select className="form-control text-uppercase" id="articulo_2_estado" name="garantia_prendaria_2_estado" value={this.state.garantia_prendaria_2_estado} onChange={this.handleChange} required={this.props.required} >
                                    <option value="" disabled>Elija una opción...</option>
                                    <option value="BUENO">Bueno</option>
                                    <option value="MALO">Malo</option>
                                    <option value="REGULAR">Regular</option>
                                </select>
                            </> || <>
                                <small className="text-muted">Estado del artículo</small>
                                <p>
                                    {this.state.garantia_prendaria_2_estado}
                                </p>
                            </>
                        }
                    </div>
                </div>
                <hr/>
                <div className="row">
                <div className="form-group col-md-6">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="articulo_3_nombre">Artículo 3</label>
                                <input type="text" className="form-control text-uppercase" id="articulo_3_nombre" name="garantia_prendaria_3_nombre" value={this.state.garantia_prendaria_3_nombre} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Artículo 3</small>
                                <p>
                                    {this.state.garantia_prendaria_3_nombre}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-3">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="articulo_3_marca">Marca</label>
                                <input type="text" className="form-control text-uppercase" id="articulo_3_marca" name="garantia_prendaria_3_marca" value={this.state.garantia_prendaria_3_marca} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Marca</small>
                                <p>
                                    {this.state.garantia_prendaria_3_marca}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-3">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="articulo_3_estado">Estado del artículo</label>
                                <select className="form-control text-uppercase" id="articulo_3_estado" name="garantia_prendaria_3_estado" value={this.state.garantia_prendaria_3_estado} onChange={this.handleChange} required={this.props.required} >
                                    <option value="" disabled>Elija una opción...</option>
                                    <option value="BUENO">Bueno</option>
                                    <option value="MALO">Malo</option>
                                    <option value="REGULAR">Regular</option>
                                </select>
                            </> || <>
                                <small className="text-muted">Estado del artículo</small>
                                <p>
                                    {this.state.garantia_prendaria_3_estado}
                                </p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </fieldset>
    );

    html_referenciasPersonales = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Referencias personales</legend>
                <div className="row">
                    <div className="form-group col-md-12">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="referencia_1_nombre-completo">Nombre completo</label>
                                <input type="text" className="form-control text-uppercase" id="referencia_1_nombre_completo" name="referencia_1_nombre_completo" value={this.state.referencia_1_nombre_completo} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Nombre completo</small>
                                <p>
                                    {this.state.referencia_1_nombre_completo}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-8">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="referencia_1_direccion">Dirección</label>
                                <input type="text" className="form-control text-uppercase" id="referencia_1_direccion" name="referencia_1_direccion" value={this.state.referencia_1_direccion} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Dirección</small>
                                <p>
                                    {this.state.referencia_1_direccion}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="referencia_1_telefono">Teléfono</label>
                                <InputTel id="referencia_1_telefono" name="referencia_1_telefono" value={this.state.referencia_1_telefono} handleChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Teléfono</small>
                                <p>
                                    <TextPhone phone={this.state.referencia_1_telefono} />
                                </p>
                            </>
                        }
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="form-group col-md-12">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="referencia_2_nombre-completo">Nombre completo</label>
                                <input type="text" className="form-control text-uppercase" id="referencia_2_nombre_completo" name="referencia_2_nombre_completo" value={this.state.referencia_2_nombre_completo} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Nombre completo</small>
                                <p>
                                    {this.state.referencia_2_nombre_completo}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-8">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="referencia_2_direccion">Dirección</label>
                                <input type="text" className="form-control text-uppercase" id="referencia_2_direccion" name="referencia_2_direccion" value={this.state.referencia_2_direccion} onChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Dirección</small>
                                <p>
                                    {this.state.referencia_2_direccion}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            !this.props.readOnly && <>
                                <label htmlFor="referencia_2_telefono">Teléfono</label>
                                <InputTel id="referencia_2_telefono" name="referencia_2_telefono" value={this.state.referencia_2_telefono} handleChange={this.handleChange} required={this.props.required} />
                            </> || <>
                                <small className="text-muted">Teléfono</small>
                                <p>
                                    <TextPhone phone={this.state.referencia_2_telefono} />
                                </p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </fieldset>
    );
}

export default PartnerFormDataPersonal;