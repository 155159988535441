const _rol = _getRol();
const _config = _getConfig();

function _getRol () {
    const Rol = localStorage.getItem('Rol');
    return JSON.parse(Rol);
}

function _getConfig () {
    const Config = localStorage.getItem('Config');
    return JSON.parse(Config);
}

// const _sucursal = _getSucursal();

// function _getSucursal () {
//     const Sucursal = localStorage.getItem('Sucursal');
//     return JSON.parse(Sucursal);
// }

export default {
    rol: _rol,
    config: _getConfig
    // Sucursal: _sucursal
}