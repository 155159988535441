import React, { Component } from 'react';
import DocumentPaymentControl from './DocumentPaymentControl';
import DocumentGrupalPromissoryNote from './DocumentGrupalPromissoryNote';
import Document404 from './Document404';
import DocumentIndividualPromissoryNote from './DocumentIndividualPromissoryNote';
import DocumentCheckPolicy from './DocumentCheckPolicy';
import DocumentVarius from './DocumentVarius';
import DocumentAccountStatement from './DocumentAccountStatement';
import DocumentBitacoraReport from './DocumentBitacoraReport';


class DocumentView extends Component {

    constructor (props) {
        super(props)
        this.margin = this.getParamValue('no_margin');
    }

    margin = '';

    getParamValue = (paramName) => {
        var url = window.location.search.substring(1); //get rid of "?" in querystring
        var qArray = url.split('&'); //get key-value pairs
        for (var i = 0; i < qArray.length; i++) 
        {
            var pArr = qArray[i].split('='); //split key and value
            if (pArr[0] === paramName) 
                return pArr[1]; //return value
        }
     }

    setDocument = () => {
        let theComponent;
        switch (this.props.match.params.document_type) {
            case 'control-pagos':
                theComponent = <DocumentPaymentControl id_grupo={this.props.match.params.id_grupo} id_solicitud_actual={this.props.match.params.id_solicitud_actual} />
                break;
            case 'pagare-grupal':
                theComponent = <DocumentGrupalPromissoryNote id_grupo={this.props.match.params.id_grupo} id_solicitud_actual={this.props.match.params.id_solicitud_actual} />
                break;
            case 'pagare-individual':
                theComponent = <DocumentIndividualPromissoryNote id_grupo={this.props.match.params.id_grupo} id_solicitud_actual={this.props.match.params.id_solicitud_actual} />
                break;
            case 'poliza':
                theComponent = <DocumentCheckPolicy id_grupo={this.props.match.params.id_grupo} id_solicitud_actual={this.props.match.params.id_solicitud_actual} />
                break;
            case 'varios':
                theComponent = <DocumentVarius id_grupo={this.props.match.params.id_grupo} id_solicitud_actual={this.props.match.params.id_solicitud_actual} />
                break;
            case 'estado-cuenta':
                theComponent = <DocumentAccountStatement/>
                break;
            case 'bitacora-reporte':
                theComponent = <DocumentBitacoraReport/>
                break;
            default:
                theComponent = <Document404/>
                break;
        }
        return theComponent;
    }
    
    render() {
        return (
            <div  style={this.margin === true?{}:{backgroundColor:'#F1F1F1'}} className="d-flex align-items-center justify-content-center">
                {this.setDocument()}
            </div>
        );
    }
}

export default DocumentView;