import React, { Component } from 'react';

class TableOrder extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        value: ''
    }

    UNSAFE_componentWillMount = () => {
        this.setState({
            value: this.props.options[0]
        })
    }

    toggleOrder = () => {
        if (this.state.value === this.props.options[0]) {
            this.setState({
                value: this.props.options[1]
            });
            this.props.setOrder(this.props.options[1]);
        }
        if (this.state.value === this.props.options[1]) {
            this.setState({
                value: this.props.options[0]
            });
            this.props.setOrder(this.props.options[0]);
        }
    }

    render() {
        return (
            <button className="btn btn-link btn-sm p-0 pl-2 pr-2" onClick={() => this.toggleOrder()} disabled={!this.props.active}>
                <i className="fas fa-sort"></i>
            </button>
        );
    }
}

export default TableOrder;