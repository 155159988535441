
import React from 'react';
import textUtils from '../../utilities/format-string';
import _ from 'underscore';
import Parse from 'parse';
import SelectBranchOffice from './../forms/SelectBranchOffice';
import GroupSearch from './GroupSearch';

class GroupSearchRequisition extends GroupSearch {

    componentDidMount = () => {
        if (Parse.User.current().toJSON().Sucursal) {
            
            this.setState({
                lugar_registro: Parse.User.current().toJSON().Sucursal.objectId
            })
        }
    }

    handleSearchSubmit = (event) => {
        if (event) event.preventDefault();

        let searchValue = textUtils.setPlainText(this.state.searchValue);
        searchValue = searchValue.toLowerCase();
        searchValue = searchValue.replace(' ','');

        let where = {};

        if (!(_.isEmpty(this.state.lugar_registro) && _.isEmpty(searchValue))) {
            where = {
                Grupo: {
                    $inQuery: {
                        where: {
                            id_busqueda: !_.isEmpty(searchValue) ? {
                                $regex: searchValue
                            } : undefined
                        },
                        className: 'Grupos'
                    }
                },
                Sucursal: !_.isEmpty(this.state.lugar_registro) ? {
                    __type: 'Pointer',
                    className: 'Sucursales',
                    objectId: this.state.lugar_registro
                } : undefined
                
            }
        }
        
        this.props.handleFilter(where);
    }

    render() {
        return (
            <>
                <form className="row" onSubmit={this.handleSearchSubmit}>
                    <div className="col-md-12">
                        <div className="row align-items-start mb-2">
                            <div className="col-md-5">
                                <label className="sr-only" htmlFor="search-input">Buscar</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </div>
                                    </div>
                                    <span className={`btn ${!this.state.searchValue.length && 'd-none'}`} onClick={() => this.resetSearchForm()} style={styles.searchInputClose}>
                                        <i className="fas fa-times"></i>
                                    </span>
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id="search-input" 
                                    placeholder="Buscar"
                                    style={styles.searchInput}
                                    onChange={this.handleSearchChange}
                                    value={this.state.searchValue}
                                    name="searchValue" />
                                </div>
                            </div>
                            <div className="col-8 col-md-4">
                                {Parse.User.current().toJSON().Sucursal && <SelectBranchOffice name="lugar_registro" placeholder="Lugar de registro" id="lugar_registro" value={Parse.User.current().toJSON().Sucursal.objectId} disabled={Parse.User.current().toJSON().Sucursal} defaultValue={Parse.User.current().toJSON().Sucursal.objectId} onChange={this.handleSearchChange} /> ||
                                <SelectBranchOffice name="lugar_registro" placeholder="Lugar de registro" id="lugar_registro" value={this.state.lugar_registro} onChange={this.handleSearchChange} />}
                            </div>
                            <div className="col-4 col-md-3 pl-0 pl-md-3">
                                <button type="submit" className="btn btn-primary btn-block">
                                    <span className="d-none d-md-block">Buscar</span>
                                    <i className="fas fa-search d-md-none"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                
                
                
            </>
        );
    }
}

export default GroupSearchRequisition;

const styles = {
    searchInputClose: {
        position: 'absolute',
        right: 0,
        zIndex: 4,
    },
    searchInput: {
        paddingRight: 36
    }
}