import React, { Component } from 'react';
import PartnerForm from '../../components/partners/PartnerForm';
import _ from 'underscore';
// import { Link } from "react-router-dom";
import Spinner from './PartnerSpinner';
import config from './../../settings/config';
import Rest from '../../Server/Rest';
import Parse from 'parse';
import textUtils from './../../utilities/format-string';
import service_catalogs from './../../services/catalogs';
import PartnerLocalList from './PartnerLocalList';

class RegistrationForm extends Component {

    constructor(props) {
        super(props);

        this.state = this.state_init();

        this.handleSubmit = this.handleSubmit.bind(this);

        
    }

    state_init = () => {
        return {
            resetform: false,
            loading: false,
            statusData: '',
            statusBuro: ''
        }
    }

    data = {};
    data_loca_key = `socio-local-${new Date().getTime()}`;

    file = null;
    notaBuro = '';

    validate_ocr = true;
    validate_ine = true;
    // validate_n_servicio = true;

    alertExistence = (res) => {
        if (!res.code) {
            if (res.results.length === 1) {
                const socio = res.results[0].Socio.nombre +' '+ res.results[0].Socio.apellido_paterno +' '+ res.results[0].Socio.apellido_materno;
                const r = window.confirm('La persona que intenta registrar ya existe como '+socio+'. ¿Deseas revisar los datos existentes?');
                if (r) {
                    window.location.href = `/clientas/${res.results[0].objectId}/${res.results[0].Socio.objectId}/${res.results[0].Socio.id_en_sucursal}/datos-personales`;
                }
            } else if (res.count > 1) {
                alert('La clienta se encuentra registrada '+res.count+' veces con el dato que acaba de proporcionar');
            } 
        }

    }
    
    checkExistence = (clave, valor, notEqualObjectId) => {

        let where = {
            Socio: {
                '$select': {
                    query: {
                        className: 'Socios',
                        where: {}
                    },
                    key: 'objectId'
                }
            }
        };
        if (!_.isEmpty(notEqualObjectId)) {
            where.Socio.$select.query.where.objectId = {
                $ne: notEqualObjectId
            };
        }

        where.Socio.$select.query.where[clave] = valor.toUpperCase();

        const params = new URLSearchParams({
            where: JSON.stringify(where),
            include: 'Socio',
            count: 1,
        });
        return Rest.peticion(`${config.api_url}/classes/SociosDiccionario?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        }).catch(error => {
            return error;
        });
    }

    validExistence = async (clave, valor) => {

        if (!_.isEmpty(valor)) {
            let validacion = false;

            switch (clave) {
                case 'numero_ocr':
                    validacion = valor.length === 13 && this.validate_ocr;
                    
                    break;
                case 'clave_elector':
                    validacion = valor.length === 18 && this.validate_ine;
                    
                    break;

                // case 'domicilio_numero_servicio':
                //     validacion = valor.length === 12 && this.validate_n_servicio;
                //     break;
                default:
                    validacion = false;
                    break;
            }
            
            if (validacion) {

                const res = await this.checkExistence(clave, valor);
                if (clave === 'numero_ocr' || clave === 'clave_elector') {
                    this.alertExistence(res);
                }
                // if (clave === 'domicilio_numero_servicio') {
                //     this.alertExistenceNServicio(res);
                // }
                
            }

            if (clave === 'numero_ocr' && valor.length === 13) {
                this.validate_ocr = false;
            }
            if (clave === 'numero_ocr' && valor.length < 13) {
                this.validate_ocr = true;
            }
            if (clave === 'clave_elector' && valor.length === 18) {
                this.validate_ine = false;
            }
            if (clave === 'clave_elector' && valor.length < 18) {
                this.validate_ine = true;
            }
            // if (clave === 'domicilio_numero_servicio' && valor.length === 12) {
            //     this.validate_n_servicio = false;
            // }
            // if (clave === 'domicilio_numero_servicio' && valor.length < 12) {
            //     this.validate_n_servicio = true;
            // }
        }
    }

    setData = (data, file, nota = '') => {
        this.data = data;

        _.each(this.data, (valor, clave) => {
            this.validExistence(clave, valor);
        });

        if (!_.isEmpty(file)) {
            this.file = file;
            this.notaBuro = nota;
        }
        if (
            !_.isEmpty(this.data.nombre) &&
            !_.isEmpty(this.data.apellido_materno) &&
            !_.isEmpty(this.data.apellido_paterno)
        ) {
            localStorage.setItem(this.data_loca_key, JSON.stringify(this.data));
        } else {
            localStorage.removeItem(this.data_loca_key);
        }
        
    }

    handleResetForm = () => {
        this.data = {};
        this.data_loca_key = `socio-local-${new Date().getTime()}`;
        this.setState({
            resetform: true
        });
        setTimeout(() => {
            this.setState({
                resetform: false
            });
        }, 200);
    }

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    setLocalDataInForm = (item, key) => {
        const d = new Date();
        const fecha_registro =  (d.getMonth()+1) +'-'+ d.getDate() +'-'+ d.getFullYear();

        this.data = item;
        this.data.fecha_registro = fecha_registro;
        this.data_loca_key = key;
        this.forceUpdate();
    }

    unSetLocalDataInForm = () => {
        this.data = {};
        this.handleResetForm();
    }

    validCURP = textUtils.validCURP;

    uploadFile = (objectId) => {
        
        var formData = new FormData();

        formData.append('file', this.file.current.files[0]);
        formData.append('socio_id', objectId);

        return fetch(`${config.domain}/upload_file.php`, {
            method: 'POST',
            body: formData,
        }).then(res => {
            return res.json()
        }).catch(error => {
            alert('Ocurrió un error al intentar subir el archivo. Intente más tarde o contacte a soporte.');
        })
    }

    saveSuburb = service_catalogs.saveSuburb;

    async handleSubmit (event) {

        if (event) event.preventDefault();

        const r = window.confirm('¿Todos los datos son correctos?');

        if (r === true) {
        
            let data = this.data;

            // formatear datos
            _.map(data, (value, key) => {
                let newValue;
                
                    if (!_.isEmpty(value) && _.isString(value)) {
                        switch (key) {
                            case 'lugar_registro':
                                newValue = {
                                    __type: 'Pointer',
                                    className: 'Sucursales',
                                    objectId: value
                                };
                                break;
                            default:
                                newValue = value.toUpperCase().trim();
                                break;
                        }
                    } else {
                        newValue = value;
                    }

                    data[key] = newValue;
            });

            if (!_.isEmpty(data.curp) && !this.validCURP(data.curp)) {

                alert('La CURP no parece cumplir con el formato válido.');
                return;
            }

            // this.handleSpinner();

            let file = false;
            let buro = false;
            let diccionario = {};
            let nuevoSocio = {};

            data.fecha_registro = {
                __type: 'Date',
                iso: new Date().toISOString()
            };

            // Establecer usuario quien registró el socio
            const currentUser = Parse.User.current();
            data.registeredBy = {
                __type: 'Pointer',
                className: '_User',
                objectId: currentUser.id
            }

            // Validar socios repetidos por número de servicios.
            // si existe se debe marcar como en revisión
            // const socios = await this.checkExistence('domicilio_numero_servicio', this.data.domicilio_numero_servicio, objectId);
            
            let en_moderacion = false;
            // if (!(socios.code||socios.error)) {
            //     if (socios.results.length >= 3) {
            //         en_moderacion = true;
            //     }
            // }

            let id_busqueda =   textUtils.setPlainText(data.nombre+data.apellido_paterno+data.apellido_materno).toLowerCase() +
                                data.curp +
                                data.numero_ocr;
                id_busqueda =   id_busqueda.toLowerCase();


            const data_POST = {
                requests: [{
                    method: 'POST',
                    path: `${config.api_path2}/classes/Socios`,
                    body: data
                },{
                    method: 'POST',
                    path: `${config.api_path2}/classes/Buro`,
                    body: {
                        nota: this.notaBuro
                    }
                }]
            };

            let id_partner = '', id_buro = '';

            this.setState({
                loading: true,
                statusBuro: 'saving',
                statusData: 'saving',
            });
            
            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify(data_POST)
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (
                    !_.isUndefined(res[0].success) &&
                    !_.isUndefined(res[1].success)
                ) {
                    id_partner = res[0].success.objectId;
                    id_buro = res[1].success.objectId;
                    return true;
                } else {
                    return false;
                }
                
            })
            .then(res_continue => {
                if (res_continue) {
                    return this.checkExistence('domicilio_numero_servicio', this.data.domicilio_numero_servicio, id_partner);    
                } else {
                    return false;
                }
            })
            .then(res => {
                if (res) {
                    if (!(res.code||res.error)) {
                        if (res.results.length >= 3) {
                            en_moderacion = true;
                        }
                    }
                    return true;
                } else {
                    return false;
                }
                
            })
            .then(res_continue => {
                if (res_continue === true) {
                    if (!_.isEmpty(this.file.current.value)) {
                        return this.uploadFile(id_partner);
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            })
            .then(file => {

                let data_PUT = {
                    requests: [{
                        method: 'POST',
                        path: `${config.api_path2}/classes/SociosDiccionario`,
                        body: {
                            id_busqueda: id_busqueda,
                            Socio: {
                                __type: 'Pointer',
                                className: 'Socios',
                                objectId: id_partner
                            },
                            en_moderacion: en_moderacion
                        }
                    }]
                };

                if (file) {
                    data_PUT.requests.push({
                        method: 'PUT',
                        path: `${config.api_path2}/classes/Socios/${id_partner}`,
                        body: {
                            Buro: {
                                __op:'AddRelation',
                                objects: [{
                                    __type: 'Pointer',
                                    className: 'Buro',
                                    objectId: id_buro
                                }]
                            }
                        }
                    });
                    data_PUT.requests.push({
                        method: 'PUT',
                        path: `${config.api_path2}/classes/Buro/${id_buro}`,
                        body: {
                            Socio: {
                                __type: 'Pointer',
                                className: 'Socio',
                                objectId: id_partner
                            },
                            URL: file.URL,
                            nombre: file.filename
                        }
                    });
                } else {
                    data_PUT.requests.push({
                        method: 'DELETE',
                        path: `${config.api_path2}/classes/Buro/${id_buro}`
                    });
                }
                
                return Rest.peticion(`${config.api_url}/batch`, {
                    method: 'POST',
                    body: JSON.stringify(data_PUT)
                });
                
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (res.length > 2) {
                    if (
                        !_.isUndefined(res[0].success) &&
                        !_.isUndefined(res[1].success) &&
                        !_.isUndefined(res[2].success)
                    ) {
                        localStorage.removeItem(this.data_loca_key);
                        this.handleResetForm();
                        
                        this.setState({
                            statusData: 'saved',
                            statusBuro: 'saved'
                        });
                        alert('La nueva clienta ha sido registrada.');
                    } else {
                        let msg = '';
                        if (!_.isUndefined(res[0].error)) {
                            this.setState({
                                statusData: 'error',
                                statusBuro: 'error'
                            });
                            msg = 'ERROR. La nueva clienta no fueron registrada. Intente más adelante.';
                        } else {
                            this.setState({
                                statusData: 'saved',
                                statusBuro: 'error'
                            });
                            msg = 'ADVERTENCIA. La nueva clienta ha sido registrada.';
                            if (
                                !_.isUndefined(res[1].error) ||
                                !_.isUndefined(res[2].error) 
                            ) {
                                msg += ' Pero no se pudo guardar el archivo de buró de crédito, intente más adelante.';
                            }
                        }
                        alert(msg);
                    }
                    
                } else if (res.length === 2) {
                    console.log('el socio se agregó al diccionario pero no se asignó el archivo de buró');
                    let msg = '';
                    this.setState({
                        statusData: 'saved',
                        statusBuro: 'error'
                    });
                    if (!_.isUndefined(res[0].success)) {
                        localStorage.removeItem(this.data_loca_key);
                        this.handleResetForm();
                        
                        msg = 'ADVERTENCIA. La nueva clienta ha sido registrada. Pero no se pudo guardar el archivo de buró de crédito, intente más adelante.';
                    } else {
                        msg = 'ERROR. La nueva clienta no fueron registrada. Intente más adelante.';
                    }
                    alert(msg);
                } else {
                    console.log('ha ocurrido un error al agregar el socio al diccionario y asignar el archivo de buró.');
                    this.setState({
                        statusData: 'error',
                        statusBuro: 'error'
                    });
                    alert('ERROR. La clienta no pudo ser registrada. Intente más tarde o contacte a soporte.');
                }
                this.setState({
                    loading: false
                });

                return true;
            })
            .then(() => {
                return this.saveSuburb(data.domicilio_colonia, data.domicilio_cp);
            })
            .catch(error => {
                alert('Ha ocurrido un error al intentar guardar los datos. Intente más tarde o contacte a soporte.');
            });
        }

    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} statusData={this.state.statusData} statusBuro={this.state.statusBuro} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                <i className="fas fa-plus mr-2"></i>
                Registro de nueva clienta
                </h3>
                <PartnerLocalList setLocalDataInForm={this.setLocalDataInForm} unSetLocalDataInForm={this.unSetLocalDataInForm}/>
                <div className="card solfin-card">
                    <div className="card-body">
                        <p className="text-info">
                            Todos los campos son obligatorios.
                        </p>

                        <form onSubmit={this.handleSubmit}>
    
                            <PartnerForm required={true} resetform={this.state.resetform} setData={this.setData} data={this.data} />
                            
                            <button type="submit" className="btn btn-primary btn-lg mr-2 btns-form-submit" data-loading-text="Loading...">
                                Guardar
                            </button>
                        </form>
                    
                    </div>
                </div>

                <div className="p-5"></div>
            
                
            </>
        );
    }
}

export default RegistrationForm;