import React, { Component } from 'react';
import _ from 'underscore';
import InputCheckbox from '../forms/InputCheckbox';
import GroupInfoIDs from './GroupInfoIDs';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import Spinner from './../others/Spinner';
import user from './../../settings/user';
import Parse from 'parse';

class GroupListCheckRequisition extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangePartner = this.handleChangePartner.bind(this);
    }

    requisition = {};
    prevState = {};
    state = {
        pagare_individual: false,
        pagare_grupal: false,
        contratos: false,
        pagares_verdes: false,
        poliza_cheques: false,
        Socios: {},

        status: '',
        disabledBtn: true,
        loading: false,
        verified: false
    }

    componentDidMount = () => {
        const params = new URLSearchParams({
            excludeKeys: 'registeredBy',
            include: 'Grupo,Grupo.Socios,Sucursal'
        });
        Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.props.match.params.id_solicitud}?${params.toString()}`, {
            method: 'GET'
        }).then(res => {
            return res.json();
        }).then(res => {
            this.requisition = res;
            if (_.isEmpty(res.verificacion_checklist)) {
                let partners = {}
                _.map(this.requisition.Grupo.Socios, partner => {
                    partners[partner.objectId] = {
                        credencial: false,
                        curp: false,
                        acta_nacimiento: false,
                        verificacion_ocular: false,
                        comprobante_domicilio: false
                    }
                });

                let state = this.state;
                state.Socios = partners;
    
                this.prevState = JSON.stringify(state);
                this.setState(state);
            } else {
                let state = _.extend(res.verificacion_checklist, {verified: res.verificado || false}, {disabledBtn: this.setDisabled(res.verificacion_checklist)})
                this.prevState = JSON.stringify(state);
                this.setState(state);
            }
        })
    }

    dismissibleAlert = () => {
        this.setState({
            status: ''
        })
    }

    setDisabled = (data) => {
        let socios = _.map(data.Socios, (value, key) => {
            return _.map(value, (value, key) => {
                return value;
            })
        })
        socios = _.flatten(socios);
        if (data.pagare_individual === true &&
            data.pagare_grupal === true &&
            data.contratos === true &&
            data.pagares_verdes === true &&
            data.poliza_cheques === true &&
            !_.contains(_.values(socios), false) === true
        ) {
            return false;
        } else {
            return true;
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let state = this.state;

        state[name] = value;

        this.setState(state);

        this.handleSubmit(state);
    }

    handleChangePartner = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let state = this.state;

        const {Socios} = state;
        const objectId = target.id;
        Socios[objectId][name] = value;
        state.Socios = Socios;

        this.setState(state);

        this.handleSubmit(state);
    }

    varTimeout = null;
    handleSubmit = (_data) => {
        clearTimeout(this.varTimeout);
        this.setState({
            status: 'sending'
        });

        const data = {
            pagare_individual: _data.pagare_individual,
            pagare_grupal: _data.pagare_grupal,
            contratos: _data.contratos,
            pagares_verdes: _data.pagares_verdes,
            poliza_cheques: _data.poliza_cheques,
            Socios: _data.Socios
        }

        Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.props.match.params.id_solicitud}`, {
            method: 'PUT',
            body: JSON.stringify({
                verificacion_checklist: data
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.code) {
                this.setState(_.extend(JSON.parse(this.prevState), {
                    status: 'error'
                }))
            } else {
                this.prevState = JSON.stringify(this.state);
                setTimeout(() => {
                    this.setState({
                        status: 'success',
                        disabledBtn: this.setDisabled(data)
                    })
                }, 400);
            }
            
        }).catch(error => {
            console.log(error);
            this.setState(_.extend(JSON.parse(this.prevState), {
                status: 'error'
            }))
        }).then(() => {
            this.varTimeout = setTimeout(() => {
                this.setState({
                    status: ''
                })
            }, 5*1000);
        })
    }

    handleCheckGroup = () => {
        this.setState({
            loading: true
        })
        Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.props.match.params.id_solicitud}`, {
            method: 'PUT',
            body: JSON.stringify({
                verificado: true,
                fecha_verificacion: {
                    __type: 'Date',
                    iso: new Date().toISOString()
                },
                verificado_por: {
                    __type: 'Pointer',
                    className: '_User',
                    objectId: Parse.User.current().toJSON().objectId
                },
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.code) {
                this.setState({
                    verified: false
                });
                alert('Ha ocurrido un error al verificar el grupo. Intente más tarde.');
            } else {
                this.setState({
                    verified: true
                });
                alert('El grupo ha sido verificado.');
            }
            
            
        }).catch(error => {
            alert('Ha ocurrido un error al verificar el grupo. Intente más tarde.');
        }).then(() => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        let id_en_sucursal = {};
        let Sucursal = {};
        let id_en_sucursal_solicitud = {};
        let Grupo = {};
        if (!_.isEmpty(this.requisition)) {
            id_en_sucursal = this.requisition.Grupo.id_en_sucursal;
            Sucursal = this.requisition.Sucursal.sigla;
            id_en_sucursal_solicitud = this.requisition.id_en_sucursal;
            Grupo = this.requisition.Grupo;
        }
        return (
            <>
                <Spinner loading={this.state.loading}/>
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-tasks mr-2"></i>
                    Verificaciones
                </h3>
                
                <div className="card">
                    {!_.isEmpty(this.requisition) === true && <div className="card-body">
                        
                        <GroupInfoIDs id_en_sucursal={id_en_sucursal} Sucursal={Sucursal} id_en_sucursal_solicitud={id_en_sucursal_solicitud}/>

                        <small className="text-muted">Grupo</small>
                        <legend>{Grupo.nombre}</legend>

                        <br/>

                        <p className="text-uppercase"><b>Validación grupal:</b></p>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body p-3">
                                        <table className="table m-0">
                                            <thead>
                                                <tr>
                                                    <th colSpan="2" className="border-top-0 pt-2">{Grupo.nombre}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Pagaré individual</td>
                                                    <td className="text-right p-0">
                                                        {
                                                            user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true &&
                                                            <InputCheckbox name="pagare_individual" disabled={this.state.verified} handleChange={this.handleChange} value={this.state.pagare_individual} handleSubmit={this.handleSubmit} />
                                                            ||
                                                            <i className={`btn disabled p-3 m-0 ${this.state.pagare_individual?'fas fa-check-square text-primary':'far fa-square'}`}></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Pagaré grupal</td>
                                                    <td className="text-right p-0">
                                                        {
                                                            user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true &&
                                                            <InputCheckbox name="pagare_grupal" disabled={this.state.verified} handleChange={this.handleChange} value={this.state.pagare_grupal} handleSubmit={this.handleSubmit} />
                                                            ||
                                                            <i className={`btn disabled p-3 m-0 ${this.state.pagare_grupal?'fas fa-check-square text-primary':'far fa-square'}`}></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Contratos</td>
                                                    <td className="text-right p-0">
                                                        {
                                                            user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true &&
                                                            <InputCheckbox name="contratos" disabled={this.state.verified} handleChange={this.handleChange} value={this.state.contratos} handleSubmit={this.handleSubmit} />
                                                            ||
                                                            <i className={`btn disabled p-3 m-0 ${this.state.contratos?'fas fa-check-square text-primary':'far fa-square'}`}></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Pagarés verdes</td>
                                                    <td className="text-right p-0">
                                                        {
                                                            user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true &&
                                                            <InputCheckbox name="pagares_verdes" disabled={this.state.verified} handleChange={this.handleChange} value={this.state.pagares_verdes} handleSubmit={this.handleSubmit} />
                                                            ||
                                                            <i className={`btn disabled p-3 m-0 ${this.state.pagares_verdes?'fas fa-check-square text-primary':'far fa-square'}`}></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Póliza de cheques</td>
                                                    <td className="text-right p-0">
                                                        {
                                                            user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true &&
                                                            <InputCheckbox name="poliza_cheques" disabled={this.state.verified} handleChange={this.handleChange} value={this.state.poliza_cheques} handleSubmit={this.handleSubmit} />
                                                            ||
                                                            <i className={`btn disabled p-3 m-0 ${this.state.poliza_cheques?'fas fa-check-square text-primary':'far fa-square'}`}></i>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br/><br/>

                        <p className="text-uppercase"><b>Validaciones indivituales:</b></p>

                        <div className="row">
                            {Grupo.Socios.length > 0 && Grupo.Socios.map((item, index) => <div className="col-md-4 d-flex flex-row" key={index}>
                                <div className="card mb-3 w-100">
                                    <div className="card-body p-3">
                                        <table className="table m-0">
                                            <thead>
                                                <tr>
                                                    <th colSpan="2" className="border-top-0 pt-2">{item.nombre} {item.apellido_paterno} {item.apellido_materno}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Credencial</td>
                                                    <td className="text-right p-0">
                                                        {
                                                            user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true &&
                                                            <InputCheckbox name="credencial" disabled={this.state.verified} handleChange={this.handleChangePartner} id={item.objectId} value={this.state.Socios[item.objectId].credencial} handleSubmit={this.handleSubmit} />
                                                            ||
                                                            <i className={`btn disabled p-3 m-0 ${this.state.Socios[item.objectId].credencial?'fas fa-check-square text-primary':'far fa-square'}`}></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>CURP</td>
                                                    <td className="text-right p-0">
                                                        {
                                                            user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true &&
                                                            <InputCheckbox name="curp" disabled={this.state.verified} handleChange={this.handleChangePartner} id={item.objectId} value={this.state.Socios[item.objectId].curp} handleSubmit={this.handleSubmit} />
                                                            ||
                                                            <i className={`btn disabled p-3 m-0 ${this.state.Socios[item.objectId].curp?'fas fa-check-square text-primary':'far fa-square'}`}></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Acta de nacimiento</td>
                                                    <td className="text-right p-0">
                                                        {
                                                            user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true &&
                                                            <InputCheckbox name="acta_nacimiento" disabled={this.state.verified} handleChange={this.handleChangePartner} id={item.objectId} value={this.state.Socios[item.objectId].acta_nacimiento} handleSubmit={this.handleSubmit} />
                                                            ||
                                                            <i className={`btn disabled p-3 m-0 ${this.state.Socios[item.objectId].acta_nacimiento?'fas fa-check-square text-primary':'far fa-square'}`}></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Verificación ocular</td>
                                                    <td className="text-right p-0">
                                                        {
                                                            user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true &&
                                                            <InputCheckbox name="verificacion_ocular" disabled={this.state.verified} handleChange={this.handleChangePartner} id={item.objectId} value={this.state.Socios[item.objectId].verificacion_ocular} handleSubmit={this.handleSubmit} />
                                                            ||
                                                            <i className={`btn disabled p-3 m-0 ${this.state.Socios[item.objectId].verificacion_ocular?'fas fa-check-square text-primary':'far fa-square'}`}></i>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Comprobante de domicilio</td>
                                                    <td className="text-right p-0">
                                                        {
                                                            user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true &&
                                                            <InputCheckbox name="comprobante_domicilio" disabled={this.state.verified} handleChange={this.handleChangePartner} id={item.objectId} value={this.state.Socios[item.objectId].comprobante_domicilio} handleSubmit={this.handleSubmit} />
                                                            ||
                                                            <i className={`btn disabled p-3 m-0 ${this.state.Socios[item.objectId].comprobante_domicilio?'fas fa-check-square text-primary':'far fa-square'}`}></i>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>)}
                        </div>

                        <br/><br/>

                        <div className="row">
                            {user.rol.permisos.Verificaciones && user.rol.permisos.Verificaciones.editar === true && <div className="col-md-4">
                                {
                                    this.state.verified === false && <button type="button" className="btn btn-primary btn-block" disabled={this.state.disabledBtn} onClick={() => this.handleCheckGroup()}>Verificar grupo</button>
                                    ||
                                    <div className="alert alert-success text-center" role="alert">
                                        Grupo verificado
                                    </div>
                                }
                            </div> || <div className="col-md-4">
                                {
                                    this.state.verified === false && <div className="alert alert-info text-center" role="alert">
                                        Pendiente a verificar
                                    </div>
                                    ||
                                    <div className="alert alert-success text-center" role="alert">
                                        Grupo verificado
                                    </div>
                                }
                            </div>}
                        </div>

                        <br/><br/>
                    </div>}

                    
                    <div className="fixed-bottom d-flex align-items-center flex-column">
                        {this.state.status === 'success' && <div className="alert alert-success alert-dismissible" role="alert">
                            Actualización exitosa.
                            <button type="button" className="close" onClick={() => this.dismissibleAlert()} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>

                        </div>}
                        {this.state.status === 'error' && <div className="alert alert-danger alert-dismissible" role="alert">
                            A ocurrido un error inesperado. Intente más tarde.
                            <button type="button" className="close" onClick={() => this.dismissibleAlert()} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>}
                        {this.state.status === 'sending' && <div className="alert alert-info" role="alert">
                            Guardando...
                        </div>}
                    </div>

                    
                </div>

                <div className="p-5"></div>
            </>
        );
    }
}

export default GroupListCheckRequisition;