import React, { Component } from 'react';
import Background from '../../assets/static/login-bg.jpg';
import Logo from '../../assets/static/logo-rosa.png';
import Parse from 'parse';
import Rest from './../../Server/Rest';
import config from './../../settings/config';
import 'animate.css';

class Login extends Component {

    constructor (props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            logged: false,
            invalid: false,
            togglePassword: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    UNSAFE_componentWillMount = () => {
        Rest.validMe().then(res => {
            if (res) {
                window.location = '/escritorio';
            } else {
                Parse.User.logOut();
                window.location = '/acceso';
            }
        })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeBtnSubmit = () => {
        let btn_submit = document.getElementsByClassName('btns-form-submit');

        btn_submit[0].disabled = !btn_submit[0].disabled;

        if (btn_submit[0].disabled === true) {
            btn_submit[0].value = 'Entrando...';
        } else {
            btn_submit[0].value = 'Entrar';
        }
    }

    getRole = async () => {

        const rol_pointer = Parse.User.current().get('Rol').toJSON();

        const rol = await Rest.peticion(`${config.api_url}/classes/Roles/${rol_pointer.objectId}`, {
            method: 'GET'
        }).then(res => {return res.json()}).then(res => {return res}).catch(error => console.log(error));

        return rol;
        
    }

    getConfig = async () => {

        const configSys = await Rest.peticion(`${config.api_url}/config`, {
            method: 'GET'
        }).then(res => {return res.json()}).then(res => {return res.params}).catch(error => console.log(error));
        return configSys;
        
    }

    handleInputPassword = () => {
        this.setState(state => ({
            togglePassword: !state.togglePassword
        }))
    }

    handleSubmit = async (event) => {

        if (event) event.preventDefault();
        
        this.handleChangeBtnSubmit();
        
        const {username, password} = this.state;

        const user = Parse.User.logIn(username, password);
        user.then(async resp => {

            if (resp.toJSON().active === true) {
                const rol = await this.getRole();
                const config = await this.getConfig();
    
                if (!(rol.code || rol.error)) {
                    localStorage.setItem('Rol', JSON.stringify(rol));
                    localStorage.setItem('Config', JSON.stringify(config));
                } else {
                    Parse.User.logOut().then(resp => {
                
                        window.location = '/acceso';
                        
                    }).catch(resp => {
                        
                        alert('Ha ocurrido un error al intentar cerrar sesión.');
                        
                    });
                }
    
                this.setState({
                    logged: true
                });
                
                this.handleChangeBtnSubmit();
    
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000);
            } else {
                this.handleChangeBtnSubmit();
                alert('Por el momento no es posible iniciar sesión.');
            }


            
        }).catch(resp => {
            
            switch (resp.code) {
                case 101:
                    this.setState({
                        invalid: true
                    });
                    setTimeout(() => {
                        this.setState({
                            invalid: false
                        });
                    }, 5000);
                    break;
                default:
                    alert('Ha ocurrido un error al intentar iniciar sesión. Intente más tarde.');
                    break;
            }
            this.handleChangeBtnSubmit();
        });
    }

    render() {
        return (
            <div className="container-fluid vh-100 d-flex flex-column align-items-center justify-content-center pt-3 pb-3" style={styles.bg}>
                <img src={Logo} className="pb-4" alt="SOLFIN" />
                <div className={`card ${this.state.invalid? 'animate__animated animate__shakeX':''}`} style={styles.widthLogin}>
                    <div className="card-body p-3">
                        <h5 className="text-uppercase text-center pb-4 pt-2 text-pink-dark"><b>Iniciar Sesión</b></h5>
                        <form className="pb-4" onSubmit={this.handleSubmit}>
                            <label htmlFor="exampleInputEmail1" className="text-pink-dark">Usuario o Email</label>
                            <div className="input-group mb-3 ">
                                <input type="text" value={this.state.username} name="username" required onChange={this.handleChange} className="form-control shadow-sm" id="exampleInputEmail1" aria-describedby="basic-addon1" />
                                <div className="input-group-append">
                                    <span className="input-group-text shadow-sm" id="basic-addon1">
                                        <i className="fas fa-user"></i>
                                    </span>
                                </div>
                                {/* <small id="emailHelp" className="form-text text-danger">We'll never share your email with anyone else.</small> */}
                            </div>
                            <label htmlFor="exampleInputPassword2" className="text-pink-dark">Contraseña</label>
                            <div className="input-group mb-3 pb-4">
                                <input type={`${!this.state.togglePassword?'password':'text'}`} value={this.state.password} name="password" required onChange={this.handleChange} className="form-control shadow-sm" id="exampleInputPassword2" aria-describedby="basic-addon2" />
                                <div className="input-group-append">
                                    <button 
                                    className="btn btn-outline-secondary shadow-sm" 
                                    type="button" 
                                    onClick={() => this.handleInputPassword()} id="button-addon2"
                                    title={`${!this.state.togglePassword?'Ver contraseña':'Ocultar contraseña'}`}>
                                        <i className={`fas fa-${!this.state.togglePassword?'eye':'eye-slash'}`}></i>
                                    </button>
                                </div>
                            </div>
                            {/* <div className="form-group form-check pb-4">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label text-pink-dark" htmlFor="exampleCheck1">Recuérdame</label>
                            </div> */}
                            <input type="submit" value="Entrar" className={`btn ${this.state.logged? 'btn-success' : 'btn-primary'} btn-lg btn-block text-uppercase shadow-sm btns-form-submit`} />
                            {this.state.logged && <p className="text-success m-0 mt-3 text-center">Redireccionando...</p>}
                            {(!this.state.logged && this.state.invalid) && <p className="text-danger m-0 mt-3 text-center">El nombre de usuario o contraseña no es correcto.</p>}
                        </form>
                        {/* <a href="#" className="btn btn-link btn-block text-pink-dark">Olvidé mi contraseña</a> */}
                    </div>
                    
                </div>
            </div>
        );
    }
}

const styles = {
    bg: {
        backgroundColor: '#C789B1',
        backgroundImage: 'url('+Background+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: 610
    },
    widthLogin: {
        maxWidth: 320,
        width: '100%'
    }
}

export default Login;