import React from 'react';
import GroupList from './GroupList';
import { Link } from "react-router-dom";
import Parse from 'parse';
import config from '../../settings/config';
import _ from 'underscore';
import Rest from '../../Server/Rest';
import Spinner from '../others/Spinner';

export function openDB() {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open('localGroups', 1);
  
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('myStore')) {
          db.createObjectStore('myStore');
        }
      };
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
}


class GroupListLocal extends GroupList {

    title = "Lista de grupos locales";

    UNSAFE_componentWillMount = () => {
        const prevState = this.state;
        this.setState({
            ...prevState,
            loading: false,
            localGroups: [],
        });
        this.getLocalGroups();
    }

    getLocalGroups = async () => {
        try {
            const db = await openDB();
            const tx = db.transaction('myStore', 'readonly');
            const store = tx.objectStore('myStore');
            const prefix = `${Parse.User.current().toJSON().objectId}-local-new-group-`;
            // Definimos el rango de claves que comienza con el prefijo especificado
            const range = IDBKeyRange.bound(prefix, prefix + '\uffff');
      
            const request = store.openCursor(range);
            const matchingData = [];
      
            request.onsuccess = (event) => {
              const cursor = event.target.result;
              if (cursor) {
                matchingData.push({
                    localId: cursor.key.replace(`${Parse.User.current().toJSON().objectId}-`, ''),
                    ...cursor.value
                });
                cursor.continue();
              } else {
                console.log('Matching data retrieved from IndexedDB:', matchingData);
                const prevState = this.state;
                this.setState({
                    ...prevState,
                    localGroups: matchingData
                });
              }
            };
      
            await new Promise((resolve) => {
              tx.oncomplete = resolve;
            });
        } catch (error) {
            console.error('Error retrieving matching data from IndexedDB:', error);
        }
    }

    deleteLocalData = async (localStorageId) => {
        try {
            const db = await openDB();
            const tx = db.transaction('myStore', 'readwrite');
            const store = tx.objectStore('myStore');
            store.delete(`${Parse.User.current().toJSON().objectId}-${localStorageId}`);
            await new Promise((resolve) => {
              tx.oncomplete = resolve;
            });
            this.getLocalGroups();
            console.log('Object deleted from IndexedDB');
          } catch (error) {
            console.error('Error deleting object from IndexedDB:', error);
          }
    }

    deleteLocalDataAlert = async (localStorageId) => {
        if (window.confirm('¿Realmente deseas eliminar el registro local?')) {
            await this.deleteLocalData(localStorageId);
        }
    };

    getResource = () => {
        this.setState({
            collection: []
        })
        console.log('No es necesario consultar grupos en la base de datos.');
    }

    saveDocumentsInDB = async (documents, groupId) => {
        return new Promise((resolve, reject) => {
            let requests = [];
    
            _.map(documents, document => {
                requests.push({
                    method: 'POST',
                    path: `${config.api_path2}/classes/Documentos`,
                    body: {
                        Socio: document.clientId ? {
                            __type: 'Pointer',
                            className: 'Socios',
                            objectId: document.clientId
                        } : {__op: 'Delete'},
                        Grupo: {
                            __type: 'Pointer',
                            className: 'Grupos',
                            objectId: groupId
                        },
                        nombre: document.nombre,
                        URL: document.ruta,
                        type: document.type || 'fotogrupal',
                    },
                })
            });
            
            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify({
                    requests: requests
                })
            }).then(res => {
                return res.json();
            }).then(res => {
                console.log(res);
                resolve(res);
            }).catch(error => {
                console.log(error);
                reject(error);
            });
        });
    }

    addPhotoGroup = async (groupId, ruta = undefined, Documentos) => {
        return new Promise((resolve, reject) => {
            Rest.peticion(`${config.api_url}/classes/Grupos/${groupId}`, {
                method: 'PUT',
                body: JSON.stringify({
                    foto_grupal_url: ruta ? ruta : {__op: 'Delete'},
                    Documentos: Documentos
                }),
            }).then(res => {
                return res.json();
            }).then(res => {
                resolve(res);
            })
            .catch(error => {
                reject(error);
            })
        });
    }

    saveDocuments = (data) => {
        
        let requests = [];
        _.map(data.clients, (client, index) => {
            let formData1 = new FormData();
            formData1.append('base64', data.documents[index].birthCertificate.imageUrl);
            formData1.append('file_name', 'actanacimiento');
            formData1.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData1,
                })
            );

            let formData2 = new FormData();
            formData2.append('base64', data.documents[index].curp.imageUrl);
            formData2.append('file_name', 'curp');
            formData2.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData2,
                })
            );

            let formData3 = new FormData();
            formData3.append('base64', data.documents[index].ineBackSide.imageUrl);
            formData3.append('file_name', 'credencialreverso');
            formData3.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData3,
                })
            );

            let formData4 = new FormData();
            formData4.append('base64', data.documents[index].ineFrontSide.imageUrl);
            formData4.append('file_name', 'credencialanverso');
            formData4.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData4,
                })
            );

            let formData5 = new FormData();
            formData5.append('base64', data.documents[index].proofAddress.imageUrl);
            formData5.append('file_name', 'comprobantedomicilio');
            formData5.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData5,
                })
            );

            let formData6 = new FormData();
            formData6.append('base64', data.documents[index].signature.imageUrl);
            formData6.append('file_name', 'firma');
            formData6.append('objectId', client.objectId);
            requests.push(
                fetch(`${config.domain}/upload_documents.php`, {
                    method: 'POST',
                    body: formData6,
                })
            );

            let formData7 = new FormData();
            formData7.append('base64', data.grupo.foto_grupal_imageUrl);
            formData7.append('file_name', 'foto_grupal');
            formData7.append('objectId', data.grupo.objectId);
            requests.push(
                fetch(`${config.domain}/upload_photo.php`, {
                    method: 'POST',
                    body: formData7,
                })
            );
        });
        
        let prevState1 = this.state;
        this.setState({
            ...prevState1,
            loading: true
        });
        this.getLocalGroups();

        Promise.all(requests)
        .then(responses => Promise.all(responses.map(response => response.json())))
        .then(async (responses) => {
            // separamos la respuesta de la foto grupal
            const groupResponse = responses.pop();
            // guardamos los documentos en la DB
            const savedDocuments = await this.saveDocumentsInDB(responses, data.grupo.objectId);
            const PointerDocs = _.map(savedDocuments, document => {
                return {
                    __type: 'Pointer',
                    className: 'Documents',
                    objectId: document.success.objectId
                }
            })
            // guardamos la foto grupal en la DB
            await this.addPhotoGroup(data.grupo.objectId, groupResponse.ruta, PointerDocs);
            await this.deleteLocalData(`${Parse.User.current().toJSON().objectId}-${data.localId}`);
            let prevState2 = this.state;
            this.setState({
                ...prevState2,
                loading: false
            });

        })
        .catch((error) => {
            // Manejar cualquier error en la solicitud
            console.error('Error al almacenar la imagen:', error);
        });
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading}/>
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-users mr-2"></i>
                    {this.title}
                </h3>
                
                <div className="card">
                    <div className="card-body">
                            {
                                this.state.localGroups !== undefined && this.state.localGroups.map((data) =>
                                <div className="card w-100 mb-3" key={data.localId}>
                                    <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
                                        <div>
                                            <h6 className="card-subtitle mb-2 text-muted">Grupo</h6>
                                            <h5 className="card-title text-primary">{data.grupo.nombre}</h5>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button type="button" className="btn btn-secondary mr-3" disabled={!data.grupo.saved} onClick={() => this.saveDocuments(data)}>
                                                Subir archivos <i className="fas fa-upload"></i>
                                            </button>
                                            <Link to={`/grupos/nuevo-grupo/${data.localId}`} title="Ver" className={`btn btn-secondary ${data.grupo.saved && 'disabled'}`}>
                                                Seguir editando <i className="fas fa-chevron-right"></i>
                                            </Link>
                                            <button type="button" className="btn btn-danger ml-3" onClick={() => this.deleteLocalDataAlert(data.localId)}>Delete</button>
                                        </div>
                                    </div>
                                </div>)
                            }
                    </div>
                </div>

                <div className="p-5"></div>
            </>
        );
    }
}

export default GroupListLocal;