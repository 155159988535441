import React, { Component } from 'react';
import PartnerList from './../partners/PartnerList';
import Spinner from './../others/Spinner';
import TablePagination from './../others/TablePagination';
import TableCountInfo from './../others/TableCountInfo';
import GroupSearchRequisitions from './GroupSearchRequisitions';
import config from './../../settings/config';
import Rest from '../../Server/Rest';
import Parse from 'parse';
import _ from 'underscore';
import Alert from '../others/Alert';
import formatString from '../../utilities/format-string';

class GroupRequisitions extends PartnerList {

    title = 'Lista de Solicitudes';
    limit = 100;

    getResource = (index = 0, _where = {}) => {

        this.where = _where;

        this.setState(state => ({
            index: index
        }));
        
        this.handleSpinner();

        let params;
        let where = {};
        where = JSON.stringify(_where);

        params = new URLSearchParams({
            
            limit: this.limit,
            skip: index,
            where: where,
            count: 1,
            order: 'id_en_sucursal,-createdAt',
            include: 'Grupo,Sucursal'
        });
        
        Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                this.rows = res.count;
                this.setState({
                    collection: res.results,
                    total: res.count
                });
            }
        })
        .then(() => {
            this.handleSpinner();
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-users mr-2"></i>
                    {this.title}
                </h3>
                
                <div className="card">
                    <div className="card-body">
                        
                        <div className="row">
                            <div className="col-lg-8">
                                <GroupSearchRequisitions getResource={this.getResource} />
                            </div>
                        </div>
                        <br />

                        <TableCountInfo total={this.state.total} />

                        <div className="table-responsive">    
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nombre</th>
                                        <th>ID grupo</th>
                                        <th>ID solicitud</th>
                                        <th>Ciclo</th>
                                        <th>Status</th>
                                        <th>Sucursal</th>
                                        <th>F. Registro</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.collection.length && this.state.collection.map((item, index) =>
                                            <tr id={item.objectId} key={item.objectId} className={`${item.Grupo?'':'bg-danger'}`}>
                                                <Requisition item={item} index={index}/>
                                            </tr>
                                        ) || <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="alert alert-warning m-0" role="alert">
                                                    No se hallaron registros
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>    
                    
                        <TableCountInfo total={this.state.total} />

                        <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where} />
                    
                    </div>
                </div>
                {/* <Alert/> se está trabajando en ello */}
                <div className="p-5"></div>
            </>
        );
    }
}

export default GroupRequisitions;




class Requisition extends Component {
    constructor(props){
        super(props);

        this.state = this.props.item;
        // this.state.id_en_sucursal_grupo = this.props.item.Grupo.id_en_sucursal;

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeGrupo = this.handleChangeGrupo.bind(this);
        this.handle_id_en_sucursal_grupo = this.handle_id_en_sucursal_grupo.bind(this);
        this.handle_id_en_sucursal_solicitud = this.handle_id_en_sucursal_solicitud.bind(this);
        this.handle_ciclo = this.handle_ciclo.bind(this);
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeGrupo = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let {state} = this;

        state.Grupo[name] = value;
    
        this.setState({
          Grupo: state.Grupo
        });
    }

    handle_id_en_sucursal_solicitud = (event) => {
        if (event) event.preventDefault();

        Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.state.objectId}`, {
            method: 'PUT',
            body: JSON.stringify({
                id_en_sucursal: parseInt(this.state.id_en_sucursal)
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.code) {
                alert('Error');
            } else {
                alert('Exito');
            }
        }).catch(error => {
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }
    handle_id_en_sucursal_grupo = (event) => {
        if (event) event.preventDefault();

        Rest.peticion(`${config.api_url}/classes/Grupos/${this.state.Grupo.objectId}`, {
            method: 'PUT',
            body: JSON.stringify({
                id_en_sucursal: parseInt(this.state.Grupo.id_en_sucursal)
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.code) {
                alert('Error');
            } else {
                alert('Exito');
            }
        }).catch(error => {
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }
    handle_ciclo = (event) => {
        if (event) event.preventDefault();

        Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.state.objectId}`, {
            method: 'PUT',
            body: JSON.stringify({
                ciclo: parseInt(this.state.ciclo)
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.code) {
                alert('Error');
            } else {
                alert('Exito');
            }
        }).catch(error => {
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    render() {
        return (
            <>
                <td>{this.props.index +1}</td>
                <td>
                    {this.state.Grupo && this.state.Grupo.nombre}
                </td>
                <td>
                    {this.state.Grupo &&
                    <form onSubmit={this.handle_id_en_sucursal_grupo}>
                        <div className="input-group">
                            <input type="number" className="form-control input-sm" name="id_en_sucursal" value={this.state.Grupo.id_en_sucursal} onChange={this.handleChangeGrupo}/>
                            <div className="input-group-append">
                                <button className="btn btn-secondary" type="submit">
                                    <i className="fas fa-save" />
                                </button>
                            </div>
                        </div>
                    </form>}
                </td>
                <td>
                    <form onSubmit={this.handle_id_en_sucursal_solicitud}>
                        <div className="input-group">
                            <input type="number" className="form-control input-sm" name="id_en_sucursal" value={this.state.id_en_sucursal} onChange={this.handleChange}/>
                            <div className="input-group-append">
                                <button className="btn btn-secondary" type="submit">
                                    <i className="fas fa-save" />
                                </button>
                            </div>
                        </div>
                    </form>
                </td>
                <td>
                    <form onSubmit={this.handle_ciclo}>
                        <div className="input-group">
                            <input type="number" className="form-control input-sm" name="ciclo" value={this.state.ciclo} onChange={this.handleChange}/>
                            <div className="input-group-append">
                                <button className="btn btn-secondary" type="submit">
                                    <i className="fas fa-save" />
                                </button>
                            </div>
                        </div>
                    </form>
                </td>
                <td>
                    {this.state.status}
                </td>
                <td>
                    {this.state.Sucursal.nombre}
                </td>
                <td>
                    {formatString.setLegibleDate(this.state.createdAt)}
                </td>
            </>
        );
    }
}