import React, { Component } from 'react';
import DatePicker from 'react-datepicker';


class DisbursmentAndPaymentFilter extends Component {

    state = {
        nombre: this.props.state.nombre,
        promotor: this.props.state.promotor,
        dateStart: this.props.state.dateStart,
        dateEnd: this.props.state.dateEnd,
        Sucursal: this.props.state.Sucursal,
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({
            nombre: nextProps.state.nombre,
            promotor: nextProps.state.promotor,
            dateStart: nextProps.state.dateStart,
            dateEnd: nextProps.state.dateEnd,
            Sucursal: nextProps.state.Sucursal,
        })
    }

    handleChange = this.props.handleChange;

    handleChangeDate = this.props.handleChangeDate;

    handleFilter = (event) => {
      if (event) event.preventDefault();
      this.props.handleFilter();
    };

    render() {
        return (
            <div>
                <div className="m-4"></div>      
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">
                            <i className="fas fa-filter mr-2"></i>
                            Filtro
                        </h5>
                        <form onSubmit={this.handleFilter} className="row align-items-start">

                            {(this.props.inputDateEnabled !== true) && <div className="col-md-4 form-group d-flex flex-column">
                                <label htmlFor="fecha">Fecha que desea contabilizar</label>
                                <div className="input-group">
                                    {/* <input type="text" className="form-control" id="fecha" /> */}
                                    <div className="d-flex">
                                        <DatePicker
                                        className="form-control mr-1"
                                        locale="es"
                                        selected={this.state.dateStart}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={date => this.handleChangeDate(date, 'dateStart')}
                                        selectsStart
                                        // startDate={this.state.dateStart}
                                        // endDate={this.state.dateEnd}
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={false}
                                        minDate={this.filter_from}
                                        placeholderText="Inicio" 
                                        required />
                                        {/* <DatePicker
                                        className="form-control ml-1"
                                        locale="es"
                                        selected={this.state.dateEnd}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={date => this.handleChangeDate(date, 'dateEnd')}
                                        selectsEnd
                                        startDate={this.state.dateStart}
                                        endDate={this.state.dateEnd}
                                        minDate={this.state.dateStart || this.filter_from}
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={false}
                                        placeholderText="Final" 
                                        required /> */}
                                    </div>
                                </div>
                            </div>}
                            <div className="col-12"></div>
                                <div className="col-md-3 mt-2 form-group">
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Aplicar
                                    </button>
                                </div>
                            </form>
                    </div>
                </div>
                <div className="m-4"></div> 
            </div>
        );
    }
}

export default DisbursmentAndPaymentFilter;