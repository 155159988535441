import React, { useEffect, useState } from "react";
import _ from 'underscore';
import { getPledgeDataModel } from "./models";

const PledgeData = React.memo((props) => {

    const [data, setData] = useState(getPledgeDataModel());
    const [toDay] = useState(new Date().getTime());

    useEffect(() => {
        if (!_.isEmpty(props.data)) {
            setData(props.data);
        }
    }, [props.data]);

    const handleChange = (e) => {
        let target = e.target;
        let value = target.value;
        let name = target.name;

        if (!_.isEmpty(value) && target.type === "number") {
            value = parseInt(value);
        }
        
        const nextData = {...data, [name]: value };
        
        setData(nextData);

        if (props.handleChange) {
            props.handleChange(nextData, props.index);
        }
    }

    return <div>
        <h3>Garantías prendarias</h3>
        <div className="row">
            <div className="form-group col-md-12">
                <label>Artículo 1</label>
                <input type="text" className="form-control text-uppercase" id={`articulo_1_nombre_${toDay}`} name="garantia_prendaria_1_nombre" label="Garantía prendaria" value={data.garantia_prendaria_1_nombre} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6">
                <label>Marca</label>
                <input type="text" className="form-control text-uppercase" id={`articulo_1_marca_${toDay}`} name="garantia_prendaria_1_marca" label="Marca del artículo" value={data.garantia_prendaria_1_marca} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6">
                <label>Estado del artículo</label>
                <select className="form-control text-uppercase" id={`articulo_1_estado_${toDay}`} name="garantia_prendaria_1_estado" label="Estado del artículo" value={data.garantia_prendaria_1_estado} onChange={handleChange} required={true} >
                    <option value="" disabled>Elija una opción...</option>
                    <option value="BUENO">Bueno</option>
                    <option value="MALO">Malo</option>
                    <option value="REGULAR">Regular</option>
                </select>
            </div>
        </div>
        <hr/>
        <div className="row">
            <div className="form-group col-md-12">
                <label>Artículo 2</label>
                <input type="text" className="form-control text-uppercase" id={`articulo_2_nombre_${toDay}`} name="garantia_prendaria_2_nombre" label="Garantía prendaria" value={data.garantia_prendaria_2_nombre} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6">
                <label>Marca</label>
                <input type="text" className="form-control text-uppercase" id={`articulo_2_marca_${toDay}`} name="garantia_prendaria_2_marca" label="Marca del artículo" value={data.garantia_prendaria_2_marca} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6">
                <label>Estado del artículo</label>
                <select className="form-control text-uppercase" id={`articulo_2_estado_${toDay}`} name="garantia_prendaria_2_estado" label="Estado del artículo" value={data.garantia_prendaria_2_estado} onChange={handleChange} required={true} >
                    <option value="" disabled>Elija una opción...</option>
                    <option value="BUENO">Bueno</option>
                    <option value="MALO">Malo</option>
                    <option value="REGULAR">Regular</option>
                </select>
            </div>
        </div>
        <hr/>
        <div className="row">
            <div className="form-group col-md-12">
                <label>Artículo 3</label>
                <input type="text" className="form-control text-uppercase" id={`articulo_3_nombre_${toDay}`} name="garantia_prendaria_3_nombre" label="Garantía prendaria" value={data.garantia_prendaria_3_nombre} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6">
                <label>Marca</label>
                <input type="text" className="form-control text-uppercase" id={`articulo_3_marca_${toDay}`} name="garantia_prendaria_3_marca" label="Marca del artículo" value={data.garantia_prendaria_3_marca} onChange={handleChange} required={true} />
            </div>
            <div className="form-group col-md-6">
                <label>Estado del artículo</label>
                <select className="form-control text-uppercase" id={`articulo_3_estado_${toDay}`} name="garantia_prendaria_3_estado" label="Estado del artículo" value={data.garantia_prendaria_3_estado} onChange={handleChange} required={true} >
                    <option value="" disabled>Elija una opción...</option>
                    <option value="BUENO">Bueno</option>
                    <option value="MALO">Malo</option>
                    <option value="REGULAR">Regular</option>
                </select>
            </div>
        </div>
    </div>
})

export default PledgeData;