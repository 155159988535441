import React, { Component } from 'react';

class BranchOffices extends Component {
    render() {
        return (
            <>
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-cogs mr-2"></i>
                    Sucursales
                </h3>
                <br />
                <div className="row">
                    <div className="col-5">
                        <button className="btn btn-primary btn-lg mb-3 btn-block">
                            Nuevo
                        </button>
                        
                        <div className="list-group">
                            <button type="button" className="list-group-item list-group-item-action">
                                Cras justo odio
                            </button>
                            <button type="button" className="list-group-item list-group-item-action">Dapibus ac facilisis in</button>
                            <button type="button" className="list-group-item list-group-item-action">Morbi leo risus</button>
                            <button type="button" className="list-group-item list-group-item-action">Porta ac consectetur ac</button>
                            <button type="button" className="list-group-item list-group-item-action" disabled>Vestibulum at eros</button>
                        </div>
                    </div>
                </div>
                
                {/* <div className="card">
                    <div className="card-body">
                    </div>
                </div> */}
                <div className="p-5"></div>
            </>
        );
    }
}

export default BranchOffices;