import React, { Component } from 'react';
import Parse from 'parse';
import AvatarImg from './../../assets/static/avatar.png';
import user from './../../settings/user';

class Avatar extends Component {

    setFirstText = (text) => {
        const textArray = text.split(' ');
        return textArray[0];
    }

    render() {
        const currentUser = Parse.User.current();
        
        return (
            <div className="d-flex align-items-center">
                <img src={AvatarImg} alt="N" className="rounded-circle mr-2" width="36" height="36" />
                                
                <div className={this.props.className}>
                    <div className="d-flex flex-column text-left text-white ">
                        <span>{this.setFirstText(currentUser.get('nombre'))} {this.setFirstText(currentUser.get('apellidos'))}</span>
                        <small>{user.rol.nombre}</small>
                    </div>
                </div>
            </div>
        );
    }
}

export default Avatar;