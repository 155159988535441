import React, { Component } from 'react';
import _ from 'underscore';

class PartnerLocalList extends Component {
    constructor(props) {
        super(props);
        
        this.handleList = this.handleList.bind(this);
        this.unSetLocalDataInForm = this.unSetLocalDataInForm.bind(this);
        this.setLocalDataInForm = this.setLocalDataInForm.bind(this);
    }

    state = {
        localPartners: [],
        selected: {}
    }

    handleList = () => {
        let items = {};
        _.each(localStorage, (item, index) => {
            if (localStorage.key(index).includes('socio-local-')) {
                items[`${localStorage.key(index)}`] = JSON.parse(localStorage.getItem(localStorage.key(index)));
            }
        });
        
        this.setState({
            localPartners: items
        });
    }

    setLocalDataInForm = (item, key) => {
        this.setState({
            selected: item
        });
        this.props.setLocalDataInForm(item, key);
    }

    unSetLocalDataInForm = () => {
        this.setState({
            selected: {}
        });
        this.props.unSetLocalDataInForm();
    }

    render() {
        const {selected} = this.state;
        return (
            <div>
                <div className="btn-group">
                    <button type="button" className="btn btn-secondary dropdown-toggle mb-3" onFocus={this.handleList} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Datos temporales
                        {/* {!_.isEmpty(selected) && <span className="text-uppercase">{selected.nombre} {selected.apellido_paterno} {selected.apellido_materno}</span> || 'Guardados temporalmente'} */}
                    </button>
                    <div className="dropdown-menu shadow">
                        {!_.isEmpty(this.state.localPartners) && <button className="dropdown-item text-uppercase" onClick={this.unSetLocalDataInForm}>
                            Ninguno
                        </button>}
                        {!_.isEmpty(this.state.localPartners) && _.map(this.state.localPartners, (item, key) => <button key={key} id={key} onClick={() => this.setLocalDataInForm(item, key)} className="dropdown-item text-uppercase">
                            {item.nombre} {item.apellido_paterno} {item.apellido_materno}
                            {/* <button className="btn btn-link text-danger" onClick={() => this.removeItem(item, key)}><i className="far fa-trash-alt"></i></button> */}
                        </button>) || <div className="dropdown-item">No hay datos locales</div>}
                    </div>
                </div>
                {/* <div className="btn-group d-flex" role="group" aria-label="Local Data">
                    <button type="button" className="btn btn-secondary">Cerrar</button>
                    <button type="button" className="btn btn-secondary">Eliminar</button>
                </div> */}
            </div>
        );
    }
}

export default PartnerLocalList;