import React, { Component } from 'react';
import DocumentPaymentControl from './DocumentPaymentControl';
import DocumentIndividualPromissoryNote from './DocumentIndividualPromissoryNote';
import DocumentGrupalPromissoryNote from './DocumentGrupalPromissoryNote';
import DocumentCheckPolicy from './DocumentCheckPolicy';
import DocumentPaperLetter from './DocumentPaperLetter';
import _ from 'underscore';


class DocumentVarius extends Component {
    constructor(props) {
        super(props);
        
        const param = this.getParamValue('docs') || '';
        let docs = param.split(',');

        let arr = []; arr.length = docs.length; arr.fill(true);
        this.state = _.object(docs, arr);
        
    }

    getParamValue = (paramName) => {
       var url = window.location.search.substring(1); //get rid of "?" in querystring
       var qArray = url.split('&'); //get key-value pairs
       for (var i = 0; i < qArray.length; i++) 
       {
           var pArr = qArray[i].split('='); //split key and value
           if (pArr[0] === paramName) 
               return pArr[1]; //return value
       }
    }
    render() {
        return (
            <div className="w-100" style={{backgroundColor: 'white'}}>

                <div className={`${this.state.control_pagos === true?'w-100':'d-none'}`}><DocumentPaymentControl id_grupo={this.props.id_grupo} id_solicitud_actual={this.props.id_solicitud_actual}/></div>
                <div className="saltopagina"></div>

                <div className={`${this.state.pagare_individual === true?'w-100':'d-none'}`}><DocumentIndividualPromissoryNote id_grupo={this.props.id_grupo} id_solicitud_actual={this.props.id_solicitud_actual}/></div>
                <div className="saltopagina"></div>

                <div className={`${this.state.pagare_grupal === true?'w-100':'d-none'}`}><DocumentGrupalPromissoryNote id_grupo={this.props.id_grupo} id_solicitud_actual={this.props.id_solicitud_actual}/></div>
                <div className="saltopagina"></div>

                <div className={`${this.state.poliza_cheque === true?'w-100':'d-none'}`}><DocumentCheckPolicy id_grupo={this.props.id_grupo} id_solicitud_actual={this.props.id_solicitud_actual}/></div>
            </div>
        );
    }
}

export default DocumentVarius;