import React, { Component } from 'react';
import Rest from '../../Server/Rest';
import config from './../../settings/config';
import _ from 'underscore';
import Popover from './../others/Popover';
import DatePicker from 'react-datepicker';
import SelectUsers from '../forms/SelectUsers';
import "react-datepicker/dist/react-datepicker.css";
import Spinner from '../others/Spinner';

class GroupPaymentsEdit extends Component {
    constructor(props){
        super(props);
        if (!_.isEmpty(this.props.pagosRealizados)) {
            
            this.state.paymentsMade = this.props.pagosRealizados;
        }
    }
    state = {
        paymentsMade: [],
        loading: false,
    }
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.pagosRealizados !== undefined && !_.isEqual(this.state.paymentsMade, nextProps.pagosRealizados)) {
            this.setState({
                paymentsMade: nextProps.pagosRealizados.reverse() || []
            });
        }
    }

    setTotal = (payments) => {
        let total = 0.0;
        _.each(payments, payment => {
            total += payment.monto_pagado;
        });
        return new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( total );
    }

    setTotalEquivalentPayments = (payments) => {
        let total = 0;
        _.each(payments, payment => {
            total += payment.pagos_adelantados || 0;
        });
        return total;
    }

    handleChangePaidAt = (payment, date, index) => {
        const paymentsMade = this.state.paymentsMade;
        paymentsMade[index].pagado_al.iso = date.toISOString();
        this.setState({
            paymentsMade
        })

        Rest.peticion(`${config.api_url}/classes/SolicitudesPagosRealizados/${payment.objectId}`, {
            method: 'PUT',
            body: JSON.stringify({
                pagado_al: {
                    __type: 'Date',
                    iso: date.toISOString()
                }
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.code) {
                alert('Error');
            } else {
                alert('Exito');
            }
        }).catch(error => {
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    handleChangeRegisteredBy = (payment, event, index) => {
        const target = event.target;
        const objectId = target.type === 'checkbox' ? target.checked : target.value;
        const paymentsMade = this.state.paymentsMade;
        const registrado_por = paymentsMade[index].registrado_por = {
            __type: 'Pointer',
            className: '_User',
            objectId: objectId
        };
        this.setState({
            paymentsMade,
        });

        Rest.peticion(`${config.api_url}/classes/SolicitudesPagosRealizados/${payment.objectId}`, {
            method: 'PUT',
            body: JSON.stringify({
                registrado_por: registrado_por
            })
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.code) {
                alert('Error');
            } else {
                alert('Exito');
            }
        }).catch(error => {
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    deletePayment = async (payment, index) => {
        const paymentsMade = this.state.paymentsMade;
        const solicitudMonto = paymentsMade[index].SolicitudMonto;
        // console.log(solicitudMonto);

        if (solicitudMonto.monto_acumulado > 0) {
            solicitudMonto.monto_acumulado -= paymentsMade[index].monto_pagado;
        }
        const newPaymentsMade = _.without(paymentsMade, paymentsMade[index]);

        

        const confirm = window.confirm('¿Está seguro de eliminar el pago? \r Esta acción no es reversible');
        if (confirm) {

            this.setState({
                loading: true
            })

            let requests = [];

            requests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Solicitudes/${payment.Solicitud.objectId}`,
                body: {
                    PagosRealizados: {
                        __op: 'Remove',
                        objects: [{
                            __type:'Pointer',
                            className: 'SolicitudesPagosRealizados',
                            objectId: payment.objectId
                        }]
                    }
                }
            });

            requests.push({
                method: 'DELETE',
                path: `${config.api_path2}/classes/SolicitudesPagosRealizados/${payment.objectId}`
            });

            requests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/SolicitudesMontos/${solicitudMonto.objectId}`,
                body: {
                    monto_acumulado: solicitudMonto.monto_acumulado
                }
            });

            const data = {
                requests: requests
            }

            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(res => {
                if (res.code) {
                    alert('Error');
                } else {
                    this.setState({
                        paymentsMade: newPaymentsMade,
                    });
                    alert('Registro eliminado');
                }
                this.setState({
                    loading: false
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            });
        }
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading}/>
                {(this.state.paymentsMade.length> 0 && <div>
                    <table className={`table m-0 ${this.props.className}`}>
                        <thead>
                            <tr>
                                <th className="border-top-0">#</th>
                                <th className="border-top-0">Cliente</th>
                                <th className="border-top-0">Registrado al</th>
                                <th className="border-top-0">Registrado por</th>
                                <th className="border-top-0">Monto pagado</th>
                                <th className="border-top-0">Pagos<br/>adelantados
                                    {!(this.props.is_print === 'true') &&<Popover title="Pagos adelantados" content="Muestra la cantidad de pagos adelantados en el abono realizado."/>}
                                </th>
                                <th className="border-top-0"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.paymentsMade.map((payment, index) => <tr id={payment.objectId} key={index}>
                                <td>{index+1}</td>
                                <td>{payment.SolicitudPago.Socio.nombre} {payment.SolicitudPago.Socio.apellido_paterno} {payment.SolicitudPago.Socio.apellido_materno}</td>
                                <td>
                                    {(this.props.edit === true && <>
                                        <DatePicker
                                        placeholderText="Fecha de elaboración"
                                        className="form-control"
                                        locale="es"
                                        dateFormat="dd/MM/yyyy"
                                        selected={new Date(payment.pagado_al.iso)}
                                        onChange={date => this.handleChangePaidAt(payment, date, index)}
                                        // minDate={this.filter_from}
                                        showDisabledMonthNavigation
                                        required
                                        />
                                    </>) || new Date(payment.pagado_al.iso).toLocaleDateString()}
                                </td>
                                <td className="text-uppercase">
                                {
                                (this.props.edit === true && <SelectUsers name="usuario" id="usuario" value={payment.registrado_por.objectId} onChange={(objectId) => this.handleChangeRegisteredBy(payment, objectId, index)} required={true} />)
                                ||
                                <>{`${payment.registrado_por.nombre} ${payment.registrado_por.apellidos}`}</>
                                }
                                </td>
                                <td>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format( payment.monto_pagado )}</td>
                                <td>{payment.pagos_adelantados||'0'}</td>
                                <td>
                                    <button className="btn btn-danger btn-sm" onClick={() => this.deletePayment(payment, index)}>
                                        <i className="fas fa-trash-alt" />
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th className="text-right">Totales</th>
                                <th>{this.state.paymentsMade.length > 0 && this.setTotal(this.state.paymentsMade)}</th>
                                <th>{this.state.paymentsMade.length > 0 && this.setTotalEquivalentPayments(this.state.paymentsMade)}</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>) || <div className="alert alert-warning text-center" role="alert">
                    Sin pagos registrados
                </div>}
            </>
        );
    }
}

export default GroupPaymentsEdit;