import React from "react";
import { NavLink, Switch, Route, useRouteMatch  } from "react-router-dom";
import config from "../../settings/config";
import Rest from "../../Server/Rest";
import NotFound from './../pages/NotFound';
import AccountEdit from "./AccountEdit";
// import { useState } from "react";
import AccountHistory from "./AccountHistory";

function Account (props) {

    // const [data, setData] = useState([]);

    const getAccountById = () => {

        Rest.peticion(`${config.api_url}/classes/CatalogoCuentas/${props.match.params.id_account}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            // console.log("URI COMPLETA => ", `${config.api_url}/classes/Grupos/${props.match.params.id_grupo}?${params.toString()}`)
            // console.log("Respuestaa => ", res)
                        
            return res;
        })
        .catch(error => {
            return error;
        });

    }

    getAccountById();

    let { path, url } = useRouteMatch();

    return(
        <>

            <div className="row align-items-center justify-content-between">
                <div className="col-md-6">
                    <h3 className="text-uppercase text-pink pt-3 pb-3 m-0">
                        <i className="fas fa-edit mr-2"></i>
                        Edición
                    </h3>
                </div>
                
            </div>

            <div className="card solfin-card">
                <div className="card-body">

                <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <NavLink title="Datos personales" className="nav-link" to={`${url}/datos-cuenta`} activeClassName="active">
                                <i className="fas fa-id-card mr-md-2"></i>
                                <span className="d-none d-sm-inline">Datos generales</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink title="Historial" className="nav-link" to={`${url}/historial`} activeClassName="active">
                                <i className="fas fa-history mr-md-2"></i>
                                <span className="d-none d-sm-inline">Historial</span>
                            </NavLink>
                        </li>
                        
                    </ul>
                        
                    <br />

                    <Switch>
                        <Route exact path={`${path}/datos-cuenta`} component={AccountEdit} />
                        <Route exact path={`${path}/historial`} component={AccountHistory} />
                        <Route component={NotFound} />
                    </Switch>

                </div>
            </div>
        </>
    )
}

export default Account;