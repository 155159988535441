import React, { Component } from 'react';
import SearchForm from '../forms/SearchForm';
import _ from 'underscore';
import textUtils from '../../utilities/format-string';
import Spinner from '../others/Spinner';
import user from './../../settings/user';
import Parse from 'parse';
import Rest from '../../Server/Rest';
import config from '../../settings/config';

class GroupSearchPartner extends SearchForm {
    constructor(props) {
        super(props);

        this.state = this.state_init;
    }

    
    resetSearchForm = () => {
        this.setState(this.state_init);
    }

    state_init = {
        searchValue: '',
        searchKey: '',
        partners: [],
        hasPartners: false,
        loading: false
    }

    /**
     * Busqueda de los socios de acuerdo a la entrada del usuario
     * para crear el grupo.
     * Se realizan 2 consultas. La primera para obtener los socios
     * que coincidan con el nombre pero que tengan una solicitud
     * en proceso de autorización o autorizado. La segunda es para
     * obtener socios que no tengan un status baja castigada, 
     * morocidad o fellecida. Los resultados se combinan, si las
     * clientas se repiten 2 o más veces no serán mostrarán al usuario.
     * @param {*} undefined 
     * @param {*} _where 
     */
    getResource = async (undefined, _where = {}) => {

        let where = {};

        // obtener socios que no esten en baja castigada, morosidad o fallecida
        // y que estén en un grupo con status baja, rechazado o baja_voluntaria
        // y que no estén en validación
        _where.en_moderacion = {
            $ne: true
        }

        _where.Socio = {
            $select: {
                query: {
                    className: 'Grupos',
                    where: {
                        Solicitudes: {
                            $inQuery: {
                                className: 'Solicitudes',
                                where: {
                                    status: {
                                        $in: ['autorizado','autorizacion']
                                    }
                                }
                            }
                        }
                    }
                },
                key: 'Socios'
            }
        }
        // obtener solo los de la sucursal que administra el usuario actual
        if (Parse.User.current().toJSON().Sucursal) {
            _where.Socio.$select.query.where.Solicitudes.$inQuery.where.lugar_registro = Parse.User.current().toJSON().Sucursal;
        }

        const params1 = new URLSearchParams({
            keys: 'Socio.id_en_sucursal,Socio.nombre,Socio.apellido_paterno,Socio.apellido_materno,Socio.telefono,Socio.lugar_registro,Socio.status',
            limit: 100,
            where: JSON.stringify(_where),
            count: 1,
            order: 'Socio.nombre,Socio.apellido_paterno,Socio.apellido_materno',
            include: 'Socios,Socio.lugar_registro'
        });
        
        const req1 = await Rest.peticion(`${config.api_url}/classes/SociosDiccionario?${params1.toString()}`, {
            method: 'GET'
        }).then(res => {return res.json()});

        _where.Socio = {
            $inQuery: {
                className: 'Socios',
                where: {
                    status: {
                        $nin: ['BAJA_CASTIGADA','MOROSIDAD','FALLECIDA']
                    }
                }
            }
        }
        // obtener solo los de la sucursal que administra el usuario actual
        if (Parse.User.current().toJSON().Sucursal) {
            _where.Socio.$inQuery.where.lugar_registro = Parse.User.current().toJSON().Sucursal;
        }

        const params2 = new URLSearchParams({
            keys: 'Socio.id_en_sucursal,Socio.nombre,Socio.apellido_paterno,Socio.apellido_materno,Socio.telefono,Socio.lugar_registro,Socio.status',
            limit: 100,
            where: JSON.stringify(_where),
            count: 1,
            order: 'Socio.nombre,Socio.apellido_paterno,Socio.apellido_materno',
            include: 'Socios,Socio.lugar_registro'
        });

        const req2 = await Rest.peticion(`${config.api_url}/classes/SociosDiccionario?${params2.toString()}`, {
            method: 'GET'
        }).then(res => {return res.json()});

        return Rest.peticiones([req1, req2])
        .then(res => {
            if (res[0].error || res[0].code || res[1].error || res[1].code) {
                return {
                    code: res[0].code || res[1].code,
                    error: res[0].error || res[1].error
                }
            } else {
                let 
                results = _.union(res[0].results, res[1].results);
                results = _.groupBy(results, 'objectId');
                results = _.map(results, (value, key) => {
                    return value.length === 1 && value[0]
                });
                results = _.compact(results);
                return { 
                    results: results,
                    count: results.length
                }
            }
        })
        .catch(err => {
            console.log(err);
            return {
                code: 'error solfin',
                error: err
            };
        });

    }

    handleSearchSubmit2 = () => {
        if (this.state.searchValue.length) {
            this.searchClients();
        } else {
            alert('Escriba un nombre y/o apellido.');
        }
    }

    handleSearchKeyDown = (event) => {
        if (event) {
            if (event.key === 'Enter') {
                event.preventDefault()
                this.handleSearchSubmit2();
            }
        }
        
    }

    searchClients = async () => {
        let 
        searchValue = textUtils.setPlainText(this.state.searchValue);
        searchValue = searchValue.toLowerCase();
        searchValue = searchValue.replace(' ','');
        
        this.setState({
            loading: true
        })

        const partners = await this.getResource(undefined, {
            id_busqueda: {
                $regex: searchValue
            }
        });
        if (partners.code || partners.error) {
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        } else {
            this.setState({
                partners: partners.results,
                hasPartners: true,
                loading: false
            })
        }
        
    };

    handleAddPartner = async (socio) => {
        this.setState({
            loading: true,
        });
        
        Rest.peticion(`${config.api_url}/classes/Socios/${socio.objectId}`, {
            method: 'GET'
        })
        .then(res => res.json())
        .then(res => this.props.handleAddPartner(res))
        .catch(error => console.log('error al agregar socio --> ', error))
        .finally(this.setState({loading:false}));
    }

    render() {
        return (<>
            <Spinner loading={this.state.loading} />
            <div className="row sticky-top bg-white pt-3">
                <div className="col-md-12">
                    <div className="-row align-items-start">
                        {/* <div className="col-9"> */}
                            <label className="sr-only" htmlFor="search-input">Buscar</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <i className="fas fa-search"></i>
                                    </div>
                                </div>
                                <input 
                                type="text" 
                                className="form-control border-right-0" 
                                id="search-input" 
                                placeholder="Buscar"
                                style={styles.searchInput}
                                onChange={this.handleSearchChange}
                                onKeyDown={this.handleSearchKeyDown}
                                value={this.state.searchValue}
                                minLength="1"
                                name="searchValue"/>
                                <span className={`pl-3 pr-3 align-items-center justify-content-center border-top border-bottom ${this.state.searchValue.length ? 'd-flex' : 'd-none'}`} onClick={() => this.resetSearchForm()} style={styles.searchInputClose}>
                                    <i className="fas fa-times"></i>
                                </span>
                            <div className="input-group-append">
                            <button type="button" className="btn btn-secondary btn-block" onClick={this.handleSearchSubmit2}>
                                <span className="d-none d-md-block">Buscar</span>
                                <i className="fas fa-search d-md-none"></i>
                            </button>
                        </div>
                            </div>
                        {/* </div> */}
                    </div>
                    <small className="form-text text-muted">
                        {this.state.hasPartners !== false && <p className="mb-2">{(this.state.partners.length === 0 || this.state.partners.length > 1)? `${this.state.partners.length} encontrados`:'1 encontrado'}.</p>}
                    </small>
                </div>
                
            </div>
            {!_.isEmpty(this.state.partners) && <div className={`w-75 rounded ${this.props.containerShadow !== false ? 'shadow' : '' }`} id="partners-results" style={{maxWidth: 400, minWidth: '94%', position: 'absolute', marginTop: this.props.showCloseBtn !== false ? 16 : 0, ...this.props.containerListStyle}}>
                {this.props.showCloseBtn !== false && <button type="button" onClick={ () => this.resetSearchForm() } className="btn btn-light btn-sm border position-absolute" title="Cerrar" style={{
                    zIndex: 1,
                    right: -16,
                    top: -16,
                    width: 32,
                    height: 32,
                    borderRadius: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <i className="fas fa-times"></i>
                </button>}
                <ul className={`list-group rounded ${this.props.scrollable !== false ? 'pre-scrollable' : ''}`}>
                    {this.state.partners.map((item, index) => <li className="list-group-item d-flex justify-content-between align-items-center pt-2 pb-2" key={index}>
                        <small className="text-truncate">
                            {item.Socio.nombre +' '+ item.Socio.apellido_paterno +' '+ item.Socio.apellido_materno}<br/>
                            {user.rol.es_administrador === true && <small className="text-lowercase">({item.Socio.lugar_registro.nombre})</small>}
                        </small>
                        <div className="text-center d-flex">
                            <button onClick={() => this.handleAddPartner(item.Socio)} type="button" className="btn btn-link btn-sm">
                                Añadir
                            </button>
                            <a href={`/clientas/${item.objectId}/${item.Socio.objectId}/${item.Socio.id_en_sucursal}/datos-personales`} title="Ver" target="_blank" className="btn btn-link btn-sm">
                                Ver
                            </a>
                        </div>
                    </li>)}
                </ul>
            </div>}
        </>)
    }
}

export default GroupSearchPartner;

const styles = {
    searchInputClose: {
        // position: 'absolute',
        right: 75,
        zIndex: 4,
    },
    searchInput: {
        paddingRight: 36
    }
}