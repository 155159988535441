import Rest from "../Server/Rest";
import config from "../settings/config";

export const saveIdBusqueda = async (objectId, id_busqueda) => {
        
    return Rest.peticion(`${config.api_url}/classes/GruposDiccionario`, {
        method: 'POST',
        body: JSON.stringify({
            id_busqueda: id_busqueda,
            Grupo: {
                __type: 'Pointer',
                className: 'Grupos',
                objectId: objectId
            },
        })
    })
    .then(res => {
        return res.json();
    })
    .then(res => {
        return res;
    })
    .catch(error => {
        return error;
    });
}

export const updateIdBusqueda = async (objectId, id_busqueda) => {
        
    return Rest.peticion(`${config.api_url}/classes/GruposDiccionario/${objectId}`, {
        method: 'PUT',
        body: JSON.stringify({
            id_busqueda: id_busqueda,
        })
    })
    .then(res => {
        return res.json();
    })
    .then(res => {
        return res;
    })
    .catch(error => {
        return error;
    });
}