import React, { Component } from 'react';
import config from '../../settings/config';
import './../../styles/css/partnerlist.css';
import { Link } from "react-router-dom";
import Spinner from '../others/Spinner';
import _ from 'underscore';
import TablePagination from '../others/TablePagination';
import TableCountInfo from '../others/TableCountInfo';
import Rest from '../../Server/Rest';
import SearchForm from '../forms/SearchForm';
import TextPhone from '../others/TextPhone';
import Parse from 'parse';
import formatString from '../../utilities/format-string';
import user from '../../settings/user';

class ListBranches extends Component {

    constructor (props) {
        super(props);

        this.state = {
            collection: [],
            cuentasContables: [],
            cuentasContables_permitidas: [],
            cuentasConfSucursal: [],
            pruebaData: '',
            itemSelect: {},
            verModal: false,
            loading: false,
            edit: false,
            index: 0,
            total: 0,
            sucursalConfigurada: false,
            screenWidth: window.innerWidth,
            data: {}
        }
    }

    state_init = () => {
        return {
            verModal: false,
            loading: false
        }
    }
    
    
    order   = 'nombre';
    limit   = 10;
    rows    = 0;
    where   = {};
    title   = 'Configuración de contabilidad';
    timeout_innerWidth = null;
    dataSelectSuc = '';
    typeAccount = '';
    typeAccountSelect = [];
    data = {};

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    getContablesAccounts = (accounts) => {
        accounts.forEach(account => { 
            let arrAccounts = account;
            let params;

            let where = JSON.stringify({
                id_tipo_cuenta: account.objectId
            });

            params = new URLSearchParams({
                where: where,
                count: 1
            });
            
            Rest.peticion(`${config.api_url}/classes/CatalogoCuentas?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (res.code) {
                    alert('Ha ocurrido un error inesperado. Intente más tarde.');
                } else {
                    // console.log(res);
                    // this.handleSpinner();
                    arrAccounts.ContablesAccounts = res.results;
                    this.setState({
                        cuentasContables: accounts
                    });
                }
                
            })
            .catch(error => {
                // this.handleSpinner();
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
                
            });
        });

        const timer = setTimeout(() => {

            let cuentasConfiguradas = [];
            let cuentas = {};
            let data = {};

            if (this.state.edit) {
                // console.log('cuentas ya conf', this.state.cuentasConfSucursal)
                this.state.cuentasContables.forEach(item => {
                    item.ContablesAccounts.map(c_cont => {
                        cuentas = c_cont;
                        var found = this.state.cuentasConfSucursal.find(e => e.idAccountContable === c_cont.objectId);
                        if (found) {
                            cuentasConfiguradas.push({
                                idTypeAccount: item.objectId,
                                idAccountContable: c_cont.objectId
                            });
                            return cuentas.actual = true;
                        }
                    })
                })

                data = {
                    cuentasConfiguradas: cuentasConfiguradas
                }

                this.typeAccountSelect = cuentasConfiguradas;

                return this.setState({
                    data: data,
                    verModal: !this.state.verModal
                })
            }
        }, 1000)

        return () => {
            clearTimeout(timer); // Cancela el temporizador si el componente se desmonta
        };
    }

    getCuentas = () => {
        Rest.peticion(`${config.api_url}/classes/TypesAccounts`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                this.getContablesAccounts(res.results);
            }
            
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
            
        });
    }

    resize = (e) => {
        clearTimeout(this.timeout_innerWidth);
        this.timeout_innerWidth = setTimeout(() => {
            this.setState({
                screenWidth: window.innerWidth
            })
        }, 300);
    }

    componentDidMount = () => {
        this.getResource();

        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.resize);
        clearTimeout(this.timeout_innerWidth);
        this.timeout_innerWidth = null;
    }

    getResource = (index = 0, _where = {}) => {

        this.where = {
            // contabilizar: true,
        };

        this.setState(state => ({
            index: index
        }));
        
        this.handleSpinner();

        let params;

        let where = JSON.stringify(this.where);

        params = new URLSearchParams({
            limit: this.limit,
            skip: index,
            where: where,
            order: this.order,
            count: 1
        });
        
        // Rest.peticion(`${config.api_url}/classes/SociosDiccionario?${params.toString()}`, {
        Rest.peticion(`${config.api_url}/classes/Sucursales?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            this.handleSpinner();
            return res.json();
        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                // console.log(res)
                this.rows = res.count;
                this.setState({
                    collection: _.map(res.results, (item, index) => { item.index = index +1 + parseInt(this.state.index); return item; }),
                    total: res.count
                });
            }
            
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
            
        });
    }

    // dar formato a fecha
    setDate = (fecha) => {
        return  formatString.setLegibleDate(fecha)
    }
  
    verModalConf = () => { 
        //   alert('hola conf')
        this.typeAccountSelect = []
        this.setState({
            verModal: !this.state.verModal
        });
        if (this.state.edit) {
            this.setState({
                edit: false
            })
        }
    }
    
    verDts = (item) => { 
        // console.log(item)
        const params = new URLSearchParams({
            where: JSON.stringify({
                id_sucursal: item.objectId
            }),
            count: 1,
            keys: 'createdAt,cuentasConfiguradas,id_sucursal,Sucursal.nombre',
            include: 'Sucursales'
        });

        return Rest.peticion(`${config.api_url}/classes/SucursalesConfiguradas?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.code) {
                console.log('Ocurrió un error al comprobar la existencia del código.');
            } else {
                if (res.count > 0) {
                    // console.log(res.results[0])
                    // this.getTypesAccountsEdit(res.results[0].cuentasConfiguradas)
                    this.getCuentas();
                    this.setState({
                        itemSelect: item,
                        sucursal_conf: res.results[0],
                        edit: true,
                        cuentasConfSucursal: res.results[0].cuentasConfiguradas
                    });
                } else {
                    this.getCuentas();

                    this.setState({
                        itemSelect: item
                    });
                    return this.setState({
                        verModal: !this.state.verModal
                    });
                }

            }
            return res;
        })
        .catch(error => {
            console.log("Error  => ", error);
            alert('Ocurrió un error al comprobar la existencia del código.');
            return error;
        });
    }

    accountValue = (accounts) => {
        let val = '';

        // if(accounts.length > 0) {
            accounts.ContablesAccounts.forEach(item => { 
                if (item.actual !== undefined) {
                    val= item.codigo
                }
            })
        // }

        return val
    }

    setUrl = (item) => {
        return <button onClick={this.verModalConf} title="Configurar producto" className="btn btn-link btn-lg">
            <i className="fas fa-cog"></i>
        </button>
    }

    setPartnerTable = () => {
        return <>
            <div className="table-responsive">
                <table id="table-partner-list" className="table table-hover position-relative">
                    <thead id="puntero1"></thead>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Sucursal</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Siglas</th>
                            <th scope="col">Fecha de creación</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <thead id="puntero2"></thead>
                    <tbody>
                        {
                            this.state.collection.length && this.state.collection.map((item, index) =>
                                <tr key={index} className={(item.en_moderacion && !(this.props.match.path === '/validacion')) && 'text-muted' || ''}>
                                    <td>{item.index}</td>
                                    <td className="td-responsive-text">{item.nombre}</td>
                                    <td className="td-responsive-text">{item.entidad_federativa}</td>
                                    <td nowrap="true">{item.sigla}</td>
                                    <td>{this.setDate(item.createdAt)}</td>
                                    {/* {(user.rol.es_administrador) && <td className="p-1 text-right"> */}
                                    {(!_.isEmpty(user.rol.permisos.Contabilidad) && user.rol.permisos.Contabilidad.consultar) && <td className="p-1 text-right">
                                        <button onClick={() => this.verDts(item)} className="btn btn-link btn-lg">
                                            <i className="fas fa-cog"></i>
                                        </button>
                                    </td>}
                                </tr>
                            ) ||
                            <tr>
                                <td colSpan="8" className="text-center">
                                    <div className="alert alert-warning m-0" role="alert">
                                        No se hallaron registros
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    }

    getTipeAccounts = (type) => {

        let params;

        let where = JSON.stringify({
            id_tipo_cuenta: type
        });

        params = new URLSearchParams({
            where: where,
            count: 1
        });
        
        Rest.peticion(`${config.api_url}/classes/CatalogoCuentas?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                // console.log(res)
                this.setState({
                    cuentasContables_permitidas: res.results
                });
            }
            
        })
        .catch(error => {
            // this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
            
        });
    }

    handleSearchChange = (event) => { 
        let target = event.target;
        let value = target.value;
        this.typeAccount = value;

        this.getTipeAccounts(value);
    }

    cambiarValor = (valorABuscar, valorViejo, valorNuevo) =>{
        this.typeAccountSelect.forEach(function (elemento) {
            elemento[valorABuscar] = elemento[valorABuscar] == valorViejo ? valorNuevo : elemento[valorABuscar]
        })
    }

    handleSelectCP = (event) => { 
        let target = event.target;
        let value = target.value;

        // console.log(typeof value)
        let splitString = value.split(",");
        // console.log(splitString)

        var found = this.typeAccountSelect.find(e => e.idTypeAccount === splitString[0]);
        if (found) {
            this.cambiarValor("idAccountContable", found.idAccountContable, splitString[1]);
            this.setState({
                pruebaData: this.dataSelectSuc
            });
        } else {
            this.typeAccountSelect.push({
                idTypeAccount: splitString[0],
                idAccountContable: splitString[1]
            });
    
            this.dataSelectSuc = splitString[0];
        
            this.setState({
                pruebaData: this.dataSelectSuc
            });
        }
    }

    deleteValue = (value) => {
        this.typeAccountSelect.splice(value, 1);
        this.setState({
            pruebaData: this.dataSelectSuc
        });
    }

    handleValid = () => {

        if (this.typeAccountSelect.length > 0 && this.typeAccountSelect.length === this.state.cuentasContables.length) {
            this.saveData();
        }else {
            alert('Tiene que realizar la configuración');
        }
    }

    saveData = () => {
        const resp = window.confirm('¿Todos los datos son correctos?');
        if (resp === true) {
            // let arrValues = [];

            this.setState({
                loading: true
            });

            this.data = {
                cuentasConfiguradas: this.typeAccountSelect
            };

            
            let data = this.data;

            data.fecha_registro = {
                __type: 'Date',
                iso: new Date().toISOString()
            };

            data.Sucursal = {
                __type: 'Pointer',
                className: 'Sucursales',
                objectId: this.state.itemSelect.objectId
            };

            data.id_sucursal = this.state.itemSelect.objectId;

            return Rest.peticion(`${config.api_url}/classes/SucursalesConfiguradas`,{
                method: 'POST',
                body: JSON.stringify(data),
            }).then(() => {

                setTimeout(() => {
                    alert('La configuración ha sido guardada.');
                    let state_init = this.state_init();
                    this.typeAccountSelect = [];
                    this.state.itemSelect = {};
                    this.setState(state_init);
                    setTimeout(() => {
                        this.setState(this.state_init());
                    }, 200);
                }, 200);

            }).catch(error => {

                console.log('my error', error);

                alert('No se pudieron guardar los datos. Intente más tarde.');
                let state_init = this.state_init();
                state_init.loading = false;
                this.setState(state_init);
                setTimeout(() => {
                    this.setState(this.state_init());
                }, 200);
            })
            .finally(() => {
                this.setState({
                    finish: true
                });
            });
        }
    }

    editData = () => {
        const resp = window.confirm('¿Todos los datos son correctos?');
        if (resp === true) {
            this.setState({
                loading: true
            });
    
            this.data = {
                cuentasConfiguradas: this.typeAccountSelect
            };
    
            let data = this.data;
    
            Rest.peticion(`${config.api_url}/classes/SucursalesConfiguradas/${this.state.sucursal_conf.objectId}`, {
                method: 'PUT',
                body: JSON.stringify(data)
            }).then(() => {
                setTimeout(() => {
                    alert('La configuración ha sido actualizada exitosamente.');
                    // alert('La configuración ha sido guardada.');
                    let state_init = this.state_init();
                    this.typeAccountSelect = [];
                    this.state.itemSelect = {};
                    this.setState(state_init);
                    setTimeout(() => {
                        this.setState(this.state_init());
                    }, 200);
                }, 200);
    
            }).catch(error => {
                console.log(error);
                alert('No se ha podido actualizar los datos de la cuenta. Intente más tarde.');
                this.handleSpinner();
            })
            .finally(() => {
                this.setState({
                    finish: true
                });
            });
        }
    }

    setHead = <h3 className="text-uppercase text-pink pt-3 pb-3">
        <i className="fas fa-cog mr-2"></i>
        {this.title}
    </h3>

    verDetallesP1 = (idAccountContable) => {
        for (let i = 0; i < this.state.cuentasContables.length; i++) { 
            var found = this.state.cuentasContables[i].ContablesAccounts.find(e => e.objectId === idAccountContable);
            if (found) { 
                return found.codigo
            }
        }
    }

    render() {
        // console.log(this.typeAccountSelect)
        // console.log(this.state.edit)
        // console.log(this.typeAccountSelect)
        // console.log(this.state.data)
        return (    
            <>
                <Spinner loading={this.state.loading} />
                
                {this.setHead}
                
                <div className="card">
                    <div className="card-body">
                        <br />                        
                        <TableCountInfo total={this.state.total} />
                        <br/>
                        {
                            this.setPartnerTable()
                        }

                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-5"></div>

                {
                    this.state.verModal === true && <div className={"modal d-block opacityModal"}>
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Configuración de la sucursal {this.state.itemSelect.nombre}</h5>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.verModalConf} aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body row align-items-start" style={{alignContent: 'center', textAlign: 'center', alignItems:'center'}}>
                                <div className="table-responsive">
                                    <table id="table-partner-list" className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Cuentas</th>
                                                <th scope="col">Cuentas contables</th>
                                            </tr>
                                        </thead>
                                        <thead id="puntero2"></thead>
                                        <tbody>
                                            {
                                                this.state.cuentasContables.length && this.state.cuentasContables.map((item, index) =>
                                                    <tr key={index}>
                                                        <td className='td-responsive-text'>{item.value}</td>
                                                        <td className="td-responsive-text">
                                                            <select className="form-control" name="status" value={this.state.status} onChange={this.handleSelectCP}>
                                                                {/* <option value="">Cuentas contables</option> */}
                                                                {
                                                                    this.state.edit === true &&
                                                                        <option>{this.accountValue(item)}</option>
                                                                    || <option value="">Cuentas contables</option>
                                                                }
                                                                {
                                                                    item.ContablesAccounts && item.ContablesAccounts.map((account, index) =>
                                                                        <option value={[item.objectId, account.objectId]} key={index}>{account.codigo}</option>
                                                                    ) || ''
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ) ||
                                                <tr>
                                                    <td colSpan="8" className="text-center">
                                                        <div className="alert alert-warning m-0" role="alert">
                                                            No se hallaron registros
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.verModalConf}>Cerrar</button>
                                {
                                    !this.state.edit && 
                                        <button type="submit" className="btn btn-primary" onClick={()=>this.handleValid()} disabled={this.state.saving}>
                                            {(this.state.saving === true && <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span>Guardando...</span>
                                            </>) || 'Guardar'}   
                                        </button>
                                    || <button type="submit" className="btn btn-primary" onClick={()=>this.editData()} disabled={this.state.saving}>
                                        {(this.state.saving === true && <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span>Editando...</span>
                                        </>) || 'Editar'}   
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
            </>
        );
    }
}

export default ListBranches;