import React, { Component } from 'react';
import DocumentPaperLetter from './DocumentPaperLetter';
import _ from 'underscore';

class Document404 extends Component {
    constructor (props) {
        super(props)
        this.margin = this.getParamValue('no_margin');
    }
    
    margin = '';

    getParamValue = (paramName) => {
        var url = window.location.search.substring(1); //get rid of "?" in querystring
        var qArray = url.split('&'); //get key-value pairs
        for (var i = 0; i < qArray.length; i++) 
        {
            var pArr = qArray[i].split('='); //split key and value
            if (pArr[0] === paramName) 
                return pArr[1]; //return value
        }
     }

    render() {
        return (
            <div>
                {<DocumentPaperLetter margin={this.margin}>
                    <h1>Formato no establecido</h1>
                </DocumentPaperLetter>}
            </div>
        );
    }
}

export default Document404;