import React, { useEffect, useState } from "react";
import Spinner from "../others/Spinner";
import GroupSearch from "../groups/GroupSearch";
import Parse from "parse";
import config from "../../settings/config";
import Rest from "../../Server/Rest";
import _ from "underscore";

const EmployeeCredits = (props) => {

    const [loading, setLoading] = useState(false);
    const [collection, setCollection] = useState([]);
    const [myCredits, setMyCredits] = useState([]);
    const [selectIndex, setSelectIndex] = useState(undefined);
    const [idEmpleado, setIdEmpleado] = useState(undefined);

    useEffect(() => {
        setIdEmpleado(props.match.params.id_empleado);
        
        setLoading(true);

        const params = new URLSearchParams({
            where: JSON.stringify({
                Empleado: {
                    __type: 'Pointer',
                    className: 'Empleados',
                    objectId: props.match.params.id_empleado
                }
            })
        });
        Rest.peticion(`${config.api_url}/classes/Grupos?${params.toString()}`, {
            method: 'GET',
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .then(res => {
            console.log('res.results --> ', res.results);
            const newMyCredits = res.results;
            setMyCredits(newMyCredits);
            setSelectIndex('');
        })
        .catch((error) => {
            console.log(error);
            alert('No se pudo obtener los créditos');
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    const handleFilter = (where) => {
        getResource(0, where);
    }

    const getResource = (index = 0, _where = {}) => {
        
        setLoading(true);
        setSelectIndex('');

        let where = {};
        if (_.isUndefined(_where.Grupo) && Parse.User.current().toJSON().Sucursal) {
            _where.Grupo = {
                $select: {
                    query: {
                        className: 'Grupos',
                        where: {
                            Sucursal: Parse.User.current().toJSON().Sucursal
                        }
                    },
                    key: 'objectId'
                }
            }
        }
        
        where = _where;
        let body = {
            where: where,
            order: '-Grupo.fecha_registro,-createdAt',
            include: 'Grupo,Grupo.Sucursal,Grupo.Solicitudes,Grupo.promotor,status',
            keys: 'Grupo,Grupo.nombre,Grupo.Sucursal.nombre,Grupo.status,Grupo.id_en_sucursal,Grupo.Sucursal.sigla,Grupo.Solicitudes.status,Grupo.Solicitudes.ciclo,Grupo.promotor,status',
            limit: 1000
        };

        where = JSON.stringify(where);

        Rest.peticion(`${config.api_url}/functions/getGroups`, {
            method: 'POST',
            body: JSON.stringify(body)
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                setCollection( res.result.results );
            }
        })
        .catch(error => {
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const onSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        
        if (_.isEmpty(selectIndex)) {
            alert('Haga una búsqueda y elija un crédito/grupo');
            return;
        }

        setLoading(true);
        Rest.peticion(`${config.api_url}/classes/Grupos/${collection[selectIndex].Grupo.objectId}`, {
            method: 'PUT',
            body: JSON.stringify({
                Empleado: {
                    __type: 'Pointer',
                    className: 'Empleados',
                    objectId: idEmpleado
                }
            })
        })
        .then(() => {
            const newMyCredits = [...myCredits, collection[selectIndex].Grupo];
            setMyCredits(newMyCredits);
            setSelectIndex('');
        })
        .catch(() => {
            alert('No se pudo asociar el crédito');
        })
        .finally(() => {
            setLoading(false);
        });
        
    }

    const removeCredit = (index) => {
        

        setLoading(true);
        Rest.peticion(`${config.api_url}/classes/Grupos/${myCredits[index].objectId}`, {
            method: 'PUT',
            body: JSON.stringify({
                Empleado: {__op: 'Delete'}
            })
        })
        .then(() => {
            const newMyCredits = myCredits;
            newMyCredits.splice(index, 1);
            setMyCredits(newMyCredits);
        })
        .catch(() => {
            alert('No se pudo quitar el crédito');
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <Spinner loading={loading} />

            <p>Realice una búsqueda del crédito/grupo que le pertenece al empleado y agreguelo a la lista de créditos</p>

            <GroupSearch handleFilter={handleFilter}/>

            <div className="card mt-3">
                <div className="card-body">
                    <form onSubmit={onSubmit}>
                        <label>Selecciona el crédito</label>
                        <div className="row">
                            <div className="col-md-8">
                                <select className="form-control" value={selectIndex} onChange={(e) => setSelectIndex(e.target.value)} required={true}>
                                    <option value="">Créditos... (hay {collection.length} créditos)</option>
                                    {collection.map((item, index) => <option key={index} value={index}>{item.Grupo.nombre}</option>)}
                                </select>
                            </div>
                            <div className="col-md-4">
                                <button type="submit" className="btn btn-secondary">Agregar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body">
                    <h5>Lista de créditos</h5>
                    <table className="table">
                        <tbody>
                            {myCredits.map((item, index) => <tr key={index}>
                                <td>{item.nombre}</td>
                                <td className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-secondary" onClick={() => removeCredit(index)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </>    
    )
}

export default EmployeeCredits;