import React, { Component } from 'react';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import Parse from 'parse';
import service_catalogs from './../../services/catalogs';
import textUtils from './../../utilities/format-string';
import Spinner from './../others/Spinner';
import EmployeeForm from './EmployeeForm';
import _ from 'underscore';

class EmployeeRegister extends Component {
    constructor(props){
        super(props);
        this.state = this.state_init;
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    state_init = {
        resetform: false,
        loading: false,
    }

    data = {};

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    saveSuburb = service_catalogs.saveSuburb;

    validCURP = textUtils.validCURP;

    setData = (data) => {
        this.data = data;
    }

    saveIdBusqueda = async (objectId, id_busqueda) => {
        
        return Rest.peticion(`${config.api_url}/classes/EmpleadosDiccionario`, {
            method: 'POST',
            body: JSON.stringify({
                id_busqueda: id_busqueda,
                Empleado: {
                    __type: 'Pointer',
                    className: 'Empleados',
                    objectId: objectId
                },
            })
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
    }

    async handleSubmit (event) {

        if (event) event.preventDefault();

        const r = window.confirm('¿Todos los datos son correctos?');

        if (r === true) {

            let data = this.data;

            if (Parse.User.current().toJSON().Sucursal) {
                data.Sucursal = Parse.User.current().toJSON().Sucursal.objectId;
            }
            

            // formatear datos
            _.map(data, (value, key) => {
                let newValue;
                
                    if (!_.isEmpty(value) && _.isString(value)) {
                        switch (key) {
                            case 'Sucursal':
                                newValue = {
                                    __type: 'Pointer',
                                    className: 'Sucursales',
                                    objectId: value
                                };
                                break;
                            default:
                                newValue = value.toUpperCase().trim();
                                break;
                        }
                    } else {
                        newValue = value;
                    }

                    data[key] = newValue;
            });

            if (!_.isEmpty(data.curp) && !this.validCURP(data.curp)) {
                alert('La CURP no parece cumplir con el formato válido.');
                return;
            }

            data.fecha_registro = {
                __type: 'Date',
                iso: new Date().toISOString()
            };
            // Establecer usuario quien registró el socio
            const currentUser = Parse.User.current();
            data.registeredBy = {
                __type: 'Pointer',
                className: '_User',
                objectId: currentUser.id
            }

            this.setState(state => ({
                loading: !state.loading
            }));

            const employee = await Rest.peticion(`${config.api_url}/classes/Empleados`, {
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                return res;
            })
            .catch(error => {
                return error;
            });
            
            if (employee.objectId) {

                let id_busqueda =   textUtils.setPlainText(data.nombre+data.apellido_paterno+data.apellido_materno).toLowerCase() +
                                    data.curp +
                                    data.numero_ocr;
                    id_busqueda =  id_busqueda.toLowerCase();

                const diccionario = await this.saveIdBusqueda(employee.objectId, id_busqueda);
                
                if (diccionario.objectId) {

                    await this.saveSuburb(data.domicilio_colonia, data.domicilio_cp);

                    this.setState({
                        resetform: true
                    });
                    setTimeout(() => {
                        this.setState({
                            resetform: false
                        });
                    }, 500);

                    alert('El empleado ha sido registrado.');
                } else {
                    alert('No se pudo registrar el empleado.');
                }
            } else {
                alert('El empleado no pudo ser registrado. Intente más tarde o contacte a soporte.');
            }
            this.setState({
                loading: false
            });
        }
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-cogs mr-2"></i>
                    Registrar empleado
                </h3>
                <div className="card solfin-card">
                    <div className="card-body">
                        <p className="text-info">
                            Todos los campos son obligatorios.
                        </p>
                        <form onSubmit={this.handleSubmit}>
                            <EmployeeForm required={true} resetform={this.state.resetform} setData={this.setData} />
                        </form>
                    </div>
                </div>
                <div className="p-5"></div>
            </>
        );
    }
}

export default EmployeeRegister;