import config from '../settings/config';
import Parse from 'parse';
import _ from 'underscore';
const Promise = require('promise');

const headers = {
    headers: config.headers,
}
const headersFull = {
    headers: config.headersFull,
}

const _peticion = (uri, _options, setHeadersFull = false) => {
    let myPromise = new Promise((resolve, reject) => {
        _validMe().then(res => {
            if (res) {
                const options = _.extend(_options, setHeadersFull ? headersFull : headers);
        
                const query = fetch(uri, options);
                resolve(query)
            } else {
                alert('La sesión actual ha sido cerrada. Intente iniciar de nuevo.');
                window.location.href = '/acceso';
            }
        })
    });
    return myPromise;
    
};

const _validMe = () => {
    
    let myPromise = new Promise((resolve, reject) => {
        let options = headers;
        const newHeaders = _.extend(options.headers, {'X-Parse-Session-Token': Parse.User.current().toJSON().sessionToken})
        options.headers = newHeaders;
        fetch(`${config.api_url}/users/me`, _.extend({
                method: 'GET',
        }, options))
        .then((res) => {
            return res.json();
        })
        .then((res) => {
            if (res.error) {
                resolve(false)
            }
            // devolvemos el status del usuario
            resolve(res.active)
        })
        .catch(res => {
            reject(false)
        })
    });
    return myPromise;
}

const _peticionConfig = (uri, _options) => {

    let myPromise = new Promise((resolve, reject) => {
        _validMe().then(res => {

            if (res) {
                const headers = {
                    headers: config.headersConfig
                }
        
                const options = _.extend(_options, headers);
        
                const query = fetch(uri, options);
                resolve(query)
            } else {
                alert('La sesión actual ha sido cerrada. Intente iniciar de nuevo.');
                window.location.href = '/';
            }
        })
    });
    return myPromise;
};

const _peticiones = (requests) => {
    let myPromise = new Promise((resolve, reject) => {
        _validMe().then(res => {

            if (res) {
                const query = Promise.all(requests);
                resolve(query);
            } else {
                alert('La sesión actual ha sido cerrada. Intente iniciar de nuevo.');
                window.location.href = '/';
            }
        })
    });
    return myPromise;
}

export default {
    peticion: _peticion,
    peticiones: _peticiones,
    peticionConfig: _peticionConfig,
    validMe: _validMe
}