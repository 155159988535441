import React, { Component } from 'react';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import Parse from 'parse';
import service_catalogs from './../../services/catalogs';
import textUtils from './../../utilities/format-string';
import user from './../../settings/user';
import Spinner from './../others/Spinner';
import EmployeeForm from './EmployeeForm';
import _ from 'underscore';

class EmployeeEdit extends Component {
    constructor(props){
        super(props);
        this.state = this.state_init;
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    state_init = {
        resetform: false,
        loading: false,
        readOnly: true
    }

    data = {};

    data_original = {};

    Sucursal = {};

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    handleChangeEdit = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        
        this.setState((state) => ({
            [name]: !state[name]
        }));
    }

    setData = (data) => {
        this.data = data;
    }

    // consultar datos del socio
    UNSAFE_componentWillMount = async () => {
        this.handleSpinner();

        const params = new URLSearchParams({
            excludeKeys: 'registeredBy',
            include: 'Sucursal'
        });
        
        Rest.peticion(`${config.api_url}/classes/Empleados/${this.props.match.params.id_empleado}?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            this.handleSpinner();
            return res.json();
        })
        .then(res => {
            if (res.code) {
                alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
            } else {
                this.Sucursal               = _.clone(res.Sucursal);
                this.data_original          = _.clone(res);
                this.data                   = _.clone(res);
                this.data.fecha_registro    = res.fecha_registro.iso
                this.data.Sucursal          = res.Sucursal && res.Sucursal.objectId || '';
                this.setState({
                    resetform: true
                });
                setTimeout(() => {
                    this.setState({
                        resetform: false
                    });
                }, 200);
            }
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
        });
    }

    validCURP = textUtils.validCURP;

    saveSuburb = service_catalogs.saveSuburb;

    saveRelationHistorial = async (id_busqueda, historial) => {

        let data = {
            id_busqueda: id_busqueda
        };

        if (historial) {
            data.historial = {
                __op: 'AddRelation',
                objects: [{
                    __type: 'Pointer',
                    className: 'EmpleadosHistorial',
                    objectId: historial.objectId
                }]
            }
        }
        
        return Rest.peticion(`${config.api_url}/classes/EmpleadosDiccionario/${this.props.match.params.id_diccionario}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
    }

    saveData = async (data) => {
        return Rest.peticion(`${config.api_url}/classes/Empleados/${this.props.match.params.id_empleado}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        }).then(res => {return res.json()}).then(res => {return res}).catch(error => {return error});
    }

    setValueSucursal = (pointer) => {
        const items = document.getElementById('Sucursal');
        let text = '';
        for (let index = 0; index < items.length; index++) {
            if (items[index].value === pointer.objectId) {
                text = items[index].text;
            }
        }
        return text;
    }

    saveEmpleadoHistorial = async (_data) => {
        if (!_.isEqual(this.data_original, _data)) {
            let data = {};
            _.each(this.data_original, (value, key) => {
                if(!_.isEqual(this.data_original[key], _data[key])) {
                    if (!_.isEmpty(value.__type) && value.className === 'Sucursales') {
                        data[key] = this.setValueSucursal(value);
                    } else {
                        data[key] = value;
                    }
                };
            });
            _.each(_data, (value, key) => {
                if (this.data_original[key] === undefined) {
                    data[key] = 'SIN DATO PREVIO';
                }
            });

            const currentUser = Parse.User.current().toJSON();
            const body = {
                datos_anteriores: data,
                afectado_por: currentUser.nombre+' '+currentUser.apellidos,
                usuario_email: currentUser.email,
                usuario_telefono: currentUser.telefono,
                grupo_datos: 'todos',
                Rol: {
                    __type: 'Pointer',
                    className: 'Roles',
                    objectId: user.rol.objectId
                },
                User: {
                    __type: 'Pointer',
                    className: '_User',
                    objectId: currentUser.objectId
                },
                Empleado: {
                    __type: 'Pointer',
                    className: 'Empleados',
                    objectId: this.props.match.params.id_empleado
                }
            }
            return Rest.peticion(`${config.api_url}/classes/EmpleadosHistorial`, {
                method: 'POST',
                body: JSON.stringify(body)
            }).then(res => {return res.json()}).then(res => {return res}).catch(error => {return error});
        } else {
            return false;
        }
    }

    handleSubmit = async (event) => {

        if (event) event.preventDefault();

        const r = window.confirm('¿Todos los datos son correctos?');

        if (r === true) {
        
            let data = this.data;
            
            // formatear datos
            _.map(data, (value, key) => {
                let newValue;
                
                    if (!_.isEmpty(value) && _.isString(value)) {
                        switch (key) {
                            case 'Sucursal':
                                newValue = {
                                    __type: 'Pointer',
                                    className: 'Sucursales',
                                    objectId: value
                                };
                                break;
                            default:
                                newValue = value.toUpperCase().trim();
                                break;
                        }
                    } else {
                        newValue = value;
                    }

                    data[key] = newValue;
            });

            if (!_.isEmpty(data.curp) && !this.validCURP(data.curp)) {
                alert('La CURP no parece cumplir con el formato válido.');
                return;
            }

            const newData       = _.omit(data, 'className','registeredBy','__type','id_en_sucursal','fecha_registro','objectId','createdAt','updatedAt');
            this.data_original  =  _.omit(this.data_original, 'className','registeredBy','__type','id_en_sucursal','fecha_registro','objectId','createdAt','updatedAt');
            
            let id_busqueda =   textUtils.setPlainText(newData.nombre+newData.apellido_paterno+newData.apellido_materno).toLowerCase() +
                                newData.curp +
                                newData.numero_ocr;

                id_busqueda = id_busqueda.toLowerCase();
            
            this.handleSpinner();
            
            const acualizacion = await this.saveData(newData);

            const historial = await this.saveEmpleadoHistorial(newData);

            await this.saveSuburb(newData.domicilio_colonia, newData.domicilio_cp);

            await this.saveRelationHistorial(id_busqueda, historial);

            if (acualizacion.code || acualizacion.error) {
                alert('Ha ocurrido un error al intentar actualizar los datos. Intente más tarde o contacte a soporte.');
            } else {
                this.data_original = _.clone(newData);
                alert('Los datos han sido actualizados.');
            }

            this.handleSpinner();
        }

    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />

                {(user.rol.permisos.Empleados && user.rol.permisos.Empleados.editar) &&
                <div className="row">
                    <div className="col-8">
                        {!this.state.readOnly && <p className="text-info">
                            Todos los campos son obligatorios.
                        </p>}
                    </div>
                    {(user.rol.permisos.Empleados && user.rol.permisos.Empleados.editar) && <div className="text-right col-4 mb-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitch1" value={!this.state.readOnly} defaultChecked={!this.state.readOnly} name="readOnly" onChange={this.handleChangeEdit} />
                            <label className="custom-control-label text-primary" htmlFor="customSwitch1">Editar</label>
                        </div>
                    </div>}
                </div>}

                <form onSubmit={this.handleSubmit}>
                    <EmployeeForm required={true} readOnly={this.state.readOnly} resetform={this.state.resetform} data={this.data} setData={this.setData} />
                </form>
            </>
        );
    }
}

export default EmployeeEdit;