import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import _ from 'underscore';
import "react-datepicker/dist/react-datepicker.css";
import user from './../../settings/user';
import { registerLocale } from  "react-datepicker";
import PopOver from '../others/Popover';
import Spinner from '../others/Spinner';
import requisitions from '../../services/requisitions';
import RequisitionModel from '../../models/RequisitionModel';
import es from 'date-fns/locale/es';
import ChargeFactor from '../forms/ChargeFactor';
registerLocale('es', es)

class GroupRequisitionFastUpdate extends Component {

    constructor(props){
        super(props);

        const config = user.config();
        if (user.rol.es_administrador !== true) {
            this.filter_from = config && config.filter_from ? new Date(config.filter_from) : null;
        }
    }

    fecha_elaboracion = null;
    filter_from = null;

    state = {
        loading: false,

        ciclo: 1,
        fecha_elaboracion: '',
        dia_pago: '',
        cantidad_pagos_semanales: 0,
        factor_cobro: 0,
        prestamo_personalizado: false,
        prestamo_personalizado_cantidad_pagos: 0,
        prestamo_personalizado_periodicidad: 0,
        prestamo_personalizado_interes: 0,
        garantia: '',
        garantia_valor: '',
        charge_factor_type: ''
    }

    componentWillReceiveProps(nextProps) {
        const {
            ciclo,
            fecha_elaboracion,
            dia_pago,
            cantidad_pagos_semanales,
            factor_cobro,
            prestamo_personalizado,
            prestamo_personalizado_cantidad_pagos,
            prestamo_personalizado_periodicidad,
            prestamo_personalizado_interes,
            garantia,
            garantia_valor,
            charge_factor_type,
            status, // nos servirá para validar si se puede generar pagos
        } = nextProps.requisition;

        this.fecha_elaboracion = fecha_elaboracion;

        this.setState({
            ciclo: ciclo,
            fecha_elaboracion: fecha_elaboracion,
            dia_pago: dia_pago,
            cantidad_pagos_semanales: cantidad_pagos_semanales,
            factor_cobro: factor_cobro,
            prestamo_personalizado: prestamo_personalizado || false,
            prestamo_personalizado_cantidad_pagos: prestamo_personalizado_cantidad_pagos,
            prestamo_personalizado_periodicidad: prestamo_personalizado_periodicidad,
            prestamo_personalizado_interes: prestamo_personalizado_interes,
            garantia: garantia,
            garantia_valor: garantia_valor || '',
            charge_factor_type: charge_factor_type || '',
            status: status
        });
    }

    setfactor_cobro = (value) => {

        let text = '';
        switch (value) {
            case 135:
                text = '$135 (10 semanas)';
                break;
            case 129:
                text = '$129 (10 semanas)';
                break;
            case 126.5:
                text = '$126.5 (10 semanas)';
                break;
            case 116:
                text = '$116 (12 semanas)';
                break;
            case 113:
                text = '$113 (12 semanas)';
                break;
            case 110:
                text = '$110 (12 semanas)';
                break;
            case 105:
                text = '$105 (14 semanas)';
                break;
            case 101:
                text = '$101 (14 semanas)';
                break;
            case 96.5:
                text = '$96.5 (14 semanas)';
                break;
            case 95:
                text = '$95 (16 semanas)';
                break;    
            case 92.5:
                text = '$92.5 (16 semanas)';
                break;
            case 89.5:
                text = '$89.5 (16 semanas)';
                break;
            case 270:
                text = '$270 (5 catorcenas)';
                break;
            case 260:
                text = '$260 (5 catorcenas)';
                break;
            case 254:
                text = '$254 (5 catorcenas)';
                break;
            case 232:
                text = '$232 (6 catorcenas)';
                break;
            case 226:
                text = '$226 (6 catorcenas)';
                break;
            case 220:
                text = '$220 (6 catorcenas)';
                break;
            case 210:
                text = '$210 (7 catorcenas)';
                break;
            case 202:
                text = '$202 (7 catorcenas)';
                break;
            case 194:
                text = '$194 (7 catorcenas)';
                break;
            case 190:
                text = '$190 (8 catorcenas)';
                break;
            case 186:
                text = '$186 (8 catorcenas)';
                break;
            case 180:
                text = '$180 (8 catorcenas)';
                break;
            default:
                text = 'Sin especificar';
                break;
        }
        return text;

    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        
        return true;
    }

    handleChangeDate = (date) => {
        if (!_.isNull(date)) {
            this.setState({
                dia_pago: date.getDay(),
                fecha_elaboracion: date
            });
        } else {
            this.setState({
                dia_pago: undefined,
                fecha_elaboracion: undefined
            });
        }
    }

    handleChargeFactor  = (event) => {
        const target = event.target;
        const value = parseFloat(target.value);
        let cantidad_pagos_semanales = 0;
        if (this.state.prestamo_personalizado) {
            cantidad_pagos_semanales = RequisitionModel[RequisitionModel.setChargeFactorType(this.state.prestamo_personalizado_periodicidad, this.state.charge_factor_type, this.state.garantia, true)][value].cantidad_pagos_semanales;
        } else {
            cantidad_pagos_semanales = RequisitionModel[RequisitionModel.setChargeFactorType(this.state.prestamo_personalizado_periodicidad, this.state.charge_factor_type, this.state.garantia)][value].cantidad_pagos_semanales;
        }

        this.setState({
            charge_factor_type: RequisitionModel.setChargeFactorType(),
            factor_cobro: value,
            cantidad_pagos_semanales: cantidad_pagos_semanales
        });
        return true;
    }

    setAmountTotal_0 = RequisitionModel.setAmountTotal_0;

    esMaster = RequisitionModel.esMaster;

    setRequiredWarranty = RequisitionModel.setRequiredWarranty;

    handleForm = (event) => {
        if (event) event.preventDefault();

        if (this.state.status === 'autorizacion') {
            alert('La solicitud debe estar autorizada para poder generar pagos.');
            return;
        }

        // si el usuario es adinistrador y el prestamo es personal
        // procedemos a la generación de pagos
        if (!user.rol.es_administrador && this.state.prestamo_personalizado) {
            alert('Lo sentimos, no tiene autorización para editar el préstamo. Contacte al administrador para realizar cambios.');
            return;
        };

        let {
            fecha_elaboracion,
            dia_pago,
            cantidad_pagos_semanales,
            factor_cobro,
            charge_factor_type,
            prestamo_personalizado,
            prestamo_personalizado_cantidad_pagos,
            prestamo_personalizado_periodicidad,
            prestamo_personalizado_interes,
            garantia,
            garantia_valor
        } = this.state;

        const toDay = new Date();
        fecha_elaboracion.setHours( toDay.getHours() );
        fecha_elaboracion.setMinutes( toDay.getMinutes() );
        if (_.isEmpty(dia_pago)) {
            dia_pago = parseInt(fecha_elaboracion.getDay());
        } else {
            dia_pago = parseInt(dia_pago);
        }

        let fechaFinal = RequisitionModel.getLastPaymentDay(fecha_elaboracion, this.state.prestamo_personalizado, this.state.prestamo_personalizado_cantidad_pagos, this.state.prestamo_personalizado_periodicidad, this.state.cantidad_pagos_semanales, 'iso', false);
        fechaFinal = {
            __type: 'Date',
            iso: fechaFinal.toISOString()
        };
        
        fecha_elaboracion = {
            __type: 'Date',
            iso: fecha_elaboracion.toISOString()
        }
        charge_factor_type = RequisitionModel.setChargeFactorType(prestamo_personalizado_periodicidad, this.state.charge_factor_type, garantia, prestamo_personalizado);
        cantidad_pagos_semanales = parseInt(cantidad_pagos_semanales);
        factor_cobro = prestamo_personalizado ? RequisitionModel[charge_factor_type][factor_cobro].charge_factor : factor_cobro;
        
        prestamo_personalizado_cantidad_pagos = parseInt(prestamo_personalizado_cantidad_pagos);
        prestamo_personalizado_periodicidad = parseInt(prestamo_personalizado_periodicidad);
        prestamo_personalizado_interes = parseFloat(prestamo_personalizado_interes);

        let data = {
            dia_pago: dia_pago,
            cantidad_pagos_semanales: cantidad_pagos_semanales || {__op:'Delete'},
            fecha_elaboracion: fecha_elaboracion,
            fecha_final_contrato: fechaFinal,
            factor_cobro: factor_cobro || {__op:'Delete'},
            prestamo_personalizado: prestamo_personalizado === true || {__op:'Delete'},
            prestamo_personalizado_cantidad_pagos: prestamo_personalizado_cantidad_pagos || {__op:'Delete'},
            prestamo_personalizado_periodicidad: prestamo_personalizado_periodicidad || {__op:'Delete'},
            prestamo_personalizado_interes: prestamo_personalizado_interes || {__op:'Delete'},
            garantia: garantia || {__op:'Delete'},
            garantia_valor: (!_.isEmpty(garantia) && garantia_valor) || {__op:'Delete'},
            charge_factor_type: charge_factor_type || {__op:'Delete'},
        };

        this.setState({
            loading: true
        });

        Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.props.id_solicitud}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        }).then(res => {
            this.setState({
                loading: false
            });
            return res.json();
        }).then(res => {
            return this.props.getRequisition();
        }).then(res => {
            if (res === true) {
                this.props.savePayments();
            } else {
                return false;
            }
        }).catch(error => {
            console.log(error);
        }).then(() => {
            this.setState({
                loading: false
            });
        });
    }

    handleChangeDirectState = (value, key) => {
        this.setState({
            [key]: value
        });
    }

    render() {
        if (this.state.status === 'baja') {
            return <div className="alert alert-info" role="alert">
                Este ciclo ha finalizado
            </div>;
        };

        return (
            <form className="card mt-3 mb-3" onSubmit={this.handleForm}>
                <Spinner loading={this.state.loading}/>
                <fieldset className="card-body" disabled={!user.rol.es_administrador && this.state.prestamo_personalizado}>
                    <div className="row">
                        
                        <div className="col-md-4 mb-3 d-flex flex-column">
                            <label htmlFor="dia_pago">Fecha de elaboración <PopOver title="Fecha de elaboración" content="Se definirá el día de pago 7 días adelante."/></label>
                            <DatePicker
                            placeholderText="Fecha de elaboración"
                            className="form-control"
                            locale="es"
                            selected={this.state.fecha_elaboracion}
                            dateFormat="dd/MM/yyyy"
                            onChange={date => this.handleChangeDate(date)}
                            minDate={this.filter_from}
                            showDisabledMonthNavigation
                            required
                            disabled={user.rol.es_administrador !== true && requisitions.inPaymentPeriod(this.props.requisition.status, this.fecha_elaboracion)}
                            />
                            {user.rol.es_administrador !== true && requisitions.inPaymentPeriod(this.props.requisition.status, this.fecha_elaboracion) && 
                            <small className="form-text text-muted">Contacte al administrador para modificar este campo.</small>}
                            {user.rol.es_administrador === true && requisitions.inPaymentPeriod(this.props.requisition.status, this.fecha_elaboracion) && 
                            <small className="form-text text-muted">Este campo permanecerá bloqueado 7 días después de la fecha establecida. Para modificar contácte al administrador.</small>}
                            
                        </div>
                        
                        <div className="col-12">
                            <ChargeFactor
                            handleChangeSolicitudData={this.handleChange}
                            handleChangeDirectState={this.handleChangeDirectState}
                            handleChargeFactor={this.handleChargeFactor}
                            factor_cobro={this.state.factor_cobro}
                            charge_factor_type={this.state.charge_factor_type}
                            cantidad_pagos_semanales={this.state.cantidad_pagos_semanales}
                            garantia={this.state.garantia}
                            garantia_valor={this.state.garantia_valor}
                            warranty={this.setRequiredWarranty()}
                            esMaster={this.esMaster}
                            ciclo={this.state.ciclo}
                            prestamo_personalizado={this.state.prestamo_personalizado}
                            prestamo_personalizado_periodicidad={this.state.prestamo_personalizado_periodicidad}
                            prestamo_personalizado_interes={this.state.prestamo_personalizado_interes}
                            prestamo_personalizado_cantidad_pagos={this.state.prestamo_personalizado_cantidad_pagos}
                            readOnly={false}
                            />
                        </div>
                    </div>
                    {(user.rol.es_administrador === true && <div className="row">
                        <div className="col-md-4 mb-3 mt-3">
                            <button type="submit" className="btn btn-primary btn-block">Generar Pagos</button>
                        </div>
                    </div>)}
                </fieldset>
            </form>
        );
    }
}

export default GroupRequisitionFastUpdate;