import React, { Component } from 'react';
import _ from 'underscore';

class InputDate extends Component {

    constructor(props) {
        super(props);

        this.init_state = {
            [`mes_${this.props.name}`]: '',
            [`dia_${this.props.name}`]: '',
            [`anio_${this.props.name}`]: ''
        }

        this.state = this.init_state;

        this.handleChange = this.handleChange.bind(this);
    }

    toDay = Date.now();

    init_state = {};
    
    // establece un afecha dada inicalmente
    toggleRunOne = true;
    setHasDate = (fecha) => {
        if (this.toggleRunOne === true && !_.isEmpty(fecha)) {
            
            const d = new Date(fecha);

            let mes = d.getUTCMonth() +1;
            let dia = d.getUTCDate();
            mes = mes <= 9 ? '0'+mes : mes;
            dia = dia <= 9 ? '0'+dia : dia;
            
            let state = this.state;
            state = {
                [`mes_${this.props.name}`]: mes,
                [`dia_${this.props.name}`]: dia,
                [`anio_${this.props.name}`]: d.getFullYear(),
            };
            this.setState(state);

            this.toggleRunOne = false;

        }

    }

    resetInputs = (resetform) => {
        if (resetform === true) {
            this.setState(this.init_state);
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        // establecer fecha dada como párametro
        this.setHasDate(nextProps.hasDate);
    }

    // validar formato de fecha
    valdiateDate = ( value ) => {
        return /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test( value );
    };

    // obtener fecha con formato ISO
    getDateISO = (state) => {
    
        let fecha_completa =  state[`anio_${this.props.name}`] +'-'+ state[`mes_${this.props.name}`]  +'-'+ state[`dia_${this.props.name}`];
        
        
        if (this.valdiateDate(fecha_completa)) {
            fecha_completa = new Date(fecha_completa).toISOString();

            return fecha_completa;
        } else {
            return '';
        }

    }

    // manejador de cambios
    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let {state} = this;
        
        state[name] = value;

        this.setState(state);

        let _event = {
            target: {
                value: this.getDateISO(state),
                name: this.props.name
            }
        }

        this.props.handleChange(_event);
    };

    render() {
        return (
            <div className="row">
                <div className="col-4">
                    <select className="form-control text-uppercase" id={`fecha_nacimiento_dia_${this.props.name}_${this.toDay}`} name={`dia_${this.props.name}`} label="Día de nacimiento" value={this.state[`dia_${this.props.name}`]} onChange={this.handleChange} required={this.props.required}>
                        <option value="">Día</option>
                        <option value="01">1</option>
                        <option value="02">2</option>
                        <option value="03">3</option>
                        <option value="04">4</option>
                        <option value="05">5</option>
                        <option value="06">6</option>
                        <option value="07">7</option>
                        <option value="08">8</option>
                        <option value="09">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                    </select>
                </div>
                <div className="col-3 p-0">
                    <select className="form-control text-uppercase" id={`fecha_nacimiento_mes_${this.props.name}_${this.toDay}`} name={`mes_${this.props.name}`} label="Mes de nacimiento" value={this.state[`mes_${this.props.name}`]} onChange={this.handleChange} required={this.props.required}>
                        <option value="">Mes</option>
                        <option value="01">Enero</option>
                        <option value="02">Febrero</option>
                        <option value="03">Marzo</option>
                        <option value="04">Abril</option>
                        <option value="05">Mayo</option>
                        <option value="06">Junio</option>
                        <option value="07">Julio</option>
                        <option value="08">Agosto</option>
                        <option value="09">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                    </select>
                </div>
                <div className="col-5">
                    <input type="number" className="form-control" id={`fecha_nacimiento_anio_${this.props.name}_${this.toDay}`} name={`anio_${this.props.name}`} label="Año de nacimiento" value={this.state[`anio_${this.props.name}`]} placeholder="AAAA" max="9999" min="1900" onChange={this.handleChange} required={this.props.required} />
                </div>
            </div>
        );
    }
}

export default InputDate;