import React, { Component } from 'react';
import SelectBranchOffice from './SelectBranchOffice';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import _ from 'underscore';
import Parse from 'parse';

class SelectEmpleyee extends SelectBranchOffice {

    UNSAFE_componentWillMount = async () => {
        
        // let where = {
        //     cargo: 'PROMOTOR'
        // }
        
        // if (Parse.User.current().toJSON().Sucursal) {
        //     where.Sucursal = Parse.User.current().toJSON().Sucursal
        // }
        this.setState({
            value: this.props.value
        })

        const params = new URLSearchParams({
            // where: JSON.stringify(where),
            order: 'nombre',
            limit: 1000,
            count: 1
        });
        const users = await Rest.peticion(`${config.api_url}/classes/_User?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {return res.json()})
        .then(res => {return res})
        .catch(error => console.log(error));

        if (_.isEmpty(users.code) && _.isEmpty(users.error)) {
            this.setState({
                collection: users.results
            })
        } else {
            console.log('Ocurrió un error al obtener la lista de usuarios');
            
        } 
    }

    setLabel = () => {
        const users = _.findWhere(this.state.collection, {
            objectId: this.state.value
        });
        if (!_.isEmpty(users)) {
            return `${users.nombre} ${users.apellidos}`;
        } else {
            return '';
        }
        
    }
    
    setOption = (item, index) => <option key={index} value={item.objectId}>{item.nombre} {item.apellidos}</option>;

}

export default SelectEmpleyee;