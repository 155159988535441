import React, { Component } from "react";
import AccountForm from "./AccountForm";
import Parse from 'parse';
import Rest from './../../Server/Rest';
import config from './../../settings/config';
import Spinner from "../others/Spinner";
import _ from "underscore";
import textUtils from './../../utilities/format-string';


class AccountRegister extends Component{

    constructor(props) {
        super(props);

        this.state = this.state_init();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    data = {};

    state_init = () => {
        this.code_account_exist = false;
        return {
            resetform: false,
            loading: false,
            savedAccount: false,
        }
    }

    setData = (data) => {
        this.data = data;
    }

    convertToUpper = () => {

        let data = this.data;

        for (let value in data){

            const typeValue = typeof(data[value]);

            if(value !== "tipo" && typeValue === "string" && value !== "id_tipo_cuenta"){
                if(!_.isEmpty(data[value])){
                    this.data[value] = data[value].toUpperCase().trim();
                }
            }
        }
    }

    // getAccountTypeDescription = (tipo) => {
    //     let valueTipo = parseInt(tipo);
    //     let valueEnum = (TypesAccounts.EnumTypeAccount)[valueTipo-1];
    //     let tipo_descripcion = (TypesAccounts.TypeAccount)[valueEnum.value];

    //     return tipo_descripcion;
    // }

    handleValidCode = () => {

        if(!_.isEmpty(this.data.codigo)){

            // console.log("Buscar el code ", this.data.codigo.toUpperCase().trim());

            const params = new URLSearchParams({
                where: JSON.stringify({
                    codigo: this.data.codigo.toUpperCase().trim(),
                    id_tipo_cuenta: this.data.id_tipo_cuenta
                }),
                count: 1,
                limit: 0
            });

            return Rest.peticion(`${config.api_url}/classes/CatalogoCuentas?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                // console.log("Res valid code => ", res);

                if (res.code) {
                    console.log('Ocurrió un error al comprobar la existencia del código.');
                } else {

                    if (res.count > 0) {
                        alert('El código que intenta registrar ya está en uso con el mismo tipo de cuenta.\n Registre una código distinto.');
                        this.code_account_exist = true;
                    } else {
                        this.code_account_exist = false;
                    }

                }
                return res;
            })
            .catch(error => {
                console.log("Error  => ", error);
                alert('Ocurrió un error al comprobar la existencia del código.');
                return error;
            });

        }
    }

    async handleSubmit(event) {

        if (event) event.preventDefault();

            // console.log("data => ", this.data)

        if(this.data.codigo === "" || this.data.nombre === "" || this.data.descripcion === "" || this.data.tipo === "" || this.data.id_tipo_cuenta === "") {
            alert('Todos los campos son requeridos.');
            return;
        }


        const resp = window.confirm('¿Todos los datos son correctos?');

        if(resp === true) {

            //Para mostrar el loading
            this.setState({
                loading: true
            });

            await this.handleValidCode();

            if (this.code_account_exist === true) {
                this.setState({
                    loading: false
                });
                return;
            }

            this.convertToUpper();

            let data = this.data;

            data.fecha_registro = {
                __type: 'Date',
                iso: new Date().toISOString()
            };

            data.Tipo_Cuenta = {
                __type: 'Pointer',
                className: 'TypesAccounts',
                objectId: this.data.id_tipo_cuenta
            };

            let id_busqueda = textUtils.setPlainText(this.data.codigo+this.data.nombre+this.data.descripcion)
            data.id_busqueda = id_busqueda.toUpperCase();

            // console.log("data => ", data)

            // Establecer usuario quien registró la cuenta
            const currentUser = Parse.User.current();
            data.registeredBy = {
                __type: 'Pointer',
                className: '_User',
                objectId: currentUser.id
            }

            return Rest.peticion(`${config.api_url}/classes/CatalogoCuentas`,{
                method: 'POST',
                body: JSON.stringify(data),
            }).then(() => {

                setTimeout(() => {
                    alert('La cuenta ha sido guardada.');
                    let state_init = this.state_init();
                    state_init.resetform = true;
                    this.setState(state_init);
                    setTimeout(() => {
                        this.setState(this.state_init());
                    }, 200);
                }, 200);

            }).catch(error => {

                console.log('my error', error);

                alert('No se pudieron guardar los datos. Intente más tarde.');
                let state_init = this.state_init();
                state_init.loading = false;
                this.setState(state_init);
                setTimeout(() => {
                    state_init.resetform = false;
                    this.setState(this.state_init());
                }, 200);
            })
            .finally(() => {
                this.setState({
                    finish: true
                });
            });

        }

    }

    render() {
        return(
            <>
                <Spinner loading={this.state.loading}></Spinner>
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <h3 className="text-uppercase text-pink pt-3 pb-3 m-0">
                            <i className="fas fa-credit-card mr-2"></i>
                            Nueva cuenta
                        </h3>
                    </div>
                </div>

                <div className="card solfin-card">
                    <div className="card-body">
                        <p className="text-info">
                            Todos los campos son obligatorios.
                        </p>
                        <form onSubmit={this.handleSubmit} autoComplete="off">
                            <AccountForm setData={this.setData} data={this.data} resetform={this.state.resetform}  required={true}/>                            
                            <br/>
                            <button type="submit" className="btn btn-primary btn-lg">Guardar</button>
                        </form>
                    </div>
                </div>
                <div className="p-5"></div>
            </>
        )
    }
}

export default AccountRegister;