import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Spinner from '../others/Spinner';

class GroupPrintDocuments extends Component {

    constructor(props) {
        super(props);
        this.state = this.state_init();

        this.handleChange = this.handleChange.bind(this);
    }

    state_init = () => {
        return {
            todos:              false,
            control_pagos:      false,
            pagare_individual:  false,
            pagare_grupal:      false,
            poliza_cheque:      false,
            varius_docs: '',
            loading: false
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let {state} = this;

        if (name === 'todos') {
            state[name] =              value;
            state.control_pagos =      value;
            state.pagare_individual =  value;
            state.pagare_grupal =      value;
            state.poliza_cheque =      value;

            this.setState(state);
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    handlePrintDocument = (documentName = undefined) => {
        
        window.frames[documentName].focus();
        window.frames[documentName].print();

    }
    handlePrintDocument2 = (documentName) => {
        let varius_docs = '&docs=';
        if (this.state.control_pagos === true) {
            varius_docs += 'control_pagos,'
        }
        if (this.state.pagare_individual === true) {
            varius_docs += 'pagare_individual,'
        }
        if (this.state.pagare_grupal === true) {
            varius_docs += 'pagare_grupal,'
        }
        if (this.state.poliza_cheque === true) {
            varius_docs += 'poliza_cheque,'
        }
        varius_docs = varius_docs.slice(0,-1);
        this.setState({
            varius_docs: varius_docs,
            loading: true
        });

        setTimeout(() => {
            this.setState({
                loading: false
            });
            window.frames[documentName].focus();
            window.frames[documentName].print();
        }, 2000);
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />
                <button type="button" className="btn btn-success mr-3 mt-3 mt-md-0" data-toggle="modal" data-target="#printDocuments">Imprimir documentos</button>
                
                <div className="modal fade" id="printDocuments" tabIndex="-1" aria-labelledby="printDocumentsLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="printDocumentsLabel">Imprimir documentos</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <table className="table m-0">
                                    <thead>
                                        <tr>
                                            <th className="border-0">
                                                <input type="checkbox" id="todos" name="todos" value={this.state.todos} checked={this.state.todos} onChange={this.handleChange} />
                                            </th>
                                            <th colSpan="2" className="border-0">Documentos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input type="checkbox" id="control_pagos" name="control_pagos" value={this.state.control_pagos} checked={this.state.control_pagos} onChange={this.handleChange} />
                                            </td>
                                            <td>
                                                <label htmlFor="control_pagos">
                                                    Control de pagos
                                                </label>
                                            </td>
                                            <td className="text-right">
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <Link to={`${this.props.match.url}/control-pagos`} target="_blank" title="Vista previa" className="btn btn-primary btn-sm">
                                                        Vista prev.
                                                    </Link>
                                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => this.handlePrintDocument('control-pagos')}>Imprimir</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="checkbox" id="pagare_individual" name="pagare_individual" value={this.state.pagare_individual} checked={this.state.pagare_individual} onChange={this.handleChange} />
                                            </td>
                                            <td>
                                                <label htmlFor="pagare_individual">
                                                    Pagaré individual
                                                </label>
                                            </td>
                                            <td className="text-right">
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <Link to={`${this.props.match.url}/pagare-individual`} target="_blank" title="Vista previa" className="btn btn-primary btn-sm">
                                                        Vista prev.
                                                    </Link>
                                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => this.handlePrintDocument('pagare-individual')}>Imprimir</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="checkbox" id="pagare_grupal" name="pagare_grupal" value={this.state.pagare_grupal} checked={this.state.pagare_grupal} onChange={this.handleChange} />
                                            </td>
                                            <td>
                                                <label htmlFor="pagare_grupal">
                                                    Pagaré grupal
                                                </label>
                                            </td>
                                            <td className="text-right">
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <Link to={`${this.props.match.url}/pagare-grupal`} target="_blank" title="Vista previa" className="btn btn-primary btn-sm">
                                                        Vista prev.
                                                    </Link>
                                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => this.handlePrintDocument('pagare-grupal')}>Imprimir</button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="checkbox" id="poliza_cheque" name="poliza_cheque" value={this.state.poliza_cheque} checked={this.state.poliza_cheque} onChange={this.handleChange} />
                                            </td>
                                            <td>
                                                <label htmlFor="poliza_cheque">
                                                    Póliza de cheque
                                                </label>
                                            </td>
                                            <td className="text-right">
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <Link to={`${this.props.match.url}/poliza`} target="_blank" title="Vista previa" className="btn btn-primary btn-sm">
                                                        Vista prev.
                                                    </Link>
                                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => this.handlePrintDocument('poliza')}>Imprimir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <td className="text-right" colSpan="3">
                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => this.handlePrintDocument2('varios')} 
                                            disabled={
                                                !(this.state.todos === true) && !(
                                                    (this.state.control_pagos === true) ||
                                                    (this.state.pagare_individual === true) ||
                                                    (this.state.pagare_grupal === true) ||
                                                    (this.state.poliza_cheque === true)
                                                )
                                            }>Imprimir selección</button>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                                <iframe id="control-pagos" className="d-none" name="control-pagos" width="0" height="0" src={`${this.props.match.url}/control-pagos?no_margin=true`}></iframe>
                                <iframe id="pagare-individual" className="d-none" name="pagare-individual" width="0" height="0" src={`${this.props.match.url}/pagare-individual?no_margin=true`}></iframe>
                                <iframe id="pagare-grupal" className="d-none" name="pagare-grupal" width="0" height="0" src={`${this.props.match.url}/pagare-grupal?no_margin=true`}></iframe>
                                <iframe id="poliza" className="d-none" name="poliza" width="0" height="0" src={`${this.props.match.url}/poliza?no_margin=true`}></iframe>
                                <iframe id="varios" className="d-none" name="varios" width="0" height="0" src={`${this.props.match.url}/varios?no_margin=true${this.state.varius_docs}`}></iframe>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default GroupPrintDocuments;