import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import SelectBranchOffice from '../forms/SelectBranchOffice';
import SelectEmpleyee from '../forms/SelectEmpleyee';
import GroupListPaymentFilter from './GroupListPaymentFilter';
import Parse from 'parse';
import { CREDIT_GROUP, CREDIT_PERSONAL, CREDIT_EMPLOYEE } from "../../constants/creditTypes";



class GroupListPaymentFilterReport extends GroupListPaymentFilter {

    state = {
        nombre: this.props.state.nombre,
        promotor: this.props.state.promotor,
        dateStart: this.props.state.dateStart,
        dateEnd: this.props.state.dateEnd,
        monto_autorizado: this.props.state.monto_autorizado,
        plazo: this.props.state.plazo,
        Sucursal: this.props.state.Sucursal,
        tipo_credito: this.props.state.tipo_credito,
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState(state => ({
            nombre: nextProps.state.nombre,
            promotor: nextProps.state.promotor,
            dateStart: nextProps.state.dateStart,
            dateEnd: nextProps.state.dateEnd,
            monto_autorizado: nextProps.state.monto_autorizado,
            plazo: nextProps.state.plazo,
            Sucursal: nextProps.state.Sucursal,
            tipo_credito: nextProps.state.tipo_credito,
        }))
    }

    render() {
        const { filter_type } = this.props.state;
        const creditTypes = [CREDIT_GROUP, CREDIT_PERSONAL, CREDIT_EMPLOYEE];

        return (
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">
                            <i className="fas fa-filter mr-2"></i>
                            Filtro
                        </h5>
                        <form onSubmit={this.handleFilter} className="row align-items-end">
                            {(this.props.inputGroupEnabled !== true) && <div className="col-md-4 form-group">
                                <label htmlFor="nombre">Grupo</label>
                                <input type="text" className="form-control" id="nombre" name="nombre" value={this.state.nombre} onChange={this.handleChange} placeholder="Nombre del grupo" />
                            </div>}
                            {(this.props.inputPrometerEnabled !== true) && <div className="col-md-4 form-group">
                                <label htmlFor="Sucursal">Promotor</label>
                                <SelectEmpleyee name="promotor" id="promotor" value={this.state.promotor} onChange={this.handleChange} />
                            </div>}
                            {(this.props.inputDateEnabled !== true) && <div className="col-md-4 form-group d-flex flex-column">
                                <label htmlFor="fecha">
                                    Fecha
                                </label>
                                <div className="input-group">
                                    <div className="d-flex">
                                        <DatePicker
                                        className="form-control mr-1"
                                        locale="es"
                                        selected={this.state.dateStart}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={date => this.handleChangeDate(date, 'dateStart')}
                                        selectsStart
                                        startDate={this.state.dateStart}
                                        endDate={this.state.dateEnd}
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={false}
                                        minDate={this.filter_from}
                                        placeholderText="Inicio" />
                                        <DatePicker
                                        className="form-control ml-1"
                                        locale="es"
                                        selected={this.state.dateEnd}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={date => this.handleChangeDate(date, 'dateEnd')}
                                        selectsEnd
                                        startDate={this.state.dateStart}
                                        endDate={this.state.dateEnd}
                                        minDate={this.state.dateStart || this.filter_from}
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={false}
                                        placeholderText="Final" />
                                    </div>
                                </div>
                            </div>}
                            {(this.props.inputAmountEnabled !== true) && <div className="col-md-4 form-group">
                                <label htmlFor="">Monto desembolsado</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">$</span>
                                    </div>
                                    <input type="number" step="any" className="form-control" name="monto_autorizado" value={this.state.monto_autorizado} onChange={this.handleChange}/>
                                </div>
                            </div>}
                            {(this.props.inputTermEnabled !== true) && <div className="col-md-4 form-group">
                                <label htmlFor="plazo">Plazo</label>
                                <select className="form-control" id="plazo" name="plazo" defaultValue={this.state.plazo} onChange={this.handleChange}>
                                    <option value="">PLAZO</option>
                                    <option value="7">SEMANAL</option>
                                    <option value="14">CATORCENAL</option>
                                </select>
                            </div>}
                            {(this.props.inputOfficeEnabled !== true) && <div className="col-md-4 form-group">
                                <label htmlFor="Sucursal">Sucursal</label>
                                {Parse.User.current().toJSON().Sucursal && 
                                    <SelectBranchOffice id="Sucursal" name="Sucursal" value={this.state.Sucursal} defaultValue={Parse.User.current().toJSON().Sucursal.objectId} onChange={this.handleChange} disabled /> || 
                                    <SelectBranchOffice id="Sucursal" name="Sucursal" value={this.state.Sucursal} onChange={this.handleChange} />
                                }
                            </div>}

                            <div className='col-md-4 form-group'>
                            <label htmlFor='tipo_credito'>Tipo de crédito</label>
                            <select
                                className="form-control text-uppercase"
                                id='tipo_credito'
                                name="tipo_credito"
                                onChange={this.handleChange}
                                //required
                                value={this.state.tipo_credito} >
                                    <option value="">Tipo de crédito</option>
                                    {creditTypes.map((creditType, index) =>
                                    <option 
                                    key={index}
                                    value={creditType} >
                                        {creditType} 
                                    </option>)}
                                </select>
                            </div>


                            <div className="col-md-4 mt-2 form-group">
                                <button type="submit" className="btn btn-primary btn-block">
                                    Aplicar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
        );
    }
}

export default GroupListPaymentFilterReport;