import React, {Component} from 'react';
import DocumentPaperLetter from './DocumentPaperLetter';
import Logo from './../../assets/static/logo-horizontal.svg';
import GroupPaymentFormSingle from '../groups/GroupPaymentFormSingle';
import GroupPaymentForm from '../groups/GroupPaymentForm';
import GroupPaymentsCheck from '../groups/GroupPaymentsCheck';
import formatString from '../../utilities/format-string';
import _ from 'underscore';
import ReportDayTableDetails from '../Reports/ReportDayTableDetails';

class DocumentBitacoraReport extends Component {

    constructor(props) {
        super(props);

        this.margin = this.getParamValue('no_margin');
        this.print_resume = this.getParamValue('print_resume') === 'true' ? true : false;
        console.log(typeof this.print_resume);
        
    }

    state = {
        data: {}
    }

    margin = '';

    UNSAFE_componentWillMount = () => {
        const data = JSON.parse(localStorage.getItem('bitacora_report'));
        this.setState({
            data: data
        });
    }


    getParamValue = (paramName) => {
        var url = window.location.search.substring(1); //get rid of "?" in querystring
        var qArray = url.split('&'); //get key-value pairs
        for (var i = 0; i < qArray.length; i++) 
        {
            var pArr = qArray[i].split('='); //split key and value
            if (pArr[0] === paramName) 
                return pArr[1]; //return value
        }
    }

    render() {
        
        return (
            <div className="w-100">
                <DocumentPaperLetter className="d-flex flex-column pt-5 pb-5" margin={this.margin}>
                    <div style={{...styles.docSection}} className="doc-section d-flex flex-column justify-content-around pt-3">
                        {!_.isEmpty(this.state.data) === true && <div>
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td className='border-0 w-50'>
                                            <span className="mb-3 text-primary"
                                            style={{
                                                fontSize: '0.7cm',
                                                fontWeight: 'bold'
                                            }}>
                                                BITÁCORA DEL DÍA
                                            </span>
                                        </td>
                                        <td className='border-0 w-50'>
                                            <img src={Logo} className='float-right' alt="SOLFIN DEL SURESTE | confía en tú palabra" width="136" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td className='border-0'>
                                            <p>
                                                {/**
                                                 * Se nombra como fecha de elaboración pero se
                                                 * muestra la fecha de publicación
                                                 */}
                                                <span className='text-black-50'>Fecha de elaboración</span>
                                                <br/>
                                                {formatString.setLegibleDate(this.state.data.fecha_publicacion)}
                                            </p>
                                            <p>
                                                <span className='text-black-50'>Sucursal</span>
                                                <br/>
                                                {this.state.data.Sucursal.nombre}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <br/>
                            
                            <ReportDayTableDetails data={this.state.data} print_resume={this.print_resume}/>

                            {this.print_resume === true && <>
                                <br/><br/><br/><br/>
                            </>}
                            
                            <br/>
                            <table className='table'>
                                <tbody>
                                    <tr>
                                        <td className='border-0' style={{width: '3cm'}}>Hecho por:</td>
                                        <td className='border-0'>{this.state.data.hecho_por.nombre} {this.state.data.hecho_por.apellidos}</td>
                                    </tr>

                                    <tr>
                                        <td className='border-0' style={{width: '3cm'}}>Firma:</td>
                                        <td className='border-0' style={{verticalAlign: 'bottom'}}><div className='w-50 border-bottom'></div></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br/>
                        </div>}
                    </div>
                </DocumentPaperLetter>
            </div>
        );
    }
}

export default DocumentBitacoraReport;

const styles = {
    docSection: {
        // height: (17.85+2.929)+'cm',
    },
    table: {
        border: 0
    }
}

