import React, {Component} from "react";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import _ from "underscore";
import Parse from 'parse';
import $ from 'jquery';
import moment from "moment";
moment.locale('es');


class ContalinkForm extends Component {

    constructor(props) {
        super(props);

        this.state = this.state_init();
    }

    fecha_envio = '';

    state_init = () => {

        const d = new Date();
        this.fecha_envio =  d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();

        return {
            saving: false,
            account_code: '',
            cargos: 0,
            credit: 0,
            abonos: 0,
            debit: 0,
            description: '',
            // record_date: '',
        }
    }

    handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        this.setState({
            [name]: value,
        })
    }

    getPolicyInfo = async(id) => {

        // console.log("Ide de la poliza => ", id)

        Rest.peticion(`${config.api_url}/classes/GLPoliza/${id}`, {
            method: 'GET',
        }).then((response) => {
            return response.json();
        }).then((res) => {
            
            if (res.code) {
                alert('Ha ocurrido un error inesperado al recuperar los tipos de cuenta para los filtros.\nIntente más tarde.');
            } else {

                // console.log("Informacion de la póliza => ", res);
                // console.log("Cargo => ", typeof(new Intl.NumberFormat('en',{maximumFractionDigits:2 , useGrouping: false}).format( res.cargos )) );
                // console.log("float => ", typeof( parseFloat( new Intl.NumberFormat('en',{maximumFractionDigits:2 , useGrouping: false}).format( res.cargos )) ) );

                this.setState({
                    cargos: res.cargos,
                    credit: parseFloat(new Intl.NumberFormat('en',{maximumFractionDigits:2 , useGrouping: false}).format( res.cargos )),
                    abonos: res.abonos,
                    debit:  parseFloat(new Intl.NumberFormat('en',{maximumFractionDigits:2 , useGrouping: false}).format( res.abonos )),
                })
            }

            

        }).catch((err) => {
            console.log("Error: ", err)
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        //verificamos si le pasamos datos para editar, de lo contarrio es registro
        if (!_.isEmpty(nextProps)) {

            if(nextProps.idPolicy){
                this.getPolicyInfo(nextProps.idPolicy);
            }

            this.setState({
                description: '',
                account_code: ''
            });

        }
    }

    handleSubmit = async(event) => {

        if (event) event.preventDefault();

        let data = _.clone(this.state)

        console.log("Data => ", data)

    }

    render(){

        const {id} = this.props;
        // console.log("Id => ", this.props.idPolicy);


        return (
            <>
                <div className="modal fade" tabIndex={-1} id={id}>
                    <div className="modal-dialog modal-md modal-dialog-scrollable">
                        
                        <form onSubmit={this.handleSubmit} className="modal-content" autoComplete="off">
                            <div className="modal-header">
                                <h5 className="modal-title">Enviar a Contalink</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={this.state.saving}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">                                
                                <div className="col-md-12">
                                    <medium className="text-muted">Fecha de envío</medium>
                                    <span className="form-control text-muted">{this.fecha_envio}</span>
                                </div>

                                <div className="col-md-12">
                                    <label>Código de cuenta</label>
                                    <input type="text" className="form-control text-uppercase" id="account_code" name="account_code" value={this.state.account_code} onChange={this.handleChange}/> 
                                </div>

                                <div className="col-md-12">
                                    <label>Cargos</label>
                                    <span type="text" className="form-control text-uppercase text-muted" id="numero" name="numero">{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( this.state.cargos )}</span> 
                                </div>

                                <div className="col-md-12">
                                    <label>Abonos</label>
                                    <span type="text" className="form-control text-uppercase text-muted" id="debit" name="debit">{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( this.state.abonos )}</span> 
                                </div>

                                <div className="col-md-12">
                                    <label>Descripción</label>
                                    <input type="text" className="form-control" id="description" name="description" value={this.state.description} onChange={this.handleChange} required/> 
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" disabled={this.state.saving}>Cancelar</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saving}>
                                    
                                    {(this.state.saving === true && <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span>Enviando...</span>
                                    </>) || 'Enviar'}
                                    
                                </button>
                            </div>
                        </form>
                        
                    </div>
                </div>
                
            </>
        )
    }
}

export default ContalinkForm;