import React, { Component } from 'react';
import ReportDay from './ReportDay';
import Rest from '../../Server/Rest';
import config from '../../settings/config';
import _ from 'underscore';

export default class ReportDayEdit extends ReportDay {

    include =   'Grupo' +
                ',Grupo.Socios' +
                ',Sucursal' +
                ',promotor' +
                ',montos' +
                ',montos.Socio' +
                ',Pagos' +
                ',PagosRealizados' +
                ',PagosRealizados.registrado_por';

    UNSAFE_componentWillMount = async () => {
        this.setState({
            loading: true
        })
        this.resetState();
        this.getData()
        .then(async () => {
            return await this.getResources();
        })
        .then(async () => {
            return await this.getGroups();
        })
        .then((groups) => {
            this.setState({
                groupsDebit: groups[0].results,
                groups: groups[1].results
            })
        })
        .catch((error) => {
            console.log(error);
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        })
        .finally(() => {
            this.setState({
                loading: false
            })
        })
        
    }

    setGroupItemEdit = (coll, key, defaultItem) => {
        const result = _.filter(coll, item => {
            if (!_.isEmpty(item.grupo)) {
                item.grupo = (item.grupo && item.grupo.objectId) || '';
                item[key] = (item[key] && parseFloat(item[key])) || 0;
                return item;
            }
        })
        if (_.isEmpty(result)) {
            return defaultItem;
        }
        return result;
    }

    getData = async () => {
        return new Promise((resolve, reject) => {
            
            const params = new URLSearchParams({
                include: 'pagos_adelantados.grupo,recuperacion_del_dia.grupo,pagos_pendientes.grupo,estimacion_del_dia.grupo,Sucursal'
            });
            
            Rest.peticion(`${config.api_url}/classes/ReporteBitacora/${this.props.match.params.id_bitacora}?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                return res;
            })
            .then(async res => {
                if (res.code) {
                    alert('Ha ocurrido un error inesperado. Intente más tarde.');
                } else {
                    const report = res;
                    console.log(report);
                    // try {
                        
                        const state = {
                            // form inputs
                            fecha_consulta: new Date(report.fecha_consulta.iso),
                            fecha_publicacion: new Date(report.fecha_publicacion.iso),
                            Sucursal: report.Sucursal.objectId,
                            hecho_por: report.hecho_por.objectId,
                            dayEstimate: this.setGroupItemEdit(report.estimacion_del_dia, 'monto_pago', [this.dayEstimateDefault()]),
                            disbursementDeposit: report.depositos_para_desembolsos || [this.disbursementDepositDefault()],
                            prepaid: this.setGroupItemEdit(report.pagos_adelantados, 'monto_pago', [this.prepaidDefault()]),
                            dayRecovery: this.setGroupItemEdit(report.recuperacion_del_dia, 'monto_pago', [this.dayRecoveryDefault()]),
                            pendingPayments: this.setGroupItemEdit(report.pagos_pendientes, 'monto_pago', [this.pendingPaymentsDefault()]),
                            depositsToAccount: report.depositos_a_cuenta,
                            extraCosts: report.gastos_extras || this.extraCostsDefault(),
                            totalDayEstimate: report.total_estimacion_del_dia,
                            totalDisbursementDeposit: report.total_depositos_para_desembolsos,
                            totalPrepaid: report.total_pagos_adelantados,
                            totalDayRecovery: report.total_recuperacion_del_dia,
                            totalDisbursements: report.total_desembolsos,
                            totalPendingPayments: report.total_pagos_pendientes,
                            totalDepositsToAccount: report.total_depositos_a_cuenta,
                            totalExtraCosts: report.total_gastos_extras,
                            total: report.sobrante_del_dia,
                            candado_edicion_bitacora: report.Sucursal.candado_edicion_bitacora
                        }
                
                        this.setState({...state})
                        resolve(true);
                    // } catch (error) {
                    //     reject(error)
                    // }
                    
                    
                }
            })
            .catch(error => {
                reject(error);
                
            })
            
        })
    }

    resetState = () => {
        const state = {
            loading: false,
    
            // form inputs
            fecha_consulta: '',
            fecha_publicacion: '',
            Sucursal: '',
            hecho_por: '',
            dayEstimate: [this.dayEstimateDefault()],
            disbursementDeposit: [this.disbursementDepositDefault()],
            prepaid: [this.prepaidDefault()],
            dayRecovery: [this.dayRecoveryDefault()],
            disbursements: [this.disbursementsDefault()],
            pendingPayments: [this.pendingPaymentsDefault()],
            depositsToAccount: [this.depositsToAccountDefault()],
            extraCosts: [this.extraCostsDefault()],
            totalDayEstimate: 0,
            totalDisbursementDeposit: 0,
            totalPrepaid: 0,
            totalDayRecovery: 0,
            totalDisbursements: 0,
            totalPendingPayments: 0,
            totalDepositsToAccount: 0,
            totalExtraCosts: 0,
            total: 0,
            candado_edicion_bitacora: true,
    
            // collections
            groups: [],
            groupsDebit: [],

            // configs
            bank_accounts: []
        }

        this.setState(state)

        this.setTotals()
    }

    handleSaveReport = async (event) => {
        event.preventDefault();

        const {
            fecha_consulta,
            fecha_publicacion,
            Sucursal,
            hecho_por,
            dayEstimate,
            disbursementDeposit,
            prepaid,
            dayRecovery,
            pendingPayments,
            depositsToAccount,
            extraCosts,
            totalDayEstimate,
            totalDisbursementDeposit,
            totalPrepaid,
            totalDayRecovery,
            totalDisbursements,
            totalPendingPayments,
            totalDepositsToAccount,
            totalExtraCosts,
            total
        } = this.state;

        const data = {
            fecha_consulta: {
                __type: 'Date',
                iso: fecha_consulta.toISOString()
            },
            fecha_publicacion: {
                __type: 'Date',
                iso: fecha_publicacion.toISOString()
            },
            Sucursal: {
                __type: 'Pointer',
                className: 'Sucursales',
                objectId: Sucursal
            },
            hecho_por: {
                __type: 'Pointer',
                className: '_User',
                objectId: hecho_por
            },
            estimacion_del_dia: this.setGroupPointer(dayEstimate),
            depositos_para_desembolsos: disbursementDeposit,
            pagos_adelantados: this.setGroupPointer(prepaid),
            recuperacion_del_dia: this.setGroupPointer(dayRecovery),
            pagos_pendientes: this.setGroupPointer(pendingPayments),
            depositos_a_cuenta: depositsToAccount,
            gastos_extras: extraCosts,
            total_estimacion_del_dia: totalDayEstimate,
            total_depositos_para_desembolsos: totalDisbursementDeposit,
            total_pagos_adelantados: totalPrepaid,
            total_recuperacion_del_dia: totalDayRecovery,
            total_desembolsos: totalDisbursements,
            total_pagos_pendientes: totalPendingPayments,
            total_depositos_a_cuenta: totalDepositsToAccount,
            total_gastos_extras: parseFloat(totalExtraCosts),
            sobrante_del_dia: total,
        };

        this.setState({
            loading: true
        })

        Rest.peticion(`${config.api_url}/classes/ReporteBitacora/${this.props.match.params.id_bitacora}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })
        .then(res => {
            return res.json();
        })
        .then(async res => {
            await this.getData();
            if (!res.code) {
                alert('Reporte actualizado exitosamente');
            }
        })
        .catch(error => {
            console.log(error);
            alert('No fue posible actualizar el reporte, intente más tarde')
        })
        .finally(() => {
            this.setState({
                loading: false
            })
        })


    }

    htmlFormButton = () => <div className="row">
        <div className="col-md-4">
            {this.state.candado_edicion_bitacora !== true && <button type="submit" className="btn btn-primary btn-block">Guardar</button>}
        </div>
    </div>
}
