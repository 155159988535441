import React, { Component } from 'react';
import SyncLoader from "react-spinners/SyncLoader";
class Spinner extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: false
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (this.state.loading !== nextProps.loading) {
            this.setState({
                loading: nextProps.loading
            })
        }
    }

    setSpinner () {
        return <SyncLoader
        size={15}
        color={"#23A1D3"}
        loading={this.state.loading}
        />
    } 

    render() {
        return (
            this.state.loading && <div className="d-flex align-items-center justify-content-center p-3" style={styles.wrapper}>
                {this.setSpinner()}
            </div>
        );
    }
}

export default Spinner;

const styles = {
    wrapper: {
        backgroundColor: 'rgba(245, 212, 234, 0.5)',
        position: 'fixed',
        zIndex: 9999,
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    }
}