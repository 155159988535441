import React, { Component } from 'react';
import Logo from './../../assets/static/logo-home.png';
import Rest from '../../Server/Rest';
import config from './../../settings/config';
import RequisitionModel from '../../models/RequisitionModel';
import _ from 'underscore';
import formatString from '../../utilities/format-string';

class Home extends Component {

    state = {
        requisitions: [],
        count: 0
    }

    componentDidMount = () => {

        // this.getGruposDiccionario();

        // this.getSolicitudes();

        // this.getSolicitudesPagosRealizados();

        // this.getSolicitudesSetFechaFinalContrato();

        // this.getSolicitudesBajas();
    }

    getSolicitudes = () => {
        const params = new URLSearchParams({
            
            limit: 1000,

            skip: 0,
            where: JSON.stringify({
                factor_cobro: {
                    $exists: true
                },
                charge_factor_type: {
                    $exists: false
                }
            }),
            count: 1,
            include: 'objectId,factor_cobro',
        });

        Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            this.setTotals(res);
        });
    }
    setTotals = (res) => {
        let allRequests = [];
        let newRequests = [];

        if (!_.isEmpty(res.results)) {
            _.each(res.results, item => {
                allRequests.push({
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Solicitudes/${item.objectId}`,
                    body: {
                        charge_factor_type: 'charge_factors_1'
                        // interes: RequisitionModel.charge_factors_2[item.factor_cobro].interes,
                        // iva_del_interes: RequisitionModel.charge_factors_2[item.factor_cobro].iva_del_interes,
                        // meses: RequisitionModel.charge_factors_2[item.factor_cobro].meses,
                    }
                });
            });
        }
        
        do{
            newRequests.push( allRequests.splice(0, 50) );
        } while (allRequests.length > 0);

        console.log(newRequests);
        this.guardarTodo(newRequests);
    }
    setTotal = (solicitud) => {
        let total = 0.0;
        for (let index = 0; index < solicitud.montos.length; index++) {
            total += solicitud.montos[index].monto_autorizado; 
        }
        return total;
    }

    getGruposDiccionario = () => {
        const params = new URLSearchParams({
            
            limit: 1000,
            skip: 0,
            where: JSON.stringify({
                Grupo: {
                    $inQuery: {
                        where: {
                            id_busqueda: {
                                $exists: false
                            }
                        },
                        className: 'Grupos'
                    }
                }
            }),
            count: 1,
        });

        Rest.peticion(`${config.api_url}/classes/GruposDiccionario?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            this.setIdBusqueda(res);
        });
    }
    setIdBusqueda = (res) => {
        let allRequests = [];
        let newRequests = [];

        if (!_.isEmpty(res.results)) {
            _.each(res.results, item => {
                allRequests.push({
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Grupos/${item.Grupo.objectId}`,
                    body: {
                        id_busqueda: item.id_busqueda
                    }
                });
            });
        }
        
        do{
            newRequests.push( allRequests.splice(0, 50) );
        } while (allRequests.length > 0);

        this.guardarTodo(newRequests);
    }
    guardarTodo = (newRequests) => {
        let newRes = [];
        _.each(newRequests, (requests, index) => {
            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify({
                    requests: requests
                })
            }).then(res => {
                return res.json();
            }).then(res => {
                newRes = _.union(newRes, res);
                if ((newRequests.length -1) === index) {
                    console.log(newRes);
                }
            }).catch(error => {
                console.log(error);
            });
        });
    }

    getSolicitudesPagosRealizados = () => {
        const params = new URLSearchParams({
            
            limit: 1000,
            skip: 0,
            where: JSON.stringify({
                SolicitudPago: {
                    $inQuery: {
                        where: {
                            objectId: {
                                $exists: true
                            }
                        },
                        className: 'SolicitudesPagos'
                    }
                },
                SolicitudMonto: {
                    $inQuery: {
                        where: {
                            objectId: {
                                $exists: true
                            }
                        },
                        className: 'SolicitudesMontos'
                    }
                }
            }),
            include: 'SolicitudPago,SolicitudMonto,SolicitudPago.Solicitud,SolicitudMonto.Solicitud',
            count: 1,
        });

        Rest.peticion(`${config.api_url}/classes/SolicitudesPagosRealizados?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            this.setRequestsSolicitudes(res);
        });
    }
    setRequestsSolicitudes = (res) => {
        let allRequests = [];
        let newRequests = [];

        if (!_.isEmpty(res.results)) {
            const groupPagosRealizados = _.groupBy(res.results, item => {
                const requisition = item.SolicitudMonto.Solicitud || item.SolicitudPago.Solicitud;
                return requisition.objectId;
            });
            _.each(groupPagosRealizados, (group, requisition) => {
                const objects = [];
                _.each(group, item => {
                    objects.push({
                        __type: 'Pointer',
                        className: 'SolicitudesPagosRealizados',
                        objectId: item.objectId
                    });
                });
                allRequests.push({
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Solicitudes/${requisition}`,
                    body: {
                        PagosRealizados: {
                            __op: 'AddUnique',
                            objects: objects
                        }
                    }
                });
            });
            _.each(res.results, item => {
                if (item.SolicitudMonto.Solicitud||item.SolicitudPago.Solicitud) {
                    const requisition = item.SolicitudMonto.Solicitud || item.SolicitudPago.Solicitud;
                    // allRequests.push({
                    //     method: 'PUT',
                    //     path: `${config.api_path2}/classes/Solicitudes/${requisition.objectId}`,
                    //     body: {
                    //         PagosRealizados: {
                    //             __op: 'AddUnique',
                    //             objects: [{
                    //                 __type: 'Pointer',
                    //                 className: 'SolicitudesPagosRealizados',
                    //                 objectId: item.objectId
                    //             }]
                    //         }
                    //     }
                    // });
                    allRequests.push({
                        method: 'PUT',
                        path: `${config.api_path2}/classes/SolicitudesPagosRealizados/${item.objectId}`,
                        body: {
                            Solicitud: {
                                __type: 'Pointer',
                                className: 'Solicitudes',
                                objectId: requisition.objectId
                            }
                        }
                    });
                } else {
                    console.log(item);
                }
            });
        }
        
        do{
            newRequests.push( allRequests.splice(0, 50) );
        } while (allRequests.length > 0);

        this.guardarTodo(newRequests);
    }

    getSolicitudesSetFechaFinalContrato = () => {
        const params = new URLSearchParams({
            
            limit: 1000,

            skip: 0,
            where: JSON.stringify({
                status: {
                    $ne: 'autorizacion'
                },
                fecha_elaboracion: {
                    $exists: true
                },
                fecha_final_contrato: {
                    $exists: false
                }
            }),
            count: 1,
        });

        Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            this.setFechasFinalContrato(res);
        });
    }
    setFechasFinalContrato = (res) => {
        let allRequests = [];
        let newRequests = [];

        if (!_.isEmpty(res.results)) {
            _.each(res.results, item => {
                allRequests.push({
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Solicitudes/${item.objectId}`,
                    body: {
                        fecha_final_contrato: this.setFechaFinalContrato(item)
                    }
                });
            });
        }
        
        do{
            newRequests.push( allRequests.splice(0, 50) );
        } while (allRequests.length > 0);

        this.guardarTodo(newRequests);
    }
    setFechaFinalContrato = (solicitud) => {
        let fecha_elaboracion = new Date(solicitud.fecha_elaboracion.iso);
        let fechaFinal = RequisitionModel.getLastPaymentDay(fecha_elaboracion, solicitud.prestamo_personalizado, solicitud.prestamo_personalizado_cantidad_pagos, solicitud.prestamo_personalizado_periodicidad, solicitud.cantidad_pagos_semanales, 'iso', false);
        return {
            __type: 'Date',
            iso: fechaFinal.toISOString()
        };
    }


    setPayWeekCustom = (requisition) => {
        const items = _.where(requisition.Pagos, {numero_pago: 1});
        return _.reduce(items, (memo, item) => {
            return memo + item.monto
        }, 0);
    }
    setPayWeek = (requisition) => {
        const items = _.where(requisition.Pagos, {numero_pago: 1});
        return _.reduce(items, (memo, item) => {
            return memo + item.monto
        }, 0);
    };
    // calcular el pago periodico
    setPay2 = (requisition) => {
        if (!_.isEmpty(requisition)) {
            let pay = 0.0;
            if (requisition.prestamo_personalizado === true) {
                pay = this.setPayWeekCustom(requisition);
            } else {
                pay = this.setPayWeek(requisition, requisition.monto_autorizado);
            }
            return pay;
        } else {
            return false;
        }
        
    }
    showWindowChangeStatus = (requisition) => {
        
        if (!_.isEmpty(requisition)) {
            const {
                cantidad_pagos_semanales,
                prestamo_personalizado_cantidad_pagos
            } = requisition;
            const pago_total_periodo = this.setPay2(requisition);
            let pago_total = 0;
    
            if (prestamo_personalizado_cantidad_pagos) {
                pago_total = prestamo_personalizado_cantidad_pagos * pago_total_periodo;
            } else {
                pago_total = cantidad_pagos_semanales * pago_total_periodo;
            }

            let total_pagado = 0.0;
            _.each(requisition.montos, monto => {
                if (monto.monto_acumulado) {
                    total_pagado += monto.monto_acumulado;
                };
            });
            
            // si el pago total es igual a lo pagado
            // entonces mostrar la ventana
            return total_pagado < pago_total;
        }
    }
    getSolicitudesBajas = (skip = 0) => {
        
        
        const params = new URLSearchParams({
            
            limit: 1000,

            skip: skip,
            where: JSON.stringify({
                status: 'baja',
                Pagos: {
                    $exists: true
                },
                PagosRealizados: {
                    $exists: true
                }
            }),
            count: 1,
            include: 'Pagos,PagosRealizados,montos,Grupo',
            order: '-createdAt'
        });

        Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            
            if (res.count > this.state.requisitions.length) {

                let {requisitions} = this.state;
                for (let index = 0; index < res.results.length; index++) {
                    const requisition = res.results[index];
                    requisitions.push(requisition);
                };
    
                this.setState({
                    requisitions: requisitions,
                    count: res.count
                });
                
                if (res.count > this.state.requisitions.length) {
                    this.getSolicitudesBajas(requisitions.length);
                }
            }
        });
    }

    montoDesem = (montos) => {
        let total = 0;
        for (let index = 0; index < montos.length; index++) {
            const monto = montos[index];
            total += monto.monto_autorizado;
        }
        return new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format(total);
    }

    render() {
        let counter = 1;
        return (
            <div className="d-flex flex-column align-items-center justify-content-center h-100">

                {/* <table className='table'>
                    <thead>
                        <tr>
                            <th>total: {this.state.requisitions.length}</th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>objectId</th>
                            <th>nombre</th>
                            <th>ciclo</th>
                            <th>Monto desem.</th>
                            <th>createdAt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.requisitions.map((item, index) => {
                            if (this.showWindowChangeStatus(item)) {    
                                return <tr key={index}>
                                    <td>{counter++}</td>
                                    <td>{item.objectId}</td>
                                    <td>{item.Grupo.nombre}</td>
                                    <td>{item.ciclo}</td>
                                    <td>{this.montoDesem(item.montos)}</td>
                                    <td>{formatString.setLegibleDate(item.Pagos[item.Pagos.length -1].fecha_pago.iso)}</td>
                                </tr>
                            }
                        })}
                    </tbody>
                </table> */}
                <img src={Logo} alt="SOLFIN DEL SURESTE" className="img-fluid" />

            </div>
        );
    }
}

export default Home;
