import React, { Component } from 'react';
import _ from 'underscore';

class PartnerForm extends Component {
    constructor(props) {
        super(props);

        this.state = this.initState;

        this.handleChange     = this.handleChange.bind(this);
        
    }

    settlements = [];

    initState = {
        // datos económicos
        destino_credito: '',
        gastos_negocio: 0,
        gastos_prestamo_banco: 0,
        gastos_prestamo_departamental: 0,
        gastos_prestamo_financiera: 0,
        gastos_prestamo_otro: 0,
        gastos_prestamo_personal: 0,
        gastos_prestamo_tdd: 0,
        gastos_vivienda_agua: 0,
        gastos_vivienda_luz: 0,
        gastos_vivienda_otros: 0,
        gastos_vivienda_renta_casa: 0,
        gastos_vivienda_telefono: 0,
        gastos_vivienda_transporte: 0,
        ingreso_beca: 0,
        ingreso_otros_trabajo: 0,
        ingreso_otros_ventas_totales: 0,
        ingreso_otros: 0,
        ingreso_prospera: 0,
        negocio_tiempo_activo: '',
        negocio_tipo: '',
        negocio_venta: 0,
        observacion_vivienda: '',
        sueldo_conyugue: 0,

        // datos mercado
        datos_mercado_pregunta_1: '',
        datos_mercado_pregunta_2: '',
        datos_mercado_pregunta_3: '',

        // totales
        total_ingresos: 0
        
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // para resetear el formulario
        if (nextProps.resetform === true) {
            this.setState(this.initState);
        }
        // sirve para la edición de socios
        if (!_.isEmpty(nextProps.data)) {
            // Establecemos la fecha de registro
            this.setState(nextProps.data);
        }
    }

    componentWillUpdate = (nextProps, nextState) => {
        this.props.setData(nextState);
    }

    setTotalIncome = () => {

        const negocio_venta =                parseInt(this.state.negocio_venta) || 0;
        const sueldo_conyugue =              parseInt(this.state.sueldo_conyugue) || 0;
        const ingreso_prospera =             parseInt(this.state.ingreso_prospera) || 0;
        const ingreso_beca =                 parseInt(this.state.ingreso_beca) || 0;
        const ingreso_otros =                parseInt(this.state.ingreso_otros) || 0;
        const ingreso_otros_ventas_totales = parseInt(this.state.ingreso_otros_ventas_totales) || 0;
        const ingreso_otros_trabajo =        parseInt(this.state.ingreso_otros_trabajo) || 0;

        let total = 
        negocio_venta +
        sueldo_conyugue +
        ingreso_prospera +
        ingreso_beca +
        ingreso_otros +
        ingreso_otros_ventas_totales +
        ingreso_otros_trabajo;

        return total;

    }

    setTotalExpenses = () => {

        const gastos_negocio =                  parseInt(this.state.gastos_negocio) || 0;
        const gastos_prestamo_banco =           parseInt(this.state.gastos_prestamo_banco) || 0;
        const gastos_prestamo_departamental =   parseInt(this.state.gastos_prestamo_departamental) || 0;
        const gastos_prestamo_financiera =      parseInt(this.state.gastos_prestamo_financiera) || 0;
        const gastos_prestamo_otro =            parseInt(this.state.gastos_prestamo_otro) || 0;
        const gastos_prestamo_personal =        parseInt(this.state.gastos_prestamo_personal) || 0;
        const gastos_prestamo_tdd =             parseInt(this.state.gastos_prestamo_tdd) || 0;
        const gastos_vivienda_agua =            parseInt(this.state.gastos_vivienda_agua) || 0;
        const gastos_vivienda_luz =             parseInt(this.state.gastos_vivienda_luz) || 0;
        const gastos_vivienda_otros =           parseInt(this.state.gastos_vivienda_otros) || 0;
        const gastos_vivienda_renta_casa =      parseInt(this.state.gastos_vivienda_renta_casa) || 0;
        const gastos_vivienda_telefono =        parseInt(this.state.gastos_vivienda_telefono) || 0;
        const gastos_vivienda_transporte =      parseInt(this.state.gastos_vivienda_transporte) || 0;

        let total = 
        gastos_negocio +
        gastos_prestamo_banco +
        gastos_prestamo_departamental +
        gastos_prestamo_financiera +
        gastos_prestamo_otro +
        gastos_prestamo_personal +
        gastos_prestamo_tdd +
        gastos_vivienda_agua +
        gastos_vivienda_luz +
        gastos_vivienda_otros +
        gastos_vivienda_renta_casa +
        gastos_vivienda_telefono +
        gastos_vivienda_transporte;

        return total;
    }

    setMonthlyTotal = () => this.setTotalIncome() - this.setTotalExpenses();

    setFormatDivisa = (number) => {
        return new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format(number);
    }
    
    handleChange = (event) => {
            
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if (!_.isEmpty(value) && target.type === "number") {
            value = parseInt(value);
        }
    
        this.setState({
            [name]: value
        });

    }

    render() {
        return (
            <>
                {this.html_datosEconomicos()}

                {this.html_datosMercado()}

            </>
        );
    }

    html_datosEconomicos = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Datos económicos</legend>
                <div className="row">
                    <div className="form-group col-md-4">
                        {
                            (!this.props.readOnly && <>
                                <label htmlFor="tipo-negocio">Tipo de negocio</label>
                                <input type="text" className="form-control text-uppercase" id="negocio_tipo" name="negocio_tipo" value={this.state.negocio_tipo} onChange={this.handleChange} required={this.props.required} />
                            </>) || <>
                                <small className="text-muted">Tipo de negocio</small>
                                <p>
                                    {this.state.negocio_tipo}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            (!this.props.readOnly && <>
                                <label htmlFor="tiempo-actividad">Tiempo en la actividad</label>
                                <input type="text" className="form-control text-uppercase" id="negocio_tiempo_activo" name="negocio_tiempo_activo" value={this.state.negocio_tiempo_activo} onChange={this.handleChange} required={this.props.required} />
                            </>) || <>
                                <small className="text-muted">Tiempo en la actividad</small>
                                <p>
                                    {this.state.negocio_tiempo_activo}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            (!this.props.readOnly && <>
                                <label htmlFor="destino-credito">Destino del crédito</label>
                                <select className="form-control text-uppercase" id="destino_credito" name="destino_credito" value={this.state.destino_credito} onChange={this.handleChange} required={this.props.required} >
                                    <option value="" disabled>Elija una opción...</option>
                                    <option>Inversión</option>
                                </select>
                            </>) || <>
                                <small className="text-muted">Destino del crédito</small>
                                <p>
                                    {this.state.destino_credito}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            (!this.props.readOnly && <>
                                <label htmlFor="venta-negocio">Venta del negocio</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">$</div>
                                    </div>
                                    <input type="number" className="form-control" id="negocio_venta" name="negocio_venta" value={this.state.negocio_venta} onChange={this.handleChange} required={this.props.required} />
                                </div>
                            </>) || <>
                                <small className="text-muted">Venta del negocio</small>
                                <p>
                                    {this.setFormatDivisa(this.state.negocio_venta)}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            (!this.props.readOnly && <>
                                <label htmlFor="gastos_negocio">Gastos</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">$</div>
                                    </div>
                                    <input type="number" className="form-control" id="gastos_negocio" name="gastos_negocio" value={this.state.gastos_negocio} onChange={this.handleChange} required={this.props.required} />
                                </div>
                            </>) || <>
                                <small className="text-muted">Gastos</small>
                                <p>
                                    {this.setFormatDivisa(this.state.gastos_negocio)}
                                </p>
                            </>
                        }
                    </div>
                    <div className="form-group col-md-4">
                        {
                            (!this.props.readOnly && <>
                                <label htmlFor="sueldo-conyugue">Sueldo del conyugue</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">$</div>
                                    </div>
                                    <input type="number" className="form-control" id="sueldo_conyugue" name="sueldo_conyugue" value={this.state.sueldo_conyugue} onChange={this.handleChange} required={this.props.required} />
                                </div>
                            </>) || <>
                                <small className="text-muted">Sueldo del conyugue</small>
                                <p>
                                    {this.setFormatDivisa(this.state.sueldo_conyugue)}
                                </p>
                            </>
                        }
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                        <h5>Ingresos familiares</h5> 
                        <div className="card">
                            <div className="card-body">
                                <h6>Subsidio del gobierno</h6> 
                                <br/>
                                <div className="form-group row">
                                    {
                                        (!this.props.readOnly && <>
                                            <label htmlFor="ingreso-prospera" className="col-sm-4 col-form-label">Prospera</label>
                                            <div className="input-group col-sm-8 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="ingreso_prospera" name="ingreso_prospera" value={this.state.ingreso_prospera} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </>) || <>
                                            <small className="text-muted col-sm-6">Prospera</small>
                                            <p className="col-sm-6 mb-2 d-flex justify-content-end">
                                                {this.setFormatDivisa(this.state.ingreso_prospera)}
                                            </p>
                                        </>
                                    }
                                </div>
                                <div className="form-group row">
                                    {
                                        (!this.props.readOnly && <>
                                            <label htmlFor="ingreso-beca" className="col-sm-4 col-form-label">Beca</label>
                                            <div className="input-group col-sm-8 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="ingreso_beca" name="ingreso_beca" value={this.state.ingreso_beca} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </>) || <>
                                            <small className="text-muted col-sm-6">Beca</small>
                                            <p className="col-sm-6 mb-2 d-flex justify-content-end">
                                                {this.setFormatDivisa(this.state.ingreso_beca)}
                                            </p>
                                        </>
                                    }
                                </div>
                                <div className="form-group row">
                                    {
                                        (!this.props.readOnly && <>
                                            <label htmlFor="ingreso-otros" className="col-sm-4 col-form-label">Otros</label>
                                            <div className="input-group col-sm-8 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="ingreso_otros" name="ingreso_otros" value={this.state.ingreso_otros} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </>) || <>
                                            <small className="text-muted col-sm-6">Otros</small>
                                            <p className="col-sm-6 mb-2 d-flex justify-content-end">
                                                {this.setFormatDivisa(this.state.ingreso_otros)}
                                            </p>
                                        </>
                                    }
                                </div>
                                <br className={`${this.props.readOnly? 'd-none' : ''}`} />
                                <h6>Otros ingresos</h6> 
                                <br/>
                                <div className="form-group row">
                                    {
                                        (!this.props.readOnly && <>
                                            <label htmlFor="otros-ingresos-ventas-totales" className="col-sm-4 col-form-label">Ventas totales</label>
                                            <div className="input-group col-sm-8 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="ingreso_otros_ventas_totales" name="ingreso_otros_ventas_totales" value={this.state.ingreso_otros_ventas_totales} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </>) || <>
                                            <small className="text-muted col-sm-6">Ventas totales</small>
                                            <p className="col-sm-6 mb-2 d-flex justify-content-end">
                                                {this.setFormatDivisa(this.state.ingreso_otros_ventas_totales)}
                                            </p>
                                        </>
                                    }
                                </div>
                                <div className="form-group row">
                                    {
                                        (!this.props.readOnly && <>
                                            <label htmlFor="ingreso-otro-trabajo" className="col-sm-4 col-form-label">Otro trabajo</label>
                                            <div className="input-group col-sm-8 mb-2 align-items-start">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">$</div>
                                                </div>
                                                <input type="number" min="0" className="form-control" id="ingreso_otros_trabajo" name="ingreso_otros_trabajo" value={this.state.ingreso_otros_trabajo} onChange={this.handleChange} required={this.props.required} />
                                            </div>
                                        </>) || <>
                                            <small className="text-muted col-sm-6">Otro trabajo</small>
                                            <p className="col-sm-6 mb-2 d-flex justify-content-end">
                                                {this.setFormatDivisa(this.state.ingreso_otros_trabajo)}
                                            </p>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <h5>Gastos familiares</h5> 
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 border-right">
                                        <h6>Vivienda</h6>
                                        <br/>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="gasto-renta-casa" className="col-sm-5 col-form-label">Renta de casa</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_vivienda_renta_casa" name="gastos_vivienda_renta_casa" value={this.state.gastos_vivienda_renta_casa} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Renta de casa</small>
                                                    <p className="col-sm-6 mb-2 d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_vivienda_renta_casa)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="gasto-telefono" className="col-sm-5 col-form-label">Teléfono</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_vivienda_telefono" name="gastos_vivienda_telefono" value={this.state.gastos_vivienda_telefono} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Teléfono</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_vivienda_telefono)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="gasto-agua" className="col-sm-5 col-form-label">Agua</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_vivienda_agua" name="gastos_vivienda_agua" value={this.state.gastos_vivienda_agua} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Agua</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_vivienda_agua)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="gasto-luz" className="col-sm-5 col-form-label">Luz</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_vivienda_luz" name="gastos_vivienda_luz" value={this.state.gastos_vivienda_luz} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Luz</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_vivienda_luz)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="gasto-transporte" className="col-sm-5 col-form-label">Transporte</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_vivienda_transporte" name="gastos_vivienda_transporte" value={this.state.gastos_vivienda_transporte} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Transporte</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_vivienda_transporte)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="gasto-otros" className="col-sm-5 col-form-label">Otros</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_vivienda_otros" name="gastos_vivienda_otros" value={this.state.gastos_vivienda_otros} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Otros</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_vivienda_otros)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                            
                                    </div>
                                    <div className="col-md-6">
                                        <h6>Préstamos</h6>
                                        <br/>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="prestamo-personal" className="col-sm-5 col-form-label">Personal</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_prestamo_personal" name="gastos_prestamo_personal" value={this.state.gastos_prestamo_personal} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Personal</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_prestamo_personal)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="prestamo-financiera" className="col-sm-5 col-form-label">Financieras</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_prestamo_financiera" name="gastos_prestamo_financiera" value={this.state.gastos_prestamo_financiera} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Financieras</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_prestamo_financiera)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="prestamo-banco" className="col-sm-5 col-form-label">Bancos</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_prestamo_banco" name="gastos_prestamo_banco" value={this.state.gastos_prestamo_banco} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Bancos</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_prestamo_banco)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="prestamo-tdd" className="col-sm-5 col-form-label">Trarjetas de crédito</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_prestamo_tdd" name="gastos_prestamo_tdd" value={this.state.gastos_prestamo_tdd} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Trarjetas de crédito</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_prestamo_tdd)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="prestamo-departamental" className="col-sm-5 col-form-label">Departamentales</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_prestamo_departamental" name="gastos_prestamo_departamental" value={this.state.gastos_prestamo_departamental} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Departamentales</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_prestamo_departamental)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group row">
                                            {
                                                (!this.props.readOnly && <>
                                                    <label htmlFor="prestamo-otro" className="col-sm-5 col-form-label">Otros</label>
                                                    <div className="input-group col-sm-7 mb-2 align-items-start">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" min="0" className="form-control" id="gastos_prestamo_otro" name="gastos_prestamo_otro" value={this.state.gastos_prestamo_otro} onChange={this.handleChange} required={this.props.required} />
                                                    </div>
                                                </>) || <>
                                                    <small className="text-muted col-sm-6">Otros</small>
                                                    <p className="col-sm-6 mb-2 text-right d-flex justify-content-end">
                                                        {this.setFormatDivisa(this.state.gastos_prestamo_otro)}
                                                    </p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    
                    <div className="form-group col-md-4">
                        {
                            (!this.props.readOnly && <>
                                <label>Total ingresos</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">$</div>
                                    </div>
                                    <span className="form-control text-muted">{this.setTotalIncome()}</span>
                                </div>
                            </>) || <>
                                <small className="text-muted">Total ingresos</small>
                                <p className="text-info">
                                    {this.setFormatDivisa(this.setTotalIncome())}
                                </p>
                            </>
                        }
                    </div>
                    
                    
                    <div className="form-group col-md-4">
                        {
                            (!this.props.readOnly && <>
                                <label>Total egreso</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">$</div>
                                    </div>
                                    <span className="form-control text-muted">{this.setTotalExpenses()}</span>
                                </div>
                            </>) || <>
                                <small className="text-muted">Total egreso</small>
                                <p className="text-info">
                                    {this.setFormatDivisa(this.setTotalExpenses())}
                                </p>
                            </>
                        }
                    </div>
                    
                    
                    <div className="form-group col-md-4">
                        {
                            (!this.props.readOnly && <>
                                <label>Total mensual</label>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">$</div>
                                    </div>
                                    <span className="form-control text-muted" >{this.setMonthlyTotal()}</span>
                                </div>
                            </>) || <>
                                <small className="text-muted">Total mensual</small>
                                <p className="text-info">
                                    {this.setFormatDivisa(this.setMonthlyTotal())}
                                </p>
                            </>
                        }
                    </div>
                    
                    <div className="form-group col-md-12">
                        {
                            (!this.props.readOnly && <>
                                <label htmlFor="observacion-vivienda">Observación de la vivienda</label>
                                <textarea className="form-control text-uppercase" id="observacion_vivienda" name="observacion_vivienda" value={this.state.observacion_vivienda} onChange={this.handleChange} rows="3" required={this.props.required} ></textarea>
                            </>) || <>
                                <small className="text-muted">Observación de la vivienda</small>
                                <p>
                                    {this.state.observacion_vivienda}
                                </p>
                            </>
                        }
                    </div>
                </div>
                
            </div>
        </fieldset>
    );

    html_datosMercado = () => (
        <fieldset className="card mb-3">
            <div className="card-body">
                <legend className="card-title">Datos de mercado</legend>
                <div className="form-group">
                    {
                        (!this.props.readOnly && <>
                            <label htmlFor="datos_mercado_pregunta_1">1. ¿Cómo se enteró de SOLFIN DEL SURESTE S.A.S. DE C.V.?</label>
                            <textarea className="form-control text-uppercase" id="datos_mercado_pregunta_1" name="datos_mercado_pregunta_1" value={this.state.datos_mercado_pregunta_1} onChange={this.handleChange} rows="3" required={this.props.required} ></textarea>
                        </>) || <>
                            <small className="text-muted">1. ¿Cómo se enteró de SOLFIN DEL SURESTE S.A.S. DE C.V.?</small>
                            <p>
                                {this.state.datos_mercado_pregunta_1}
                            </p>
                            <br/>
                        </>
                    }
                </div>
                <div className="form-group">
                    {
                        (!this.props.readOnly && <>
                            <label htmlFor="datos-mercado-pregunta-2">2. ¿Por qué decidió solicitar crédito con SOLFIN DEL SURESTE S.A.S. DE C.V.?</label>
                            <textarea className="form-control text-uppercase" id="datos_mercado_pregunta_2" name="datos_mercado_pregunta_2" value={this.state.datos_mercado_pregunta_2} onChange={this.handleChange} rows="3" required={this.props.required} ></textarea>
                        </>) || <>
                            <small className="text-muted">2. ¿Por qué decidió solicitar crédito con SOLFIN DEL SURESTE S.A.S. DE C.V.?</small>
                            <p>
                                {this.state.datos_mercado_pregunta_2}
                            </p>
                            <br/>
                        </>
                    }
                </div>
                <div className="form-group">
                    {
                        (!this.props.readOnly && <>
                            <label htmlFor="datos-mercado-pregunta-3">3. ¿Qué beneficios le llama más la atención de SOLFIN DEL SURESTE S.A.S. DE C.V.?</label>
                            <textarea className="form-control text-uppercase" id="datos_mercado_pregunta_3" name="datos_mercado_pregunta_3" value={this.state.datos_mercado_pregunta_3} onChange={this.handleChange} rows="3" required={this.props.required} ></textarea>
                        </>) || <>
                            <small className="text-muted">3. ¿Qué beneficios le llama más la atención de SOLFIN DEL SURESTE S.A.S. DE C.V.?</small>
                            <p>
                                {this.state.datos_mercado_pregunta_3}
                            </p>
                        </>
                    }
                </div>
            </div>
        </fieldset>
    );
}

export default PartnerForm;