import React, { Component } from 'react';
import Background from '../../assets/static/login-bg.jpg';
import Logo from '../../assets/static/logo-rosa.png';

class RememberPassword extends Component {
    render() {
        return (
            <div className="container-fluid vh-100 d-flex flex-column align-items-center justify-content-center pt-3 pb-3" style={styles.bg}>
                <img src={Logo} className="pb-4" alt="SOLFIN" />
                <div className="card" style={styles.widthLogin}>
                    <div className="card-body p-3">
                        <h5 className="text-uppercase text-center pb-4 pt-2 text-pink-dark"><b>Recuperar contraseña</b></h5>
                        <form className=" pb-4">
                            <label htmlFor="exampleInputEmail1" className="text-pink-dark">Escriba el email del usuario</label>
                            <div className="input-group mb-3 ">
                                <input type="email" className="form-control shadow-sm" id="exampleInputEmail1" aria-describedby="basic-addon1" />
                                <div className="input-group-append">
                                    <span className="input-group-text shadow-sm" id="basic-addon1">
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                </div>
                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                            </div>
                            <button type="submit" className="btn btn-primary btn-lg btn-block text-uppercase shadow-sm">Recuperar</button>
                        </form>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default RememberPassword;

const styles = {
    bg: {
        backgroundColor: '#C789B1',
        backgroundImage: 'url('+Background+')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    },
    widthLogin: {
        maxWidth: 320,
        width: '100%'
    }
}