/* eslint-disable no-useless-concat */
/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'underscore';
import './../../styles/css/group.css';
import config from '../../settings/config';
import GroupListPayment from './GroupListPayment';
import GroupListPaymentFilterReport from './GroupListPaymentFilterReport';
import Popover from './../others/Popover';
import { jsPDF } from 'jspdf';
import Rest from '../../Server/Rest';
import user from './../../settings/user';
import Spinner from '../others/Spinner';
import TableCountInfo from './../others/TableCountInfo';
import TablePagination from './../others/TablePagination';
import textUtils from '../../utilities/format-string';
import Parse from 'parse';
import TableOrder from '../others/TableOrder';
import requisitions_service from '../../services/requisitions';
import { CREDIT_GROUP, CREDIT_PERSONAL, CREDIT_EMPLOYEE } from '../../constants/creditTypes';
import { exists, stat } from 'fs';

class GroupListPaymentReport extends GroupListPayment {
    
    title = 'Reportes';

    status = 'baja';

    order = '-updatedAt';

    className = '';

    state = {
        loading: false,
        filter_type: 'pagos',

        // datos de filtro
        nombre: '',
        promotor: '',
        dateStart: '',
        dateEnd: '',
        monto_autorizado: 0.0,
        plazo: '',
        Sucursal: !_.isEmpty(Parse.User.current().toJSON().Sucursal) ? Parse.User.current().toJSON().Sucursal.objectId : '',
        tipoReporte: '',
        tipo_credito: '',

        total: 0,
        collection: []
    }

    setWhere = (data = {}) => {

        let where = {};
        let where_fechas = {};

        switch (this.state.filter_type) {
            case 'pagos':
                where_fechas = {
                    Pagos: (data.dateStart || data.dateEnd)? {
                        $inQuery: {
                            where: {
                                fecha_pago: {}
                            },
                            className: 'SolicitudesPagos'
                        }
                    } : {
                        $exists: true
                    }
                };
                if (data.dateStart) {
                    where_fechas.Pagos.$inQuery.where.fecha_pago.$gte = data.dateStart;
                }    
                if (data.dateEnd) {
                    where_fechas.Pagos.$inQuery.where.fecha_pago.$lte = data.dateEnd;
                }                     



                where = _.extend({
                    promotor: !_.isEmpty(data.promotor)? data.promotor : undefined,
                    status: this.state.filter_type === 'pagos' ? this.status : {$nin: ['autorizacion','rechazado']},
                    // la propiedad prestamo_personalizado_periodicidad se configura dependiendo si se requiere obtener resultados en general, pagos catorcenales o semanales
                    prestamo_personalizado_periodicidad: data.prestamo_personalizado_periodicidad === 14 ? 14 : data.prestamo_personalizado_periodicidad === 7 ? {$ne: 14} : undefined,
                    Grupo: !_.isEmpty(data.id_busqueda) || !_.isEmpty(data.Empleado)? {
                        $inQuery: {
                            where: {
                                ...(data.id_busqueda && {id_busqueda: data.id_busqueda}),
                                ...(data.Empleado && {Empleado: data.Empleado})
                            },
                            className: 'Grupos'
                        }
                    }: undefined,
                    Sucursal: data.Sucursal? data.Sucursal : undefined,
                    monto_autorizado: data.monto_autorizado? data.monto_autorizado : undefined,
                    prestamo_personalizado: data.prestamo_personalizado ? data.prestamo_personalizado : undefined, 
                    factor_cobro: data.factor_cobro ?  data.factor_cobro : undefined, 
                    prestamo_personalizado_interes: data.prestamo_personalizado_interes ?  data.prestamo_personalizado_interes : undefined,
                    prestamo_personalizado_cantidad_pagos: data.prestamo_personalizado_cantidad_pagos ?  data.prestamo_personalizado_cantidad_pagos : undefined
                }, where_fechas);
                break;
                
            case 'desembolsos':
            case 'morosidad':
                if (data.dateStart || data.dateEnd) {
                    where_fechas = {
                        fecha_elaboracion: {}
                    };
                    if (data.dateStart) {
                        where_fechas.fecha_elaboracion.$gte = data.dateStart;
                    }    
                    if (data.dateEnd) {
                        where_fechas.fecha_elaboracion.$lte = data.dateEnd;
                    }
                }/* else {
                    where_fechas = {
                        Pagos: {
                            $exists: true
                    };
                }*/

                where = _.extend({
                    promotor: !_.isEmpty(data.promotor)? data.promotor : undefined,
                    status: this.state.filter_type === 'pagos' ? this.status : {$nin: ['autorizacion','rechazado']},
                    // la propiedad prestamo_personalizado_periodicidad se configura dependiendo si se requiere obtener resultados en general, pagos catorcenales o semanales
                    prestamo_personalizado_periodicidad: data.prestamo_personalizado_periodicidad === 14 ? 14 : data.prestamo_personalizado_periodicidad === 7 ? {$ne: 14} : undefined,
                    Grupo: !_.isEmpty(data.id_busqueda)? {
                        $inQuery: {
                            where: {
                                id_busqueda: data.id_busqueda
                            },
                            className: 'Grupos'
                        }
                    }: undefined,
                    Sucursal: data.Sucursal? data.Sucursal : undefined,
                    monto_autorizado: data.monto_autorizado? data.monto_autorizado : undefined,
                    prestamo_personalizado: data.prestamo_personalizado ? data.prestamo_personalizado : undefined, 
                    factor_cobro: data.factor_cobro ?  data.factor_cobro : undefined, 
                    prestamo_personalizado_interes: data.prestamo_personalizado_interes ?  data.prestamo_personalizado_interes : undefined,
                    prestamo_personalizado_cantidad_pagos: data.prestamo_personalizado_cantidad_pagos ?  data.prestamo_personalizado_cantidad_pagos : undefined
                }, where_fechas);
                break;
        }

        switch (this.state.filter_type) {
            case 'pagos':
                this.order = '-pagado_al,-updatedAt';
                this.className = 'Solicitudes';
                this.include = 
                'Grupo' +
                ',Sucursal' +
                ',promotor' +
                ',montos' +
                ',Pagos';
                break;
            case 'desembolsos':
            case 'morosidad':
                this.order = '-fecha_elaboracion,-updatedAt';
                this.className = 'Solicitudes';
                this.include =   
                'Grupo' +
                ',Grupo.Solicitudes' +
                ',Grupo.Solicitudes.montos' +
                ',Grupo.Solicitudes.montos.Socio' +
                ',Grupo.Solicitudes.PagosRealizados' +
                ',Grupo.Solicitudes.Pagos' +
                ',Sucursal' +
                ',promotor' +
                ',montos' +
                ',montos.Socio' +
                ',PagosRealizados' +
                ',Pagos';
                break;
        }

        const config = user.config();
        const filter_from = config && config.filter_from ? {
            $gte: {
                __type: 'Date',
                iso: config.filter_from
                
            }
        } : undefined;

        where.createdAt = filter_from;
        
        this.where = where;
        return where;
    }

    componentDidMount = () => {}

    getBigLotTotalsPagos = (where) => {
        let promise = new Promise((resolve, reject) => {
            let montos = [];
            const query = (where, skip = 0) => {
                const params = new URLSearchParams({
                    limit: 1000,
                    skip: skip,
                    where: where,
                    count: 1,
                    order: this.order,
                    include: this.include,
                    // keys: 'Grupo,Sucursal,promotor,montos,pagos'
                    keys: 'PagosRealizados.pagos_adelantados,montos,montos.monto_autorizado,prestamo_personalizado_interes,prestamo_personalizado_cantidad_pagos,prestamo_personalizado_periodicidad,prestamo_personalizado_cantidad_pagos,factor_cobro,prestamo_personalizado'
                });
                
                Rest.peticion(`${config.api_url}/classes/${this.className}?${params.toString()}`, {
                    method: 'GET'
                })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    montos = montos.concat(res.results);
                    if (res.count === montos.length) {
                        const total_pagosadelantados = _.reduce(montos, function(memo, item){ return memo + (item.pagos_adelantados || 0); }, 0);
                        let total_montopagos = 0;
                        _.each(montos, item => {
                            total_montopagos += this.setPay(item.Solicitud);
                        });
                        resolve({
                            total_pagosadelantados: total_pagosadelantados,
                            total_montopagos: total_montopagos
                        });
                    } else {
                        query(where, montos.length);
                    }
                }).catch(error => {
                    reject(error);
                });
            };
    
            query(where, 0);
        });

        return promise;
    }
    getBigLotTotalsDesembolsos = (where) => {
        let promise = new Promise((resolve, reject) => {
            let requisitions = [];
            const query = (where, skip = 0) => {
                const params = new URLSearchParams({
                    limit: 1000,
                    skip: skip,
                    where: where,
                    count: 1,
                    order: this.order,
                    include: this.include,
                    keys: 'PagosRealizados.pagos_adelantados,montos,montos.monto_autorizado,prestamo_personalizado_interes,prestamo_personalizado_cantidad_pagos,prestamo_personalizado_periodicidad,prestamo_personalizado_cantidad_pagos,factor_cobro,prestamo_personalizado'
                });
                
                Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
                    method: 'GET'
                })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    requisitions = requisitions.concat(res.results);
                    if (res.count === requisitions.length) {
                        let
                        montos = _.pluck(requisitions, 'montos');
                        montos = _.flatten(montos);
                        const total_desembolsos = _.reduce(montos, function(memo, item){ return memo + item.monto_autorizado; }, 0);
                        const total_clientes = _.reduce(requisitions, function(memo, item){ return memo + item.montos.length; }, 0);
                        let total_montopagos = 0;
                        _.each(requisitions, item => {
                            total_montopagos += this.setPay(item);
                        });
                        let total_pagosadelantados = 0;
                        _.each(requisitions, item => {
                            total_pagosadelantados += this.setEquivalentPayments(item);
                        })
                        resolve({
                            total_desembolsos: total_desembolsos,
                            total_montopagos: total_montopagos,
                            total_pagosadelantados: total_pagosadelantados,
                            total_clientes: total_clientes
                        });
                    } else {
                        query(where, requisitions.length);
                    }
                }).catch(error => {
                    reject(error);
                });
            };
    
            query(where, 0);
        });

        return promise;
    }

    getBigLotTotals = (where) => {
        let query;
        switch (this.state.filter_type) {
            case 'pagos':
                query = this.getBigLotTotalsPagos(where);
                break;
            case 'desembolsos':
            case 'morosidad':
                query = this.getBigLotTotalsDesembolsos(where);
                break;
        }
        return query;
    }

    getResource = (index = 0, _where = {}) => {

        this.totalAuthorizedAmount = 0.0;
        this.totalWeeklyPayment = 0.0;

        this.setState(state => ({
            index: index
        }));
        
        this.handleSpinner();

        switch (this.state.filter_type) {
            case 'pagos':
                Rest.peticion(`${config.api_url}/functions/getReportPayments`, {
                    method: 'POST',
                    body: JSON.stringify({
                        className: this.className,
                        where: this.where,
                        order: this.order,
                        include: this.include,
                        type: this.state.filter_type,
                        index: parseInt(index),
                        limit: this.limit
                    })
                })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    this.rows = res.result.count;
                    let collection = this.getCollection(res.result);
                    if (!_.isEmpty(res.result.totals)) {
                        collection.push({
                            monto_pago: res.result.totals.monto_pago,
                            monto_pagado: res.result.totals.monto_pagado,
                            pagos_adelantados: res.result.totals.pagos_adelantados
                        });
                    }
                    this.setState({
                        collection: collection,
                        total: this.rows
                    });
                })
                .then(() => {
                    this.handleSpinner();
                })
                .catch(error => {
                    this.handleSpinner();
                    alert('Ha ocurrido un error inesperado. Intente más tarde.');
                });
                break;
            case 'desembolsos':
                
                const where = JSON.stringify(_where);

                Rest.peticion(`${config.api_url}/functions/getReportDisbursement`, {
                    method: 'POST',
                    body: JSON.stringify({
                        className: this.className,
                        where: where,
                        order: this.order,
                        include: this.include,
                        type: this.state.filter_type,
                        skip: parseInt(index),
                        limit: this.limit,
                        format: 'json'
                    })
                })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    this.rows = res.result.count;
                    this.setState({
                        collection: res.result.results,
                        total: this.rows
                    });
                })
                .then(() => {
                    this.handleSpinner();
                })
                .catch(error => {
                    this.handleSpinner();
                    alert('Ha ocurrido un error inesperado. Intente más tarde.');
                });
                break;
            case 'morosidad':
                // const where = JSON.stringify(_where);

                Rest.peticion(`${config.api_url}/functions/getReportDelinquency`, {
                    method: 'POST',
                    body: JSON.stringify({
                        className: 'this.className',
                        where: {
                            Pagos: {
                                $inQuery: {
                                    where: {
                                        fecha_pago: {
                                            $lte: {
                                                __type: 'Date',
                                                iso: new Date().toISOString()
                                            }
                                        }
                                    },
                                    className: 'SolicitudesPagos'
                                }
                            }
                        },
                        order: this.order,
                        include: this.include,
                        type: this.state.filter_type,
                        skip: parseInt(index),
                        limit: this.limit,
                        format: 'json'
                    })
                })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    console.log(res);
                })
                .then(() => {
                    this.handleSpinner();
                })
                .catch(error => {
                    this.handleSpinner();
                    alert('Ha ocurrido un error inesperado. Intente más tarde.');
                });
                break;
            default:
                break;
        }
    }

    getCollection = (res) => {
        let collection = [];
        const days = [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
        ]
        switch (this.state.filter_type) {
            case 'pagos':
                collection = _.map(res.results, (item, index) => {
                    const pagado_al = new Date(item.pagado_al);
                    return {
                        objectId: item.objectId,
                        groupId: item.Grupo.objectId,
                        index: index + 1 + parseInt(this.state.index),
                        // Grupo
                        nombre_grupo: item.Grupo.nombre,
                        // Núm. Clientes
                        num_clientes: item.montos.length,
                        // Ciclo de la solicitud
                        ciclo: item.ciclo,
                        // Plazo
                        plazo: this.setPeriodisidad(item),
                        // Núm. Pagos
                        num_pagos: this.setNumberPayments(item),
                        // Pago aplicado
                        pago_aplicado: item.pago_aplicado,
                        // Pagado al
                        pagado_al: this.setLegibleDate(item.pagado_al),
                        // Pago semanal
                        monto_pago: item.monto_pago,
                        // Monto pagado
                        monto_pagado: item.monto_pagado,
                        // Pagos adelantados
                        pagos_adelantados: item.pagos_adelantados || 0,
                        // Día pago
                        pagado_al_dia: days[pagado_al.getDay()].toUpperCase(),
                        // Promotor
                        promotor: this.setPromotor(item),
                        // Sucursal
                        sucursal: this.setLugarRegistro(item.Sucursal)
                    }
                });
                
            break;
            case 'desembolsos':
                collection = _.map(res.results, (item, index) => {
                    return {
                        objectId: item.objectId,
                        groupId: item.Grupo.objectId,
                        index: index + 1 + parseInt(this.state.index),
                        // Grupo
                        nombre_grupo: item.Grupo.nombre,
                        // Núm. Clientes
                        num_clientes: item.montos.length,
                        // Ciclo actual
                        ciclo: this.setCicle(item),
                        // Plazo
                        plazo: this.setPeriodisidad(item),
                        // Núm. Pagos
                        num_pagos: this.setNumberPayments(item),
                        // Monto desem.
                        monto_desembolsado: this.setAuthorizedAmount(item),
                        // Fecha desem.
                        fecha_desem: this.setLegibleDate(item.fecha_elaboracion.iso),
                        // Pago semanal
                        monto_pago: this.setPay(item),
                        // Pagos adelantados
                        pagos_adelantados: this.setEquivalentPayments(item),
                        // Día pago
                        dia_pago: this.setPayday(item),
                        // Última fecha pago
                        ultima_fecha_pago: this.lastPayDay(item),// this.lastPaymentRecord(item),
                        // Promotor
                        promotor: this.setPromotor(item),
                        // Sucursal
                        sucursal: this.setLugarRegistro(item.Sucursal)
                    }
                });
            break;
            case 'morosidad':
                collection = _.map(res.results, (item, index) => {
                    return {
                        objectId: item.objectId,
                        groupId: item.Grupo.objectId,
                        index: index + 1 + parseInt(this.state.index),
                        // Grupo
                        nombre_grupo: item.Grupo.nombre,
                        // Núm. Clientes
                        num_clientes: item.montos.length,
                        // Ciclo actual
                        ciclo: this.setCicle(item),
                        // Plazo
                        plazo: this.setPeriodisidad(item),
                        // Núm. Pagos
                        num_pagos: this.setNumberPayments(item),
                        // Monto desem.
                        monto_desembolsado: this.setAuthorizedAmount(item),
                        // Fecha desem.
                        fecha_desem: this.setLegibleDate(item.fecha_elaboracion.iso),
                        // Pago semanal
                        monto_pago: this.setPay(item),
                        // Pagos adelantados
                        monto_atrasado: this.currentDebt(item),
                        // Día pago
                        dia_pago: this.setPayday(item),
                        // Última fecha pago
                        ultima_fecha_pago: this.lastPayDay(item),// this.lastPaymentRecord(item),
                        // Promotor
                        promotor: this.setPromotor(item),
                        // Sucursal
                        sucursal: this.setLugarRegistro(item.Sucursal)
                    }
                });
            break;
        }
        return collection;
    }

    currentDebt = (requisition) => {
        let result = 0.0;
        for (let index1 = 0; index1 < requisition.montos.length; index1++) {
            const amount = requisition.montos[index1];

            const payments = _.filter(requisition.Pagos, (pago) => {
                return amount.objectId === pago.SolicitudMonto.objectId
            });
            
            let monto_acumulado = amount.monto_acumulado || 0.0;
            let debt = requisitions_service.getTotalPayment(payments);
            debt = debt - monto_acumulado;
            if (debt < 0) { // cuando tiene saldo a favor
                result += 0;        
            } else if (debt > 0) { // cuando tiene deuda
                result += debt;
            } else { // cuando no debe nada
                result += 0;
            }
        }
        return result;
    }

    setTableHead = () => {
        let tr;
        switch (this.state.filter_type) {
            case 'pagos':
                tr = <tr>
                    <th nowrap="true">#</th>
                    <th nowrap="true">Grupo</th>
                    <th nowrap="true"># Clientes</th>
                    <th nowrap="true">Ciclo</th>
                    <th nowrap="true">Plazo</th>
                    <th nowrap="true">Pagos</th>
                    <th nowrap="true">Pago aplicado</th>
                    <th nowrap="true">Pagado al</th>
                    <th nowrap="true">Monto pago <span className="no-show-pdf"><Popover title="Monto de pago" content="Es el pago semanal o catorcenal según sea el caso." /></span></th>
                    <th nowrap="true">Monto pagado</th>
                    <th nowrap="true">Pagos adelantados</th>
                    <th nowrap="true">Pagado <br/>al día</th>
                    <th nowrap="true">Promotor</th>
                    <th nowrap="true">Sucursal</th>
                </tr>;
                break;
            case 'desembolsos':
                tr = <tr>
                    <th nowrap="true">#</th>
                    <th nowrap="true">Grupo</th>
                    <th nowrap="true"># Clientes</th>
                    <th nowrap="true">Ciclo</th>
                    <th nowrap="true">Plazo</th>
                    <th nowrap="true">Pagos</th>
                    <th nowrap="true">Monto desem.</th>
                    <th nowrap="true">Fecha desem. <TableOrder setOrder={this.setOrder} active={this.state.collection.length > 0} options={['-fecha_elaboracion,-updatedAt', 'fecha_elaboracion,-updatedAt']}/></th>
                    <th nowrap="true">Monto pago <span className="no-show-pdf"><Popover title="Monto de pago" content="Es el pago semanal o catorcenal según sea el caso." /></span></th>
                    <th nowrap="true">Pagos adelantados <span className="no-show-pdf"><Popover title="Pagos adelantados" content="Corresponde a los pagos adelantados del ciclo anterior." /></span></th>
                    <th nowrap="true">Día pago</th>
                    <th nowrap="true">Última fecha pago</th>
                    <th nowrap="true">Promotor</th>
                    <th nowrap="true">Sucursal</th>
                </tr>
                break;
            case 'morosidad':
                tr = <tr>
                    <th nowrap="true">#</th>
                    <th nowrap="true">Grupo</th>
                    <th nowrap="true"># Clientes</th>
                    <th nowrap="true">Ciclo</th>
                    <th nowrap="true">Plazo</th>
                    <th nowrap="true">Pagos</th>
                    <th nowrap="true">Monto desem.</th>
                    <th nowrap="true">Fecha desem. <TableOrder setOrder={this.setOrder} active={this.state.collection.length > 0} options={['-fecha_elaboracion,-updatedAt', 'fecha_elaboracion,-updatedAt']}/></th>
                    <th nowrap="true">Monto pago <span className="no-show-pdf"><Popover title="Monto de pago" content="Es el pago semanal o catorcenal según sea el caso." /></span></th>
                    <th nowrap="true">Monto atrasado</th>
                    <th nowrap="true">Día pago</th>
                    <th nowrap="true">Última fecha pago</th>
                    <th nowrap="true">Promotor</th>
                    <th nowrap="true">Sucursal</th>
                </tr>
                break;
            default:
                break;
        }
        return tr;
    }

    setTableBody = () => {
        const {state} = this;
        let body;

        switch (this.state.filter_type) {
            case 'pagos':
                body = state.collection.map((item, index) => <tr key={index} id={`solicitud-${item.objectId}`}>
                    <td nowrap="true">{item.index}</td>
                    <td nowrap="true">{item.nombre_grupo}</td>
                    <td nowrap="true">{item.num_clientes}</td>
                    <td nowrap="true">{item.ciclo}</td>
                    <td nowrap="true">{item.plazo}</td>
                    <td nowrap="true">{item.num_pagos}</td>
                    <td>{item.pago_aplicado}</td>
                    <td nowrap="true">{item.pagado_al}</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_pago )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_pago )
                    }</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_pagado )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_pagado )
                    }</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{item.pagos_adelantados}</b> : item.pagos_adelantados
                    }</td>
                    <td nowrap="true" className="text-uppercase">{item.pagado_al_dia}</td>
                    <td nowrap="true">{item.promotor}</td>
                    <td nowrap="true">{item.sucursal}</td>
                </tr>);
            break;
            case 'desembolsos':
                body = state.collection.map((item, index) => <tr key={index} id={`solicitud-${item.objectId}`}>
                    <td nowrap="true">{item.index}</td>
                    <td nowrap="true">{item.nombre_grupo}</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en-MX').format(item.num_clientes)}</b> : item.num_clientes
                    }</td>
                    <td nowrap="true">{item.ciclo}</td>
                    <td nowrap="true">{item.plazo}</td>
                    <td nowrap="true">{item.num_pagos}</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_desembolsado )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_desembolsado )
                    }</td>
                    <td nowrap="true">{item.fecha_desem}</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_pago )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_pago )
                    }</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en-MX').format(item.pagos_adelantados)}</b> : item.pagos_adelantados
                    }</td>
                    <td nowrap="true" className="text-uppercase">{item.dia_pago}</td>
                    <td nowrap="true">{item.ultima_fecha_pago}</td>
                    <td nowrap="true">{item.promotor}</td>
                    <td nowrap="true">{item.sucursal}</td>
                </tr>);
            break;
            case 'morosidad':
                body = state.collection.map((item, index) => <tr key={index} id={`solicitud-${item.objectId}`}>
                    <td nowrap="true">{item.index}</td>
                    <td nowrap="true">{item.nombre_grupo}</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en-MX').format(item.num_clientes)}</b> : item.num_clientes
                    }</td>
                    <td nowrap="true">{item.ciclo}</td>
                    <td nowrap="true">{item.plazo}</td>
                    <td nowrap="true">{item.num_pagos}</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_desembolsado )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_desembolsado )
                    }</td>
                    <td nowrap="true">{item.fecha_desem}</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_pago )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_pago )
                    }</td>
                    <td nowrap="true">{
                        (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_atrasado )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.monto_atrasado )
                    }</td>
                    <td nowrap="true" className="text-uppercase">{item.dia_pago}</td>
                    <td nowrap="true">{item.ultima_fecha_pago}</td>
                    <td nowrap="true">{item.promotor}</td>
                    <td nowrap="true">{item.sucursal}</td>
                </tr>);
            break;
        }
        
        return body;
    }

    handleChangeFilterType = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            total: 0,
            collection: []

        });
        
        return true;
    }

    setCicle = (requisition) => {
        return requisition.ciclo;
    }

    setPayText = (number) => {
        let text = '';
        switch (number) {
            case 7:
                text = 'SEMANAL';
                break;
            case 14:
                text = 'CATORCENAL';
                break;
            default:
                text = 'SEMANAL';
                break;
        }
        return text;
    }

    setPeriodisidad = (requisition) => {
        return this.setPayText(requisition.prestamo_personalizado_periodicidad);
    }

    setLegibleDate = (date_iso) => {
        const _days = ['01','02','03','04','05','06','07','08','09','10',
                      '11','12','13','14','15','16','17','18','19','20',
                      '21','22','23','24','25','26','27','28','29','30',
                      '31'];
        const _months = ['01','02','03','04','05','06','07','08','09','10','11','12']
        
        const theDate = new Date(date_iso);
        return `${_days[theDate.getDate() -1]}/${_months[theDate.getMonth()]}/${theDate.getFullYear()}`;
    }

    setPayday = (requisition) => {
        if (requisition.dia_pago) {
            const days = [
                "Domingo",
                "Lunes",
                "Martes",
                "Miércoles",
                "Jueves",
                "Viernes",
                "Sábado"
            ];
            return days[requisition.dia_pago].toUpperCase();
        } else {
            return <i>No especificado</i>
        }
    }

    setEquivalentPayments = (requisition) => {
        
        let total = 0;
        if (requisition.ciclo > 1) {
            try {
                requisition = _.findWhere(requisition.Grupo.Solicitudes, {ciclo: requisition.ciclo - 1});
                if (requisition.PagosRealizados !== undefined) {
                    let gte;
                    let lte;
                    switch (this.state.filter_type) {
                        case 'pagos':
                            // eslint-disable-next-line no-mixed-operators
                            gte = this.where.Pagos.$inQuery && this.where.Pagos.$inQuery.where.fecha_pago.$gte || undefined;
                            // eslint-disable-next-line no-mixed-operators
                            lte = this.where.Pagos.$inQuery && this.where.Pagos.$inQuery.where.fecha_pago.$lte || undefined;
    
                            // si hay rango de fecha
                            if (!_.isEmpty(lte) && !_.isEmpty(gte)) {
                                const dateStart = new Date(gte.iso).getTime();
                                const dateEnd = new Date(lte.iso).getTime();
                                
                                for (let index = 0; index < requisition.PagosRealizados.length; index++) {
                                    const datePay = new Date(requisition.PagosRealizados[index].pagado_al.iso);
                                    if (datePay >= dateStart && datePay <= dateEnd) {
                                        total += Math.floor(requisition.PagosRealizados[index].pagos_adelantados/requisition.montos.length) || 0;
                                    }
                                }
                            // si hay fecha mínima
                            } else if (!_.isEmpty(gte)) {
                                const dateStart = new Date(gte.iso).getTime();
                                
                                for (let index = 0; index < requisition.PagosRealizados.length; index++) {
                                    const datePay = new Date(requisition.PagosRealizados[index].pagado_al.iso);
                                    if (datePay >= dateStart) {
                                        total += Math.floor(requisition.PagosRealizados[index].pagos_adelantados/requisition.montos.length) || 0;
                                    }
                                }
                            // si hay fecha máxima
                            } else if (!_.isEmpty(lte)) {
                                const dateEnd = new Date(lte.iso).getTime();
                                
                                for (let index = 0; index < requisition.PagosRealizados.length; index++) {
                                    const datePay = new Date(requisition.PagosRealizados[index].pagado_al.iso);
                                    if (datePay <= dateEnd) {
                                        total += Math.floor(requisition.PagosRealizados[index].pagos_adelantados/requisition.montos.length) || 0;
                                    }
                                }
                            // si no hay rango de fecha
                            } else {
                                for (let index = 0; index < requisition.PagosRealizados.length; index++) {
                                    total += Math.floor(requisition.PagosRealizados[index].pagos_adelantados/requisition.montos.length) || 0;
                                }
                            }
                            break;
                        case 'desembolsos':
                            for (let index = 0; index < requisition.PagosRealizados.length; index++) {
                                total += requisition.PagosRealizados[index].pagos_adelantados || 0;
                            }
                            total = Math.floor(total/requisition.montos.length);
                            break;
                    }
                }
            } catch (error) {
                console.log(error);
                total = 0;
            }
        }
        return total;
    }

    setEquivalentPaymentsTotal = (requisitions) => {
        let total = 0;
        _.each(requisitions, item => {
            total += item.pagos_adelantados || 0;
        });
        return total;
    }

    lastPaymentRecord = (requisition) => {
        const montos = requisition.montos;
        let fechas = _.pluck(montos, 'ultima_fecha_pago');
        fechas = _.compact(fechas);
        if (!_.isEmpty(fechas)) {
            const marcasTiempo = _.map(fechas, fecha => {
                if (fecha) {
                    return new Date(fecha.iso).getTime();
                }
            });
            const fechaMasActual = _.max(marcasTiempo);
            return new Date(fechaMasActual).toLocaleDateString();
        } else {
            return <i>Sin fecha</i>;
        }
    }

    lastPayDay = (requisition) => {
        let last_date = 'Sin registro';
        try {
            const last_pay = _.max(requisition.Pagos, (pago) => {return pago.numero_pago});
            last_date = new Date(last_pay.fecha_pago.iso).toLocaleDateString();
        } catch (error) {
            console.log(error);
        }
        return last_date;
    }

    setOrder = (order) => {
        this.order = order;
        this.getResource(this.state.index, this.where);
    }

    getXLS = () => {
        if (this.state.collection.length) {
            this.handleSpinner();

            let req;

            switch (this.state.filter_type) {
                case 'pagos':
                    req = Rest.peticion(`${config.api_url}/functions/getReportPayments`, {
                        method: 'POST',
                        body: JSON.stringify({
                            className: this.className,
                            where: this.where,
                            order: this.order,
                            include: this.include,
                            type: this.state.filter_type,
                            format: 'xlsx',
                            limit: -1
                        })
                    });
                    break;
                case 'desembolsos':
                    req = Rest.peticion(`${config.api_url}/functions/getXLS`, {
                        method: 'POST',
                        body: JSON.stringify({
                            className: this.className,
                            where: this.where,
                            order: this.order,
                            include: this.include,
                            type: this.state.filter_type,
                            format: 'xlsx'
                        })
                    });
                    break;
            }

            req
            .then(res => {
                return res.json();
            })
            .then(res => {

                if (res.code || res.error) {
                    alert(res.error);
                } else {
                    this.downloadXLS(res.result);
                }
                
            })
            .catch(error => {
                alert('Ha ocurrido un error al intentar descargar el archivo Excel');
            })
            .then(() => {
                this.handleSpinner();
            });
        } else {
            alert('Realice un filtro para descargar el archivo Excel');
        }
    }

    handlePrintDocument = () => {

        if (this.state.collection.length === 0) {
            alert('Sin datos para descargar');
            return;
        }

        this.handleSpinner();

        let req;

        switch (this.state.filter_type) {
            case 'pagos':
                req = Rest.peticion(`${config.api_url}/functions/getReportPayments`, {
                    method: 'POST',
                    body: JSON.stringify({
                        className: this.className,
                        where: this.where,
                        order: this.order,
                        include: this.include,
                        type: this.state.filter_type,
                        format: 'json',
                        limit: -1
                    })
                });
            break;
            case 'desembolsos':
            case 'morosidad':
                req = Rest.peticion(`${config.api_url}/functions/getXLS`, {
                    method: 'POST',
                    body: JSON.stringify({
                        className: this.className,
                        where: this.where,
                        order: this.order,
                        include: this.include,
                        type: this.state.filter_type,
                        format: 'json'
                    })
                });
            break;
        }

        req
        .then(res => {
            return res.json();
        })
        .then(res => {
            let generateData = (collection) => {
                let result = [];
                switch (this.state.filter_type) {
                    case 'pagos':
                        result = _.map(collection, item => {
                            let data = {};
                            data['#'] = ''+`${item.index?item.index:''}`;
                            data['Grupo'] = ''+`${item.nombre_grupo?item.nombre_grupo:''}`;
                            data['# Clientes'] = ''+`${item.num_clientes?item.num_clientes:''}`;
                            data['Ciclo'] = ''+`${item.ciclo?item.ciclo:''}`;
                            data['Plazo'] = ''+`${item.plazo?item.plazo:''}`;
                            data['Pagos'] = ''+`${item.num_pagos?item.num_pagos:''}`;
                            data['Pago aplicado'] = ''+`${item.pago_aplicado?item.pago_aplicado:''}`;
                            data['Pagado al'] = ''+`${item.pagado_al?item.pagado_al:''}`;
                            data['Monto pago'] = ''+(new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format(item.monto_pago));
                            data['Monto pagado'] = ''+(new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format(item.monto_pagado));
                            data['Pagos adelantados'] = ''+item.pagos_adelantados;
                            data['Pagado al día'] = ''+`${item.pagado_al_dia?item.pagado_al_dia:''}`;
                            data['Promotor'] = ''+`${item.promotor?item.promotor:''}`;
                            data['Sucursal'] = ''+`${item.sucursal?item.sucursal:''}`;
                            return Object.assign({}, data);
                        })
                    break;
                    case 'desembolsos':
                        result = _.map(collection, item => {
                            let data = {};
                            data['#'] = ''+`${item.index?item.index:''}`;
                            data['Grupo'] = ''+`${item.nombre_grupo?item.nombre_grupo:''}`;
                            data['# Clientes'] = ''+`${item.num_clientes?item.num_clientes:''}`;
                            data['Ciclo'] = ''+`${item.ciclo?item.ciclo:''}`;
                            data['Plazo'] = ''+`${item.plazo?item.plazo:''}`;
                            data['# Pagos'] = ''+`${item.num_pagos?item.num_pagos:''}`;
                            data['Monto desem.'] = ''+(new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format(item.monto_desembolsado));
                            data['Fecha desem.'] = ''+`${item.fecha_desem?item.fecha_desem:''}`;
                            data['Monto pago'] = ''+(new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format(item.monto_pago));
                            data['Pagos adel.'] = ''+item.pagos_adelantados;
                            data['Día pago'] = ''+`${item.dia_pago?item.dia_pago:''}`;
                            data['Última fecha pago'] = ''+`${item.ultima_fecha_pago?item.ultima_fecha_pago:''}`;
                            data['Promotor'] = ''+`${item.promotor?item.promotor:''}`;
                            data['Sucursal'] = ''+`${item.sucursal?item.sucursal:''}`;
                            return Object.assign({}, data);
                        })
                    break;
                    case 'morosidad':
                        result = _.map(collection, item => {
                            let data = {};
                            data['#'] = ''+`${item.index?item.index:''}`;
                            data['Grupo'] = ''+`${item.nombre_grupo?item.nombre_grupo:''}`;
                            data['# Clientes'] = ''+`${item.num_clientes?item.num_clientes:''}`;
                            data['Ciclo'] = ''+`${item.ciclo?item.ciclo:''}`;
                            data['Plazo'] = ''+`${item.plazo?item.plazo:''}`;
                            data['# Pagos'] = ''+`${item.num_pagos?item.num_pagos:''}`;
                            data['Monto desem.'] = ''+(new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format(item.monto_desembolsado));
                            data['Fecha desem.'] = ''+`${item.fecha_desem?item.fecha_desem:''}`;
                            data['Monto pago'] = ''+(new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format(item.monto_pago));
                            data['Monto atrasado'] = ''+item.pagos_adelantados;
                            data['Día pago'] = ''+`${item.dia_pago?item.dia_pago:''}`;
                            data['Última fecha pago'] = ''+`${item.ultima_fecha_pago?item.ultima_fecha_pago:''}`;
                            data['Promotor'] = ''+`${item.promotor?item.promotor:''}`;
                            data['Sucursal'] = ''+`${item.sucursal?item.sucursal:''}`;
                            return Object.assign({}, data);
                        })
                    break;
                }
                return result;
            };
            
            function createHeaders(keys) {
                var result = [];
                for (var i = 0; i < keys.length; i += 1) {
                result.push({
                    id: keys[i],
                    name: keys[i],
                    prompt: keys[i],
                    padding: 0
                });
                }
                return result;
            }
            
            let headers;

            let title = '';

            let doc = new jsPDF({
                orientation: 'landscape',
            });

            switch (this.state.filter_type) {
                case 'pagos':
                    title = 'Reporte de pagos';
                    headers = createHeaders([
                        '#',
                        'Grupo',
                        '# Clientes',
                        'Ciclo',
                        'Plazo',
                        'Pagos',
                        'Pago aplicado',
                        'Pagado al',
                        'Monto pago',
                        'Monto pagado',
                        'Pagos adelantados',
                        'Pagado al día',
                        'Promotor',
                        'Sucursal',
                    ]);

                    doc.setFontSize(20);
                    doc.setTextColor(199, 137, 177);
                    doc.text(title, 7, 10);
                    doc.setTextColor(0, 0, 0);
                    doc.table(
                        7, 15, 
                        generateData(res.result), 
                        headers, 
                        { autoSize: true, fontSize: 9, padding: 1.5 }
                    );
                break;
                
                case 'desembolsos':
                    title = 'Reporte de desembolsos';
                    headers = createHeaders([
                        '#',
                        'Grupo',
                        '# Clientes',
                        'Ciclo',
                        'Plazo',
                        '# Pagos',
                        'Monto desem.',
                        'Fecha desem.',
                        'Monto pago',
                        'Pagos adel.',
                        'Día pago',
                        'Última fecha pago',
                        'Promotor',
                        'Sucursal',
                    ]);

                    doc.setFontSize(20);
                    doc.setTextColor(199, 137, 177);
                    doc.text(title, 7, 10);
                    doc.setTextColor(0, 0, 0);
                    doc.table(
                        1, 15, 
                        generateData(res.result), 
                        headers, 
                        { autoSize: true, fontSize: 7.5, padding: .8 }
                    );
                break;
                
                case 'morosidad':
                    title = 'Reporte de morosidad';
                    headers = createHeaders([
                        '#',
                        'Grupo',
                        '# Clientes',
                        'Ciclo',
                        'Plazo',
                        '# Pagos',
                        'Monto desem.',
                        'Fecha desem.',
                        'Monto pago',
                        'Monto atrasado',
                        'Día pago',
                        'Última fecha pago',
                        'Promotor',
                        'Sucursal',
                    ]);

                    doc.setFontSize(20);
                    doc.setTextColor(199, 137, 177);
                    doc.text(title, 7, 10);
                    doc.setTextColor(0, 0, 0);
                    doc.table(
                        1, 15, 
                        generateData(res.result), 
                        headers, 
                        { autoSize: true, fontSize: 7.5, padding: .8 }
                    );
                break;
            }
            
            doc.save(`reporte-${this.state.filter_type}.pdf`);    
        })
        .catch(error => {
            alert('Ha ocurrido un error al intentar descargar el archivo Excel');
        })
        .then(() => {
            this.handleSpinner();
        });

    }

handleFilter = (event) => {

        if (event) event.preventDefault();

        const state = this.state;
        //const creditTypes = [CREDIT_GROUP, CREDIT_PERSONAL, CREDIT_EMPLOYEE];    

        let dateStart = undefined;
        if (_.isDate(state.dateStart)) {
            dateStart = new Date(state.dateStart);
            dateStart.setHours(0);
            dateStart.setMinutes(0);
            dateStart.setSeconds(0);
            dateStart.setMilliseconds(0);
        }
    
        let dateEnd = undefined;
        if (_.isDate(state.dateEnd)) {
            dateEnd = new Date(state.dateEnd);
            dateEnd.setHours(23);
            dateEnd.setMinutes(59);
            dateEnd.setSeconds(59);
            dateEnd.setMilliseconds(999);
        }

        const name_group = textUtils.setIdBusqueda(state.nombre);

        let where = {
            dateStart: _.isUndefined(dateStart) ? undefined : {
                __type: 'Date',
                iso: dateStart.toISOString()
            },
            dateEnd: _.isUndefined(dateEnd) ? undefined : {
                __type: 'Date',
                iso: dateEnd.toISOString()
            },
            id_busqueda: _.isEmpty(name_group)? undefined : {
                $regex: name_group
            },
            Sucursal: _.isEmpty(state.Sucursal)? undefined : {
                __type: 'Pointer',
                className: 'Sucursales',
                objectId: state.Sucursal
            },
            promotor: _.isEmpty(state.promotor)? undefined : {
                __type: 'Pointer',
                className: 'Empleados',
                objectId: state.promotor
            },
            monto_autorizado: _.isEmpty(state.monto_autorizado)? undefined : parseFloat(state.monto_autorizado)
        }

        if (!_.isEmpty(state.plazo)) {
            where.prestamo_personalizado_periodicidad = parseInt(state.plazo);
        }

        if (!_.isEmpty(state.tipo_credito)) {
            if( state.tipo_credito === CREDIT_GROUP ) {
                where.prestamo_personalizado = {$ne: true};
                where.factor_cobro = {$exists: true};
                where.prestamo_personalizado_interes = {$exists: false};
                where.Empleado = {$exists: false};
            }
           
            if( state.tipo_credito === CREDIT_PERSONAL ) {
                where.prestamo_personalizado = {$eq: true};
                where.prestamo_personalizado_interes = false;
                where.Empleado = {$exists: false};
            }

            if( state.tipo_credito === CREDIT_EMPLOYEE ) {
                where.prestamo_personalizado_interes = {$exists: true};
                where.prestamo_personalizado_cantidad_pagos = {$exists: true};
                where.Empleado = {$exists: true};
            }
        }

        where = this.setWhere(where);

        this.rows = 0;
        
        this.getResource(0, where);
    }
    
    render() {
        const {state} = this;
        return (
            <>
                <Spinner loading={state.loading} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-file-contract mr-2"></i>
                    {this.title}
                </h3>
                <div className="card">
                    <div className="card-body">
                        <div className="row mt-1">
                            <div className="col-md-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text" htmlFor="filter_type">Tipo</label>
                                    </div>
                                    <select className="form-control" id="filter_type" name="filter_type" value={this.state.filter_type} onChange={this.handleChangeFilterType}>
                                        <option value='pagos'>PAGOS</option>
                                        <option value='desembolsos'>DESEMBOLSOS</option>
                                        {/* <option value='morosidad'>MOROSIDAD</option> */}
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div className="m-4"></div>
                        <GroupListPaymentFilterReport handleChange={this.handleChange} handleChangeDate={this.handleChangeDate} handleFilter={this.handleFilter} state={state} />
                        <div className="m-4"></div>
                        <div className="text-right">
                            <div className="btn-group" role="group" aria-label="Descargas">
                                <button className="btn btn-secondary" onClick={() => this.getXLS()}>Descargar Excel</button>
                                <button className="btn btn-secondary" onClick={() => this.handlePrintDocument()}>Descargar PDF</button>
                            </div>
                        </div>

                        <TableCountInfo total={state.total} />

                        <div id="table-pdf" className="table-responsive" ref={this.ref}>
                            <table className="table">
                                <thead>
                                    {this.setTableHead()}
                                </thead>
                                <tbody>
                                    {state.collection.length > 0 
                                    // eslint-disable-next-line no-mixed-operators
                                    && this.setTableBody() 
                                    // eslint-disable-next-line no-mixed-operators
                                    || <tr>
                                        <td colSpan="16" className="text-center">
                                            <div className="alert alert-warning m-0" role="alert">
                                                No se hallaron registros
                                            </div>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-5"></div>
            </>
        );
    }
}

export default GroupListPaymentReport;