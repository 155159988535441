import React, { Component } from 'react';

class ChangeRecord extends Component {
    render() {
        return (
            <>
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-users mr-2"></i>
                    Registro de cambios
                </h3>
                
                <div className="card">
                    <div className="card-body">
                        <h4>02/05/2022</h4>
                        <ul>
                            <li>Se programó el candado de usuarios activos.</li>
                        </ul>
                        <h4>01/05/2022</h4>
                        <ul>
                            <li>Cambio de iconos en la barra lateral principal.</li>
                            <li>Corrección de errores.</li>
                        </ul>
                        <h4>11/04/2022</h4>
                        <ul>
                            <li>Se cambió el diseño de la lista de pagos en móbiles.</li>
                        </ul>
                        <h4>10/04/2022</h4>
                        <ul>
                            <li>Se bloquea el campo de Lugar de registro en el formularios de socias cuando el usuario actual es parte de una sucursal.</li>
                        </ul>
                        <h4>08/04/2022</h4>
                        <ul>
                            <li>Correcciones de la consulta para obtener las sumatorias en Pagos.</li>
                            <li>Optimización en Pagos: Se carga los registros primero y luego se consultan los registros necesarios para realizar las sumatorias que aparecen al final de la tabla</li>
                        </ul>
                        <h4>06/04/2022</h4>
                        <ul>
                            <li>Corrección en la lista de factor de cobro.</li>
                        </ul>
                        <h4>04/04/2022</h4>
                        <ul>
                            <li>Corrección de la validación que se hace para mostrar el botón de Nuevo ciclo.</li>
                        </ul>
                        <h4>04/04/2022</h4>
                        <ul>
                            <li>Se agregó la funcionalidad de validación de cierre de ciclo, solo se permite cerrar ciclo cuando se está en la antepenúltima fecha de pago</li>
                        </ul>
                        <h4>16/03/2022</h4>
                        <ul>
                            <li>En el módulo de pagos se consulta solo las solicitudes activas y en morosidad. El administrador tiene una opción en el filtro para obtener las solicitudes cons status baja</li>
                        </ul>
                        <h4>10/03/2022</h4>
                        <ul>
                            <li>Se configuró el sistema para que los promotores puedan registrar pagos</li>
                        </ul>
                        <ul>
                            <li>Se quitó la auto-selección del status al momento de dar de baja el ciclo en el módulo de pagos para no crear confusión al usuario</li>
                        </ul>
                        <h4>18/01/2022</h4>
                        <ul>
                            <li>Se redondea el decimal en el control de pagos por cada cliente, si es menor que $0.5 pero mayor que $0.0 se redondea a $0.5. Si es mayor a $0.5 se suma $1 al total</li>
                        </ul>
                        <h4>17/01/2022</h4>
                        <ul>
                            <li>Corrección de los nuevos porcentajes de intereses.</li>
                        </ul>
                        <h4>30/05/2021</h4>
                        <ul>
                            <li>Se cambió la dirección de la api para consultar entidades federativas por medio del código postal. Este cambio pude producir un costo de $100 por 1000 consultas.</li>
                        </ul>
                        <h4>23/05/2021</h4>
                        <ul>
                            <li>Se agregó filtro por status de clienta y visualización por cards en dispositivos móviles en la lista de clientas.</li>
                        </ul>
                        <h4>18/04/2021</h4>
                        <ul>
                            <li>Corrección de las sumatorias en el reporte de desembolsos.</li>
                        </ul>
                        <h4>11/04/2021</h4>
                        <ul>
                            <li>En el reporte de desembolsos, en la columna Pagos adelantados hace referencia a lo adelantado del ciclo anterior, y en la columna Última fecha pago hace referencia a la fecha del ultimo pago pero del calendarizado.</li>
                            <li>se revirtió la actualización anterior.</li>
                        </ul>
                        <h4>04/04/2021</h4>
                        <ul>
                            <li>Corrección en el filtro de reportes de desembolsos. El campo de fecha ahora hace referencia a los días de pagos.</li>
                        </ul>
                        <h4>29/03/2021</h4>
                        <ul>
                            <li>Solo el Administrador o Gerente pueden quitar una clienta de un grupo cuando la solicitud actual está autorizada.</li>
                        </ul>
                        <h4>25/03/2021</h4>
                        <ul>
                            <li>En el filtro del módulo de grupos se agregó los campos de Status y Promotor.</li>
                        </ul>
                        <h4>21/03/2021</h4>
                        <ul>
                            <li>Corrección de indicador de color para mostrar al usuario los pagos realizados.</li>
                        </ul>
                        <h4>19/03/2021</h4>
                        <ul>
                            <li>Se corrigió el error de que no se mostraba los pagos adelantados en reportes.</li>
                            <li>Mejoras visuales.</li>
                        </ul>
                        <h4>16/03/2021</h4>
                        <ul>
                            <li>Se corrigió el calculo del pago recurrente en los prestamos personales.</li>
                        </ul>
                        <h4>11/03/2021</h4>
                        <ul>
                            <li>En el módulo de pagos, aquellos que ya fueron realizados están diferenciados por un fondo verde.</li>
                        </ul>
                        <h4>27/01/2021</h4>
                        <ul>
                            <li>Se agregó un indicador del comportamiento de pagos de la socios en el modulo de edición de clientas.</li>
                            <li>Se bloquea el campo de fecha de elaboración cuando pasan 7 días después de la fecha establecida. Solo el administrador puede actualizar este campo en dichas condiciones.</li>
                        </ul>
                        <h4>26/01/2021</h4>
                        <ul>
                            <li>Corrección del filtro pagos.</li>
                        </ul>
                        <h4>26/01/2021</h4>
                        <ul>
                            <li>Se mejoró la exportación de pdf de lista de pagos y reportes, ahora se descargan más de 100 de acuerdo al filtro realizado.</li>
                        </ul>
                        <h4>25/01/2021</h4>
                        <ul>
                            <li>Se desactivaron los botones de Finalizar ciclo y Reestructurado para la sucursal de ocosingo.</li>
                            <li>Correcciones en el filtro de reportes de pagos.</li>
                        </ul>
                        <h4>20/01/2021</h4>
                        <ul>
                            <li>Se restringió la edición del campo de Fecha de elaboración para los grupos y solicitudes, solo el administrador puede modificar una vez que la solicitud fue autorizada.</li>
                            <li>La descarga de reportes en formato Excel son procesadas por el servidor parse-server.</li>
                        </ul>
                        <h4>17/01/2021</h4>
                        <ul>
                            <li>Corrección del filtro de clientas en el formulario de grupo. Las clientas en validación no se muestran como resultado.</li>
                        </ul>
                        <h4>14/01/2021</h4>
                        <ul>
                            <li>Se corrigió el problema de fecha de pago en el control de pagos, esto fue provocado por la actualización del sistema con fecha 10/01/2021.</li>
                        </ul>
                        <h4>12/01/2021</h4>
                        <ul>
                            <li>Correcciones de las tablas de reporte, se presenta diferentes tablas dependiendo del tipo (pagos o desembolsos).</li>
                        </ul>
                        <h4>10/01/2021</h4>
                        <ul>
                            <li>Corrección en el filtro  por fecha de reportes de desembolsos.</li>
                        </ul>
                        <h4>03/01/2021</h4>
                        <ul>
                            <li>Se calcula y se guarda los pagos adelantados.</li>
                        </ul>
                        <h4>11/12/2020</h4>
                        <ul>
                            <li>Se Permite agregar clientas a otros grupos donde el grupo origen tiene uno de los siguientes status: BAJA, RECHAZADO, REESTRUCTURADO, BAJA VOLUNTARIA.</li>
                        </ul>
                        <h4>04/12/2020</h4>
                        <ul>
                            <li>Se creó el módulo de Verificaciones, solo el Administrador y Analista pueden acceder a ello. El analista es el unico quien puede verificar el grupo.</li>
                        </ul>
                        <h4>02/12/2020</h4>
                        <ul>
                            <li>Corrección de ordenamiento por fecha de las solicitudes en autorización así como solicitudes autorizadas.</li>
                        </ul>
                        <h4>26/11/2020</h4>
                        <ul>
                            <li>Se activó la descarga de pdf en los módulos de pagos y reportes.</li>
                        </ul>
                        <ul>
                            <li>Se agregó el módulo de reportes.</li>
                        </ul>
                        <h4>16/11/2020</h4>
                        <ul>
                            <li>Se agregó la funcionalidad de establecer los pagos a grupos que ya fueron dados de baja con anterioridad.</li>
                        </ul>
                        <h4>13/11/2020</h4>
                        <ul>
                            <li>Se agregó la funcionalidad de mantener datos de clientas localmente antes de guardar en la base de datos.</li>
                        </ul>
                        <h4>30/09/2020</h4>
                        <ul>
                            <li>Corrección de redirección al momento de encontrar una coincidencia de clienta por medio de la credencial de elector en el registro de clientas.</li>
                        </ul>
                        <h4>17/09/2020</h4>
                        <ul>
                            <li>Se agregó campos para establecer un aval para grupos con 1 socio.</li>
                            <li>Correcciones generales.</li>
                        </ul>
                        <h4>11/09/2020</h4>
                        <ul>
                            <li>Se realizaron ajustes en el documento de Control de Pago.</li>
                        </ul>
                        <h4>09/09/2020</h4>
                        <ul>
                            <li>Se agregó funcionalidad de crear préstamos personalizados. El administrador es el unico que puede realizar esta operación.</li>
                        </ul>
                        <h4>07/09/2020</h4>
                        <ul>
                            <li>Corrección de margen en el documento Pagaré individual.</li>
                        </ul>
                        <h4>04/09/2020</h4>
                        <ul>
                            <li>Se agregó la funcionalidad de imprimir los documentos: Control de pagos, Pagaré individual, Pagaré grupal y Póliza de cheque.</li>
                        </ul>
                        <h4>14/08/2020</h4>
                        <ul>
                            <li>Se agregó sección de Datos de crédito en la sección de cada clienta.</li>
                        </ul>
                        <h4>13/08/2020</h4>
                        <ul>
                            <li>Corrección al agregar y quitar clientas cuando el status de la solicitud es Autorización o Autorizado.</li>
                            <li>Corrección al crear nuevas solicitudes con 1 o más clientas nuevas.</li>
                            <li>Se agregó un botón para cambiar el status del grupo a Reestructurado, la acción del botón finalizará el ciclo actual sin pedirle al usuario modificar el status de las clientas ni especificar uno para el grupo.</li>
                            <li>Se cambió de lugar el status de grupo.</li>
                        </ul>
                        <h4>12/08/2020</h4>
                        <ul>
                            <li>Se corrigió errores al registrar grupos de forma consecutiva.</li>
                            <li>Corrección de despliegue de Estado, Población y Fraccionamiento.</li>
                            <li>Se corrigió la paginación en todas las listas.</li>
                            <li>Se actualizó los status que aparecen al finalizar un ciclo (Activo, Baja, Rechazado, Morosidad).</li>
                            <li>Se agregó un botón para activar el grupo.</li>
                            <li>Se corrigió el filtro de la lista de solicitudes con status autorización</li>
                        </ul>
                        <h4>11/08/2020</h4>
                        <ul>
                            <li>Se permite usar el mismo nombre de grupo solo si pertenecen a sucursales distintas</li>
                            <li>Correcciones generales en el módulo de grupos y edición de clientas.</li>
                        </ul>
                        <h4>10/08/2020</h4>
                        <ul>
                            <li>Correcciones generales en el módulo de grupos.</li>
                        </ul>
                        <h4>07/08/2020</h4>
                        <ul>
                            <li>Correcciones generales en el módulo de grupos.</li>
                        </ul>
                        <h4>06/08/2020</h4>
                        <ul>
                            <li>Correcciones generales en el módulo de grupos.</li>
                        </ul>
                        <h4>05/08/2020</h4>
                        <ul>
                            <li>Correcciones generales en el módulo de grupos.</li>
                        </ul>
                        <h4>04/08/2020</h4>
                        <ul>
                            <li>Realizaron correcciones sobre el módulo de grupos y se activó las funciones de autorizar, rechazar, cancelar solicitudes y creación de nuevos ciclos.</li>
                        </ul>
                        <h4>28/07/2020</h4>
                        <ul>
                            <li>Se activó el módulo de Grupos con la función de registro del primer ciclo.</li>
                        </ul>
                        <h4>13/07/2020</h4>
                        <ul>
                            <li>Se agregó la lista de selección de surcursal en la búsqueda rápida de Clientas y Empleados.</li>
                        </ul>
                        <h4>06/07/2020</h4>
                        <ul>
                            <li>Se dió un estilo propio a las barras de scroll.</li>
                            <li>Corrección del guardado de la fecha de nacimiento. Error producido al usar el mismo campo en el formulario de empleado.</li>
                        </ul>
                        <h4>01/07/2020</h4>
                        <ul>
                            <li>Si un usuario con sucursal definida y permiso de registrar empleados, y registra un empleado, se toma la sucursal del usuario para el nuevo empleado.</li>
                            <li>Se activó las migajas de pan en la edición de empleados.</li>
                        </ul>
                        <h4>30/06/2020</h4>
                        <ul>
                            <li>Se activó el historial de cambios de los datos de empleados. Solo puede ser visualizado por el Administrador.</li>
                        </ul>
                        <h4>30/06/2020</h4>
                        <ul>
                            <li>Se activó el registro de empleados así como la visualización y edición de los mismos.</li>
                            <li>Se agregó la función de colapsar grupos de elementos en el menú principal.</li>
                        </ul>
                        <h4>26/06/2020</h4>
                        <ul>
                            <li>Se agregó modulos de creación, listado y edición de empleados.</li>
                            <li>Se activó la visualización de clientas en proceso de validación para el usuario alaista.</li>
                        </ul>
                        <h4>24/06/2020</h4>
                        <ul>
                            <li>Se agregó sigla al ID de clientas y de empleados.</li>
                        </ul>
                        <h4>23/06/2020</h4>
                        <ul>
                            <li>Corrección de formulario de registro de nuevas clientas.</li>
                            <li>Corrección de restablecimiento del campo de fecha de nacimiento de nuevas clientas.</li>
                        </ul>
                        <h4>21/06/2020</h4>
                        <ul>
                            <li>Se muestra solo el primer nombre y el apellido paterno en las migajas de pan.</li>
                            <li>Corrección de validación de CURP. En donde la CURP contiene la letra X para evitar palabras curiosas.</li>
                        </ul>
                        <h4>11/06/2020</h4>
                        <ul>
                            <li>Correcciones de acceso a contenido.</li>
                        </ul>
                        <h4>10/06/2020</h4>
                        <ul>
                            <li>Se mejoró el esquema de color del menú principal.</li>
                            <li>Se cambió de color el botón de cargar buró en la edición de la clienta.</li>
                        </ul>
                        <h4>09/06/2020</h4>
                        <ul>
                            <li>El hostorial de la clienta solo puede visualizarlo el administrador.</li>
                            <li>Se agregó nombre de la clienta en las migajas de pan.</li>
                            <li>Se agregó indicador de clientas para Validación.</li>
                        </ul>
                        <h4>04/06/2020</h4>
                        <ul>
                            <li>Se agregó búsqueda avanzada en la lista de clientas.</li>
                        </ul>
                        <h4>03/06/2020</h4>
                        <ul>
                            <li>Se agregó la visualización del historial de cambios de los datos de las clientas.</li>
                            <li>Cambio de fuente de texto a Roboto de fonts.google.com</li>
                        </ul>
                        <h4>01/06/2020</h4>
                        <ul>
                            <li>Se agregó campo de "Tipo servicio" a un lado del campo "Número de servicio".</li>
                            <li>Se agregó Beadcrumbs.</li>
                            <li>Se agregó una alerta que indica cuando una clienta es enviada a Validación después de que es registrada.</li>
                        </ul>
                        <h4>29/05/2020</h4>
                        <ul>
                            <li>Se mejoró el indicador de guardado de nuevas clientas.</li>
                        </ul>
                        <h4>28/05/2020</h4>
                        <ul>
                            <li>Se corrigió la paginación en la lista de clientas.</li>
                            <li>Se creó el módulo Validación para aquellas clientas que presenten un número de servicio que aparece más de 3 veces en el sistema.</li>
                            <li>Se agregó campo de número de servicio.</li>
                            <li>Se agregó nuevos estados civiles Unión libre y Viuda.</li>
                            <li>Se agregó un botón en la pantalla de login para mostrar y ocultar la contraseña.</li>
                        </ul>
                        <h4>25/05/2020</h4>
                        <ul>
                            <li>Se agregó tipo de status un nuevo tipo de status.</li>
                            <li>Se guardan historial de cambios.</li>
                            <li>Se agregó un botón para conmutar entre la vista de revisión y edición de los datos personales y económico.</li>
                            <li>El usuario gerente puede realizar la edición de los datos personales de la clientas.</li>
                            <li>Se restringió al usuario analista la edición de los datos personales de la clientas.</li>
                        </ul>
                        <h4>18/05/2020</h4>
                        <ul>
                            <li>Se agrega un ID en la tabla de Socios está definido por la sucursal en el cual está sieno registrado. El ID es numérico y consecutivo.</li>
                        </ul>
                        <h4>14/05/2020</h4>
                        <ul>
                            <li>El dato "Lugar de registro" ahora es una referencia a la tabla Sucursales.</li>
                            <li>Se guarda la referencia al usuario que registró el socio en la misma tabla de Socios.</li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default ChangeRecord;