import React, { Component } from "react";
import _ from "underscore";
import $ from 'jquery';


class OpenClose extends Component {
    state = {
        btnToggle: false
    }
    toggleCollapse = (id) => {
        $(id).collapse('toggle');
        this.setState((state) => ({
            btnToggle: !state.btnToggle
        }))
    }
  render() {
    return (
        <td style={{width:50}}>
            <button className={`btn btn-link ${true?'':'d-none'}`} type="button" onClick={() => this.toggleCollapse(this.props.keyComponent)} reference={this.props.keyComponent}>
                {(this.state.btnToggle === false) && <i className="fas fa-chevron-down"></i>}
                {(this.state.btnToggle === true) && <i className="fas fa-chevron-up"></i>}
            </button>
        </td>
    );
  }
}


export default class ReportDayTableDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
        data: this.props.data
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.state.data !== nextProps.data) {
        this.setState({
            data: nextProps.data
        })
    }
  }

  render() {
    return (<>
      {!_.isEmpty(this.state.data) === true && <div>
        {/** Recuperación del día */}
        <table className="table">
          <thead>
            <tr>
              <td>
                <strong className="text-primary">Recuperación del día</strong>
              </td>
              <td className="text-right">
                <strong>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(this.state.data.totalDayEstimate)}
                </strong>
              </td>
              {this.props.showControls === true && <OpenClose keyComponent={`#collapse_estimacion_del_dia_${this.state.data.objectId}`}/>}
            </tr>
          </thead>
          {this.props.print_resume !== true && <tbody>
            <tr>
              <td colSpan={2} className="p-0">
                <div className={`collapse ${this.props.showControls !== true && 'show'}`} id={`collapse_estimacion_del_dia_${this.state.data.objectId}`}>
                    <table className="table text-black-50">
                    <thead>
                        <tr>
                        <th>
                            <strong>Grupo</strong>
                        </th>
                        <th className="text-right">
                            <strong>Monto pago</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.dayEstimate && this.state.data.dayEstimate.map((item, index) => (
                        <tr key={index}>
                            <td>{item.grupo && item.grupo.nombre}</td>
                            <td className="text-right">
                            {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                            }).format(item.monto_pago)}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
              </td>
            </tr>
          </tbody>}
        </table>

        {/** Depósitos para desembolso */}
        <table className="table">
          <thead>
            <tr>
              <td>
                <strong className="text-primary">
                  Depósitos para desembolso
                </strong>
              </td>
              <td className="text-right">
                <strong>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(this.state.data.totalDisbursementDeposit)}
                </strong>
              </td>
              {this.props.showControls === true && <OpenClose keyComponent={`#collapse_depositos_desembolsos_${this.state.data.objectId}`}/>}
            </tr>
          </thead>
          {this.props.print_resume !== true && <tbody>
            <tr>
              <td colSpan={2} className="p-0">
                <div className={`collapse ${this.props.showControls !== true && 'show'}`} id={`collapse_depositos_desembolsos_${this.state.data.objectId}`}>
                    <table className="table text-black-50">
                    <thead>
                        <tr>
                        <th>
                            <strong>Nombre del destinatario</strong>
                        </th>
                        <th>
                            <strong>Banco</strong>
                        </th>
                        <th>
                            <strong>Cuenta origen</strong>
                        </th>
                        <th>
                            <strong>Referencia bancaria</strong>
                        </th>
                        <th className="text-right">
                            <strong>Monto</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.disbursementDeposit && this.state.data.disbursementDeposit.map((item, index) => (
                        <tr key={index}>
                            <td>{item.nombre_destinatario}</td>
                            <td>{item.banco}</td>
                            <td>{item.cuenta_origen}</td>
                            <td>{item.referencia_bancaria}</td>
                            <td className="text-right">
                            {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                            }).format(item.monto)}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
              </td>
            </tr>
          </tbody>}
        </table>

        {/** Pagos adelantados */}
        <table className="table">
          <thead>
            <tr>
              <td>
                <strong className="text-primary">Pagos adelantados</strong>
              </td>
              <td className="text-right">
                <strong>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(this.state.data.totalPrepaid)}
                </strong>
              </td>
              {this.props.showControls === true && <OpenClose keyComponent={`#collapse_pagos_adelantados_${this.state.data.objectId}`}/>}
            </tr>
          </thead>
          {this.props.print_resume !== true && <tbody>
            <tr>
              <td colSpan={2} className="p-0">
                <div className={`collapse ${this.props.showControls !== true && 'show'}`} id={`collapse_pagos_adelantados_${this.state.data.objectId}`}>
                    <table className="table text-black-50">
                    <thead>
                        <tr>
                        <th>
                            <strong>Grupo</strong>
                        </th>
                        <th>
                            <strong>Pagos adelantados</strong>
                        </th>
                        <th className="text-right">
                            <strong>Monto pago</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.prepaid && this.state.data.prepaid.map((item, index) => (
                        <tr key={index}>
                            <td>{item.grupo && item.grupo.nombre}</td>
                            <td>{item.pagos_adelantados}</td>
                            <td className="text-right">
                            {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                            }).format(item.monto_pago)}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
              </td>
            </tr>
          </tbody>}
        </table>

        {/** Mora */}
        <table className="table">
          <thead>
            <tr>
              <td>
                <strong className="text-primary">Recuperación de mora</strong>
              </td>
              <td className="text-right">
                <strong>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(this.state.data.totalDayRecovery)}
                </strong>
              </td>
              {this.props.showControls === true && <OpenClose keyComponent={`#collapse_recuperacion_dia_${this.state.data.objectId}`}/>}
            </tr>
          </thead>
          {this.props.print_resume !== true && <tbody>
            <tr>
              <td colSpan={2} className="p-0">
                <div className={`collapse ${this.props.showControls !== true && 'show'}`} id={`collapse_recuperacion_dia_${this.state.data.objectId}`}>
                    <table className="table text-black-50">
                    <thead>
                        <tr>
                        <th>
                            <strong>Grupo</strong>
                        </th>
                        <th className="text-right">
                            <strong>Monto pago</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.dayRecovery && this.state.data.dayRecovery.map((item, index) => (
                        <tr key={index}>
                            <td>{item.grupo && item.grupo.nombre}</td>
                            <td className="text-right">
                            {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                            }).format(item.monto_pago)}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
              </td>
            </tr>
          </tbody>}
        </table>

        {/** Desembolsos */}
        <table className="table">
          <thead>
            <tr>
              <td>
                <strong className="text-primary">Desembolsos</strong>
              </td>
              <td className="text-right">
                <strong>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(this.state.data.totalDisbursements)}
                </strong>
              </td>
              {this.props.showControls === true && <td style={{width:50}}/>}
            </tr>
          </thead>
        </table>

        {/** Pendientes del día */}
        <table className="table">
          <thead>
            <tr>
              <td>
                <strong className="text-primary">Pendientes del día</strong>
              </td>
              <td className="text-right">
                <strong>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(this.state.data.totalPendingPayments)}
                </strong>
              </td>
              {this.props.showControls === true && <OpenClose keyComponent={`#collapse_pagos_pendientes_${this.state.data.objectId}`}/>}
            </tr>
          </thead>
          {this.props.print_resume !== true && <tbody>
            <tr>
              <td colSpan={2} className="p-0">
                <div className={`collapse ${this.props.showControls !== true && 'show'}`} id={`collapse_pagos_pendientes_${this.state.data.objectId}`}>
                    <table className="table text-black-50">
                    <thead>
                        <tr>
                        <th>
                            <strong>Grupo</strong>
                        </th>
                        <th className="text-right">
                            <strong>Monto pago</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.pendingPayments && this.state.data.pendingPayments.map((item, index) => (
                        <tr key={index}>
                            <td>{item.grupo && item.grupo.nombre}</td>
                            <td className="text-right">
                            {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                            }).format(item.monto_pago)}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
              </td>
            </tr>
          </tbody>}
        </table>

        {/** Depósitos a cuenta */}
        <table className="table">
          <thead>
            <tr>
              <td>
                <strong className="text-primary">Depósitos a cuenta</strong>
              </td>
              <td className="text-right">
                <strong>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(this.state.data.totalDepositsToAccount)}
                </strong>
              </td>
              {this.props.showControls === true && <OpenClose keyComponent={`#collapse_depositos_cuenta_${this.state.data.objectId}`}/>}
            </tr>
          </thead>
          {this.props.print_resume !== true && <tbody>
            <tr>
              <td colSpan={2} className="p-0">
                <div className={`collapse ${this.props.showControls !== true && 'show'}`} id={`collapse_depositos_cuenta_${this.state.data.objectId}`}>
                    <table className="table text-black-50">
                    <thead>
                        <tr>
                        <th>
                            <strong>Nombre del destinatario</strong>
                        </th>
                        <th>
                            <strong>Banco/Cuenta</strong>
                        </th>
                        <th>
                            <strong>Concepto</strong>
                        </th>
                        <th className="text-right">
                            <strong>Monto</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.depositsToAccount && this.state.data.depositsToAccount.map((item, index) => (
                        <tr key={index}>
                            <td>{item.nombre_depositante}</td>
                            <td>{item.banco_cuenta}</td>
                            <td>{item.concepto}</td>
                            <td className="text-right">
                            {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                            }).format(item.monto)}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
              </td>
            </tr>
          </tbody>}
        </table>

        {/** Gastos extras */}
        <table className="table">
          <thead>
            <tr>
              <td>
                <strong className="text-primary">Gastos extras</strong>
              </td>
              <td className="text-right">
                <strong>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(this.state.data.totalExtraCosts)}
                </strong>
              </td>
              {this.props.showControls === true && <OpenClose keyComponent={`#collapse_gastos_extras_${this.state.data.objectId}`}/>}
            </tr>
          </thead>
          {this.props.print_resume !== true && <tbody>
            <tr>
              <td colSpan={2} className="p-0">
                <div className={`collapse ${this.props.showControls !== true && 'show'}`} id={`collapse_gastos_extras_${this.state.data.objectId}`}>
                    <table className="table text-black-50">
                    <thead>
                        <tr>
                        <th>
                            <strong>Concepto</strong>
                        </th>
                        <th>
                            <strong>Factura</strong>
                        </th>
                        <th>
                            <strong>Emisor</strong>
                        </th>
                        <th className="text-right">
                            <strong>Monto</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.extraCosts && this.state.data.extraCosts.map((item, index) => (
                        <tr key={index}>
                            <td>{item.concepto}</td>
                            <td>{item.factura}</td>
                            <td>{item.emisor}</td>
                            <td className="text-right">
                            {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                            }).format(item.monto)}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
              </td>
            </tr>
          </tbody>}
        </table>

        {/** Total */}
        <table className="table">
          <thead>
            <tr>
              <td>
                <strong className="text-primary">Total</strong>
              </td>
              <td className="text-right">
                <strong>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(this.state.data.total)}
                </strong>
              </td>
              {this.props.showControls === true && <td style={{width:50}}/>}
            </tr>
          </thead>
        </table>
      </div>}
      </>);
  }
}
