import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L, { Icon } from 'leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet/src/images/marker.svg";


const MyMaps = (props) => {

    const [coords, setCoords] = useState([]);
    const [markerPosition, setMarkerPosition] = useState([51.505, -0.09]);

    const customIcon = new Icon({
        iconUrl: require('../../../assets/map/pin.png'),
        iconSize: [100,100]
    })

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                    setCoords([latitude, longitude]);
                    if (props.point && (props.point[0] !== undefined && props.point[1] !== undefined)) {
                        setMarkerPosition(props.point);
                    } else {
                        setMarkerPosition([latitude, longitude]);
                    }
                },
                (error) => {
                    console.error("Error al obtener la ubicación:", error.message);
                }
            );
        } else {
            console.error("Geolocalización no está disponible en este navegador");
        }

    }, []);

    const handleClick = (event) => {
        const { latlng } = event;
        const { lat, lng } = latlng;
        setMarkerPosition([lat, lng]);
        if (props.handleChange) {
            props.handleChange(lat, lng);
        }
    };

    const handleDragEnd = (event) => {
        const { _latlng } = event.target;
        const { lat, lng } = _latlng;
        setMarkerPosition([lat, lng]);
        if (props.handleChange) {
            props.handleChange(lat, lng);
        }
    };

    const MapClickHandler = () => {
        useMapEvents({
          click: handleClick,
        });
        return null;
    };

    return (
        <>
            <div className="d-flex align-items-center justify-content-center" style={styles.container}>
                {coords.length === 0 && <div className="spinner-border text-primary">
                    <span className="sr-only">Loading...</span>
                </div>}
                {coords.length > 0 && <MapContainer 
                center={coords}
                zoom={14}
                scrollWheelZoom={false}
                style={{ height: '400px', width: '100%' }}
                >
                    <TileLayer
                        attribution="© OpenStreetMap contributors"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MapClickHandler />
                    <Marker position={markerPosition}
                    icon={customIcon}
                    draggable={true} eventHandlers={{ dragend: handleDragEnd }}/>
                </MapContainer>}
            </div>
        </>
    )
}

export default MyMaps;

const styles = {
    container: {
        width: '100%',
        minHeight: 400,
        position: 'relative',
        zIndex: 0
    },
}