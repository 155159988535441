import React from 'react';
import _ from 'underscore';
import './../../styles/css/group.css';
import config from '../../settings/config';
import AccountingChangesFilterReport from './AccountingChangesFilterReport';
import Rest from '../../Server/Rest';
import Spinner from '../others/Spinner';
import TablePagination from '../others/TablePagination';
import Parse from 'parse';
import GroupListPaymentReport from '../groups/GroupListPaymentReport';

export default class AccountingChanges extends GroupListPaymentReport {

    title = 'Reporte de cobros';

    state = {
        loading: false,
        filter_type: 'pagos',
        fecha_real_pago: false,
        // datos de filtro
        nombre: '',
        promotor: '',
        dateStart: '',
        dateEnd: '',
        monto_autorizado: 0.0,
        plazo: '',
        Sucursal: !_.isEmpty(Parse.User.current().toJSON().Sucursal) ? Parse.User.current().toJSON().Sucursal.objectId : '',
        tipoReporte: '',

        total: 0,
        collection: []
    }

    getResource = (index = 0, _where = {}) => {

        this.totalAuthorizedAmount = 0.0;
        this.totalWeeklyPayment = 0.0;

        this.setState(state => ({
            index: index
        }));
        
        this.handleSpinner();

        Rest.peticion(`${config.api_url}/functions/getReportCharge`, {
            method: 'POST',
            body: JSON.stringify({
                className: this.className,
                where: this.where,
                order: this.order,
                include: this.include+',PagosRealizados',
                type: this.state.filter_type,
                index: parseInt(index),
                limit: this.limit,
                fecha_real_pago: this.state.fecha_real_pago,
            })
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            this.rows = res.result.count;
            let collection = this.getCollection(res.result);
            if (!_.isEmpty(res.result.totals)) {
                collection.push({
                    iva: res.result.totals.iva,
                    capital: res.result.totals.capital,
                    interes_ordinario: res.result.totals.interes_ordinario,
                    total: res.result.totals.total,
                });
            }
            this.setState({
                collection: collection,
                total: this.rows
            });
        })
        .then(() => {
            this.handleSpinner();
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    setWhere = (data = {}) => {
        
        let where = {};
        let where_fechas = {};

        where_fechas = {
            Pagos: (data.fecha_real_pago === false && (data.dateStart || data.dateEnd))? {
                $inQuery: {
                    where: {
                        fecha_pago: {}
                    },
                    className: 'SolicitudesPagos'
                }
            } : {
                $exists: true
            },
            PagosRealizados: (data.fecha_real_pago === true && (data.dateStart || data.dateEnd))? {
                $inQuery: {
                    where: {
                        pagado_al: {}
                    },
                    className: 'SolicitudesPagosRealizados'
                }
            } : undefined
        };

        if (data.fecha_real_pago === true) {
            if (data.dateStart) {
                where_fechas.PagosRealizados.$inQuery.where.pagado_al.$gte = data.dateStart;
            }    
            if (data.dateEnd) {
                where_fechas.PagosRealizados.$inQuery.where.pagado_al.$lte = data.dateEnd;
            }
        } else {    
            if (data.dateStart) {
                where_fechas.Pagos.$inQuery.where.fecha_pago.$gte = data.dateStart;
            }    
            if (data.dateEnd) {
                where_fechas.Pagos.$inQuery.where.fecha_pago.$lte = data.dateEnd;
            }
        }

        where = _.extend({
            promotor: !_.isEmpty(data.promotor)? data.promotor : undefined,
            // la propiedad prestamo_personalizado_periodicidad se configura dependiendo si se requiere obtener resultados en general, pagos catorcenales o semanales
            prestamo_personalizado_periodicidad: data.prestamo_personalizado_periodicidad === 14 ? 14 : data.prestamo_personalizado_periodicidad === 7 ? {$ne: 14} : undefined,
            Grupo: !_.isEmpty(data.id_busqueda)? {
                $inQuery: {
                    where: {
                        id_busqueda: data.id_busqueda
                    },
                    className: 'Grupos'
                }
            }: undefined,
            Sucursal: data.Sucursal? data.Sucursal : {
                $inQuery: {
                    where: {
                        contabilizar: true
                    },
                    className: 'Sucursales'
                }
            },
            monto_autorizado: data.monto_autorizado? data.monto_autorizado : undefined
        }, where_fechas);

        this.order = 
        '-pagado_al,-updatedAt';
        this.className = 'Solicitudes';
        this.include = 
        'Grupo' +
        ',Sucursal' +
        ',promotor' +
        ',montos' +
        ',Pagos';

        // if (!user.rol.es_administrador) {
        //     const config = user.config();
        //     const filter_from = config && config.filter_from ? {
        //         $gte: {
        //             __type: 'Date',
        //             iso: config.filter_from
                    
        //         }
        //     } : undefined;
    
        //     where.createdAt = filter_from;
        // }

        this.where = where;

        return where;
    }

    getXLS = () => {
        if (this.state.collection.length) {
            this.handleSpinner();
            
            Rest.peticion(`${config.api_url}/functions/getAccountingChangesDocument`, {
                method: 'POST',
                body: JSON.stringify({
                    where: this.where,
                    order: this.order,
                    className: this.className,
                    include: this.include,
                    fecha_real_pago: this.state.fecha_real_pago,
                    format: 'xlsx'
                })
            })
            .then(res => {
                return res.json();
            })
            .then(res => {

                if (res.code || res.error) {
                    alert(res.error);
                } else {
                    this.downloadXLS(res.result);
                }
                
            })
            .catch(error => {
                alert('Ha ocurrido un error al intentar descargar el archivo Excel');
            })
            .then(() => {
                this.handleSpinner();
            });
        } else {
            alert('Realice un filtro para descargar el archivo Excel');
        }
    }

    getCollection = (res) => {
        let collection = [];
        const days = [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
        ]
        
        collection = _.map(res.results, (item, index) => {
            const pagado_al = new Date(item.pagado_al);
            return {
                objectId: item.objectId,
                groupId: item.Grupo.objectId,
                index: index + 1 + parseInt(this.state.index),
                // Grupo
                nombre_grupo: item.Grupo.nombre,
                // Núm. Clientes
                num_clientes: item.montos.length,
                // Ciclo de la solicitud
                ciclo: item.ciclo,
                // Plazo
                plazo: this.setPeriodisidad(item),
                // Núm. Pagos
                num_pagos: this.setNumberPayments(item),
                // Pago aplicado
                pago_aplicado: item.pago_aplicado,
                // Pagado al
                pagado_al: this.setLegibleDate(item.pagado_al),
                // Pago semanal
                monto_pago: item.monto_pago,
                // Monto pagado
                monto_pagado: item.monto_pagado,
                // Pagos adelantados
                pagos_adelantados: item.pagos_adelantados || 0,
                // Día pago
                pagado_al_dia: days[pagado_al.getDay()].toUpperCase(),
                // Promotor
                promotor: this.setPromotor(item),
                // Sucursal
                sucursal: this.setLugarRegistro(item.Sucursal),
                // clase css
                html_class: item.html_class,
                
                // se usa en el módulo de contabilidad en el reporte de cobros
                id_en_sucursal: item.Grupo.id_en_sucursal,
                sucursal_sigla: item.Sucursal.sigla,
                interes_ordinario: item.interes_ordinario,
                capital: item.capital,
                iva: item.iva,
                total: item.total
            }
        });

        return collection;
    }

    setTableHead = () => {
        return <tr>
            {/* <th nowrap="true">#</th> */}
            <th nowrap="true">Grupo</th>
            {/* <th nowrap="true">Sucursal</th> */}
            <th nowrap="true">ID</th>
            <th nowrap="true">Pagado al</th>
            <th nowrap="true">Capital</th>
            <th nowrap="true">Interés ordinario</th>
            <th nowrap="true">IVA</th>
            <th nowrap="true">Total</th>
        </tr>;
    }

    setTableBody = () => {
        const {state} = this;
        let body;
        body = state.collection.map((item, index) => <tr key={index} id={`solicitud-${item.objectId}`} className={item.html_class}>
            {/* <td nowrap="true">{item.index}</td> */}
            <td nowrap="true">{item.nombre_grupo}</td>
            {/* <td nowrap="true">{item.sucursal}</td> */}
            <td nowrap="true">
                {
                    (state.collection.length !== (index +1)) && 
                    <code className="text-body">
                        {this.setID(item.id_en_sucursal, {sigla:item.sucursal_sigla})}
                    </code>
                }
            </td>
            <td nowrap="true">{item.pagado_al}</td>
            <td nowrap="true">{
                (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.capital )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.capital )
            }</td>
            <td nowrap="true">{
                (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.interes_ordinario )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.interes_ordinario )
            }</td>
            <td nowrap="true">{
                (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.iva )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.iva )
            }</td>
            <td nowrap="true">{
                (state.collection.length === (index +1))? <b>{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.total )}</b> : new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( item.total )
            }</td>
        </tr>);
        
        return body;
    }

    handleFilter = (event) => {

        if (event) event.preventDefault();

        const state = this.state;
    
        let dateStart = undefined;
        if (_.isDate(state.dateStart)) {
            dateStart = new Date(state.dateStart);
            dateStart.setHours(0);
            dateStart.setMinutes(0);
            dateStart.setSeconds(0);
            dateStart.setMilliseconds(0);
        }
    
        let dateEnd = undefined;
        if (_.isDate(state.dateEnd)) {
            dateEnd = new Date(state.dateEnd);
            dateEnd.setHours(23);
            dateEnd.setMinutes(59);
            dateEnd.setSeconds(59);
            dateEnd.setMilliseconds(999);
        }

        let where = {
            Sucursal: _.isEmpty(state.Sucursal)? undefined : {
                __type: 'Pointer',
                className: 'Sucursales',
                objectId: state.Sucursal
            },
            dateStart: _.isUndefined(dateStart) ? undefined : {
                __type: 'Date',
                iso: dateStart.toISOString()
            },
            dateEnd: _.isUndefined(dateEnd) ? undefined : {
                __type: 'Date',
                iso: dateEnd.toISOString()
            },
            // id_busqueda: _.isEmpty(state.nombre)? undefined : {
            //     $regex: textUtils.setIdBusqueda(state.nombre)
            // },
            fecha_real_pago: state.fecha_real_pago
        }

        where = this.setWhere(where);

        this.rows = 0;
        
        this.getResource(0, where);
    }

    render() {
        const {state} = this;
        return (
            <>
                <Spinner loading={state.loading} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-calculator mr-2"></i>
                    {this.title}
                </h3>
                <div className="card">
                    <div className="card-body">
                        
                        <AccountingChangesFilterReport 
                        handleChange={this.handleChange} 
                        handleChangeDate={this.handleChangeDate} 
                        handleFilter={this.handleFilter} state={state}
                        inputGroupEnabled={true}
                        inputPrometerEnabled={true}
                        inputAmountEnabled={true}
                        inputTermEnabled={true} />
                        <div className="m-4"></div>
                        <div className="text-right">
                            <div className="btn-group" role="group" aria-label="Descargas">
                                <button className="btn btn-secondary" onClick={() => this.getXLS()}>Descargar Excel</button>
                                <button disabled={true} className="btn btn-secondary" onClick={() => this.handlePrintDocument()}>Descargar PDF</button>
                            </div>
                        </div>

                        {/* <TableCountInfo total={state.total} /> */}

                        <div id="table-pdf" className="table-responsive mt-3" ref={this.ref}>
                            <table className="table">
                                <thead>
                                    {this.setTableHead()}
                                </thead>
                                <tbody>
                                    {(state.collection.length > 0 && this.setTableBody()) || <tr>
                                        <td colSpan="16" className="text-center">
                                            <div className="alert alert-warning m-0" role="alert">
                                                No se hallaron registros
                                            </div>
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </div>

                        <div className="row align-items-center justify-content-end">
                            {/* <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div> */}
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-5"></div>
            </>
        );
    }
}
