import React, {Component} from "react";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import Spinner from "../others/Spinner";
import _ from "underscore";
import Parse from 'parse';
import $ from 'jquery';
import moment from "moment";
moment.locale('es');


class CreatePoliceForm extends Component {

    constructor(props) {
        super(props);

        this.state = this.state_init();
        
    }

    data = {};
    fecha_registro = '';
    date= '';
    exercise = '';
    period = ''
    typesAccounts = [];
    // collectionAll = [];

    state_init = () => {

        const d = new Date();
        this.fecha_registro =  d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();
        this.date =  d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();
        // this.period = d.getMonth()+1;
        // this.exercise = d.getFullYear();
        
        return {
            loading: false,
            saving: false,
            numero: 0,
            concepto: '',
            cargo: '',
            abono: '',
            periodo: '',
            fecha: '',
            fecha_emision: '',
            ejercicio: '',
            status: '',
            // envios: 0,
            tipo_transacion: '',
            collectionAll: [],
            type_transactionId: {},
            enum: 0
        }
    }

    getRecentPeriodNumber = () => {

        // console.log("Consultar para el periodo => ", this.period);
        // console.log("Consultar para el ejercicio => ", this.exercise);

        const params = new URLSearchParams({
            count: 1,
            order: '-numero',
            where: JSON.stringify({
                periodo: this.period,
                ejercicio: this.exercise
            })
        });

        Rest.peticion(`${config.api_url}/classes/GLPoliza?${params.toString()}`,{
            method:"GET"
        }).then((response) => {
            return response.json();
        }).then((res) => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado al recuperar las pólizas');
            } else {
                // console.log("Respuesta polizas => ", res);

                if(res.results.length > 0) {
                    const num = parseInt(res.results[0].numero)
                    // this.numero = num + 1;
                    this.setState({numero: num + 1});
                }else{
                    // this.numero = 1;
                    this.setState({numero: 1});
                }
            }
        }).catch(err => {
            console.log("Error al recuperar polizas => ", err);
            alert('Ha ocurrido un error inesperado al recuperar las pólizas');
        })

    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        //verificamos si le pasamos datos para editar, de lo contarrio es registro
        if (!_.isEmpty(nextProps)) {

            // console.log("Fecha => ", this.resetDate(nextProps.fecha));
            this.period = nextProps.fecha.getMonth()+1;
            this.exercise = nextProps.fecha.getFullYear();
            this.getTypesTransactions(nextProps.filter_type);
            this.getRecentPeriodNumber();
            this.setState({
                periodo: this.period,
                ejercicio: this.exercise,
                fecha: this.resetDate(nextProps.fecha),
                // fecha_emision: this.fecha_registro,
                status: 'Activo',
                collectionAll: nextProps.collectionAll
            });

        }
    }

    UNSAFE_componentWillUpdate = (nextProps, nextState) => {

        nextProps.setData(nextState);
    }

    getTypesTransactions = (filtertype) => {

        let type = 1;

        switch(filtertype){
            case 'desembolso':
                type = 1
            break;
            case 'pagos': 
                type = 2
            break;
            default:
                type = 1;
            break;
        }


        let params = new URLSearchParams({
            limit: 50,
            count: 1,
            where: JSON.stringify({
                enum: type
            })
        });

        Rest.peticion(`${config.api_url}/classes/TypesTransactions?${params.toString()}`, {
            method: 'GET',
        }).then((response) => {
            return response.json();
        }).then((res) => {
            
            if (res.code) {
                alert('Ha ocurrido un error inesperado al recuperar los tipos de cuenta para los filtros.\nIntente más tarde.');
            } else {

                // console.log("Respuesta Tipos de transacciones => ", res.results);

                this.typesAccounts = res.results;
                this.setState({
                    concepto: res.results[0].tipo,
                    tipo_transacion: res.results[0].descripcion,
                    enum: res.results[0].enum,
                    type_transactionId: {
                        __type: 'Pointer',
                        className: 'TypesTransactions',
                        objectId: res.results[0].objectId
                    }
                })
            }

            

        }).catch((err) => {
            console.log("Error: ", err)
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });
    }

    getDetailsPolicy = async(id_policy) => {
        let params = new URLSearchParams({
            limit: 100000,
            where: JSON.stringify({
                poliza_id: id_policy
            })
        })

        return Rest.peticion(`${config.api_url}/classes/GLPolizaDetalle?${params.toString()}`, {
            method: 'GET',
        }).then(response =>{
            return response.json();
        }).then( async(res) => {

            return res;

        }).catch(err =>{
            return err;
        })
    }

    validatePolicy = async(validateNumero=false) => {

        let params;

        if(validateNumero) {

            params = new URLSearchParams({
                where: JSON.stringify({
                    periodo: this.period,
                    ejercicio: this.exercise,
                    numero: this.state.numero
                })
            });

        }else {

            params = new URLSearchParams({
                where: JSON.stringify({
                    fecha: {
                        __type: 'Date',
                        iso: this.state.fecha
                    },
                    type_transaction: this.state.type_transactionId
                }),
            });
        }


        return Rest.peticion(`${config.api_url}/classes/GLPoliza?${params}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
    }

    handleSubmit = async(e) => {

        if(e) e.preventDefault();

        //Validamos que o exista una póliza con el mismo número en el ejercicio y periodo
        const validateNumber = await this.validatePolicy(true);
        if(validateNumber.results.length > 0){
            $(`#${this.props.id}`).modal('hide');
            return alert(`¡La póliza número ${this.state.numero} del periodo ${this.period} ya ha sido generada!`)
        }

        //Validamos si existe una póliza en esa fecha y con el mismo tipo
        const validate = await this.validatePolicy();

        if(validate.results.length > 0 ){

            // console.log("Validación => ", validate.results);
            // console.log("Validación length => ", validate.results[0].objectId);
            
            const confirm = window.confirm(`Hay una póliza existente ya contabilizada de tipo ${this.state.concepto} para la fecha ${moment.utc(this.state.fecha).format("DD/MM/YYYY")} \r¿Desea crear una nueva póliza y reemplazar la anterior?, esta acción es irreversible`);
            // const confirm = window.confirm(`Hay una póliza existente ya contabilizada de tipo ${this.state.concepto} para la fecha ${moment.utc(this.state.fecha).format("DD/MM/YYYY")} con el número ${validate.results[0].numero} \r¿Desea actualizar lo detalles de la póliza?`);
            if (confirm) {
                const confirm = window.confirm(`¿Todos los datos son correctos?`);
                if (confirm) {

                    this.setState({
                        saving: true
                    })
                    const id_policy = validate.results[0].objectId;
                    const details = await this.getDetailsPolicy(id_policy);
                    // console.log("Detalles => ", details.results)

                    if (details.code) {
                        return alert('Ha ocurrido un error inesperado al recuperar los detalles de la póliza anterior');
                    }else {
                        //Eliminamos los detalles
                        if(details.results.length > 0){
                            for(let i=0; i<details.results.length; i++){

                                const detalle = details.results[i];
                                await this.fuctionDelete('GLPolizaDetalle', detalle.objectId);
                            }
                        }
                        //Eliminamos la póliza
                        await this.fuctionDelete('GLPoliza', validate.results[0].objectId);

                        //creamos la nueva póliza
                        await this.savePolicie();
                    }

                }
            }
        } else{

            const confirm = window.confirm(`¿Todos los datos son correctos?`);
            if (confirm) {

                this.setState({
                    saving: true
                });
                //creamos la nueva póliza
                await this.savePolicie();
            }
        }

        // this.setState({
        //     saving: true
        // })

        // const data = _.clone(this.state);
        // let hoy = moment().format();
        // hoy = hoy.split('T')[0];
        // hoy = `${hoy}T00:00:00`

        // // console.log("Hoy => ",  hoy);

        // // Establecer usuario quien registró la cuenta
        // const currentUser = Parse.User.current();
        // data.creado_por = {
        //     __type: 'Pointer',
        //     className: '_User',
        //     objectId: currentUser.id
        // }

        // data.fecha = {
        //     __type: 'Date',
        //     iso: this.state.fecha
        // };
        
        // data.fecha_emision = {
        //     __type: 'Date',
        //     iso: hoy
        // };

        // // return console.log("Data  => ", data);

        // Rest.peticion(`${config.api_url}/functions/createPoliza`, {
        //     method: 'POST',
        //     body: JSON.stringify({data})
        // })
        // .then(res => {
        //     return res.json();
        // })
        // .then(res => {

        //     console.log("Resultado crear poliza => ", res);

        //     if (res.code) {
        //         alert('Ha ocurrido un error inesperado. Intente más tarde.');
        //     } else {
        //         alert('Los póliza ha sido creada exitosamente.');

        //         $(`#${this.props.id}`).on('hidden.bs.modal', (e) => {
        //             // this.props.handleFilter();
        //             $(`#${this.props.id}`).off('hidden.bs.modal');
        //         });
        //     }                
            
        // })
        // .then(() => {
        //     this.setState({
        //         saving: false
        //     });
        // })
        // .catch(error => {
        //     this.setState({
        //         saving: false
        //     });
        //     alert('Ha ocurrido un error inesperado. Intente más tarde.');
        // });

    }

    savePolicie = async() => {

        const data = _.clone(this.state);
        let hoy = moment().format();
        hoy = hoy.split('T')[0];
        hoy = `${hoy}T00:00:00`

        // console.log("Hoy => ",  hoy);

        // Establecer usuario quien registró la cuenta
        const currentUser = Parse.User.current();
        data.creado_por = {
            __type: 'Pointer',
            className: '_User',
            objectId: currentUser.id
        }

        data.fecha = {
            __type: 'Date',
            iso: this.state.fecha
        };
        
        data.fecha_emision = {
            __type: 'Date',
            iso: hoy
        };

        // console.log("Data  => ", data);

        for (let j = 0; j < data.collectionAll.length; j++) {

            const newData = {
                Grupo : {objectId : data.collectionAll[j].Grupo.objectId}, 
                Sucursal : {objectId : data.collectionAll[j].Sucursal.objectId},
                monto_autorizado: data.collectionAll[j].monto_autorizado,
                total : data.collectionAll[j].total,
                capital: data.collectionAll[j].capital,
                interes_ordinario: data.collectionAll[j].interes_ordinario,
                iva: data.collectionAll[j].iva
            }

            data.collectionAll[j] = newData;
        }

        // console.log("Data 2  => ", data);

        return Rest.peticion(`${config.api_url}/functions/createPoliza`, {
            method: 'POST',
            body: JSON.stringify({data})
        })
        .then(res => {
            return res.json();
        })
        .then(res => {

            // console.log("Resultado crear poliza => ", res);

            if (res.code) {
                return alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {

                this.setState({
                    saving: false
                });

                $(`#${this.props.id}`).modal('hide');

                return alert('Los póliza ha sido creada exitosamente.');

            }
            
        })
        .catch(error => {
            this.setState({
                saving: false
            });
            return alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });

    }

    fuctionDelete = async(className, id) => {
        
        let requests = []

        requests.push({
            method: 'DELETE',
            path: `${config.api_path2}/classes/${className}/${id}`
        });


        const data = {
            requests: requests
        }

        return Rest.peticion(`${config.api_url}/batch`, {
            method: 'POST',
            body: JSON.stringify(data)
        }).then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
    }

    resetDate = (fecha) =>{

        let date = moment(fecha).format();
        date = date.split('T')[0];
        date = `${date}T00:00:00`;

        return date;
    }

    render () {
        const {id} = this.props;

        return (
            <>
                <div className="modal fade" tabIndex={-1} id={id}>
                    <Spinner loading={this.state.loading} />
                    <div className="modal-dialog modal-xl modal-dialog-scrollable">
                        <form onSubmit={this.handleSubmit} className="modal-content" autoComplete="off">
                            <div className="modal-header">
                                <h5 className="modal-title">Crear póliza</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled={this.state.saving}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                <div className="d-flex">
                                    <div className="col-md-12">
                                        <medium className="text-muted">Fecha de emisión</medium>
                                        <span className="form-control text-muted">{this.fecha_registro}</span>
                                    </div>
                                </div>
                                <p/>

                                <div className="d-flex">
                                    <div className="col-md-6">
                                        <label>Fecha contable</label>
                                        <span className="form-control text-muted">{moment.utc(this.state.fecha).format("DD/MM/YYYY")}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Ejercicio</label>
                                        <span type="text" className="form-control text-uppercase text-muted" id="ejercicio" name="ejercicio">{this.exercise}</span>
                                    </div>
                                </div>
                                <p/>

                                <div className="d-flex">
                                    <div className="col-md-6">
                                        <label>Periodo</label>
                                        <span type="text" className="form-control text-uppercase text-muted" id="periodo" name="periodo">{this.period}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Número de póliza</label>
                                        <span type="text" className="form-control text-uppercase text-muted" id="numero" name="numero">{this.state.numero}</span>
                                    </div>
                                </div>
                                <p/>

                                <div className="d-flex">
                                    {/* <div className="col-md-6">
                                        <label>Envíos</label>
                                        <span type="text" className="form-control text-uppercase text-muted" id="" name="">{this.state.envios}</span>
                                    </div> */}
                                    <div className="col-md-6">
                                        <label>Concepto</label>
                                        <span type="text" className="form-control text-uppercase text-muted" id="concepto" name="concepto">{this.state.concepto}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Tipo de transacción</label>
                                        <span type="text" className="form-control text-uppercase text-muted" id="tipoTransaccion" name="tipoTransaccion">{this.state.tipo_transacion}</span>
                                    </div>
                                </div>
                                <p/>

                                <div className="d-flex">
                                    <div className="col-md-6">
                                        <label>Status</label>
                                        <span className="form-control text-uppercase text-muted">Activa</span>
                                    </div>
                                </div>
                                <p/>

                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" disabled={this.state.saving}>Cancelar</button>
                                <button type="submit" className="btn btn-primary" disabled={this.state.saving}>
                                    
                                    {(this.state.saving === true && <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span>Guardando...</span>
                                    </>) || 'Guardar'}
                                    
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default CreatePoliceForm;