import React, { Component } from 'react';
import PartnerList from './../partners/PartnerList';
import { Link } from "react-router-dom";
import Spinner from './../others/Spinner';
import TablePagination from './../others/TablePagination';
import TableCountInfo from './../others/TableCountInfo';
import SearchFormEmployees from '../forms/SearchFormEmployees';
import TextPhone from './../others/TextPhone';
import config from './../../settings/config';
import Rest from '../../Server/Rest';
import Parse from 'parse';
import _ from 'underscore';

class EmployeeList extends PartnerList {
    
    title = 'Lista de empleados';

    getResource = (index = 0, _where = {}) => {

        this.where = _where;

        this.setState(state => ({
            index: index
        }));
        
        this.handleSpinner();

        let params;
        let where = {};

        if (Parse.User.current().toJSON().Sucursal && _.isEmpty(_where)) {
            _where.Empleado = {
                $inQuery: {
                    where: {
                        Sucursal: Parse.User.current().toJSON().Sucursal
                    },
                    className: 'Empleados'
                }
            }
        }
        
        where = JSON.stringify(_where);

        params = new URLSearchParams({
            keys: 'Empleado.cargo,Empleado.id_en_sucursal,Empleado.nombre,Empleado.apellido_paterno,Empleado.apellido_materno,Empleado.telefono,Empleado.Sucursal,Empleado.status',
            limit: this.limit,
            skip: index,
            where: where,
            count: 1,
            order: '-fecha_registro,-createdAt',
            include: 'Empleado,Empleado.Sucursal'
        });
        
        Rest.peticion(`${config.api_url}/classes/EmpleadosDiccionario?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {

            this.handleSpinner();
            return res.json();

        })
        .then(res => {
            
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                
                this.rows = res.count;
                this.setState({
                    collection: _.map(res.results, (item, index) => { item.index = index +1 + parseInt(this.state.index); return item; }),
                    total: res.count
                });
                
            }
            
        })
        .catch(error => {
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
            
        });
    }

    establecerStatus = (status) => {
        return status;
    }

    setUrl = (item) => {
        return <Link to={`/empleados/${item.objectId}/${item.Empleado.objectId}/datos-generales`} title="Ver" className="btn btn-link btn-lg">
            <i className="fas fa-chevron-right"></i>
        </Link>
    }
    
    setCargo = (cargo) => {
        let _cargo = '';
        switch (cargo) {

            case 'ANALISTA':
                _cargo = 'ANALISTA'
                break;
            case 'AUDITOR':
                _cargo = 'AUDITOR'
                break;
            case 'GERENCIA_REGIONAL':
                _cargo = 'GERENCIA REGIONAL'
                break;
            case 'GERENCIA':
                _cargo = 'GERENCIA'
                break;
            case 'JURIDICO':
                _cargo = 'JURIDICO'
                break;
            case 'PROMOTOR':
                _cargo = 'PROMOTOR'
                break;
            case 'SUPERVISOR':
                _cargo = 'SUPERVISOR'
                break;

            default:
                _cargo = cargo;
                break;
        }

        return _cargo;
    }

    handleFilter = (where) => {
        this.rows = 0;
        this.getResource(0, where);
    }

    render() {
        return (    
            <>
                <Spinner loading={this.state.loading} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-users mr-2"></i>
                    {this.title}
                </h3>
                
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-8">
                                <SearchFormEmployees handleFilter={this.handleFilter} />
                            </div>
                        </div>
                        <br />
                        
                        <TableCountInfo total={this.state.total} />

                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nombre completo</th>
                                    <th scope="col">ID</th>
                                    <th scope="col">Cargo</th>
                                    <th scope="col" width="150">Teléfono</th>
                                    <th scope="col">Sucursal</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Fecha de registro</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.collection.length && this.state.collection.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.index}</td>
                                                <td className="td-responsive-text">
                                                    {item.Empleado.nombre +' '+ item.Empleado.apellido_paterno +' '+ item.Empleado.apellido_materno }
                                                </td>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <code className="text-body">{this.setID(item.Empleado.id_en_sucursal, item.Empleado.Sucursal)}</code>
                                                </td>
                                                <td>{this.setCargo(item.Empleado.cargo)}</td>
                                                <td nowrap="true"><TextPhone phone={item.Empleado.telefono} /></td>
                                                <td>{this.setLugarRegistro(item.Empleado.Sucursal)}</td>
                                                <td>{this.establecerStatus(item.Empleado.status)}</td>
                                                <td>{this.setDate((item.Empleado.fecha_registro && item.Empleado.fecha_registro.iso)||item.Empleado.createdAt)}</td>
                                                <td className="p-1 text-right">
                                                    {this.setUrl(item)}
                                                </td>
                                            </tr>
                                        ) ||
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="alert alert-warning m-0" role="alert">
                                                    No se hallaron registros
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>    
                    
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where} />
                            </div>
                        </div>
                    
                    </div>
                </div>

                <div className="p-5"></div>
            </>
        );
    }

}

export default EmployeeList;