import React, { Component } from 'react';
import Avatar from './../../components/user/Avatar';
import './../../styles/css/header.css';
import { Link } from "react-router-dom";
import Parse from 'parse';

class Header extends Component {

    handleLogout = () => {

        const hasForm = !!document.getElementsByTagName('form').length;
        let r = true;
        if (hasForm === true) {
            r = window.confirm('¿Está seguro de cerrar sesión?');
        }

        if (r === true) {
            Parse.User.logOut().then(resp => {

                localStorage.removeItem('Rol');
                localStorage.removeItem('Config');
                window.location = '/acceso';
                
            }).catch(resp => {
                
                alert('Ha ocurrido un error al intentar cerrar sesión.');
                
            });
        }
        
    }

    render() {
        return (
            <header id="header" className="row justify-content-between align-items-center bg-pink-700">
                <Link to="/escritorio" id="brand" className="bg-pink-800 h-100 d-flex align-items-center justify-content-start justify-content-md-center text-white p-3 p-md-2 pl-sm-4 pr-sm-4">
                    <h3 className="m-0 d-flex align-items-baseline">SOLFIN <small><small>v1.0.74</small></small></h3>
                </Link>
                <div id="sub-header" className="col d-flex h-100 align-items-center justify-content-end">
                    <div className="d-flex align-items-center justify-content-end">
                        <Avatar className="d-md-flex d-none" />
                        <button type="button" className="btn btn-link text-white ml-2" title="Salir" onClick={() => this.handleLogout()}>
                            <i className="fas fa-sign-out-alt"></i>
                        </button>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;