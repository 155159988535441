import React from "react";
import _ from "underscore";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import { Component } from "react";

class AccountTypeHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            description: ''
        }
    }


    UNSAFE_componentWillMount = () => {

        const params = new URLSearchParams({
            where: JSON.stringify({
                objectId: this.props.id_type
            }),
        });

        Rest.peticion(`${config.api_url}/classes/TypesAccounts?${params.toString()}`, {
            method: 'GET',
        }).then((response) => {
            return response.json()
        }).then((response) => {
            
            if (_.isEmpty(response.code) && _.isEmpty(response.error)) {

                console.log("Respuesta Descripcion => ", response.results[0].value);

                this.setState({
                    description: response.results[0].value
                })
            
            } else {
                this.setState({
                    description: "Error"
                })
            }
            
        }).catch((err) => {
            this.setState({
                description: "Error"
            })
        });

    }

    render() {
        return(
            <>
                <span>{this.state.description}</span>
            </>
        )
    }

}

export default AccountTypeHistory;