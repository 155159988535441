import React, { useEffect, useState } from "react";
import _ from 'underscore';

const Amounts = (props) => {

    const [amounts, setAmounts] = useState([{
        clientFullName: '',
        monto_solicitado: 0
    }]);
    const [total, setTotal] = useState('0');
    

    useEffect(() => {
        if (amounts.length !== props.defaultAmounts.length) {
            const newAmounts = _.map(props.defaultAmounts, (client, index) => {
                return {
                  ...props.defaultAmounts[index],
                  monto_solicitado: props.defaultAmounts[index].monto_solicitado ? props.defaultAmounts[index].monto_solicitado : 0
                };
            })
            setAmounts(newAmounts);
            setAmountTotal(newAmounts);
        }
    }, [props.defaultAmounts]);

    useEffect(() => {
        setAmounts(_.map(props.clients, (client, index) => {
            return {
                monto_solicitado: props.defaultAmounts[index] ? props.defaultAmounts[index].monto_solicitado : 0,
                clientFullName: client.nombre ? `${client.nombre} ${client.apellido_paterno} ${client.apellido_materno}` : `Cliente ${index + 1}`,
            };
        }));
    }, [props.clients.length]);

    const setAmountTotal = (amounts) => {
        if (amounts.length) {
            const sum = _.reduce(amounts, (memo, amount) =>{
                return memo + amount.monto_solicitado;
            }, 0);
            if (sum) {
                setTotal(new Intl.NumberFormat('en-MX').format(sum));
            } else {
                setTotal(new Intl.NumberFormat('en-MX').format(0));
            }
        }

    }

    const handleChange = (value, index) => {
        if (value) {
            amounts[index].monto_solicitado = parseFloat(value);
            setAmounts(amounts);
            setAmountTotal(amounts);
            if (props.handleChange) {
                props.handleChange(_.map(amounts, (amount) => {
                    return {
                        monto_solicitado: amount.monto_solicitado
                    }
                }))
            }
        }
    }

    return (
        <div>
            <h2>Montos solicitados</h2>
            <p>
                En esta sección, deberás asignar la cantidad de dinero solicitada por cada cliente. Es importante tener en cuenta que ningún cliente podrá recibir el 50% o más del monto total solicitado por el grupo.
            </p>
            <br/>
            {
                amounts.map((amount, index) => <div key={index}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Cliente</label>
                            <p className="text-uppercase">
                                <b>{amount.clientFullName}</b>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <label>Monto solicitado</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">$</div>
                                </div>
                                <input type="number" min="" className="form-control" value={amount.monto_solicitado} onChange={(e) => handleChange(e.target.value, index)} required={true} />
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>)
            }
            <div className="row align-items-center">
                <div className="col-md-6 text-right">
                    <b>TOTAL</b>
                </div>
                <div className="col-md-6">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text border-0">$</div>
                        </div>
                        <span type="text" className="form-control text-info border-0">
                            {total}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Amounts;