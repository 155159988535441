import React, { Component } from 'react';
import DocumentPaperLetterCheckPolicy from './DocumentPaperLetterCheckPolicy';
import config from '../../settings/config';
import Rest from '../../Server/Rest';
import Parse from 'parse';
import _ from 'underscore';

function Unidades(num){

    switch(num)
    {
        case 1: return "UN";
        case 2: return "DOS";
        case 3: return "TRES";
        case 4: return "CUATRO";
        case 5: return "CINCO";
        case 6: return "SEIS";
        case 7: return "SIETE";
        case 8: return "OCHO";
        case 9: return "NUEVE";
    }

    return "";
}//Unidades()

function Decenas(num){

    let decena = Math.floor(num/10);
    let unidad = num - (decena * 10);

    switch(decena)
    {
        case 1:
            switch(unidad)
            {
                case 0: return "DIEZ";
                case 1: return "ONCE";
                case 2: return "DOCE";
                case 3: return "TRECE";
                case 4: return "CATORCE";
                case 5: return "QUINCE";
                default: return "DIECI" + Unidades(unidad);
            }
        case 2:
            switch(unidad)
            {
                case 0: return "VEINTE";
                default: return "VEINTI" + Unidades(unidad);
            }
        case 3: return DecenasY("TREINTA", unidad);
        case 4: return DecenasY("CUARENTA", unidad);
        case 5: return DecenasY("CINCUENTA", unidad);
        case 6: return DecenasY("SESENTA", unidad);
        case 7: return DecenasY("SETENTA", unidad);
        case 8: return DecenasY("OCHENTA", unidad);
        case 9: return DecenasY("NOVENTA", unidad);
        case 0: return Unidades(unidad);
    }
}//Unidades()

function DecenasY(strSin, numUnidades) {
    if (numUnidades > 0)
    return strSin + " Y " + Unidades(numUnidades)

    return strSin;
}//DecenasY()

function Centenas(num) {
    let centenas = Math.floor(num / 100);
    let decenas = num - (centenas * 100);

    switch(centenas)
    {
        case 1:
            if (decenas > 0)
                return "CIENTO " + Decenas(decenas);
            return "CIEN";
        case 2: return "DOSCIENTOS " + Decenas(decenas);
        case 3: return "TRESCIENTOS " + Decenas(decenas);
        case 4: return "CUATROCIENTOS " + Decenas(decenas);
        case 5: return "QUINIENTOS " + Decenas(decenas);
        case 6: return "SEISCIENTOS " + Decenas(decenas);
        case 7: return "SETECIENTOS " + Decenas(decenas);
        case 8: return "OCHOCIENTOS " + Decenas(decenas);
        case 9: return "NOVECIENTOS " + Decenas(decenas);
    }

    return Decenas(decenas);
}//Centenas()

function Seccion(num, divisor, strSingular, strPlural) {
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let letras = "";

    if (cientos > 0)
        if (cientos > 1)
            letras = Centenas(cientos) + " " + strPlural;
        else
            letras = strSingular;

    if (resto > 0)
        letras += "";

    return letras;
}//Seccion()

function Miles(num) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
    let strCentenas = Centenas(resto);

    if(strMiles === "")
        return strCentenas;

    return strMiles + " " + strCentenas;
}//Miles()

function Millones(num) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)

    let strMillones = Seccion(num, divisor, "UN MILLON DE", "MILLONES DE");
    let strMiles = Miles(resto);

    if(strMillones === "")
        return strMiles;

    return strMillones + " " + strMiles;
}//Millones()

function NumeroALetras(num) {
    var data = {
        numero: num,
        enteros: Math.floor(num),
        centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
        letrasCentavos: "",
        letrasMonedaPlural: 'PESOS',//"PESOS", 'Dólares', 'Bolívares', 'etcs'
        letrasMonedaSingular: 'PESO', //"PESO", 'Dólar', 'Bolivar', 'etc'

        letrasMonedaCentavoPlural: "CENTAVOS",
        letrasMonedaCentavoSingular: "CENTAVO"
    };

    if (data.centavos > 0) {
        data.letrasCentavos = "CON " + (function (){
            if (data.centavos === 1)
                return Millones(data.centavos) + " " + data.letrasMonedaCentavoSingular;
            else
                return Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural;
            })();
    };

    if(data.enteros === 0)
        return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    if (data.enteros === 1)
        return Millones(data.enteros) + " " + data.letrasMonedaSingular + " " + data.letrasCentavos;
    else
        return Millones(data.enteros) + " " + data.letrasMonedaPlural + " " + data.letrasCentavos;
}

class DocumentCheckPolicy extends Component {

    constructor (props) {
        super(props)
        this.margin = this.getParamValue('no_margin');
        this.state = JSON.parse(localStorage.getItem('requisition'));
        if (!_.isEmpty(this.state.fecha_elaboracion)) {
            this.fecha = new Date(this.state.fecha_elaboracion.iso);
        }
    }

    clientas_en_pagina = 8;
    
    margin = '';

    fecha = new Date();

    months = [
        'ENERO',
        'FEBRERO',
        'MARZO',
        'ABRIL',
        'MAYO',
        'JUNIO',
        'JULIO',
        'AGOSTO',
        'SEPTIEMBRE',
        'OCTUBRE',
        'NOVIEMBRE',
        'DICIEMBRE'
    ];

    getParamValue = (paramName) => {
        var url = window.location.search.substring(1); //get rid of "?" in querystring
        var qArray = url.split('&'); //get key-value pairs
        for (var i = 0; i < qArray.length; i++) 
        {
            var pArr = qArray[i].split('='); //split key and value
            if (pArr[0] === paramName) 
                return pArr[1]; //return value
        }
     }

    setAmountTotal = (amount_name) => {
        let sum = 0;
        _.each(this.state.montos, (item) => {
            if (item[amount_name] === '') {
                sum += 0;
            } else if (!_.isUndefined(item[amount_name])) {
                sum += parseInt(item[amount_name]);
            }
            
        });
        return sum;
    }

    setMadeBy = () => {
        
        if (!_.isEmpty(this.state.registeredBy)) {
            return `${this.state.registeredBy.nombre} ${this.state.registeredBy.apellidos}`;
        } else {
            return `${this.state.Grupo.registeredBy.nombre} ${this.state.Grupo.registeredBy.apellidos}`;
        }
    }

    render() {
        const currentUser = Parse.User.current();
        return (
            <div className="w-100">
                {!_.isEmpty(this.state) && <DocumentPaperLetterCheckPolicy className="d-flex flex-column" margin={this.margin}>

                    <div /*style={{minWidth:'21.6cm', minHeight:'16.5cm', maxWidth:'21.6cm', maxHeight:'16.5cm'}}*/ className="container-fluid">
                        <div className="card mb-4" style={{...styles.panel_1, ...styles.panel_border_radius}}>
                            <div className="card-body d-flex flex-column justify-content-around" style={{...styles.panel_body, padding: '1cm'}}>
                                <div className="d-flex justify-content-between">
                                    <div>
                                    <span className="text-center badge-primary border-0 d-block rounded-0 pl-3 pr-3" style={{fontSize: '12.5pt'}}>PÓLIZA DE CHEQUE</span>
                                    </div>
                                    <div>
                                        {this.fecha.getDate()} DE {this.months[this.fecha.getMonth()]} DEL {this.fecha.getFullYear()}
                                    </div>
                                </div>

                                <br/>

                                <div className="d-flex justify-content-between">
                                    <div>
                                        {this.state.responsable.nombre} {this.state.responsable.apellido_paterno} {this.state.responsable.apellido_materno}
                                    </div>
                                    <div style={{fontSize: '13.5pt'}}>
                                        {new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( this.setAmountTotal('monto_autorizado') )}
                                    </div>
                                </div>
                                <div style={{fontSize: '13.5pt'}}>
                                    ({NumeroALetras( this.setAmountTotal('monto_autorizado') )} 00/100 M.N.)
                                </div>

                                <br/>

                                <div className="row">
                                    <div className="col-4">
                                        BANCO: INBURSA
                                    </div>
                                    <div className="col-4 text-center">
                                        CUENTA: 50052925871
                                    </div>
                                    <div className="col-4 text-right">
                                        TRANSFERENCIA ELECTRÓNICA
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-8">
                                <div className="card" style={styles.panel_border_dark}>
                                    <div className="card-header" style={styles.card_header}>
                                        CONCEPTO DEL PAGO
                                    </div>
                                    <div className="card-body">
                                        <p className="m-0">
                                            DESEMBOLSO DEL GRUPO: {this.state.Grupo.nombre}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 d-flex">
                                <div className="card w-100" style={styles.panel_border_dark}>
                                    <div className="card-header" style={styles.card_header}>
                                        FIRMA CHEQUE RECIBIDO
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <p className="mt-0">
                            <b>DISTRIBUCION</b> <small style={{fontSize:'7pt'}}>CHEQUE. - BENEFICIARIO COPIA COLOR. - ARCHIVO CON COMPROBANTE. - COPIA BLANCA ARCHIVO NUMERO. - CONTABILIDAD CONCILIACIONES BANCARIAS</small>
                        </p>

                        <div className="card mb-2" style={styles.panel_border_dark}>
                            <table className="table table-striped table-borderless m-0">
                                <tbody>
                                    <tr>
                                        <th style={{...styles.td_1, ...styles.td, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>CUENTA</span></th>
                                        <th style={{...styles.td_2, ...styles.td, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>SUB-CUENTA</span></th>
                                        <th style={{...styles.td_3, ...styles.td, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>NOMBRE</span></th>
                                        <th style={{...styles.td_4, ...styles.td, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>PARCIAL</span></th>
                                        <th style={{...styles.td_5, ...styles.td, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>DEBE</span></th>
                                        <th style={{...styles.td_6, ...styles.td, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>HABER</span></th>
                                    </tr>
                                    <tr>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={styles.td}><span></span></td>
                                    </tr>
                                    <tr>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={styles.td}><span></span></td>
                                    </tr>
                                    <tr>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={styles.td}><span></span></td>
                                    </tr>
                                    <tr>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={styles.td}><span></span></td>
                                    </tr>
                                    <tr>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={styles.td}><span></span></td>
                                    </tr>
                                    <tr>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}><span></span></td>
                                        <td style={styles.td}><span></span></td>
                                    </tr>
                                    <tr>
                                        <td style={{...styles.td, ...styles.td_border_right, ...styles.td_border_bottom}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right, ...styles.td_border_bottom}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right, ...styles.td_border_bottom}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right, ...styles.td_border_bottom}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_right, ...styles.td_border_bottom}}><span></span></td>
                                        <td style={{...styles.td, ...styles.td_border_bottom}}><span></span></td>
                                    </tr>
                                    <tr>
                                        <td className="bg-white text-right p-1" style={styles.td_border_right} colSpan="4"><p className="m-0 p-0" style={{fontSize:'9pt'}}>SUMAS IGUALES</p></td>
                                        <td className="bg-white" style={styles.td_border_right}></td>
                                        <td className="bg-white"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div className="card" style={styles.panel_border_dark}>
                            <table className="table table-striped table-borderless m-0">
                                <tbody>
                                    <tr>
                                        <th style={{...styles.td, ...styles.td_b, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>HECHO POR</span></th>
                                        <th style={{...styles.td, ...styles.td_b, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>REVISADO POR</span></th>
                                        <th style={{...styles.td, ...styles.td_b, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>AUTORIZADO POR</span></th>
                                        <th style={{...styles.td, ...styles.td_b, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>AUXILIARES</span></th>
                                        <th style={{...styles.td, ...styles.td_b, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>DIARIO</span></th>
                                        <th style={{...styles.td, ...styles.td_b, ...styles.bg_green}} className="text-center"><span style={styles.card_header}>POLIZA NUM</span></th>
                                    </tr>
                                    <tr>
                                        <td style={{...styles.td, ...styles.td_border_right}}>
                                            <span>{this.setMadeBy()}</span>
                                        </td>
                                        <td style={{...styles.td, ...styles.td_border_right}}></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}>
                                            <span>{this.state.autorizado_por.nombre} {this.state.autorizado_por.apellidos}</span>
                                        </td>
                                        <td style={{...styles.td, ...styles.td_border_right}}></td>
                                        <td style={{...styles.td, ...styles.td_border_right}}></td>
                                        <td style={styles.td}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    
                    
                    
                </DocumentPaperLetterCheckPolicy>}
            </div>
        );
    }
}

export default DocumentCheckPolicy;

const styles = {
    panel_1: {
        borderTop: '3mm solid #8bb95e',
        borderBottom: '3mm solid #8bb95e',
        borderLeft: '1mm solid #8bb95e',
        borderRight: '1mm solid #8bb95e',
        backgroundColor: '#8bb95e',
        maxHeight: '6cm',
        minHeight: '6cm'
    },
    panel_border_radius: {
        borderRadius: '4mm'
    },
    panel_body: {
        backgroundColor: 'white',
        borderRadius: '4mm'
    },
    panel_border_dark: {
        borderColor: '#233840',
        overflow: 'hidden'
    },
    card_header: {
        backgroundColor: '#b6de81',
        minHeight: '5mm',
        maxHeight: '5mm',
        width: '100%',
        display: 'block',
        padding: '.6mm 5mm',
        fontSize: '6.5pt',
        border: 0
    },
    bg_green: {
        background: '#b6de81',
    },
    td: {
        height: '5mm',
        padding: 0,
    },
    td_1: {
        width: '1.2cm'
    },
    td_2: {
        width: '2cm'
    },
    td_3: {
        width: '6cm'
    },
    td_4: {
        width: '2.2cm'
    },
    td_5: {
        width: '2.2cm'
    },
    td_6: {
        width: '2.2cm'
    },

    td_b: {
        width: '16.67%'
    },
    td_border_right: {
        borderRight: '1px solid #233840'
    },
    td_border_bottom: {
        borderBottom: '1px solid #233840'
    }
}