import React, { Component } from 'react';
import Spinner from '../others/Spinner';
import DatePicker from 'react-datepicker';
import Rest from '../../Server/Rest';
import config from '../../settings/config';
import user from '../../settings/user';
import _ from 'underscore';

export default class Configuration extends Component {

    constructor(props) {
        super(props);

        this.saveFilterFrom = this.saveFilterFrom.bind(this);
    }

    title = 'Configuración';
    tOut = null;

    state = {
        loading: false,
        filter_from: '',
        filter_from__save_status: null
    }

    componentDidMount = () => {
        if (user.config() && user.config().filter_from) {
            this.setState({filter_from: new Date(user.config().filter_from)});
        }
    }

    componentWillUnmount = () => {
        clearTimeout(this.tOut);
    }

    handleChangeDate = (date) => {
        this.setState({
            filter_from: date
        })
    }

    saveFilterFrom = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        Rest.peticionConfig(`${config.api_url}/config`, {
            method: 'PUT',
            body: JSON.stringify({
                params: {
                    filter_from: this.state.filter_from
                }
            })
        }).then(res => {
            return res.json();
        })
        .then(res => {
            if (res.result) { // devuelve true si se guarda correctamente
                const {filter_from} = this.state;
                console.log(filter_from);
                localStorage.removeItem('Config');
                localStorage.setItem('Config', JSON.stringify({
                    filter_from: filter_from.toISOString()
                }));
                this.setState({
                    filter_from__save_status: true
                });
            } else {
                this.setState({
                    filter_from__save_status: false
                });
                alert('Lo sentimos, no se puedo guardar la fecha de filtro. Itente más tarde.');
            }
        })
        .finally(() => {
            this.setState({
                loading: false
            });
            clearTimeout(this.tOut);
            this.tOut = setTimeout(() => {
                this.setState({
                    filter_from__save_status: null
                });
            }, 3000);
        })
    }

    render() {
        const {state} = this;
        return (
            <>
                <Spinner loading={state.loading} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-cog mr-2"></i>
                    {this.title}
                </h3>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.saveFilterFrom}>
                                    <div className="form-group">
                                        <label htmlFor="filter-from">Filtrar a partir de la fecha:</label>
                                        
                                        <DatePicker
                                        id="filter-from"
                                        aria-describedby="filter-from-help"
                                        className="form-control btn-block"
                                        locale="es"
                                        selected={this.state.filter_from}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={date => this.handleChangeDate(date, 'filter_from')}
                                        selectsStart
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={true}
                                        placeholderText="--/--/----" />

                                        
                                    </div>
                                    <button type="submit" className="btn btn-primary">Guardar</button>
                                </form>
                                {this.state.filter_from__save_status === true && <small id="filter-from-help" className="form-text text-success">Fecha guardada correctamente.</small>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-5"></div>
            </>
        );
    }
}
