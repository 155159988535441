import React, { Component } from 'react';
import formatString from '../../utilities/format-string';
import ReportDayTableDetails from './ReportDayTableDetails';

export default class ReportDayPreviewPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }

    // UNSAFE_componentWillReceiveProps = (nextProps) => {
    //     if (this.state.data !== nextProps.data) {
    //         this.setState({
    //             data: nextProps.data
    //         })
    //     }
    // }

    getData = async () => {
        const data = await this.props.callback();
        this.setState({data})
    }
  render() {
    return (
    <>
        <button 
        type="button"
        className={`btn btn-primary ${this.props.className}`}
        data-toggle="modal"
        data-target={`#vista-previa-${this.props.index}`}
        onClick={() => this.getData()}>
            Detalles
        </button>
        <div className="modal fade" id={`vista-previa-${this.props.index}`} tabIndex={-1} aria-labelledby={`vista-previa-${this.props.index}-label`} aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id={`vista-previa-${this.props.index}-label`}>Vista previa</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <table className='table'>
                        <tbody>
                            <tr>
                                <td className='border-0'>
                                    Fecha de elaboración
                                </td>
                                <td className='border-0'>
                                    {this.state.data.fecha_consulta && formatString.setLegibleDate(this.state.data.fecha_consulta)}
                                </td>
                            </tr>
                            <tr>
                                <td className='border-0'>
                                    Sucursal
                                </td>
                                <td className='border-0'>
                                    {this.state.data.Sucursal && this.state.data.Sucursal.nombre}
                                </td>
                            </tr>
                            <tr>
                                <td className='border-0'>Hecho por:</td>
                                <td className='border-0 text-uppercase'>
                                    {this.state.data.hecho_por && this.state.data.hecho_por.nombre + ' ' + this.state.data.hecho_por.apellidos}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ReportDayTableDetails data={this.state.data} showControls={true}/>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    <button type="button" className="btn btn-primary" onClick={() => this.props.callbackPrint(this.state.data)}>Imprimir</button>
                    <button type="button" className="btn btn-primary" onClick={() => this.props.callbackPreview(this.state.data)}>Vista previa</button>
                </div>
                </div>
            </div>
        </div>
    </>
    );
  }
}
