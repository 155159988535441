import React, { Component } from 'react';

class Alert extends Component {
    setAlert = (alertType) => {
        let _alert;
        switch (alertType) {
            case 'value':
                _alert = <div className="alert alert-primary alert-dismissible fade show shadow-sm" role="alert">
                    A simple primary alert with <a href="#" className="alert-link">an example link</a>. Give it a click if you like.
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                break;
        
            default:
                _alert = <></>;
                break;
        }
        return _alert;
    }
    render() {
        return (
            <div className="alert-wrapper d-flex justify-content-center align-items-center flex-column">
                {this.setAlert()}
            </div>
        );
    }
}

export default Alert;