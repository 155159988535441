import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import SelectBranchOffice from '../forms/SelectBranchOffice';
import SelectEmpleyee from '../forms/SelectEmpleyee';
import Parse from 'parse';
import user from './../../settings/user';

class GroupListPaymentFilter extends Component {

    constructor(props) {
        super(props);
        this.handleFilter = this.handleFilter.bind(this);
        const config = user.config();
        if (user.rol.es_administrador !== true) {
            this.filter_from = config && config.filter_from ? new Date(config.filter_from) : null;
        }
    }
    
    filter_from = null;

    state = {
        nombre: this.props.state.nombre,
        promotor: this.props.state.promotor,
        dateStart: this.props.state.dateStart,
        dateEnd: this.props.state.dateEnd,
        Sucursal: this.props.state.Sucursal,
        includeDowns: this.props.includeDowns,
        fintrar_por_fecha_finalizacion: this.props.fintrar_por_fecha_finalizacion
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({
            nombre: nextProps.state.nombre,
            promotor: nextProps.state.promotor,
            dateStart: nextProps.state.dateStart,
            dateEnd: nextProps.state.dateEnd,
            Sucursal: nextProps.state.Sucursal,
            includeDowns: nextProps.state.includeDowns,
            fintrar_por_fecha_finalizacion: nextProps.state.fintrar_por_fecha_finalizacion
        })
    }

    handleChange = this.props.handleChange;

    handleChangeDate = this.props.handleChangeDate;

    handleFilter = (event) => {
        if (event) event.preventDefault();
        this.props.handleFilter();
    };

    render() {
        return (
            <div>
               <div className="m-4"></div>      
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">
                            <i className="fas fa-filter mr-2"></i>
                            Filtro
                        </h5>
                        <form onSubmit={this.handleFilter} className="row align-items-start">
                            {(this.props.inputGroupEnabled !== true) && <div className="col-md-4 form-group">
                                <label htmlFor="nombre">Grupo</label>
                                <input type="text" className="form-control" id="nombre" name="nombre" value={this.state.nombre} onChange={this.handleChange} placeholder="Nombre del grupo" />
                            </div>}
                            {(this.props.inputPrometerEnabled !== true) && <div className="col-md-4 form-group">
                                <label htmlFor="Sucursal">Promotor</label>
                                <SelectEmpleyee name="promotor" id="promotor" value={this.state.promotor} onChange={this.handleChange} />
                            </div>}
                            {(this.props.inputDateEnabled !== true) && <div className="col-md-4 form-group d-flex flex-column">
                                <label htmlFor="fecha">Fecha de pago</label>
                                <div className="input-group">
                                    {/* <input type="text" className="form-control" id="fecha" /> */}
                                    <div className="d-flex">
                                        <DatePicker
                                        className="form-control mr-1"
                                        locale="es"
                                        selected={this.state.dateStart}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={date => this.handleChangeDate(date, 'dateStart')}
                                        selectsStart
                                        startDate={this.state.dateStart}
                                        endDate={this.state.dateEnd}
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={false}
                                        minDate={this.filter_from}
                                        placeholderText="Inicio" />
                                        <DatePicker
                                        className="form-control ml-1"
                                        locale="es"
                                        selected={this.state.dateEnd}
                                        dateFormat="dd/MM/yyyy"
                                        onChange={date => this.handleChangeDate(date, 'dateEnd')}
                                        selectsEnd
                                        startDate={this.state.dateStart}
                                        endDate={this.state.dateEnd}
                                        minDate={this.state.dateStart || this.filter_from}
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={false}
                                        placeholderText="Final" />
                                    </div>
                                    {/* <div className="form-check form-group mt-3 w-100">
                                        <input className="form-check-input" type="checkbox" defaultValue={false} value={this.state.fintrar_por_fecha_finalizacion} name="fintrar_por_fecha_finalizacion" onChange={this.handleChange} id="fintrar_por_fecha_finalizacion" />
                                        <label className="form-check-label" htmlFor="fintrar_por_fecha_finalizacion">
                                            Finalizado al
                                        </label>
                                    </div> */}
                                </div>
                            </div>}
                            {(this.props.inputSucursalEnabled !== true) && <div className="col-md-4 form-group">
                                <label htmlFor="Sucursal">Sucursal</label>
                                
                                {Parse.User.current().toJSON().Sucursal && 
                                    <SelectBranchOffice id="Sucursal" name="Sucursal" value={this.state.Sucursal} defaultValue={Parse.User.current().toJSON().Sucursal.objectId} onChange={this.handleChange} disabled /> || 
                                    <SelectBranchOffice id="Sucursal" name="Sucursal" value={this.state.Sucursal} onChange={this.handleChange} />
                                }
                            </div>}
                            {(this.props.inputincludeDownsEnabled !== true) && <div className="col-md-4">
                            <label htmlFor="includeDowns">Incluir Bajas</label>
                                <div className="form-check form-group">
                                    <input className="form-check-input" type="checkbox" defaultValue={false} value={this.state.includeDowns} name="includeDowns" onChange={this.handleChange} id="includeDowns"/>
                                    <label className="form-check-label" htmlFor="includeDowns">
                                        Incluir Bajas
                                    </label>
                                </div>

                            </div>}
                            <div className="col-12"></div>
                            <div className="col-md-4 mt-2 form-group">
                                <button type="submit" className="btn btn-primary btn-block">
                                    Aplicar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="m-4"></div> 
            </div>
        );
    }
}

export default GroupListPaymentFilter;