import React, {Component} from "react";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import _ from "underscore";
import DatePicker from 'react-datepicker';
import moment from "moment";
moment.locale("es")

class PoliciesSearchForm extends Component{

    // constructor(props) {
    //     super(props);
    // }

    state = {
        busqueda: '',
        type_transaction: '',
        date: '',
        collection: [],
    }

    handleSearchChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    handleChangeDate = (date, name) => {
        this.setState({
            [name]: date
        })
    }

    UNSAFE_componentWillMount = async() => {
        let params = new URLSearchParams({
            limit: 50,
            count: 1,
        });

        Rest.peticion(`${config.api_url}/classes/TypesTransactions?${params.toString()}`, {
            method: 'GET',
        }).then((response) => {
            return response.json();
        }).then((res) => {
            
            if (res.code) {
                alert('Ha ocurrido un error inesperado al recuperar los tipos de cuenta para los filtros.\nIntente más tarde.');
            } else {
                // console.log("Respuesta Tipos de transacciones => ", res);

                this.setState({
                    collection: res.results
                })
            }

            

        }).catch((err) => {
            console.log("Error: ", err)
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        });

    }

    handleSearchSubmit = (event) => {

        if (event) event.preventDefault();

        let where = {};

        // if (!_.isEmpty(this.state.busqueda) && !_.isEmpty(this.state.type_transaction)) {
        //     where = {
        //         numero : parseInt(this.state.busqueda.trim()),
        //         type_transaction : {
        //             __type: 'Pointer',
        //             className: 'TypesTransactions',
        //             objectId: this.state.type_transaction
        //         }
        //     }
        // } else if (!_.isEmpty(this.state.busqueda)) {
        //     where = {
        //         numero : parseInt(this.state.busqueda.trim()),
        //     }
        // } else if (!_.isEmpty(this.state.type_transaction)) {
        //     where = {
        //         type_transaction : {
        //             __type: 'Pointer',
        //             className: 'TypesTransactions',
        //             objectId: this.state.type_transaction
        //         }
        //     }
        // }else if(_.isDate(this.state.date)){
        //     where = {
        //         fecha: {
        //             __type: 'Date',
        //             iso: this.resetDate(this.state.date)
        //         }
        //     }
        // }


        if (!_.isEmpty(this.state.busqueda)) {

            let number = {
                numero : parseInt(this.state.busqueda.trim())
            }

            Object.assign(where, number);
        }
        
        if (!_.isEmpty(this.state.type_transaction)) {
            let transaction = {
                type_transaction : {
                    __type: 'Pointer',
                    className: 'TypesTransactions',
                    objectId: this.state.type_transaction
                }
            }

            Object.assign(where, transaction);
        }
        
        if(_.isDate(this.state.date)){
            let fecha = {
                fecha: {
                    __type: 'Date',
                    iso: this.resetDate(this.state.date)
                }
            }

            Object.assign(where, fecha);
        }


        console.log("where => ", where)

        this.props.handleFilter(where);
            
    }

    resetDate = (fecha) =>{

        let date = moment(fecha).format();
        date = date.split('T')[0];
        date = `${date}T00:00:00`;

        return date;
    }

    render () {

        return (
            <>
                <div className="card">

                <div className="card-header">
                    <i className="fas fa-filter mr-2"></i>
                    Filtros
                </div>

                <div className="card-body">
                    
                    <form className="row" onSubmit={this.handleSearchSubmit}>
                        <div className="col-md-12">
                            <div className="row align-items-start">
                                <div className="col-md-4">
                                    <label htmlFor="search-input">Número de póliza</label>
                                    <div className="input-group mb-3 mb-md-0">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </div>
                                        </div>

                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="search-input" 
                                            placeholder="Número de póliza"
                                            style={styles.searchInput}
                                            onChange={this.handleSearchChange}
                                            value={this.state.busqueda}
                                            name="busqueda"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="cuenta_tipo">Tipo de transacción</label>
                                    <select className="form-control text-uppercase" id="type_transaction" name="type_transaction" value={this.state.type_transaction} onChange={this.handleSearchChange}>
                                        <option value="">Tipo de cuenta</option>
                                        {   (this.state.collection && this.state.collection.length) && this.state.collection.map((item, idx) =>
                                            <option value={item.objectId} key={idx}>{item.tipo}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="cuenta_tipo">Fecha de póliza</label>
                                    <div className="input-group mb-4 mb-md-0">
                                        
                                        <DatePicker
                                            className="form-control"
                                            locale="es"
                                            selected={this.state.date}
                                            dateFormat="dd/MM/yyyy"
                                            onChange={date => this.handleChangeDate(date, 'date')}
                                            selectsStart
                                            startDate={this.state.date}
                                            // endDate={this.state.dateEnd}
                                            showDisabledMonthNavigation
                                            shouldCloseOnSelect={false}
                                            minDate={this.filter_from}
                                            placeholderText="Inicio"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 mt-0 mt-md-3">
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Buscar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            </>
        )
    }
}

export default PoliciesSearchForm;

const styles = {
    searchInputClose: {
        position: 'absolute',
        right: 0,
        zIndex: 4,
    },
    searchInput: {
        paddingRight: 36
    }
}