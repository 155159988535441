import Rest from "../Server/Rest";
import config from "../settings/config";
import _ from 'underscore';

export const setAmounts = (_amounts, groupId) => {

    const fecha_registro = {
        __type: 'Date',
        iso: new Date().toISOString()
    }

    let amounts = _.map(_amounts, amount => {
        let newAmount = {
            Socio: {
                __type: 'Pointer',
                className: 'Socios',
                objectId: amount.Socio.objectId
            },
            Grupo: {
                __type: 'Pointer',
                className: 'Grupos',
                objectId: groupId
            },
            fecha_registro: fecha_registro
        };
        if (amount.monto_actual) {
            newAmount.monto_actual = parseInt(amount.monto_actual);
        } else {
            newAmount.monto_actual = 0;
        }
        if (amount.monto_solicitado) {
            newAmount.monto_solicitado = parseInt(amount.monto_solicitado);
        } else {
            newAmount.monto_solicitado = 0;
        }
        if (amount.monto_sugerido) {
            newAmount.monto_sugerido = parseInt(amount.monto_sugerido);
        } else {
            newAmount.monto_sugerido = 0;
        }
        return newAmount;
    });

    return amounts;
}

export const saveAmounts = async (amounts) => {
    return new Promise((resolve, reject) => {
        const data = {
            requests: _.map(amounts, amount => {
                return {
                        method: 'POST',
                        path: `${config.api_path2}/classes/SolicitudesMontos`,
                        body: amount
                    }
                }
            )
        }
        
        Rest.peticion(`${config.api_url}/batch`, {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
}

export const addSolicitud = async (solicitudId, groupId) => {
    return new Promise((resolve, reject) => {
        let data = {
            Solicitudes: {
                __op: 'AddUnique',
                objects: [{
                    __type: 'Pointer',
                    className: 'Solicitudes',
                    objectId: solicitudId
                }]
            }
        }
        Rest.peticion(`${config.api_url}/classes/Grupos/${groupId}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
}

export const updatePartnersStatus = async (amounts) => {
    return new Promise((resolve, reject) => {
        const data = {
            requests: _.map(amounts, amount => {
                return {
                        method: 'PUT',
                        path: `${config.api_path2}/classes/Socios/${amount.Socio.objectId}`,
                        body: {
                            status: 'ACTIVO'
                        }
                    }
                }
            )
        }
        
        Rest.peticion(`${config.api_url}/batch`, {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
}