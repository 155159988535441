import React, { Component } from "react";
import DatePicker from "react-datepicker";
import SelectBranchOffice from "../forms/SelectBranchOffice";
import SelectEmpleyee from "../forms/SelectUsers";
import Spinner from "../others/Spinner";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import formatString from "../../utilities/format-string";
import _ from "underscore";
import InputSlectOtherOption from "../forms/InputSelectOtherOption";
import Popover from "../others/Popover";
import BackBtn from "../navigation/BackBtn";

class ReportDay extends Component {

    constructor(props){
        super(props)

        this.handleChange = this.handleChange.bind(this);
        this.handleFilterForm = this.handleFilterForm.bind(this);
        this.handleSaveReport = this.handleSaveReport.bind(this);
    }


    include =   'Grupo' +
                ',Sucursal' +
                ',promotor' +
                ',montos' +
                ',montos.Socio' +
                ',Pagos' +
                ',PagosRealizados' +
                ',PagosRealizados.registrado_por';
    order =     'Grupo.nombre';
    keys =      'montos' +
                ',Pagos' +
                ',PagosRealizados';

    UNSAFE_componentWillMount = () => {
        this.resetState();
    }

    componentDidMount = () => {
        if (!this.state.bank_accounts.length) {
            this.getConfigs();
        }
        this.setTotals();
    }

    resetState = () => {
        const state = {
            loading: false,
    
            // form inputs
            fecha_consulta: '',//new Date('2022-11-02'),
            fecha_publicacion: '',
            Sucursal: '',//'T1eDFnsXWF',
            hecho_por: '',
            dayEstimate: [this.dayEstimateDefault()],
            disbursementDeposit: [this.disbursementDepositDefault()],
            prepaid: [this.prepaidDefault()],
            dayRecovery: [this.dayRecoveryDefault()],
            disbursements: [this.disbursementsDefault()],
            pendingPayments: [this.pendingPaymentsDefault()],
            depositsToAccount: [this.depositsToAccountDefault()],
            extraCosts: [this.extraCostsDefault()],
            totalDayEstimate: 0,
            totalDisbursementDeposit: 0,
            totalPrepaid: 0,
            totalDayRecovery: 0,
            totalDisbursements: 0,
            totalPendingPayments: 0,
            totalDepositsToAccount: 0,
            totalExtraCosts: 0,
            total: 0,
    
            // collections
            groups: [],
            groupsDebit: [],

            // configs
            bank_accounts: []
        }

        this.setState(state)
    }

    dayEstimateDefault = () => {
        return {
            grupo: {},
            monto_pago: 0,
        }
    }

    getConfigs = () => {
        Rest.peticion(`${config.api_url}/Config`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.params && res.params.bank_accounts) {
                this.setState({
                    bank_accounts: res.params.bank_accounts
                })
            }
        })
    }

    disbursementDepositDefault = () => {
        return {
            nombre_destinatario: '',
            referencia_bancaria: '',
            cuenta_origen: '',
            banco: '',
            monto: 0,
        }
    }

    prepaidDefault = () => {
        return {
            grupo: {},
            monto_pago: 0,
            pagos_adelantados: 0,
        }
    }

    dayRecoveryDefault = () => {
        return {
            grupo: {},
            monto_pago: 0,
        }
    }

    disbursementsDefault = () => {
        return {
            Solicitud: {},
            monto: 0,
        }
    }

    pendingPaymentsDefault = () => {
        return {
            grupo: {},
            monto_pago: 0,
        }
    }

    depositsToAccountDefault = () => {
        return {
            nombre_depositante: '',
            concepto: '',
            monto: 0,
            banco_cuenta: ''
        }
    }

    extraCostsDefault = () => {
        return {
            concepto: '',
            factura: '',
            emisor: '',
            monto: 0,
        }
    }

    addItem = (key, preIndex) => {
        const coll = this.state[key];
        const newColl = [];
        for (let index = 0; index < coll.length; index++) {
            newColl.push(coll[index]);
            if (index === preIndex) {
                newColl[index+1] = this[key+'Default']();
            }
        }
        this.setState({[`${key}`]: newColl});
        this.setTotals();
    }

    removeItem = (key, index) => {
        const coll = this.state[key];
        if (!(coll.length === 1)) {
            coll.splice(index, 1)
            this.setState({[`${key}`]: coll});
        }
        this.setTotals();
    }

    handleChangeInputs = (keyColl, keyInput, value, index) => {
        const coll = this.state[keyColl];
        coll[index][keyInput] = value;
        this.setState({[`${keyColl}`]: coll});
        this.setTotals()
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleValueChange = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    handleChangeDate = (date, name) => {
        this.setState({
            [name]: date
        })
    }

    setAuthorizedAmount = (solicitud) => {
        let tital = 0.0;
        for (let index = 0; index < solicitud.montos.length; index++) {
            tital += solicitud.montos[index].monto_autorizado;
        }
        return tital;
    }

    setAuthorizedAmounts = (solicitudes) => {
        let total = 0.0;
        for (let index = 0; index < solicitudes.length; index++) {
            const solicitud = solicitudes[index];
            total += this.setAuthorizedAmount(solicitud);
        }
        return total;
    }

    getGroups = () => {

        return new Promise((resolve, reject) => {

            const reqGruposMorosidadParams = new URLSearchParams({
                
                limit: 1000,
                count: 1,
                where: JSON.stringify({
                    Sucursal: {
                        __type: 'Pointer',
                        className: 'Sucursales',
                        objectId: this.state.Sucursal
                    },
                    // status: 'morosidad'
                    Solicitudes: {
                        $inQuery: {
                            where: {
                                status: 'morosidad',
                            },
                            className: 'Solicitudes'
                        }
                    }
                }),
                order: 'nombre',
                keys: 'nombre,Sucursal.id_en_sucursal,Solicitudes'
            });
            const reqGruposMorosidad = Rest.peticion(`${config.api_url}/classes/Grupos?${reqGruposMorosidadParams.toString()}`, {
                method: 'GET'
            }).then(res => {return res.json()});

            const reqGruposParams = new URLSearchParams({
                
                limit: 1000,
                count: 1,
                where: JSON.stringify({
                    Sucursal: {
                        __type: 'Pointer',
                        className: 'Sucursales',
                        objectId: this.state.Sucursal
                    },
                    Solicitudes: {
                        $exists: true
                    }
                }),
                // order: 'nombre',
                keys: 'nombre,Sucursal.id_en_sucursal,Solicitudes'
            });
            const reqGrupos = Rest.peticion(`${config.api_url}/classes/Grupos?${reqGruposParams.toString()}`, {
                method: 'GET'
            }).then(res => {return res.json()})

            Rest.peticiones([
                reqGruposMorosidad,
                reqGrupos
            ]).then(res => {
                resolve(res);
            }).catch(reject);
        })

    }

    getDisbursementsAt = async () => {
        return new Promise((resolve, reject) => {
            const dateStart = new Date(this.state.fecha_consulta);
            dateStart.setHours(0);
            dateStart.setMinutes(0);
            dateStart.setSeconds(0);
            dateStart.setMilliseconds(0);
            
            const dateEnd = new Date(this.state.fecha_consulta);
            dateEnd.setHours(24);
            dateEnd.setMinutes(0);
            dateEnd.setSeconds(0);
            dateEnd.setMilliseconds(0);
    
            const where = {
                fecha_elaboracion: {
                    $gte: {
                        __type: 'Date',
                        iso: dateStart.toISOString()
                    },
                    $lte: {
                        __type: 'Date',
                        iso: dateEnd.toISOString()
                    }
                },
                Sucursal: {
                    __type: 'Pointer',
                    className: 'Sucursales',
                    objectId: this.state.Sucursal
                },
                // status: {$ne: 'baja'}
            };
            const params = new URLSearchParams({
                
                limit: 1000,
                where: JSON.stringify(where),
                count: 1,
                order: this.order,
                include: this.include,
                keys: 'montos' +
                ',Pagos' +
                ',PagosRealizados' +
                ',Grupo.objectId'
            });
            
            Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                const result = _.map(res.results, item => {
                    return {
                        grupo: item.Grupo.objectId,
                        monto: this.setAuthorizedAmount(item)
                    }
                })
                resolve(result);
            })
            .catch(() => {
                reject(0);
            })
        })
    }

    setTotals = () => {
        setTimeout(() => {
            const totalDayEstimate = this.setTotalSection('dayEstimate', 'monto_pago');
            const totalDisbursementDeposit = this.setTotalSection('disbursementDeposit', 'monto');
            const totalPrepaid = this.setTotalSection('prepaid', 'monto_pago');
            const totalDayRecovery = this.setTotalSection('dayRecovery', 'monto_pago');
            const totalPendingPayments = this.setTotalSection('pendingPayments', 'monto_pago');
            const totalDepositsToAccount = this.setTotalSection('depositsToAccount', 'monto');
            const totalExtraCosts = this.setTotalSection('extraCosts', 'monto');
            
            const total = totalDayEstimate - this.state.totalDisbursements + totalDisbursementDeposit + totalPrepaid + totalDayRecovery - totalPendingPayments - totalDepositsToAccount - totalExtraCosts;

            this.setState({
                totalDayEstimate: totalDayEstimate,
                totalDisbursementDeposit: totalDisbursementDeposit,
                totalPrepaid: totalPrepaid,
                totalDayRecovery: totalDayRecovery,
                totalPendingPayments: totalPendingPayments,
                totalDepositsToAccount: totalDepositsToAccount,
                totalExtraCosts: totalExtraCosts,
                total: total
            })
        }, 500);
    }

    setTotalSection = (keyColl, key) => {
        let total = 0;
        for (let index = 0; index < this.state[keyColl].length; index++) {
            const element = this.state[keyColl][index];
            total += element[key];
        }
        
        return total;
    }

    getPayments = () => {
        const dateStart = new Date(this.state.fecha_consulta);
        dateStart.setHours(0);
        dateStart.setMinutes(0);
        dateStart.setSeconds(0);
        dateStart.setMilliseconds(0);

        const dateEnd = new Date(this.state.fecha_consulta);
        dateEnd.setHours(24);
        dateEnd.setMinutes(0);
        dateEnd.setSeconds(0);
        dateEnd.setMilliseconds(0);

        const where = {
            pagado_al: {
                $gte: {
                    __type: 'Date',
                    iso: dateStart.toISOString()
                },
                $lte: {
                    __type: 'Date',
                    iso: dateEnd.toISOString()
                }
            },
            Solicitud: {
                $inQuery: {
                    where: {
                        Sucursal: {
                            __type: 'Pointer',
                            className: 'Sucursales',
                            objectId: this.state.Sucursal.objectId
                        },
                    },
                    className: 'Solicitudes'
                }
            },
            // status: {$ne: 'baja'}
        };

        const params = new URLSearchParams({
            where: JSON.stringify(where),
            limit: 1000,
        });

        Rest.peticion(
            `${config.api_url}/classes/SolicitudesPagosRealizados?${params.toString()}`,
            {
                method: "GET",
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                return res;
            })
            .then(res => {
                const total_pagos_realizados = _.reduce(res.results, (memo, item) => {
                    return memo + item.monto_pagado;
                }, 0);
                this.setState({
                    total_pagos_realizados
                })
            })
    }

    setLastRequisition = (group) => {
        let result = _.max(group, (item) => {
            return new Date(item.fecha_elaboracion.iso).getTime()
        })
        return result;
    }

    /**
     * 
     * @param {*} requisitions 
     * @returns regreas un arreglo de solicitues
     * Verifica que la solicitud tenga una renovación y la descarta
     */
    deprecated_validateRenovacion = (requisitions) => {
        let result = [];
        const fecha_consulta = formatString.setLegibleDate(this.state.fecha_consulta.toISOString());
        // iterar sobre todas las solicitudes
        for (let i = 0; i < requisitions.length; i++) {
            const requisition = requisitions[i];
            let index = 0;
            /**
             * Cada item de solicitud contiene el grupo
             * y cada grupo sus solicitudes.
             * Encontrar donde el item actual coincida
             * con el array de solicitudes del grupo.
             */
            do {
                const item = requisition.Grupo.Solicitudes[index];
                /**
                 * Al econtrar donde las solicitudes coinciden,
                 * averiguar si tiene una renovación. Se comprueba
                 * obteniendo la solicitud posicionada adelante de 
                 * la encontrada.
                 */
                if (item.objectId === requisition.objectId) {
                    const renovacion = requisition.Grupo.Solicitudes[index +1];
                    if (renovacion) {
                        /**
                         * si existe, comprobar si la fecha de elaboración y la
                         * fecha de busqueda son iguales, en tal caso descartar
                         * la solicitud actual.
                         */
                        const renovacion_fecha_elaboracion = formatString.setLegibleDate(renovacion.fecha_elaboracion.iso)
                        if (!(renovacion_fecha_elaboracion === fecha_consulta)) {
                            result.push(requisition);
                            index = requisition.Grupo.Solicitudes.length;
                        }
                    } else {
                        result.push(requisition);
                    }
                }
                index++;
            } while (index < requisition.Grupo.Solicitudes.length);
        }
        return result;
    }

    validateRenovacion = (requisitions) => {
        const fecha_consulta = new Date(this.state.fecha_consulta);
        fecha_consulta.setHours(24);
        fecha_consulta.setMinutes(0);
        fecha_consulta.setSeconds(0);
        fecha_consulta.setMilliseconds(0);
        let timestamp_fecha_consulta = fecha_consulta.getTime();
        let result = [];

        // itetar sobre las solicitudes
        for (let index = 0; index < requisitions.length; index++) {
            
            const requisition = requisitions[index];
            
            const totals = {
                monto_pago: 0,
                monto_pagado: 0,
                pagos_adelantados: 0,
                fecha_pago: '',
                pagado_al: ''
            };
            
            // crear un array de 1 nivel de los pagos de la
            // solicitud en turno
            const numeros_pagos = _.groupBy(requisition.Pagos, 'numero_pago');
            const pago_recurrente = _.reduce(numeros_pagos[1], function(memo, item){ return memo + item.monto; }, 0);
            const pagos = _.map(numeros_pagos, (arr, numero_pago) => {
                return {
                    monto: _.reduce(arr, function(memo, item){ return memo + item.monto; }, 0),
                    numero_pago: parseInt(numero_pago),
                    fecha_pago: arr[0].fecha_pago,
                    // monto_pagado_x: 0
                }
            })

            // if (requisition.objectId === "cKkTkJAnvq") {
            //     console.log(`${requisition.Grupo.nombre} --> `, pagos);
            // }

            _.map(pagos, pago => {
                const timestamp_fecha_pago = new Date(pago.fecha_pago.iso).getTime();
                if (timestamp_fecha_pago <= timestamp_fecha_consulta) {
                    // console.log('pago --> ', pago);
                    totals.monto_pago += pago.monto;
                    // totals.numero_pago_actual = parseInt(pago.numero_pago)
                    // totals.fecha_pago = fecha_consulta.toISOString();
                }
            });

            // if (requisition.objectId === "cKkTkJAnvq") {
            //     console.log('requisition.PagosRealizados --> ', requisition.PagosRealizados);
            // }

            _.map(requisition.PagosRealizados, pagoRealizado => {
                const timestamp_pagado_al = new Date(pagoRealizado.pagado_al.iso).getTime();
                if (timestamp_pagado_al >= (timestamp_fecha_consulta - 1000*60*60*24*1) && timestamp_pagado_al <= timestamp_fecha_consulta) {
                    if (pagos[pagoRealizado.SolicitudPago.numero_pago - 1]) {
                        // pagos[pagoRealizado.SolicitudPago.numero_pago - 1].monto_pagado_x += pagoRealizado.monto_pagado;
                        totals.monto_pagado += pagoRealizado.monto_pagado;
                    }
                    // totals.pagos_adelantados = pagoRealizado.pagos_adelantados;
                    totals.pagado_al = pagoRealizado.pagado_al.iso;
                }
            });

            // if (requisition.objectId === "cKkTkJAnvq") {
            //     console.log('pagos --> ', pagos);
            // }

            // if (requisition.objectId === "cKkTkJAnvq") {
            //     console.log('totals --> ', totals);
            // }
            
            // totals.grupo = requisition.Grupo.nombre;
            /**
             * si no hay información en pagado_al, se da por sentado que falta el pago y se lista el grupo en pantalla
             * Verificar si la fecha pagado_al es menor a la establecida en el filtro.
             */
            if (
                !_.isEmpty(totals.pagado_al) 
                // || (
                    // (new Date(totals.pagado_al).getTime() <= timestamp_fecha_consulta) &&
                    // (
                        // (totals.monto_pagado < totals.monto_pago) ||
                        // (totals.monto_pagado > totals.monto_pago) ||
                        // (totals.monto_pagado === totals.monto_pago && totals.pagos_adelantados === 0)
                    // )
                // )
            ) {
                /**
                 * obtener el pago parcial, esta cantidad se restará del monto actual requerido.
                 * se calcula validando si el pago fue efectuado en la fecha anterior a la actual.
                 */
                // if (new Date(totals.pagado_al).getTime() < (timestamp_fecha_consulta - 1000*60*60*24*1)) {
                        const monto_pagado_parcial = pago_recurrente - totals.monto_pagado;
                        // console.log('monto_pagado_parcial --> ', monto_pagado_parcial);
                        requisition.monto_pagado_parcial = monto_pagado_parcial > 0 ? monto_pagado_parcial : 0;
                // }
            }
            
            result.push(requisition);
        }
        return result;
    }

    setUniqueRequisitions = async (requisitions) => {
        

            let groups = _.groupBy(requisitions, item => {
                return item.Grupo.objectId
            });
            
            let newRequisitions = _.map(groups, group => {
                if (group.length > 1) {
                    return this.setLastRequisition(group);
                }
                return group[0];
            });
    
            newRequisitions = this.validateRenovacion(newRequisitions);
    
            newRequisitions = await this.getAmounts(newRequisitions);
    
            return newRequisitions;
        
    }

    getAmounts = (groups) => {
        return new Promise(async (resolve) => {

            const dateStart = new Date(this.state.fecha_consulta);
            dateStart.setHours(0);
            dateStart.setMinutes(0);
            dateStart.setSeconds(0);
            dateStart.setMilliseconds(0);
            const dateStartTime = dateStart.getTime();
    
            const dateEnd = new Date(this.state.fecha_consulta);
            dateEnd.setHours(24);
            dateEnd.setMinutes(0);
            dateEnd.setSeconds(0);
            dateEnd.setMilliseconds(0);
            const dateEndTime = dateEnd.getTime();
    
            for (let indexG = 0; indexG < groups.length; indexG++) {
                const group = groups[indexG];
                
                group.monto = _.reduce(group.Pagos, (memo, pago) => {
                    const fecha_pago = new Date(pago.fecha_pago.iso).getTime();
                    if (fecha_pago >= dateStartTime && fecha_pago <= dateEndTime) {
                        return memo + pago.monto;
                    } else {
                        return memo;
                    }
                }, 0);
    
                /**
                 * Si existe pagos adelantados entonces se valida 
                 * para saber cómo representarlos.
                 * Si el monto es menor que cero quiere decir 
                 * Si el monto es menor que cero quiere decir 
             * Si el monto es menor que cero quiere decir 
                 * Si el monto es menor que cero quiere decir 
                 * Si el monto es menor que cero quiere decir
             * Si el monto es menor que cero quiere decir 
                 * Si el monto es menor que cero quiere decir 
                 * que el monto a pagar a la fecha ya fue cubierto,
                 * por tanto se mostrará 0 en el monto a pagar.
                 * De lo contrario se mostrará el residuo de la 
                 * operación aritmética, monto correspondiente a 
                 * pagar menos el pago adelantado
                 */
                if (group.monto_pagado_parcial) {
                    const monto = group.monto - group.monto_pagado_parcial
                    if (monto < 0) {
                        group.monto = 0;
                    } else {
                        group.monto = monto;
    
                    }
                    group.adelantado = true;
                }
    
                
                if (group.montos) {

                    group.pagos_adelantados = 0;
                    const pagosRealizados = await this.getPrepaidDirectSolicitudesPagosRealizados(group.objectId);

                    for (let index = 0; index < pagosRealizados.length; index++) {
                        group.pagos_adelantados += pagosRealizados[index].pagos_adelantados;
                    }

                    group.pagos_adelantados = Math.floor(group.pagos_adelantados/group.montos.length) || 0;
                    
                }
            }

            resolve(groups)
        })
    }

    setDayEstimateGroupName = (objectId) => {
        const group = _.findWhere(this.state.groups, {objectId: objectId});
        if (group) {
            return group.nombre;
        } else {
            return '';
        }
    }

    getDayEstimate = () => {
        
        return new Promise((resolve, reject) => {

            const dateStart = new Date(this.state.fecha_consulta);
            dateStart.setHours(0);
            dateStart.setMinutes(0);
            dateStart.setSeconds(0);
            dateStart.setMilliseconds(0);
            
            const dateEnd = new Date(this.state.fecha_consulta);
            dateEnd.setHours(24);
            dateEnd.setMinutes(0);
            dateEnd.setSeconds(0);
            dateEnd.setMilliseconds(0);
      
            const where = {
                Pagos: {
                    $inQuery: {
                        where: {
                            fecha_pago: {
                                $gte: {
                                    __type: 'Date',
                                    iso: dateStart.toISOString()
                                },
                                $lte: {
                                    __type: 'Date',
                                    iso: dateEnd.toISOString()
                                }
                            }
                        },
                        className: 'SolicitudesPagos'
                    }
                },
                Sucursal: {
                    __type: 'Pointer',
                    className: 'Sucursales',
                    objectId: this.state.Sucursal
                },
            };
            
            const include =   'Grupo,' +
                              'Grupo.Solicitudes,' +
                              'Sucursal,' +
                              'promotor,' +
                              'montos,' +
                              'Pagos,' +
                              'PagosRealizados,' +
                              'PagosRealizados.SolicitudPago,' +
                              'PagosRealizados.registrado_por';
            const keys =  'Grupo.nombre,' +
                          'Grupo.Solicitudes.fecha_elaboracion,' +
                          'Sucursal,' +
                        //   'ciclo,' +
                          'Pagos.monto,' +
                          'Pagos.fecha_pago,' +
                          'Pagos.numero_pago,' +
                          'PagosRealizados.monto_pagado,' +
                          'PagosRealizados.pagado_al,' +
                          'PagosRealizados.SolicitudPago,' +
                          'PagosRealizados.SolicitudPago.numero_pago,' +
                          'PagosRealizados.pagos_adelantados,' +
                          'PagosRealizados.pagos_adelantados,' +
                          'fecha_elaboracion';
            const order = 'fecha_elaboracion';
            
            const params = new URLSearchParams({
                
                limit: 1000,
                where: JSON.stringify(where),
                count: 1,
                order: order,
                include: include,
                keys: keys
            });
            
            Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                return res;
            })
            .then(async res => {
                let result = _.sortBy(res.results, item => {
                    return item.Grupo.nombre
                });
                result = this.setUniqueRequisitions(result);
                
                resolve(result);
            })
            .catch(error => {
                reject(error)
            })
        });
    
      
    }

    getPrepaidDirectSolicitudesPagosRealizados = (solicitudId) => {
        
        return new Promise((resolve, reject) => {

            const dateStart = new Date(this.state.fecha_consulta);
            dateStart.setHours(0);
            dateStart.setMinutes(0);
            dateStart.setSeconds(0);
            dateStart.setMilliseconds(0);
            
            const dateEnd = new Date(this.state.fecha_consulta);
            dateEnd.setHours(24);
            dateEnd.setMinutes(0);
            dateEnd.setSeconds(0);
            dateEnd.setMilliseconds(0);
      
            const where = {
                pagado_al: {
                    $gte: {
                        __type: 'Date',
                        iso: dateStart.toISOString()
                    },
                    $lte: {
                        __type: 'Date',
                        iso: dateEnd.toISOString()
                    }
                },
                Solicitud: {
                    __type: 'Pointer',
                    className: 'Solicitudes',
                    objectId: solicitudId
                },
            };
            
            
            
            const params = new URLSearchParams({
                
                limit: 1000,
                where: JSON.stringify(where),
                count: 1,
            });
            
            Rest.peticion(`${config.api_url}/classes/SolicitudesPagosRealizados?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                return res;
            })
            .then(res => {
                resolve(res.results);
            })
            .catch(error => {
                reject(error)
            })
        });
    
      
    }

    getPrepaid = () => {
        
        return new Promise((resolve, reject) => {

            const dateStart = new Date(this.state.fecha_consulta);
            dateStart.setHours(0);
            dateStart.setMinutes(0);
            dateStart.setSeconds(0);
            dateStart.setMilliseconds(0);
            
            const dateEnd = new Date(this.state.fecha_consulta);
            dateEnd.setHours(24);
            dateEnd.setMinutes(0);
            dateEnd.setSeconds(0);
            dateEnd.setMilliseconds(0);
      
            const where = {
                PagosRealizados: {
                    $inQuery: {
                        where: {
                            pagado_al: {
                                $gte: {
                                    __type: 'Date',
                                    iso: dateStart.toISOString()
                                },
                                $lte: {
                                    __type: 'Date',
                                    iso: dateEnd.toISOString()
                                }
                            },
                            pagos_adelantados: {
                                $gte: 1
                            }
                        },
                        className: 'SolicitudesPagosRealizados'
                    }
                },
                Sucursal: {
                    __type: 'Pointer',
                    className: 'Sucursales',
                    objectId: this.state.Sucursal
                },
            };
            
            const include =   'Grupo,' +
                              'Grupo.Solicitudes,' +
                              'Sucursal,' +
                              'promotor,' +
                              'Pagos,' +
                              'PagosRealizados,' +
                              'PagosRealizados.registrado_por';
            const keys =  'Grupo.nombre,' +
                          'Grupo.Solicitudes.fecha_elaboracion,' +
                          'Sucursal,' +
                          'montos,' +
                          'Pagos.monto,' +
                          'Pagos.fecha_pago,' +
                          'Pagos.numero_pago,' +
                          'PagosRealizados.monto_pagado,' +
                          'PagosRealizados.pagado_al,' +
                          'PagosRealizados.SolicitudPago,' +
                          'PagosRealizados.pagos_adelantados,' +
                          'PagosRealizados.pagos_adelantados,' +
                          'fecha_elaboracion';
            const order = 'fecha_elaboracion';
            
            const params = new URLSearchParams({
                
                limit: 1000,
                where: JSON.stringify(where),
                count: 1,
                order: order,
                include: include,
                keys: keys
            });
            
            Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                return res;
            })
            .then(async res => {
                let result = _.sortBy(res.results, item => {
                    return item.Grupo.nombre
                });


                result = this.setUniqueRequisitions(result);
                resolve(result);
            })
            .catch(error => {
                reject(error)
            })
        });
    
      
    }

    getResources = async () => {
        

        return new Promise((resolve, reject) => {
            const dateStart = new Date(this.state.fecha_consulta);
            dateStart.setHours(0);
            dateStart.setMinutes(0);
            dateStart.setSeconds(0);
            dateStart.setMilliseconds(0);
            
            const dateEnd = new Date(this.state.fecha_consulta);
            dateEnd.setHours(24);
            dateEnd.setMinutes(0);
            dateEnd.setSeconds(0);
            dateEnd.setMilliseconds(0);
    
            const where = {
                Pagos: {
                    $inQuery: {
                        where: {
                            fecha_pago: {
                                $gte: {
                                    __type: 'Date',
                                    iso: dateStart.toISOString()
                                },
                                $lte: {
                                    __type: 'Date',
                                    iso: dateEnd.toISOString()
                                }
                            }
                        },
                        className: 'SolicitudesPagos'
                    }
                },
                Sucursal: {
                    __type: 'Pointer',
                    className: 'Sucursales',
                    objectId: this.state.Sucursal
                },
                // status: {$ne: 'baja'}
            };
    
            const params = new URLSearchParams({
                
                limit: 1000,
                where: JSON.stringify(where),
                count: 1,
                order: this.order,
                include: this.include,
                keys: this.keys
            });
            
            Rest.peticion(`${config.api_url}/classes/Solicitudes?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                return res;
            })
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                reject(error)
                
            })
        })
    }

    setGroupPointer = (coll) => {
        const result = _.filter(coll, item => {
            if (!_.isEmpty(item.grupo)) {
                item.grupo = {
                    __type: 'Pointer',
                    className: 'Grupos',
                    objectId: item.grupo
                }
                return item;
            }
        })
        if (_.isEmpty(result)) {
            return []
        }
        return result;
    }

    handleSaveReport = (event) => {

        event.preventDefault();

        if (_.isEmpty(this.state.fecha_consulta) && _.isEmpty(this.state.Sucursal)) {
            alert('Debe realizar un filtrado de datos. Vaya a la parte superior.');
            return;
        }
        
        const {
            fecha_consulta,
            fecha_publicacion,
            Sucursal,
            hecho_por,
            dayEstimate,
            disbursementDeposit,
            prepaid,
            dayRecovery,
            requisitions,
            pendingPayments,
            depositsToAccount,
            extraCosts,
            totalDayEstimate,
            totalDisbursementDeposit,
            totalPrepaid,
            totalDayRecovery,
            totalDisbursements,
            totalPendingPayments,
            totalDepositsToAccount,
            totalExtraCosts,
            total
        } = this.state;

        const data = {
            fecha_consulta: {
                __type: 'Date',
                iso: fecha_consulta.toISOString()
            },
            fecha_publicacion: {
                __type: 'Date',
                iso: fecha_publicacion.toISOString()
            },
            Sucursal: {
                __type: 'Pointer',
                className: 'Sucursales',
                objectId: Sucursal
            },
            hecho_por: {
                __type: 'Pointer',
                className: '_User',
                objectId: hecho_por
            },
            estimacion_del_dia: this.setGroupPointer(dayEstimate),
            depositos_para_desembolsos: disbursementDeposit,
            pagos_adelantados: this.setGroupPointer(prepaid),
            recuperacion_del_dia: this.setGroupPointer(dayRecovery),
            // desembolsos: this.setRequisitionPointer(requisitions),
            pagos_pendientes: this.setGroupPointer(pendingPayments),
            depositos_a_cuenta: depositsToAccount,
            gastos_extras: extraCosts,
            total_estimacion_del_dia: totalDayEstimate,
            total_depositos_para_desembolsos: totalDisbursementDeposit,
            total_pagos_adelantados: totalPrepaid,
            total_recuperacion_del_dia: totalDayRecovery,
            total_desembolsos: totalDisbursements,
            total_pagos_pendientes: totalPendingPayments,
            total_depositos_a_cuenta: totalDepositsToAccount,
            total_gastos_extras: totalExtraCosts,
            sobrante_del_dia: total,
        };

        this.setState({
            loading: true
        })

        Rest.peticion(`${config.api_url}/classes/ReporteBitacora`, {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (!res.code) {
                alert('Reporte guardado exitosamente');
                this.resetState()
            }
        })
        .catch(error => {
            console.log(error);
            alert('No fue posible guardar el reporte, intente más tarde')
        })
        .finally(() => {
            this.setState({
                loading: false
            })
        })
        
    }

    handleFilterForm = async (event) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            loading: true
        })
        await this.getResources().then(async (res) => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                const disbursements = await this.getDisbursementsAt();
                const totalDisbursements = _.reduce(disbursements, function(memo, item){ return memo + item.monto; }, 0);
                const resultDayEstimate = await this.getDayEstimate();
                let prepaid = await this.getPrepaid();
                const totalDayEstimate = _.reduce(resultDayEstimate, (memo, pago) => {
                    return memo + pago.monto;
                }, 0);

                const dayEstimate = _.map(resultDayEstimate, item => {
                    return {
                        grupo: item.Grupo.objectId,
                        monto_pago: item.monto,
                        adelantado: item.adelantado
                    }
                });

                prepaid = _.map(prepaid, item => {
                    return {
                        solicitudId: item.objectId,
                        grupo: item.Grupo.objectId,
                        monto_pago: item.monto,
                        pagos_adelantados: item.pagos_adelantados,
                    }
                })
                
                this.setState({
                    dayEstimate,
                    totalDayEstimate,
                    totalDisbursements,
                    disbursements,
                    prepaid
                });

                this.setTotals();
                return await this.getGroups();
            }
        })
        .then((groups) => {
            this.setState({
                groupsDebit: groups[0].results,
                groups: _.sortBy(groups[1].results, 'nombre')
            })
        })
        .catch((error) => {
            console.log(error);
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    /**
     * 
     * @param {*} props datos del grupo seleccionado
     * Obtiene la suma total del pago recurrente del grupo seleccionado.
     */
    setAmount = (props) => {
        const group = _.findWhere(props.items, {objectId: props.value});
        // console.log('props --> ', props);
        // console.log('group --> ', group);
        if (group && props.solicitudId) {
            const lastRequisition = _.findWhere(group.Solicitudes, {objectId: props.solicitudId});
            if (group.listed !== true) {
                group.listed = true;
                group.monto_pago = 'loading';
    
                const params = new URLSearchParams({
                    include: 'Pagos',
                    keys: 'Pagos.monto,Pagos.numero_pago'
                });
    
                Rest.peticion(`${config.api_url}/classes/Solicitudes/${lastRequisition.objectId}?${params.toString()}`, {
                    method: 'GET'
                })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    return res;
                })
                .then(res => {
                    if (res) {
                        const Pagos = res.Pagos;
                        let total = 0;
                        for (let index = 0; index < Pagos.length; index++) {
                            if (Pagos[index].numero_pago === 1) {
                                total += Pagos[index].monto;
                            }
                        }
                        group.monto_pago = new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(total);
                        this.setState({groups: props.items});
                    } else {
                        group.monto_pago = '$0.00';
                        group.pagos_adelantados = 44;
                        this.setState({groups: props.items});
                    }
                })
            }
        }
    }

    render() {
        
        return (
            <>
                <Spinner loading={this.state.loading} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-file-contract mr-2"></i>
                    Nuevo Arqueo
                </h3>
                <div className="card">
                    <div className="card-body">
                        <div className="card mb-4">
                            <form className="card-body" onSubmit={this.handleFilterForm} autocomplete="off">
                                <div className="row align-items-end">
                                    <div className="col-md-4">
                                        <div className="form-group mb-0">
                                            <label htmlFor="fecha_consulta">Filtrar datos al</label>
                                            <DatePicker className="form-control" wrapperClassName="w-100" id="fecha_consulta" name="fecha_consulta" selected={this.state.fecha_consulta} locale="es" showDisabledMonthNavigation dateFormat="dd/MM/yyyy" shouldCloseOnSelect={true} onChange={date => this.handleChangeDate(date, 'fecha_consulta')} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group mb-0">
                                            <label htmlFor="Sucursal">Sucursal</label>
                                            <SelectBranchOffice id="Sucursal" name="Sucursal"  value={this.state.Sucursal} onChange={this.handleChange} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <button type="submit" className="btn btn-primary btn-block">Filtrar</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <form onSubmit={this.handleSaveReport} autocomplete="off">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row minheightcard align-items-center">
                                        <div className="col-md-4">
                                            <b className="text-primary">
                                                Recuperación del día
                                            </b>
                                        </div>
                                        <div className="col-md-8">
                                            {this.setDropdownTotal({data: _.map(this.state.dayEstimate, item => {item.monto = item.monto_pago; return item}), total: this.state.totalDayEstimate})}
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="row minheightcard">
                                        <div className="col-md-4">
                                            <b className="text-primary">
                                                Depósitos para desembolso
                                            </b>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                this.state.disbursementDeposit.map((item, index) => {return this.htmlDisbursementDeposit(item, index, index, "disbursementDeposit")})
                                            }
                                            <br/>
                                            {this.htmlTotal({value:this.state.totalDisbursementDeposit, className:"float-right mr-4"})}
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="row minheightcard">
                                        <div className="col-md-4">
                                            <b className="text-primary">
                                                Pagos adelantados
                                            </b>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                this.state.prepaid.map((item, index) => {return this.htmlPrepaid({data:item, key:index, index:index, keyCollection:"prepaid"})})
                                            }
                                            <br/>
                                            {this.htmlTotal({value:this.state.totalPrepaid, className:"float-right mr-4"})}
                                        </div>
                                    </div>
                                    
                                    <hr/>
                                    
                                    <div className="row minheightcard">
                                        <div className="col-md-4">
                                            <b className="text-primary">
                                                Recuperación de mora
                                            </b>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                this.state.dayRecovery.map((item, index) => {return this.htmlDayRecovery({data:item, key:index, index:index, keyCollection:"dayRecovery"})})
                                            }
                                            <br/>
                                            {this.htmlTotal({value:this.state.totalDayRecovery, className:"float-right mr-4"})}
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="row minheightcard align-items-center">
                                        <div className="col-md-4">
                                            <b className="text-primary">
                                                Desembolsos
                                            </b>
                                        </div>
                                        <div className="col-md-8 d-flex justify-content-end">
                                            {this.setDropdownTotal({data: this.state.disbursements, total: this.state.totalDisbursements})}
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="row minheightcard">
                                        <div className="col-md-4">
                                            <b className="text-primary">
                                                Pendientes del día
                                            </b>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                this.state.pendingPayments.map((item, index) => {return this.htmlGroups({data:item, key:index, index:index, keyCollection:"pendingPayments"})})
                                            }
                                            <br/>
                                            {this.htmlTotal({value:this.state.totalPendingPayments, className:"float-right mr-4"})}
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="row minheightcard">
                                        <div className="col-md-4">
                                            <b className="text-primary">
                                                Depósitos a cuenta
                                            </b>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                this.state.depositsToAccount.map((item, index) => {return this.htmlDepositsToAccount({data:item, key:index, index:index, keyCollection:"depositsToAccount"})})
                                            }
                                            <br/>
                                            {this.htmlTotal({value:this.state.totalDepositsToAccount, className:"float-right mr-4"})}
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="row minheightcard">
                                        <div className="col-md-4">
                                            <b className="text-primary">
                                                Gastos extras
                                            </b>
                                        </div>
                                        <div className="col-md-8">
                                            {
                                                this.state.extraCosts.map((item, index) => {return this.htmlExtraCosts({data:item, key:index, index:index, keyCollection:"extraCosts"})})
                                            }
                                            <br/>
                                            {this.htmlTotal({value:this.state.totalExtraCosts, className:"float-right mr-4"})}
                                        </div>
                                    </div>
                                    
                                    <hr/>
                                    
                                    <br/>
                                    <div className="row minheightcard align-items-center">
                                        <div className="col-md-4">
                                            <b className="text-primary">
                                                Total
                                            </b>
                                        </div>
                                        <div className="col-md-8 d-flex justify-content-end">
                                            {this.htmlTotal({value:this.state.total, className:"mr-4"})}
                                        </div>
                                    </div>
                                    <br/>
                                </div>

                            </div>
                            
                            <div className="card border-0">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="fecha_publicacion">Fecha de publicación</label>
                                                <DatePicker className="form-control" wrapperClassName="w-100" id="fecha_publicacion" name="fecha_publicacion" selected={this.state.fecha_publicacion} locale="es" showDisabledMonthNavigation dateFormat="dd/MM/yyyy" shouldCloseOnSelect={true} onChange={date => this.handleChangeDate(date, 'fecha_publicacion')} required/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label htmlFor="hecho_por">Hecho por</label>
                                                <SelectEmpleyee id="hecho_por" name="hecho_por"  value={this.state.hecho_por} onChange={this.handleChange} required/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <br/><br/>

                                    {this.htmlFormButton()}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="p-5"></div>
            </>
        );
    }

    htmlFormButton = () => <div className="row">
    <div className="col-md-4">
        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
    </div>
        <div className="col-md-4">
            <button type="button" className="btn btn-secondary btn-block" onClick={this.resetState}>Reset</button>
        </div>
    </div>

    htmlTotal = (props) => (<div className={`d-flex flex-column align-items-end ${props.className}`}>
        <span>Total</span>
        <b>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(props.value)}</b>
    </div>)

    setDropdownTotal = (props) => {
        return <div className="btn-group float-right dropleft">

            <label className={`m-0 ${props.total === 0 && 'disabled'}`} data-toggle="dropdown" data-display="static" aria-expanded="false" style={{cursor:'pointer'}}>
                {props.total > 0 && <i className="fas fa-eye text-primary mr-2" />}
                {this.htmlTotal({value:props.total, className:"float-right mr-4"})}
            </label>
            <div className="dropdown-menu p-0 border-0 shadow-lg pre-scrollable mr-2" style={{width: 350}}>
                <table className="table m-0">
                    <thead>
                        <tr>
                            <th className="border-top-0">#</th>
                            <th className="border-top-0">Grupo</th>
                            <th className="border-top-0 text-right">Monto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        (!!this.state.groups && this.state.groups.length > 0) && props.data.map((item, index) => 
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>
                                {this.setDayEstimateGroupName(item.grupo)}<br/>
                                {item.adelantado === true && <small className="text-muted"><i>Adelantado en una fecha anterior</i></small>}
                            </td>
                            <td className="text-right">
                                {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(item.monto)}
                            </td>
                        </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    }

    htmlAddRemoveTopDowmBtns = (props) => {
        return (
            <div>
                <div className="btn-toolbar mr-2" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group-vertical" role="group" aria-label="Add/Remove">
                        <button type="button" className="btn btn-secondary" onClick={() => this.addItem(props.keyCollection, props.index)}>
                            <i className="fas fa-plus"></i>
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.removeItem(props.keyCollection, props.index)}>
                            <i className="fas fa-minus"></i>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    htmlSelectGroups = (props) => {
        return (
            <>
                <label htmlFor="grupo">Grupo</label>
                <select className="form-control" name="grupo" value={props.value}  onChange={props.onChange}>
                    <option value="">Grupos...</option>
                    {props.items.map((item, index) => <option key={index} value={item.objectId}>{item.nombre}</option>)}
                </select>
            </>
        )
    }

    htmlSelectGroupsWithPayment = (props) => {
        !_.isEmpty(props.value) && this.setAmount(props);
        const group = _.findWhere(props.items, {objectId: props.value});
        return (
            <div className="row">
                <div className="col-6">
                    <label htmlFor="grupo">Grupo</label>
                    <select className="form-control" name="grupo" value={props.value}  onChange={props.onChange}>
                        <option value="">Grupos...</option>
                        {props.items.map((item, index) => <option key={index} value={item.objectId}>{item.nombre}</option>)}
                    </select>
                </div>
                <div className="col-6">
                    <div>
                        <label htmlFor="grupo">
                            Pagos semanal
                        </label>
                        &nbsp;
                        <Popover title="Pagos semanales" content="Representa la cantidad que el grupo paga semanalmente."/>
                    </div>
                    <big>
                        <small className="p-1 bg-secondary rounded">
                            <b>
                                {
                                    (((!_.isEmpty(props.value) && group) && group.monto_pago === 'loading') && 'calculando...') ||
                                    (group === undefined && '$0.00') ||
                                    ((!_.isEmpty(props.value) && group) && group.monto_pago)
                                }
                            </b>
                        </small>
                    </big>
                </div>
            </div>
        )
    }

    htmlDayEstimate = (props) => {
        return (
            <div className="mb-4 d-flex" key={props.key}>
                {this.htmlAddRemoveTopDowmBtns({keyCollection:props.keyCollection, index:props.index})}
                <div className="card bg-light w-100">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    {this.htmlSelectGroups({value:props.data.grupo, items:this.state.groups, onChange:(event) => this.handleChangeInputs(props.keyCollection, 'grupo', event.target.value, props.index)})}
                                </div>
                                
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="monto_pago">Monto pago</label>
                                    <input type="number" min="0" step="any" className="form-control" name="monto_pago" value={props.data.monto_pago} onChange={(event) => this.handleChangeInputs(props.keyCollection, 'monto_pago', ((event.target.value && parseFloat(event.target.value)) || 0), props.index)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    htmlDisbursementDeposit = (data, key, index, keyCollection) => {
        return (
            <div className="mb-4 d-flex" key={key}>
                {this.htmlAddRemoveTopDowmBtns({keyCollection:keyCollection, index:index})}
                <div className="card bg-light w-100">
                    <div className="card-body">
                        <div className="form-group">
                            <label htmlFor="nombre_destinatario">Nombre del destinatario</label>
                            <input type="text" className="form-control" name="nombre_destinatario" value={data.nombre_destinatario}  onChange={(event) => this.handleChangeInputs(keyCollection, 'nombre_destinatario', event.target.value, index)}/>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="banco">Banco</label>
                                    <input type="text" className="form-control" name="banco" value={data.banco}  onChange={(event) => this.handleChangeInputs(keyCollection, 'banco', event.target.value, index)}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="referencia_bancaria">Número de referencia bancaria</label>
                                    <input type="text" className="form-control" name="referencia_bancaria" value={data.referencia_bancaria}  onChange={(event) => this.handleChangeInputs(keyCollection, 'referencia_bancaria', event.target.value, index)}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="banco">Cuenta orgien</label>
                                    <InputSlectOtherOption
                                    name="cuenta_origen"
                                    value={data.cuenta_origen}
                                    defaultValue={this.state.bank_accounts}
                                    // required={true}
                                    onChange={(event) => this.handleChangeInputs(keyCollection, 'cuenta_origen', event.target.value, index)}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="monto">Monto</label>
                                    <input type="number" min="0" step="any" className="form-control" name="monto" value={data.monto} onChange={(event) => this.handleChangeInputs(keyCollection, 'monto', ((event.target.value && parseFloat(event.target.value)) || 0), index)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    htmlPrepaid = (props) => {
        return (
            <div className="mb-4 d-flex" key={props.key}>
                {this.htmlAddRemoveTopDowmBtns({keyCollection:props.keyCollection, index:props.index})}
                <div className="card bg-light w-100">
                    <div className="card-body">
                        <div className="form-group">
                            {this.htmlSelectGroupsWithPayment({
                                value:props.data.grupo,
                                items:this.state.groups,
                                onChange:(event) => this.handleChangeInputs(props.keyCollection, 'grupo', event.target.value, props.index),
                                index: props.index,
                                solicitudId: props.data.solicitudId
                            })}
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="pagos_adelantados">Pagos adelantados</label>
                                    <input type="text" className="form-control" name="pagos_adelantados" value={props.data.pagos_adelantados}  onChange={(event) => this.handleChangeInputs(props.keyCollection, 'pagos_adelantados', event.target.value, props.index)}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="monto_pago">Monto pago</label>
                                    <input type="number" min="0" step="any" className="form-control" name="monto_pago" value={props.data.monto_pago} onChange={(event) => this.handleChangeInputs(props.keyCollection, 'monto_pago', ((event.target.value && parseFloat(event.target.value)) || 0), props.index)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    htmlDayRecovery = (props) => {
        return (
            <div className="mb-4 d-flex" key={props.key}>
                {this.htmlAddRemoveTopDowmBtns({keyCollection:props.keyCollection, index:props.index})}
                <div className="card bg-light w-100">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    {this.htmlSelectGroupsWithPayment({
                                        value:props.data.grupo,
                                        items:this.state.groupsDebit,
                                        onChange:(event) => this.handleChangeInputs(props.keyCollection, 'grupo', event.target.value, props.index)
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="monto_pago">Monto pago</label>
                                    <input type="number" min="0" step="any" className="form-control" name="monto_pago" value={props.data.monto_pago} onChange={(event) => this.handleChangeInputs(props.keyCollection, 'monto_pago', ((event.target.value && parseFloat(event.target.value)) || 0), props.index)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    htmlGroups = (props) => {
        return (
            <div className="mb-4 d-flex" key={props.key}>
                {this.htmlAddRemoveTopDowmBtns({keyCollection:props.keyCollection, index:props.index})}
                <div className="card bg-light w-100">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    {this.htmlSelectGroupsWithPayment({
                                        value:props.data.grupo,
                                        items:this.state.groups,
                                        onChange:(event) => this.handleChangeInputs(props.keyCollection, 'grupo', event.target.value, props.index)
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="monto_pago">Monto pago</label>
                                    <input type="number" min="0" step="any" className="form-control" name="monto_pago" value={props.data.monto_pago} onChange={(event) => this.handleChangeInputs(props.keyCollection, 'monto_pago', ((event.target.value && parseFloat(event.target.value)) || 0), props.index)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    htmlDepositsToAccount = (props) => {
        return (
            <div className="mb-4 d-flex" key={props.key}>
                {this.htmlAddRemoveTopDowmBtns({keyCollection:props.keyCollection, index:props.index})}
                <div className="card bg-light w-100">
                    <div className="card-body">
                        <div className="form-group">
                            <label htmlFor="nombre_depositante">Nombre del depositante</label>
                            <input type="text" className="form-control" name="nombre_depositante" value={props.data.nombre_depositante}  onChange={(event) => this.handleChangeInputs(props.keyCollection, 'nombre_depositante', event.target.value, props.index)}/>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="concepto">Concepto</label>
                                    <input type="text" className="form-control" name="concepto" value={props.data.concepto}  onChange={(event) => this.handleChangeInputs(props.keyCollection, 'concepto', event.target.value, props.index)}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="monto">Monto</label>
                                    <input type="number" min="0" step="any" className="form-control" name="monto" value={props.data.monto} onChange={(event) => this.handleChangeInputs(props.keyCollection, 'monto', ((event.target.value && parseFloat(event.target.value)) || 0), props.index)}/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="banco_cuenta">Banco/Cuenta</label>
                                    <input type="text" className="form-control" name="banco_cuenta" value={props.data.banco_cuenta}  onChange={(event) => this.handleChangeInputs(props.keyCollection, 'banco_cuenta', event.target.value, props.index)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    htmlExtraCosts = (props) => {
        return (
            <div className="mb-4 d-flex" key={props.key}>
                {this.htmlAddRemoveTopDowmBtns({keyCollection:props.keyCollection, index:props.index})}
                <div className="card bg-light w-100">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="concepto">Concepto</label>
                                    <input type="text" className="form-control" name="concepto" value={props.data.concepto}  onChange={(event) => this.handleChangeInputs(props.keyCollection, 'concepto', event.target.value, props.index)}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="concepto">Factura</label>
                                    <input type="text" className="form-control" name="factura" value={props.data.factura}  onChange={(event) => this.handleChangeInputs(props.keyCollection, 'factura', event.target.value, props.index)}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="concepto">Emisor</label>
                                    <input type="text" className="form-control" name="emisor" value={props.data.emisor}  onChange={(event) => this.handleChangeInputs(props.keyCollection, 'emisor', event.target.value, props.index)}/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="monto">Monto</label>
                                    <input type="number" min="0" step="any" className="form-control" name="monto" value={props.data.monto} onChange={(event) => this.handleChangeInputs(props.keyCollection, 'monto', ((event.target.value && parseFloat(event.target.value)) || 0), props.index)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
   
}

export default ReportDay;
