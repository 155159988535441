import React, { Component } from 'react';

class InputFile extends Component {

    constructor (props) {
        super(props);

        this.state = {
            textoDelInput: 'Seleccionar archivo',
            esPDF: false,
            hayArchivo: false
        }

        this.handleChangeBuro = this.handleChangeBuro.bind(this);
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // para resetear el formulario
        if (nextProps.resetform === true) {
            document.getElementById("buro-archivo").value = "";
            this.props.nota.current.value = ""
            this.setState({
                textoDelInput: 'Seleccionar archivo',
                esPDF: false,
                hayArchivo: false
            });
        }
    }

    handleChangeBuro = () => {
        if (
            !(
                (this.props.fileInput.current.files[0].type === 'application/pdf') ||
                (this.props.fileInput.current.files[0].type === 'image/jpg') ||
                (this.props.fileInput.current.files[0].type === 'image/jpeg') ||
                (this.props.fileInput.current.files[0].type === 'image/png') ||
                (this.props.fileInput.current.files[0].type === 'image/gif')
            )
        ) {
            document.getElementById("buro-archivo").value = "";
            this.setState({
                textoDelInput: 'Seleccionar archivo',
                esPDF: false,
                hayArchivo: false
            });
            alert('El archivo no es valido. Intente con un archivo de tipo PDF, JPG, JPEG o PNG.');
        } else {
            
            if (this.props.fileInput.current.files[0].type === 'application/pdf') {
                this.setState({
                    textoDelInput: this.props.fileInput.current.files[0].name,
                    esPDF: true,
                    hayArchivo: true
                })
            } else {
                this.setState({
                    textoDelInput: this.props.fileInput.current.files[0].name,
                    esPDF: false,
                    hayArchivo: true
                })
            }
        }
    }

    render() {
        return (
            <>
                <label>Archivo</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="archivo-de-buro-addon">
                            <i className="fas fa-cloud-upload-alt"></i>
                        </span>
                    </div>
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="buro-archivo" aria-describedby="buro-archivo-addon" ref={this.props.fileInput} onChange={this.handleChangeBuro} required={this.props.required} />
                        <label className="custom-file-label" htmlFor="buro-archivo">{this.state.textoDelInput}</label>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="buro-nota">Nota {(!this.state.esPDF && this.state.hayArchivo) && <span>*</span>}</label>
                    <textarea className="form-control text-uppercase" id="buro-nota" rows="3" required={!this.state.esPDF && this.state.hayArchivo} ref={this.props.nota} disabled={!this.state.hayArchivo}></textarea>
                </div>
            </>
        );
    }
}

export default InputFile;