import React, { Component } from 'react';
import _ from 'underscore';
import Rest from './../../Server/Rest';
import config from './../../settings/config';

class PartnerStatusPaymentBadge extends Component {

    state = {
        status: ''
    }

    componentDidMount = () => {

        const params = new URLSearchParams({
            where: JSON.stringify({
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: this.props.partnerId
                },
                Solicitud: {
                    $inQuery: {
                        where: {
                            status: 'autorizado'
                        },
                        className: 'Solicitudes'
                    }
                }
            }),
            count: 1,
            order: 'numero_pago',
            include: 'SolicitudPago,SolicitudMonto'
        });

        Rest.peticion(`${config.api_url}/classes/SolicitudesPagos?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.results.length) {
                let currentDebt = this.currentDebt(res.results);
                this.setState({
                    status: currentDebt
                });
            };
        })
    }

    getTotalPayment = (payments) => {
        let debt = 0.0;
        let 
        toDay = new Date();
        toDay.setHours(24);
        toDay.setMinutes(0);
        toDay.setSeconds(0);
        toDay.setMilliseconds(0);
        toDay = toDay.getTime();
        _.each(payments, payment => {
            if (new Date(payment.fecha_pago.iso).getTime() <= toDay) {
                debt += payment.monto;
            } else {
                debt += 0;
            }
        });
        return debt;
    }

    currentDebt = (payments) => {
        let monto_acumulado = payments[0].SolicitudMonto.monto_acumulado || 0.0;

        let debt = this.getTotalPayment(payments);
        const result = debt - monto_acumulado;
        if (result < 0) { // cuando tiene saldo a favor
            return 'al-corriente';        
        } else if (result > 0) { // cuando tiene deuda
            return 'atrasado';
        } else { // cuando no debe nada
            return 'al-corriente';
        }
    }

    render() {
        return (
            <div className="ml-2">
                {this.state.status === 'al-corriente' && <span className="badge badge-success">Pagos: al corriente</span>}
                {this.state.status === 'atrasado' && <span className="badge badge-danger">Pagos: atrasado</span>}
            </div>
        );
    }
}

export default PartnerStatusPaymentBadge;