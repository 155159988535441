import React, { Component } from "react";
import { Link } from "react-router-dom";
import Spinner from "../others/Spinner";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import _ from 'underscore';
import formatString from '../../utilities/format-string';
import TableCountInfo from "../others/TableCountInfo";
import TablePagination from "../others/TablePagination";
import AccountSearch from "../forms/AccountSearchForm";

class AccountsList extends Component {

    constructor (props) {
        super(props);

        this.state = {
            loading: false,
            collection: [],
            index: 0,
            total: 0,
        }
    }

    limit = 5;
    rows = 0;
    where   = {};

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    componentDidMount = () => {
        this.getResource();
    }

    getResource = (index = 0, _where = {}) => {
        
        this.handleSpinner();
        this.where = _where;
        this.setState(({
            index: index
        }));

        let params;
        let where = {};

        where = JSON.stringify(_where);//si aplicamos filtros viene en _where

        params = new URLSearchParams({
            // keys: 'CatalogoCuentas.codigo,CatalogoCuentas.nombre,CatalogoCuentas.descripcion,CatalogoCuentas.id_tipo_cuenta,CatalogoCuentas.descripcion,tipo_Cuenta.value',
            keys: 'codigo,nombre,descripcion,id_tipo_cuenta,tipo_descripcion,Tipo_Cuenta.value',
            limit: this.limit,
            count: 1,
            order: '-createdAt',
            skip: index,
            where,
            include: 'TypesAccounts'

        });

        Rest.peticion(`${config.api_url}/classes/CatalogoCuentas?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {

            this.handleSpinner();
            return res.json();

        })
        .then(res => {
            if (res.code) {
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
            } else {
                // console.log("Respuesta => ", res.results)
                
                this.rows = res.count;
                this.setState({
                    collection: _.map(res.results, (item, index) => { 
                        item.index = index + 1 + parseInt(this.state.index);
                        return item; 
                    }),
                    total: res.count
                });

            }
            
        })
        .catch(error => {
            console.log("Error: ", error)
            this.handleSpinner();
            alert('Ha ocurrido un error inesperado. Intente más tarde.');
            
        });

    }

    //metodo para ejecutar los filtros y busqueda 
    handleFilter = (where) => {
        this.rows = 0;
        this.getResource(0, where);
    }

    setDate = (item) => {
        const d = formatString.setLegibleDate((item.fecha_registro && item.fecha_registro.iso) || item.createdAt);
        return  d;
    }

    render() {
        return(
            <>
                <Spinner loading={this.state.loading}/>

                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    {/* <i className="fas fa-file-contract mr-2"></i> */}
                    <i className="fas fa-clipboard-list mr-2"></i>
                    Lista de Cuentas
                </h3>

                <div className="card">
                    <div className="card-body">

                        <div className="row">
                            <div className="col">
                                <AccountSearch handleFilter={this.handleFilter}/>
                            </div>
                        </div>
                        <br />

                        <TableCountInfo total={this.state.total} />

                        <div className="table-responsive">
                            <table className="table table-hover">

                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Código</th>
                                        <th scope="col">Nombre de cuenta</th>
                                        {/* <th scope="col">Tipo de cuenta</th> */}
                                        <th scope="col">Tipo de cuenta</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Fecha de registro</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        this.state.collection.length ? this.state.collection.map((item, idx) => 
                                            <tr key={idx}>
                                                <td>{item.index}</td>
                                                <td className="td-responsive-text">{item.codigo}</td>
                                                <td className="td-responsive-text">{item.nombre}</td>
                                                {/* <td className="td-responsive-text">{item.tipo_descripcion}</td> */}
                                                <td className="td-responsive-text">{item.Tipo_Cuenta.value}</td>
                                                <td className="td-responsive-text">{item.descripcion}</td>
                                                <td className="td-responsive-text">{this.setDate(item)}</td>
                                                <td className="td-responsive-text">
                                                    <Link to={`/account/${item.objectId}/datos-cuenta`} title="Ver" className="btn btn-link btn-lg">
                                                        <i className="fas fa-chevron-right"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ) :
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="alert alert-warning m-0" role="alert">
                                                    No se hallaron registros
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>

                            </table>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where}/>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default AccountsList;