import React, { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const Signature = (props) => {


    const [imageURL, setImageURL] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [area, setArea] = useState({
        height: window.innerWidth,
        width: window.innerHeight*0.5
    });
    
    let sigCanvas = {};
    
    const clearCanvas = () => {
        sigCanvas.clear();
        setImageURL(null);
        props.handleChange(null);
    }

    const create = () => {
        const URL = sigCanvas.toDataURL("image/png");
        console.log('URL --> ', URL);
        setImageURL(URL);
        setShowModal(!showModal);
        if (props.handleChange) {
            props.handleChange(URL);
        }
    };

    const changeArea = () => {
        // horizontal
        if (window.innerWidth > window.innerHeight) {
            setArea({
                height: window.innerHeight*0.65,
                width: window.innerWidth*0.85
            });
        // vertical
        } else {
            setArea({
                height: window.innerHeight*0.5,
                width: window.innerWidth*0.90
            });
        }
    }

    useEffect(() => {
        changeArea();
        const handleOrientationChange = () => {
          changeArea();
        };
    
        // Agregar el evento orientationchange al cargar el componente
        window.addEventListener('orientationchange', handleOrientationChange);
    
        // Eliminar el evento orientationchange al desmontar el componente
        return () => {
          window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    useEffect(() => {
        if (props.imageUrl !== undefined && imageURL === null) {
            setImageURL( props.imageUrl);
        }
    }, [props.imageUrl])
    
    return (<div className="text-center">
        <div className="border-bottom bg-gray d-block mb-3 d-flex align-items-end justify-content-center"
        onClick={() => setShowModal(!showModal)}
        style={{
            width: 200,
            height: 130,
            backgroundColor: '#F1F1F1',
            backgroundImage: `url("${imageURL}")`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            marginLeft: 'auto',
            marginRight: 'auto',
        }}>
        </div>
        <p className="p-1 mb-2">
            <small className="text-info m-0"><i className="fas fa-info-circle"></i> Haz clic en "Hacer Firma", dibújala y Guarda. Aparecerá en el área gris.</small>
        </p>

        <button type="button" className="btn btn-primary" onClick={() => setShowModal(!showModal)}>Hacer Firma <i className="fas fa-signature"></i></button>
        
        {showModal === true && <div style={styles.modalContainer} className="d-flex flex-column align-items-center justify-content-around">
            <h4>Debuje su firma en el área marcada</h4>
            <SignatureCanvas 
            penColor='black' 
            canvasProps={{height: area.height, width: area.width, className: 'border rounded-lg'}}
            ref={(ref) => { sigCanvas = ref }}/>

            <div className="d-flex justify-content-around w-100">
                <button type="button" className="btn btn-secondary" onClick={clearCanvas}>Borrar <i className="fas fa-eraser"></i></button>
                
                <div>
                    <button type="button" className="btn btn-secondary mr-4" onClick={() => setShowModal(!showModal)}>
                        <span aria-hidden="true">Cancelar</span>
                    </button>
                    
                    <button type="button" className="btn btn-primary" onClick={create}>Guardar</button>
                </div>
            </div>
        </div>}

    </div>)
}

export default Signature;

const styles = {
    modalContainer: {
        height: '100vh',
        width: '100vw',
        backgroundColor: 'white',
        position: 'fixed',
        left: 0, right: 0, top: 0, bottom: 0,
        zIndex: 1198,
    }
}