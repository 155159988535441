import React from 'react';
import _ from 'underscore';
import GroupSearchRequisition from './GroupSearchRequisition';
import Spinner from './../others/Spinner';
import TablePagination from './../others/TablePagination';
import TableCountInfo from './../others/TableCountInfo';
import GroupListAuthorized from './GroupListAuthorized';
import { Link } from "react-router-dom";

class GroupListCheck extends GroupListAuthorized {
    title = 'Verificaciones';

    status = 'autorizado';
    
    order = '-fecha_autorizacion';

    include = 'Grupo,Sucursal';

    columnas = ['#', 'Nombre', 'ID', 'Sucursal', 'Status', 'Autorizado al', 'Verificación'];

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />
                <h3 className="text-uppercase text-pink pt-3 pb-3">
                    <i className="fas fa-tasks mr-2"></i>
                    {this.title}
                </h3>
                
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-8">
                                <GroupSearchRequisition handleFilter={this.handleFilter} />
                            </div>
                        </div>
                        <br />

                        <TableCountInfo total={this.state.total} />

                        <div className="table-responsive">    
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                    <th scope="col">{this.columnas[0]}</th>
                                    <th scope="col">{this.columnas[1]}</th>
                                    <th scope="col">{this.columnas[2]}</th>
                                    <th scope="col">{this.columnas[3]}</th>
                                    <th scope="col">{this.columnas[4]}</th>
                                    <th scope="col">{this.columnas[5]}</th>
                                    <th scope="col">{this.columnas[6]}</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.collection.length && this.state.collection.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.index}</td>
                                                <td className="td-responsive-text">
                                                    {item.Grupo.nombre}
                                                </td>
                                                <td style={{whiteSpace: 'nowrap'}}>
                                                    <code className="text-body">{this.setID(item.Grupo.id_en_sucursal, item.Sucursal)}</code>
                                                </td>
                                                <td>{this.setLugarRegistro(item.Sucursal)}</td>
                                                <td className="text-uppercase">{this.setStatus(item.status)}</td>
                                                <td>{this.setDate(item)}</td>
                                                <td>{item.verificado===true && <span className="text-success">VERIFICADO</span>||<span>PENDIENTE</span>}</td>
                                                <td className="p-1 text-right">
                                                    <Link to={`/verificaciones/${item.objectId}`} title="Ver" className="btn btn-link btn-lg">
                                                        <i className="fas fa-chevron-right"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ) || <tr>
                                            <td colSpan="8" className="text-center">
                                                <div className="alert alert-warning m-0" role="alert">
                                                    No se hallaron registros
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>    
                    
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <TableCountInfo total={this.state.total} />
                            </div>
                            <div className="col-md-6">
                                <TablePagination index={this.state.index} align="end" limit={this.limit} rows={this.rows} getData={this.getResource} where={this.where} />
                            </div>
                        </div>
                    
                    </div>
                </div>

                <div className="p-5"></div>
            </>
        );
    }
}

export default GroupListCheck;