import React, { Component } from "react";
import GroupPaymentsEdit from "./GroupPaymentsEdit";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import _ from 'underscore';

export default class componentName extends Component {

    state = {

    }

    componentDidMount = () => {
        const params = new URLSearchParams({
            include: 'Grupo,montos,Pagos,PagosRealizados,PagosRealizados.registrado_por,PagosRealizados.SolicitudPago,PagosRealizados.SolicitudPago.Socio,PagosRealizados.SolicitudMonto,montos.monto_autorizado,prestamo_personalizado_interes,prestamo_personalizado_cantidad_pagos,prestamo_personalizado_periodicidad,prestamo_personalizado_cantidad_pagos,factor_cobro,prestamo_personalizado,cantidad_pagos_semanales,prestamo_personalizado_cantidad_pagos',
        });
        Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.props.match.params.id_solicitud}?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            console.log(res);
            this.setState(res)
        })
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <label className="text-muted">Grupo</label>
                    <h1>{(this.state.Grupo && this.state.Grupo.nombre) || '[NOMBRE]'}</h1>
                    <p>
                        <label className="text-muted">Ciclo:</label> {this.state.ciclo || '0'}
                    </p>
                    <p>
                        <label className="text-muted">Monto autorizado:</label> {new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(_.reduce(this.state.montos, (memo, monto) => {
                            return memo + monto.monto_autorizado;
                        }, 0))}
                    </p>
                    <hr/>
                    <GroupPaymentsEdit className="table-striped" edit={true} pagosRealizados={this.state.PagosRealizados} />
                </div>
            </div>
        );
    }
}
