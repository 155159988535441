import React from "react";
import AccountRegister from "./AccountRegister";
import Spinner from "../others/Spinner";
import Rest from "../../Server/Rest";
import config from "../../settings/config";
import user from './../../settings/user';
import _ from "underscore";
import Parse from 'parse';
import AccountForm from "./AccountForm";
import textUtils from './../../utilities/format-string';


class AccountEdit extends AccountRegister {

    constructor(props) {
        super(props);
        this.state = this.state_init();
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    state_init = () => {
        this.data = {};
        this.data_original = {};
        this.account_objectId = '';
        this.edit_code_account_exist = false;

        return {
            resetform: false,
            loading: false,
            readOnly: true,
        }
    }

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    //Obtenemos datos de la cuenta para pasarlo al form que vamos a reutilizar
    getAccountById(){

        this.handleSpinner();

        // console.log("Traer la cuenta => ", this.props.match.params.id_account);

        Rest.peticion(`${config.api_url}/classes/CatalogoCuentas/${this.props.match.params.id_account}`,{
            method: 'GET'
        }).then(res => {
            this.handleSpinner();
            return res.json();
        }).then(res => {

            // console.log("Respuestaa Edit => ", res);

            this.data = _.clone(res);
            this.data_original = _.clone(res);
            this.account_objectId = res.objectId;

            
            setTimeout(() => {
                this.setState({
                    resetform: false
                });
            }, 200);

        }).catch(err => {
            this.handleSpinner();
            alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
        })

    }

    UNSAFE_componentWillMount = async () => {
        this.getAccountById();//ejecutamos la funcion antes del montaje
        // console.log("Read Only ", this.state.readOnly)
    }

    handleChangeEdit = (event) => {

        let target = event.target;
        let name = target.name;
        
        this.setState((state) => ({
            [name]: !state[name]
        }));
    }

    setData = (data) => {
        this.data = data;
    }

    handleValidCodeEdit = () => {

        if(!_.isEmpty(this.data.codigo)){

            const params = new URLSearchParams({
                where: JSON.stringify({
                    codigo: this.data.codigo.toUpperCase().trim(),
                    id_tipo_cuenta: this.data.id_tipo_cuenta
                }),
                count: 1,
                limit: 50
            });

            return Rest.peticion(`${config.api_url}/classes/CatalogoCuentas?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {

                // console.log("Validacion de codigo en edit ", res);

                if (res.code) {
                    console.log('Ocurrió un error al comprobar la existencia del código.');
                } else {

                    if (res.count > 0) {
                        
                        //Verificamos que no sea el mismo registro
                        if(res.results[0].objectId !== this.props.match.params.id_account){
                            alert('El código que intenta registrar ya está en uso con el mismo tipo de cuenta.\n Registre una código distinto.');
                            this.edit_code_account_exist = true;
                        }else{
                            this.edit_code_account_exist = false;
                        }

                    } else {
                        this.edit_code_account_exist = false;
                    }

                }
                return res;
            })
            .catch(error => {
                console.log("Error  => ", error);
                alert('Ocurrió un error al comprobar la existencia del código.');
                return error;
            });

        }
    }

    saveAccountHistory = async (_data) => {

        if (!_.isEqual(this.data_original, _data)) {

            let previousData = {};

            _.each(this.data_original, (value, key) => {
                //Si los datos no son iguales, guardamos el valor anterior
                if(!_.isEqual(this.data_original[key], _data[key])) {
                    previousData[key] = value;
                }
            });

            //Recorremos los nuevos datos ara verificar si algun dato anterior era indefinido
            _.each(_data, (value, key) => {
                if (this.data_original[key] === undefined) {
                    previousData[key] = 'SIN DATO PREVIO';
                }
            });

            const currentUser = Parse.User.current().toJSON();

            const body = {
                datos_anteriores: previousData,
                afectado_por: currentUser.nombre+' '+currentUser.apellidos,
                usuario_email: currentUser.email,
                usuario_telefono: currentUser.telefono,
                grupo_datos: 'datos-personales',
                Rol: {
                    __type: 'Pointer',
                    className: 'Roles',
                    objectId: user.rol.objectId
                },
                User: {
                    __type: 'Pointer',
                    className: '_User',
                    objectId: currentUser.objectId
                },
                Account: {
                    __type: 'Pointer',
                    className: 'CatalogoCuentas',
                    objectId: this.account_objectId
                }
            }

            return Rest.peticion(`${config.api_url}/classes/AccountHistorial`, {
                method: 'POST',
                body: JSON.stringify(body)
            }).then(res => {
                return res.json()
            }).then(res => {
                return res
            }).catch(error => {
                return error}
            );

        } else {
            return false;
        }

    }

    handleSubmit = async (event) => {

        if (event) event.preventDefault();

        if(this.data.codigo === "" || this.data.nombre === "" || this.data.descripcion === "" || this.data.tipo === "") {
            alert('Todos los campos son requeridos.');
            return;
        }

        const resp = window.confirm('¿Todos los datos son correctos?');

        if(resp === true) {

        
            await this.handleValidCodeEdit();

            if (this.edit_code_account_exist === true) {
                return;
            }

            this.convertToUpper();//funcion del componente padre (AccountRegister), de este extiende el componente de edit

            let data = _.clone(this.data);

            // data.tipo_descripcion = this.getAccountTypeDescription(data.tipo);

            data.Tipo_Cuenta = {
                __type: 'Pointer',
                className: 'TypesAccounts',
                objectId: this.data.id_tipo_cuenta
            };

            let id_busqueda = textUtils.setPlainText(this.data.codigo+this.data.nombre+this.data.descripcion)
            data.id_busqueda = id_busqueda.toUpperCase();

            
            this.handleSpinner();
            
            const newData = _.omit(data, 'registeredBy','createdAt','updatedAt','objectId');
            this.data_original  =  _.omit(this.data_original, 'registeredBy','createdAt','updatedAt','objectId');

            // console.log("Actualizar a => ", newData);

            //Guardadmos el historial
            await this.saveAccountHistory(newData);

            Rest.peticion(`${config.api_url}/classes/CatalogoCuentas/${this.props.match.params.id_account}`, {
                method: 'PUT',
                body: JSON.stringify(newData)
            }).then(res => {

                this.handleSpinner();
                return res.json();

            }).then(async res => {

                // console.log("Respuesta al editar => ", res);
                

                if (res.code || res.error) {
                    alert('No se ha podido actualizar los datos. Intente más tarde.');
                    return res;

                } else {
                    
                    alert('La cuenta ha sido actualizada exitosamente.');
                    this.setState(this.state_init());

                    this.getAccountById();
                    return res;
                }

            }).catch(error => {
                console.log(error);
                alert('No se ha podido actualizar los datos de la cuenta. Intente más tarde.');
                this.handleSpinner();
            });

        }

    }
    
    render() {

        return(
            <>
                <Spinner loading={this.state.loading}/>
                
                <div className="row">
                    <div className="col-8">
                        {!this.state.readOnly && <p className="text-info">
                            Todos los campos son obligatorios.
                        </p>}
                    </div>

                    <div className="text-right col-4 mb-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitch1" value={!this.state.readOnly} checked={!this.state.readOnly} name="readOnly" onChange={this.handleChangeEdit} />
                            <label className="custom-control-label text-primary" htmlFor="customSwitch1">Editar</label>
                        </div>
                    </div>
                </div>

                <form onSubmit={this.handleSubmit} autoComplete="off">

                    <AccountForm 
                        required={true} 
                        readOnly={this.state.readOnly} 
                        resetform={this.state.resetform}
                        data={this.data}
                        setData={this.setData}
                        // handleSpinner={this.handleSpinner} 
                        getAccountById={this.getAccountById}
                        id_account={this.props.match.params.id_account}
                    />
                    
                    {!this.state.readOnly && <>
                        <button type="submit" className="btn btn-primary btn-lg">Actualizar</button>
                    </>}
                </form>
            </>
        )
    }

}

export default AccountEdit;