import React, { Component } from 'react';
import PartnerList from './PartnerList';
import { Link } from "react-router-dom";
import TextPhone from './../others/TextPhone';
class PartnerListModeration extends PartnerList {
    title   = 'Lista de clientas para validación';
    setUrl = (item) => {
        return <Link to={`/validacion/${item.objectId}/${item.Socio.objectId}/${item.Socio.id_en_sucursal}/datos-personales`} title="Ver" className="btn btn-link btn-lg">
            <i className="fas fa-chevron-right"></i>
        </Link>
    }
    setPartnerCard = () => {
        
        return this.state.collection.length && this.state.collection.map((item, index) => 
        <div key={index} className="card position-relative mb-4 mt-3"> 
            <div className="card-body bg-light">
                <span className="badge badge-secondary list-partner-item-counter">{item.index}</span>
                <div className="p-1 container">
                    <div className="row">
                        <div className="col">
                            <h5 className="font-weight-bold">
                                {item.Socio.nombre +' '+ item.Socio.apellido_paterno +' '+ item.Socio.apellido_materno }
                            </h5>
                        </div>
                        <div className="col-5 text-right">
                            <span className="text-muted">ID:</span> {this.setID(item.Socio.id_en_sucursal, item.Socio.lugar_registro)}
                        </div>
                    </div>
                </div>
                <table className="table table-borderless">               
                    <tbody>
                        <tr>
                        <td className="p-1 text-muted">Teléfono:</td>
                        <td className="p-1 text-right"><TextPhone phone={item.Socio.telefono} /></td>
                        </tr>
                        <tr>
                        <td className="p-1 text-muted">Lugar de registro:</td>
                        <td className="p-1 text-right">{this.setLugarRegistro(item.Socio.lugar_registro)}</td>
                        </tr>
                        <tr>
                        <td className="p-1 text-muted">Status:</td>
                        <td className="p-1 text-right">{this.establecerStatus(item.Socio.status)}</td>
                        </tr>
                        <tr>
                        <td className="p-1 text-muted">Fecha de registro:</td>
                        <td className="p-1 text-right">{this.setDate((item.Socio.fecha_registro && item.Socio.fecha_registro.iso)||item.Socio.createdAt)}</td>
                        </tr>
                    </tbody>
                </table>
                <Link to={`/validacion/${item.objectId}/${item.Socio.objectId}/${item.Socio.id_en_sucursal}/datos-personales`} title="Ver" className="btn btn-secondary btn-lg btn-block">
                    Ver detalles
                </Link>
            </div>
        </div>
        
    ) || <div className="alert alert-warning m-0" role="alert">
        No se hallaron registros
    </div>}

    setHead = <h3 className="text-uppercase text-pink pt-3 pb-3 d-flex">
        <i className="fas fa-address-book d-flex align-items-start mr-2">
            <small><small><i className="fas fa-question"></i></small></small>
        </i>
        {this.title}
    </h3>
}

export default PartnerListModeration;