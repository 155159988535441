import React, { Component } from 'react';
import config from './../../settings/config';
import Spinner from './../others/Spinner';
import _ from 'underscore';
import InputFile from '../forms/InputFile';
import $ from 'jquery';
import Rest from './../../Server/Rest';
import user from './../../settings/user';
import formatString from '../../utilities/format-string';

class PartnerBuro extends Component {
    constructor (props) {
        super(props);

        this.state = {
            archivos: [],
            loading: false
        }

        this.fileInput              = React.createRef();
        this.nota                   = React.createRef();
    }

    socio_objectId = '';

    collapseForm = () => {
        $('.collapse').collapse('toggle');
    }

    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    handleChangeBtnSubmit = () => {
        let btn_submit = document.getElementsByClassName('btns-form-submit');

        btn_submit[0].disabled = !btn_submit[0].disabled;

        if (btn_submit[0].disabled === true) {
            btn_submit[0].innerHTML = 'Guardando...';
        } else {
            btn_submit[0].innerHTML = 'Guardar';
        }
    }

    UNSAFE_componentWillMount = () => {
        this.obtenerArchivos();
    }

    // dar formato a fecha
    setDate = (fecha) => {
        const d = new Date(fecha);
        return  formatString.setLegibleDate(d.toISOString());
    }

    obtenerArchivos = async () => {
    
        this.handleSpinner();

        const socio = await Rest.peticion(`${config.api_url}/classes/SociosDiccionario/${this.props.match.params.id_diccionario}`, {
            method: 'GET'
        }).then(res => {return res.json()}).then(res => {return res;}).catch(error => {return error});

        if (_.isEmpty(socio.code)) {
            
            if (socio.en_moderacion === true && !(user.rol.permisos.Validacion && user.rol.permisos.Validacion.editar === true)) {
                alert('No tienes accesos a estos datos.');
                window.location = '/escritorio';
                return false;
            }

            this.socio_objectId = socio.Socio.objectId;
            const params = new URLSearchParams({
                where: JSON.stringify({
                    Socio: {
                        __type: 'Pointer',
                        className: 'Socio',
                        objectId: socio.Socio.objectId
                    }
                }),
                limit: 1000,
                count: 1,
                order: '-createdAt'
            });
            Rest.peticion(`${config.api_url}/classes/Buro?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {

                this.handleSpinner();
                return res.json();

            })
            .then(res => {
                if (res.code) {
                    alert('Ha ocurrido un error inesperado. Intente más tarde.');
                } else {
                    this.setState({
                        archivos: res.results
                    });
                    
                }
                
            })
            .catch(error => {
                this.handleSpinner();
                alert('Ha ocurrido un error inesperado. Intente más tarde.');
                
            });
        } else {
            alert('No se ha podido obtener los archivos de buró');
        }
        
        
    }

    uploadFile = (file) => {
        
        var formData = new FormData();

        formData.append('file', this.fileInput.current.files[0]);
        formData.append('socio_id', this.socio_objectId);

        return fetch(`${config.domain}/upload_file.php`, {
            method: 'POST',
            body: formData,
        }).then(res => {
            return res.json()
        }).catch(error => {
            alert('Ocurrió un error al intentar subir el archivo. Intente más tarde o contacte a soporte.');
        })
    }
    
    saveBuroFile = (pdf) => {

        let nota = this.nota.current.value || '';
        nota = nota.toUpperCase();

        let data = {
            Socio: {
                __type: 'Pointer',
                className: 'Socio',
                objectId: this.socio_objectId
            },
            URL: pdf.URL,
            nombre: pdf.filename,
            nota: nota
        }
        
        return Rest.peticion(`${config.api_url}/classes/Buro`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(res => {
            return res.json();
        }).then(res => {
            let _res = _.extend(data, res);
            return _res;
        })
        .catch(error => {
            alert('Ocurrió un error al intentar subir el archivo. Intente más tarde o contacte a soporte.');
        })
    }

    handleSubmit = async (event) => {

        if (event) event.preventDefault();

        this.handleSpinner();
        this.handleChangeBtnSubmit();

        let file = false;
        let buro = false;

        let data = {};
        
        if (!_.isEmpty(this.fileInput.current.value)) {
            file = await this.uploadFile();
            if (file) {
                buro = await this.saveBuroFile(file);

                data.Buro = {
                    __op:'AddRelation',
                    objects: [{
                        __type: 'Pointer',
                        className: 'Buro',
                        objectId: this.props.match.params.id_diccionario
                    }]
                }
            }
        }

        if (!_.isEmpty(data)) {
            await Rest.peticion(`${config.api_url}/classes/Socios/${this.socio_objectId}`, {
                method: 'PUT',
                body: JSON.stringify(data),
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (res.code) {
                    alert('Lo sentimos, no se ha podido cargar el archivo.');
                } else {
                    alert('Se ha cargado un nuevo archivo.');
                }

                let archivos = this.state.archivos;
                
                archivos.unshift(buro);
                
                this.setState({
                    archivos: archivos,
                    resetform: true
                });
            })
            .catch(error => {
                alert('Lo sentimos, no se ha podido cargar el archivo.');
            });
        }

        this.handleSpinner();
        this.handleChangeBtnSubmit();
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />

                {(user.rol.permisos.Socios && user.rol.permisos.Socios.editar === true) &&
                <button type="button" className="btn btn-primary mb-3" onClick={() => this.collapseForm()} aria-expanded="false" aria-controls="collapseExample">
                    <i className="fas fa-cloud-upload-alt"></i> Cargar buró
                </button>}

                <form onSubmit={this.handleSubmit} className="collapse mb-5" id="collapseExample">
                    <fieldset className="card mb-3">
                        <div className="card-body">
                        <button type="button" onClick={() => this.collapseForm()} className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                            <legend className="card-title">Buró de crédito</legend>
                            <p className="text-info">
                                Suba el buró de la clienta en formato PDF. De lo contrario, suba una captura de pantalla que refiera la problemática y escriba una nota.
                            </p>
                            <InputFile fileInput={this.fileInput} nota={this.nota} resetform={this.state.resetform} required={true} />
                            <br/>
                            <button type="submit" className="btn btn-primary btn-lg mr-2 btns-form-submit" data-loading-text="Loading...">
                                Guardar
                            </button>     
                        </div>
                    
                    </fieldset>
                </form>

                <div className="table-responsive">
                    <table className="table table table-hover">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Nota</th>
                            <th scope="col">Subido al</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.archivos.length && this.state.archivos.map((archivo, index) =>
                                    <tr key={index}>
                                        <th scope="row">{index+1}</th>
                                        <td>
                                            <a href={`${config.domain}${archivo.URL}`} title="Abrir" target="_blank">
                                                {archivo.nombre}
                                            </a>
                                            
                                            
                                        </td>
                                        <td>{archivo.nota}</td>
                                        <td>{this.setDate(archivo.createdAt)}</td>
                                    </tr>
                                ) ||
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        <div className="alert alert-warning m-0" role="alert">
                                            No se hallaron registros
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default PartnerBuro;